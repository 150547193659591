/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import PlusIcon from "../../../../assets/image/add.svg";
import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { TextButtonNoColor } from "../../../Buttons/Buttons";
import { addTags } from "../../../../actions/menu";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../../../assets/image/right.svg";
import { useSelector } from "react-redux";
import TagsTextFields from "./TextField/Tags";
export default function CustomizedHook(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  let languageReducer = useSelector((state) => state?.languageReducer);

  const [values, setValues] = useState([]);
  const [opens, setOpens] = useState(false);

  useEffect(() => {
    setValues([...props?.oldTags]);
    // eslint-disable-next-line
  }, [props, props?.oldTags]);

  const handleClick = (e, value, reason) => {};

  const handleChangeEdit = (e, tag) => {
    e.stopPropagation();
    props.handleEditTagsModal(tag);
  };

  const handleChangeTags=(e,index)=>{
    let updatedTag=props?.oldTags[index]
    updatedTag={...updatedTag,translations:[{
      text: e.target.value,
      field: "name",
      language: languageReducer?.menuManagerLanguage}]
    }

    let allValues=[...props?.oldTags];
    allValues[index]={...updatedTag}
    console.log("updated",updatedTag)
  props.setTagsToItem(allValues)
// console.log("e",e.target.value,index)
  }
  return (
    <div>
      <div>
      {props?.oldTags&&props?.oldTags.length>0&&<Typography
          style={{
            fontFamily: theme.fontFamily,
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "18px",
            color: "#303A5F",
            marginBottom: "10px",
          }}
        >
          {intl.formatMessage({
            id: "Mark_item_as",
          })}
        </Typography>}
        {props?.oldTags &&
          props?.oldTags.map((item, index) => {
            return<> 
            <TagsTextFields data={item} index={index} handleChangeTags={handleChangeTags}/>
              {/* // <Grid container key={index}>
              //   <Grid
              //     item
              //     style={{
              //       display: "flex",
              //       alignItems: "center",
              //       width: "28px",
              //       justifyContent: "center",
              //       marginBottom: "10px",
              //       backgroundColor:item?.colour?.bg_hex,
              //       borderRadius:"5px",
              //       marginRight:"10px"
              //     }}
              //   >
              //     <img
              //       src={item?.image?.image}
              //       style={{
              //         marginLeft: "5px",
              //         marginRight: "5px",
              //         height: "20px",
              //         width: "20px",
              //         background: "",
              //       }}
              //     />
              //   </Grid>
              //   <Grid item style={{ flexGrow: 1 }}>
              //     <TextField
              //       variant="outlined"
              //       placeholder={intl.formatMessage({
              //         id: "Item_name",
              //       })}
              //       disabled={true}
              //       fullWidth
              //       // error={itemDetailsErrors?.name}
              //       // helperText={
              //       //   itemDetailsErrors?.name &&
              //       //   intl.formatMessage({
              //       //     id: "Item_Name_is_required",
              //       //   })
              //       // }
              //       value={item?.name}
              //       style={{
              //         borderRadius: "5px",
              //         width: "100%",
              //       }}
              //       inputProps={{
              //         className: classes.TextInputStyle,
              //         "aria-label": "search",
              //       }}
              //       InputProps={{
              //         classes: {
              //           notchedOutline: classes.outlinedStyle,
              //         },
              //       }}
              //     />
              //   </Grid>
              //   <Grid
              //     item
              //     style={{
              //       display: "flex",
              //       alignItems: "center",
              //       width: "28px",
              //       justifyContent: "center",
              //       marginBottom: "10px",
              //     }}
              //   >
              //     <RightIcon />
              //   </Grid>
              //   <Grid item style={{ flexGrow: 1 }}>
              //     <TextField
              //       variant="outlined"
              //       placeholder={intl.formatMessage({
              //         id: "Item_name",
              //       })}
              //       onChange={(e) => handleChangeTags(e,index)}

              //       fullWidth
              //       // error={itemDetailsErrors?.name}
              //       // helperText={
              //       //   itemDetailsErrors?.name &&
              //       //   intl.formatMessage({
              //       //     id: "Item_Name_is_required",
              //       //   })
              //       // }
              //       // onChange={(e) => handleChangeDetails(e, "name")}
              //       value={item?.translations[0]?.text}
              //       style={{
              //         borderRadius: "5px",
              //         width: "100%",
              //       }}
              //       inputProps={{
              //         className: classes.TextInputStyle,
              //         "aria-label": "search",
              //       }}
              //       InputProps={{
              //         classes: {
              //           notchedOutline: classes.outlinedStyle,
              //         },
              //       }}
              //     />
              //   </Grid>
              // </Grid> */}
            </>;
          })}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));
