import React from "react";
import { useEffect, useState, useRef } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import beep from "../assets/sound/beep.mp3";
import incomingBeep from "../assets/sound/incomingBeep.mp3";
import VerifyTabDialog from "../Components/DialogBox/VerifyTabDialog";
import ConfirmVerificationTabDialog from "../Components/DialogBox/ConfirmVerificationTabDialog";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import Swing from "react-swing";
import { withStyles } from "@material-ui/core/styles";
import useSound from "use-sound";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import {
  updateReviewOrder,
  removeOrderList,
  dismissOrder,
  dismissRequest,
  getVenueOrder
} from "../actions/order";
import RejectDialog from "../Components/DialogBox/RejectDialog";
import ExpandIcon from "../assets/image/Line.png";
import PrintIcon from "../assets/image/print.png";
import PrintIconDisable from "../assets/image/disableprint.png";
import {
  Grid,
  Card,
  Divider,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core";
import TimeCounter from "./TimeCounter/TimeCounter";
import HourglassFullTwoToneIcon from "@material-ui/icons/HourglassFullTwoTone";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import TextField from "@material-ui/core/TextField";
import TableNumber from "./OrderCard";
import "../style/home.css";
import { requestColor, paidColor, unpaidColor } from "../constant/colors";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../constant/config";
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#EDF0F4",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "transparent",
    border: "  1px solid #EDF0F4",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
    "&:focus": {
      backgroundColor: "#E5E5E5",
      color: "#34CEB2",
      border: "  1px solid #34CEB2",
    },
  },
}))(Button);

const RejectButton = withStyles((theme) => ({
  root: {
    color: "#EDF0F4",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",

    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "transparent",
    border: "  1px solid #EDF0F4",

    "&:hover": {
      backgroundColor: "#FF3366",
      border: "  1px solid #FF3366",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  requestTitle: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",

    fontSize: "40px",
    lineHeight: "54px",
    color: "#FFFFFF",
  },
  requestType: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#34CEB2",
  },

  root: {
    padding: "0px",
  },
  TabName: {
    fontFamily: theme.fontFamily,

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#A3A9BC",
    margin: "10px 16px",
    textTransform: "uppercase",
  },
  noOrder: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",

    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },

  OrderGridInner: {
    padding: "30px 0px 20px 30px",
  },
  gridList: {
    width: 320,
    height: "100vh",
  },
  cardList: {
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "20px",
  },
  cardStyle: {
    marginTop: "28px",
    boxShadow: "none",
    minHeight: "145px",

    // border: "1px solid #EDF0F4",

    borderRadius: "5px",
  },
  tableNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",
    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  Accepted: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "2px  0px 2px 0px",
    margin: "auto",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  Rejected: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "2px  0px 2px 0px",
    margin: "auto",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  tickIcon: {
    color: "#FFFFFF",
    margin: "4px  0px 3px 3px",
    height: 17,
    width: 20,
  },
  crossIcon: {
    color: "#FFFFFF",
    margin: "3px  0px 3px 3px",
    height: 20,
    width: 20,
  },

  TableDigit: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",
    fontSize: "36px",
    lineHeight: "18px",
    padding: " 0px",
    margin: "20px 0px 20px 10px",
    color: paidColor,
  },
  orderIdCard: {
    borderRadius: "5px",

    margin: "11px 0px 20px 13px",
    boxShadow: "none",
  },
  orderId: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "5px 5px 5px 5px",
  },
  time: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    textTransform: "uppercase",
    margin: "0px",
    color: theme.colors.primary,
    padding: "10px  0px 16px 0px",
  },
  loadingIcon: {
    width: "20px",
    height: "20px",
    padding: "15px  5px 0px 3px",
    color: theme.colors.primary,
  },
  SelectedOrder: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    margin: "0px",
    color: "#EDF0F4",
    paddingTop: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  printButton: {
    textTransform: "none",
    background: paidColor,
    marginBottom: "22px",
    color: "#FFFFFF",
  },
  disablePrintButton: {
    textTransform: "none",
    background: theme.colors.primary,
    marginBottom: "22px",
    color: "#44507B",
    border: "1px solid #44507B",
    boxShadow: "none",
    "&:hover": {
      background: theme.colors.primary,
      boxShadow: "none",
    },
  },
  FullScreenIcon: {
    // width: "25px",
    height: "20px",
    color: "#FFFFFF",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    border: "0px",
    padding: "12px",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    margin: "0px",
  },
  dishName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: paidColor,
  },
  dishPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#EDF0F4",
  },
  DishListItem: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#EDF0F4",
  },
  OrderListFooter: {
    marginTop: "10px",
  },
  FotterPrices: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#EDF0F4",
    margin: "10px 0px",
  },
  Subtotal: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    color: paidColor,
    margin: "10px 0px 0px 0px",
  },
}));
let wakeLock = null;

function IncomingOrders(props) {
  const theme = useTheme();
  const intl = useIntl();

  let dispatch = useDispatch();
  const [timeCounter, settimeCounter] = useState(false);
  const [cardClicked, setcardClicked] = useState("");
  const classes = useStyles();
  const [Loader, setLoader] = useState(true);
  const [verifyDialog, setVerifyDialog] = useState(false);
  const [open, setOpen] = useState(false);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let VenueOrderReducer = useSelector((state) => state.VenueOrderReducer);
  const [orderReceivedBeep] = useSound(beep);
  const [orderIncomingBeep] = useSound(incomingBeep);
  let reviewOrderList = useSelector(
    (state) => state.VenueOrderReducer.reviewOrderList
  );
  let orderCount = useSelector((state) => state.VenueOrderReducer.orderCount);
  let lastCount = useSelector((state) => state.VenueOrderReducer.lastCount);

  let tempReviewOrderList = useSelector(
    (state) => state.VenueOrderReducer.tempReviewOrderList
  );
  const [OrderSelected, setOrderSelected] = useState(null);
  const [submittedOrder, setSubmittedOrder] = useState([]);
  const componentRef = useRef();
  const [tempOrderCount, setTempOrderCount] = useState(
    orderCount ? orderCount : null
  );
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // The wake lock sentinel.

  useEffect(() => {
    if (OrderSelected && OrderSelected.status === 2) {
      const timer = setTimeout(() => {
        orderReceivedBeep();
      }, 30000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [OrderSelected, OrderSelected?.status]);

  useEffect(() => {
    if (orderCount > lastCount) {
      orderIncomingBeep();
    }
    // eslint-disable-next-line
  }, [orderCount]);

  const handleClose = async (order, closeType) => {
    if (closeType === "request") {
      await dispatch(
        dismissRequest({
          venue_uuid: venueDetail && venueDetail.uuid,
          Authorization: localStorage.AuthUserKey,
          order_uuid: order.id,
          remove: {
            order: venueDetail?.auto_accept_orders
              ? submittedOrder?.filter((item) => item?.id !== order?.id)
              : tempReviewOrderList.filter((item) => item !== order),
            autoSave: venueDetail?.auto_accept_orders,
          },
        })
      );

      await dispatch(
        removeOrderList({
          order: venueDetail?.auto_accept_orders
            ? submittedOrder?.filter((item) => item?.id !== order?.id)
            : tempReviewOrderList.filter((item) => item !== order),
          autoSave: venueDetail?.auto_accept_orders,
        })
      );
      if (OrderSelected?.id === order?.id) {
        setOrderSelected(null);
      }
    } else if (venueDetail?.auto_accept_orders) {
      if (order?.status !== 2) {
        await dispatch(
          dismissOrder({
            venue_uuid: venueDetail && venueDetail.uuid,
            Authorization: localStorage.AuthUserKey,
            order_uuid: order.order_uuid,
            remove: {
              order: venueDetail?.auto_accept_orders
                ? submittedOrder?.filter(
                    (item) => item?.order_uuid !== order?.order_uuid
                  )
                : tempReviewOrderList.filter((item) => item !== order),
              autoSave: venueDetail?.auto_accept_orders,
            },
          })
        );

        await dispatch(
          removeOrderList({
            order: venueDetail?.auto_accept_orders
              ? submittedOrder?.filter(
                  (item) => item?.order_uuid !== order?.order_uuid
                )
              : tempReviewOrderList.filter((item) => item !== order),
            autoSave: venueDetail?.auto_accept_orders,
          })
        );
        if (OrderSelected?.order_uuid === order?.order_uuid) {
          setOrderSelected(null);
        }
      }
    } else {
      await dispatch(
        removeOrderList({
          order: venueDetail?.auto_accept_orders
            ? submittedOrder?.filter(
                (item) => item?.order_uuid !== order?.order_uuid
              )
            : tempReviewOrderList.filter((item) => item !== order),
          autoSave: venueDetail?.auto_accept_orders,
        })
      );

      if (OrderSelected?.order_uuid === order?.order_uuid) {
        setOrderSelected(null);
      }
    }
    await dispatch(
      getVenueOrder({
        venue_uuid: venueDetail?.uuid,
        Authorization: localStorage.AuthUserKey,
      })
    );
  };

  const changeStatus = () => {
    dispatch(
      updateReviewOrder({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        Status: "3",
        order_uuid: OrderSelected.order_uuid,
      })
    );
  };

  useEffect(() => {
    setLoader(!Loader);

    setSubmittedOrder([...VenueOrderReducer.submittedOrder]);

    // eslint-disable-next-line
  }, [VenueOrderReducer?.submittedOrder]);

  useEffect(() => {
    const timer = setTimeout(() => {
      settimeCounter(true);
    }, 1000);
    settimeCounter(false);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [timeCounter]);

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid
          item
          className={"cardGrid"}
          xs={4}
          style={{ height: props.fullScreenView ? "98vh" : "83vh" }}
        >
          <Grid className={classes.cardList}>
            <p className={classes.pendingOrder}>
              {intl.formatMessage({
                id: "Pending_orders",
              })}
            </p>
            <SwipeableList>
              {submittedOrder &&
                submittedOrder
                  .slice(0)
                  // .reverse()
                  .map((order) => (
                    <>
                      {order?.order_uuid ? (
                        <SwipeableListItem
                          swipeLeft={{
                            content: (
                              <div
                                style={{
                                  color: theme.colors.primary,
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {intl.formatMessage({
                                  id: "Order_is_going_to_removed",
                                })}
                              </div>
                            ),
                            action: () => handleClose(order, "order"),
                          }}
                        >
                          <a style={{ width: "100%" }}>
                            <Card
                              onClick={() => {
                                setLoader(false);
                                if (order.tab_verified === false) {
                                  setOrderSelected(null);
                                  setcardClicked(order);
                                  setVerifyDialog(true);
                                } else if (
                                  order?.request_type === 2 &&
                                  order?.tab?.verified !== true
                                ) {
                                  setOrderSelected(null);
                                  setcardClicked(order);
                                  setVerifyDialog(true);
                                } else {
                                  setOrderSelected(order);
                                  setcardClicked(order.order_uuid);
                                }
                              }}
                              className={classes.cardStyle}
                              style={{
                                backgroundColor:
                                  cardClicked === order.order_uuid
                                    ? order.payment_method === "card"
                                      ? paidColor
                                      : unpaidColor
                                    : "rgba(72, 83, 121, 0.1)",
                                cursor: "pointer",
                              }}
                            >
                              <Grid container justify="space-between">
                                <TableNumber
                                  table_number={order?.table_number}
                                  cardClicked={cardClicked}
                                  payment_method={order?.payment_method}
                                  order_uuid={order?.order_uuid}
                                  request={false}
                                />

                                <Grid item>
                                  {order.status === 3 ||
                                  (OrderSelected &&
                                    OrderSelected.order_uuid ===
                                      order.order_uuid &&
                                    OrderSelected.status === 3) ||
                                  order.status === 4 ||
                                  (OrderSelected &&
                                    OrderSelected.order_uuid ===
                                      order.order_uuid &&
                                    OrderSelected.status === 4) ? (
                                    <Card
                                      style={{
                                        background:
                                          order.status === 3 ||
                                          (OrderSelected &&
                                            OrderSelected.order_uuid ===
                                              order.order_uuid &&
                                            OrderSelected.status === 3)
                                            ? ////here accepted color
                                              "rgba(48, 58, 95, 0.2)"
                                            : "#FF3366",
                                        padding: "3px 3px 3px 9px",
                                        margin: "12px 12px 0px 12px",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <Grid container>
                                        {order.status === 3 ||
                                        (OrderSelected &&
                                          OrderSelected.order_uuid ===
                                            order.order_uuid &&
                                          OrderSelected.status === 3) ? (
                                          <>
                                            <p className={classes.Accepted}>
                                              {intl.formatMessage({
                                                id: "ACCEPTED",
                                              })}
                                            </p>

                                            <CheckRoundedIcon
                                              className={classes.tickIcon}
                                            />
                                          </>
                                        ) : order.status === 4 ||
                                          (OrderSelected &&
                                            OrderSelected.order_uuid ===
                                              order.order_uuid &&
                                            OrderSelected.status === 4) ? (
                                          <>
                                            <p className={classes.Rejected}>
                                              {intl.formatMessage({
                                                id: "Rejected",
                                              })}
                                            </p>
                                            <ClearIcon
                                              className={classes.crossIcon}
                                              style={{
                                                color:
                                                  cardClicked ===
                                                  order.order_uuid
                                                    ? "#FFFFFF"
                                                    : "#FFFFFF",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              className={classes.time}
                                              style={{
                                                color:
                                                  cardClicked ===
                                                  order.order_uuid
                                                    ? "#FFFFFF "
                                                    : theme.colors.primary,
                                              }}
                                            >
                                              {timeCounter ? (
                                                <TimeCounter
                                                  submitted_for_review={
                                                    order &&
                                                    order.submitted_for_review
                                                  }
                                                  timeCounter={timeCounter}
                                                  settimeCounter={
                                                    settimeCounter
                                                  }
                                                />
                                              ) : (
                                                <TimeCounter
                                                  submitted_for_review={
                                                    order &&
                                                    order.submitted_for_review
                                                  }
                                                  timeCounter={timeCounter}
                                                  settimeCounter={
                                                    settimeCounter
                                                  }
                                                />
                                              )}
                                            </p>
                                            <HourglassFullTwoToneIcon
                                              className={classes.loadingIcon}
                                              style={{
                                                color:
                                                  cardClicked ===
                                                  order.order_uuid
                                                    ? "#FFFFFF "
                                                    : theme.colors.primary,
                                              }}
                                            />
                                          </>
                                        )}
                                      </Grid>
                                    </Card>
                                  ) : (
                                    <Grid container>
                                      <>
                                        <div
                                          className={classes.time}
                                          style={{
                                            color:
                                              cardClicked === order.order_uuid
                                                ? "#FFFFFF "
                                                : theme.colors.primary,
                                          }}
                                        >
                                          {timeCounter ? (
                                            <TimeCounter
                                              submitted_for_review={
                                                order &&
                                                order.submitted_for_review
                                              }
                                              timeCounter={timeCounter}
                                              settimeCounter={settimeCounter}
                                            />
                                          ) : (
                                            <TimeCounter
                                              submitted_for_review={
                                                order &&
                                                order.submitted_for_review
                                              }
                                              timeCounter={timeCounter}
                                              settimeCounter={settimeCounter}
                                            />
                                          )}
                                        </div>
                                        <HourglassFullTwoToneIcon
                                          className={classes.loadingIcon}
                                          style={{
                                            color:
                                              cardClicked === order.order_uuid
                                                ? "#FFFFFF "
                                                : theme.colors.primary,
                                          }}
                                        />
                                      </>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid>
                                <Card
                                  className={classes.orderIdCard}
                                  style={{
                                    backgroundColor:
                                      order.payment_method === "card"
                                        ? cardClicked === order.order_uuid
                                          ? "#C5524E"
                                          : paidColor
                                        : cardClicked === order.order_uuid
                                        ? "rgba(0, 0, 0, 0.2)"
                                        : unpaidColor,
                                    width:
                                      order.payment_method === "card"
                                        ? "35px"
                                        : "60px",
                                  }}
                                >
                                  <div
                                    className={classes.orderId}
                                    style={{
                                      color: "#FFFFFF ",
                                    }}
                                  >
                                    {order.tab_uuid
                                      ? order.payment_method === "card"
                                        ? intl.formatMessage({
                                            id: "Paid",
                                          })
                                        : intl.formatMessage({
                                            id: "Not_paid",
                                          })
                                      : order.payment_method === "card"
                                      ? intl.formatMessage({
                                          id: "Paid",
                                        })
                                      : intl.formatMessage({
                                          id: "Not_paid",
                                        })}
                                  </div>
                                </Card>
                              </Grid>
                              <p
                                className={classes.TabName}
                                style={{
                                  color:
                                    cardClicked === order.order_uuid
                                      ? "#FFFFFF"
                                      : theme.colors.primary,
                                }}
                              >
                                {order && order.tab_name ? order.tab_name : ""}
                              </p>
                            </Card>
                          </a>
                        </SwipeableListItem>
                      ) : (
                        <SwipeableListItem
                          swipeLeft={{
                            content: (
                              <div
                                style={{
                                  color: theme.colors.primary,
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                  paddingRight: "5px",
                                }}
                              >
                                {intl.formatMessage({
                                  id: "Order_is_going_to_removed",
                                })}
                              </div>
                            ),
                            action: () => handleClose(order, "request"),
                          }}
                        >
                          <a style={{ width: "100%" }}>
                            <Card
                              onClick={() => {
                                setLoader(false);
                                if (order.tab_verified === false) {
                                  setOrderSelected(null);
                                  setcardClicked(order);
                                  setVerifyDialog(true);
                                } else if (
                                  order?.request_type === 2 &&
                                  order?.tab?.verified !== true
                                ) {
                                  setOrderSelected(null);
                                  setcardClicked(order);
                                  setVerifyDialog(true);
                                } else {
                                  setOrderSelected(order);
                                  setcardClicked(order.id);
                                }
                              }}
                              className={classes.cardStyle}
                              style={{
                                backgroundColor:
                                  cardClicked === order.id
                                    ? "#34CEB2"
                                    : "rgba(72, 83, 121, 0.1)",
                                cursor: "pointer",
                              }}
                            >
                              {/* {console.log(
                                "cardClicked",
                                cardClicked,
                                order.id
                              )} */}
                              <Grid container justify="space-between">
                                <TableNumber
                                  table_number={
                                    order?.table_number
                                      ? order?.table_number
                                      : order?.tab?.table_number
                                  }
                                  cardClicked={cardClicked}
                                  payment_method={order?.payment_method}
                                  order_uuid={order?.id}
                                  request={true}
                                />

                                <Grid item>
                                  {OrderSelected &&
                                  OrderSelected.id === order.id ? (
                                    <Card
                                      style={{
                                        background:
                                          order.status === 3 ||
                                          (OrderSelected &&
                                            OrderSelected.id === order.id &&
                                            OrderSelected.status === 3)
                                            ? ////here accepted color
                                              "rgba(48, 58, 95, 0.2)"
                                            : "#34CEB2",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <Grid container>
                                        {order.status === 3 ||
                                        (OrderSelected &&
                                          OrderSelected.id === order.id &&
                                          OrderSelected.status === 3) ? (
                                          <>
                                            <p className={classes.Accepted}>
                                              {intl.formatMessage({
                                                id: "ACCEPTED",
                                              })}
                                            </p>

                                            <CheckRoundedIcon
                                              className={classes.tickIcon}
                                            />
                                          </>
                                        ) : order.status === 4 ||
                                          (OrderSelected &&
                                            OrderSelected.id === order.id &&
                                            OrderSelected.status === 4) ? (
                                          <>
                                            <p className={classes.Rejected}>
                                              {intl.formatMessage({
                                                id: "Rejected",
                                              })}
                                            </p>
                                            <ClearIcon
                                              className={classes.crossIcon}
                                              style={{
                                                color:
                                                  cardClicked === order.id
                                                    ? "#FFFFFF"
                                                    : "#FFFFFF",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              className={classes.time}
                                              style={{
                                                color:
                                                  cardClicked === order.id
                                                    ? "#FFFFFF "
                                                    : theme.colors.primary,
                                              }}
                                            >
                                              {timeCounter ? (
                                                <TimeCounter
                                                  submitted_for_review={
                                                    order && order.created_at
                                                  }
                                                  timeCounter={timeCounter}
                                                  settimeCounter={
                                                    settimeCounter
                                                  }
                                                />
                                              ) : (
                                                <TimeCounter
                                                  submitted_for_review={
                                                    order && order.created_at
                                                  }
                                                  timeCounter={timeCounter}
                                                  settimeCounter={
                                                    settimeCounter
                                                  }
                                                />
                                              )}
                                            </p>
                                            <HourglassFullTwoToneIcon
                                              className={classes.loadingIcon}
                                              style={{
                                                color:
                                                  cardClicked === order.id
                                                    ? "#FFFFFF "
                                                    : theme.colors.primary,
                                              }}
                                            />
                                          </>
                                        )}
                                      </Grid>
                                    </Card>
                                  ) : (
                                    <Grid container>
                                      <>
                                        <div
                                          className={classes.time}
                                          style={{
                                            color:
                                              cardClicked === order.id
                                                ? "#FFFFFF "
                                                : theme.colors.primary,
                                          }}
                                        >
                                          {timeCounter ? (
                                            <TimeCounter
                                              submitted_for_review={
                                                order && order.created_at
                                              }
                                              timeCounter={timeCounter}
                                              settimeCounter={settimeCounter}
                                            />
                                          ) : (
                                            <TimeCounter
                                              submitted_for_review={
                                                order && order.created_at
                                              }
                                              timeCounter={timeCounter}
                                              settimeCounter={settimeCounter}
                                            />
                                          )}
                                        </div>
                                        <HourglassFullTwoToneIcon
                                          className={classes.loadingIcon}
                                          style={{
                                            color:
                                              cardClicked === order.id
                                                ? "#FFFFFF "
                                                : theme.colors.primary,
                                          }}
                                        />
                                      </>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid>
                                <Card
                                  className={classes.orderIdCard}
                                  style={{
                                    backgroundColor:
                                      cardClicked === order.id
                                        ? "rgba(0, 0, 0, 0.2)"
                                        : "#34CEB2",
                                    width:
                                      order.payment_method === "card"
                                        ? order.request_type !== 1
                                          ? "60px"
                                          : "35px"
                                        : "60px",
                                  }}
                                >
                                  <div
                                    className={classes.orderId}
                                    style={{
                                      color: "#FFFFFF ",
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: "Request",
                                    })}
                                  </div>
                                </Card>
                              </Grid>
                              <p
                                className={classes.TabName}
                                style={{
                                  color:
                                    cardClicked === order.id
                                      ? "#FFFFFF"
                                      : theme.colors.primary,
                                }}
                              >
                                {order && order.name ? order.name : ""}
                              </p>
                            </Card>
                          </a>
                        </SwipeableListItem>
                      )}
                    </>
                  ))}
            </SwipeableList>
          </Grid>
        </Grid>
        {/* Selected Order section */}
        <Grid
          item
          className="OrderGrid"
          xs={props.fullScreenView ? 8 : 8}
          style={{ height: props.fullScreenView && "100vh" }}
          ref={componentRef}
        >
          <Grid container>
            <Grid item xs={11}>
              {OrderSelected ? (
                <>
                  {OrderSelected?.order_uuid && OrderSelected.table_number ? (
                    <Grid className={classes.OrderGridInner}>
                      <Grid container justify="space-between">
                        <Grid item className={classes.SelectedOrder}>
                          {intl.formatMessage({
                            id: "Selected_Order",
                          })}
                        </Grid>
                        <Grid item>
                          <Grid container>
                            <Grid item></Grid>
                            <Grid item>
                              {" "}
                              <div
                                style={{
                                  border: "1px solid #44507B",
                                  borderRadius: "5px",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "40px",
                                  width: "40px",
                                }}
                              >
                                <img
                                  alt={intl.formatMessage({
                                    id: "no_img",
                                  })}
                                  src={ExpandIcon}
                                  onClick={() =>
                                    props.setfullScreenView(
                                      !props.fullScreenView
                                    )
                                  }
                                  className={classes.FullScreenIcon}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {OrderSelected?.order_uuid && (
                        <Grid container spacing={2} justify={"space-between"}>
                          <Grid item xs={4}>
                            <p
                              className={classes.textLabel}
                              style={{ textTransform: "uppercase" }}
                            >
                              {intl.formatMessage({
                                id: "Table_number",
                                defaultMessage: "TABLE_NUMBER",
                              })}
                            </p>

                            <TextField
                              variant="outlined"
                              disabled
                              fullWidth
                              classes={{}}
                              style={{
                                background: "#3C476B",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                              value={
                                OrderSelected.tab_uuid
                                  ? `${OrderSelected.table_number}`
                                  : OrderSelected.table_number
                              }
                              inputProps={{
                                className: classes.TextInputStyle,
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.outlinedStyle,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <p
                              className={classes.textLabel}
                              style={{ textTransform: "uppercase" }}
                            >
                              {intl.formatMessage({
                                id: "Tab_name",
                              })}
                            </p>

                            <TextField
                              variant="outlined"
                              disabled
                              fullWidth
                              classes={{}}
                              style={{
                                background: "#3C476B",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                              value={
                                OrderSelected.tab_name
                                  ? OrderSelected.tab_name
                                  : intl.formatMessage({
                                      id: "na",
                                    })
                              }
                              inputProps={{
                                className: classes.TextInputStyle,
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.outlinedStyle,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <p className={classes.textLabel}>
                              {intl.formatMessage({
                                id: "payment_Status",
                              })}
                            </p>

                            <TextField
                              variant="outlined"
                              disabled
                              //  placeholder="Month"
                              //  className={classes.fieldtext}
                              fullWidth
                              style={{
                                background:
                                  OrderSelected.payment_method === "card"
                                    ? paidColor
                                    : unpaidColor,
                                borderRadius: "5px",
                                border: "0px",
                              }}
                              value={
                                OrderSelected.payment_method === "card"
                                  ? intl.formatMessage({
                                      id: "Paid",
                                    })
                                  : intl.formatMessage({
                                      id: "Not_paid",
                                    })
                              }
                              inputProps={{
                                // defaultValue: OrderSelected.reference_id,
                                className: classes.TextInputStyle,
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.outlinedStyle,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid className={classes.OrderDetailList}>
                        {OrderSelected &&
                          OrderSelected.order_items &&
                          OrderSelected.order_items.map((MenuList) => (
                            <div>
                              <Grid
                                container
                                justify={"space-between"}
                                style={{ margin: "20px 0px 15px 0px" }}
                              >
                                <Grid item className={classes.dishName}>
                                  (x{MenuList.quantity}) {MenuList.name}
                                  {MenuList?.price_description &&
                                    ` - ${MenuList?.price_description}`}
                                </Grid>
                                <Grid item className={classes.dishPrice}>
                                  {parseFloat(
                                    MenuList.price * MenuList.quantity
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      venueDetail && venueDetail.currency,
                                  })}
                                </Grid>
                              </Grid>

                              {MenuList.order_item_options &&
                                MenuList.order_item_options
                                  ?.filter(
                                    (item) =>
                                      item?.display_as_main_item === false
                                  )
                                  ?.map((CustomMenu) => (
                                    <Grid
                                      container
                                      justify={"space-between"}
                                      style={{ margin: "10px 0px 10px 0px" }}
                                    >
                                      <Grid
                                        item
                                        className={classes.DishListItem}
                                      >
                                        {CustomMenu.name}
                                      </Grid>
                                      <Grid item className={classes.dishPrice}>
                                        {CustomMenu.price
                                          ? parseFloat(
                                              CustomMenu.price *
                                                MenuList.quantity
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency:
                                                venueDetail &&
                                                venueDetail.currency,
                                            })
                                          : ""}
                                      </Grid>
                                    </Grid>
                                  ))}

                              {MenuList.note && (
                                <Grid>
                                  <TextField
                                    variant="outlined"
                                    disabled
                                    fullWidth
                                    classes={{}}
                                    style={{
                                      background: "#3C476B",
                                      borderRadius: "5px",
                                      margin: "15px 0px",
                                    }}
                                    value={MenuList.note}
                                    inputProps={{
                                      className: classes.TextInputStyle,
                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.outlinedStyle,
                                      },
                                    }}
                                  />
                                </Grid>
                              )}

                              {MenuList.order_item_options &&
                                MenuList.order_item_options
                                  ?.filter(
                                    (item) =>
                                      item?.display_as_main_item === true
                                  )
                                  ?.map((CustomMenu) => (
                                    <div>
                                      <Divider
                                        style={{ background: "#3C476B" }}
                                      />
                                      <Grid
                                        container
                                        justify={"space-between"}
                                        style={{ margin: "10px 0px 10px 0px" }}
                                      >
                                        {/* <Grid
                                    container
                                    justify={"space-between"}
                                    style={{ margin: "20px 0px 15px 0px" }}
                                  > */}
                                        <Grid item className={classes.dishName}>
                                          (x{MenuList.quantity}){" "}
                                          {CustomMenu.name}
                                        </Grid>
                                        <Grid
                                          item
                                          className={classes.dishPrice}
                                        >
                                          {parseFloat(
                                            CustomMenu.price * MenuList.quantity
                                          ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency:
                                              venueDetail &&
                                              venueDetail.currency,
                                          })}
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid item className={classes.DishListItem}>
                                    {CustomMenu.name}
                                  </Grid>
                                  <Grid item className={classes.dishPrice}>
                                    {CustomMenu.price
                                      ? parseFloat(
                                          CustomMenu.price * MenuList.quantity
                                        ).toLocaleString("en-US", {
                                          style: "currency",
                                          currency:
                                            venueDetail && venueDetail.currency,
                                        })
                                      : ""}
                                  </Grid> */}
                                        {/* <Divider style={{ background: "#3C476B" }} /> */}
                                      </Grid>
                                    </div>
                                  ))}

                              {/* {MenuList.note && (
                            <Grid>
                              <TextField
                                 
                                variant="outlined"
                                disabled
                                fullWidth
                                classes={{}}
                                style={{
                                  background: "#3C476B",
                                  borderRadius: "5px",
                                  margin: "15px 0px",
                                }}
                                value={MenuList.note}
                                inputProps={{
                                  className: classes.TextInputStyle,
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.outlinedStyle,
                                  },
                                }}
                              />
                            </Grid>
                          )} */}
                              <Divider style={{ background: "#3C476B" }} />
                            </div>
                          ))}
                      </Grid>

                      <Grid className={classes.OrderListFooter}>
                        {OrderSelected.note && (
                          <Grid>
                            <p className={classes.SelectedOrder}>
                              {intl.formatMessage({
                                id: "Order_Note",
                              })}
                            </p>
                            <TextField
                              variant="outlined"
                              disabled
                              //  placeholder="Month"
                              //  className={classes.fieldtext}
                              fullWidth
                              classes={{}}
                              style={{
                                background: "#3C476B",
                                borderRadius: "5px",
                                margin: "15px 0px",
                              }}
                              value={OrderSelected.note}
                              inputProps={{
                                // defaultValue: "Order Note",
                                className: classes.TextInputStyle,
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.outlinedStyle,
                                },
                              }}
                            />
                          </Grid>
                        )}
                        <Grid container>
                          <Grid xs={8}></Grid>
                          <Grid xs={4}>
                            <Grid container justify="space-between">
                              <p className={classes.FotterPrices}>
                                {intl.formatMessage({
                                  id: "Subtotal",
                                })}
                              </p>
                              <p className={classes.Subtotal}>
                                {/* {OrderSelected.order_price} */}
                                {parseFloat(
                                  parseFloat(OrderSelected.order_price) -
                                    parseFloat(OrderSelected.tip_amount)
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: venueDetail && venueDetail.currency,
                                })}
                              </p>
                            </Grid>
                            <Grid container justify="space-between">
                              <p className={classes.FotterPrices}>
                                {intl.formatMessage({
                                  id: "Tip",
                                })}
                              </p>
                              <p className={classes.FotterPrices}>
                                {parseFloat(
                                  OrderSelected.tip_amount
                                ).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: venueDetail && venueDetail.currency,
                                })}
                              </p>
                            </Grid>
                            <Grid container justify="space-between">
                              <p className={classes.FotterPrices}>
                                {intl.formatMessage({
                                  id: "Total",
                                })}
                              </p>
                              <p className={classes.FotterPrices}>
                                {OrderSelected.order_price
                                  ? parseFloat(
                                      parseFloat(OrderSelected.order_price)
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency:
                                        venueDetail && venueDetail.currency,
                                    })
                                  : ""}
                                {/* {OrderSelected.order_price + OrderSelected.tip_amount } */}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          style={{ margin: "20px 0px " }}
                        >
                          <Grid item xs={6}>
                            {" "}
                            <RejectButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              style={{
                                background:
                                  OrderSelected &&
                                  (OrderSelected.status === 4 ||
                                    OrderSelected.status === 3) &&
                                  OrderSelected.status === 4
                                    ? "#FF3366"
                                    : theme.colors.primary,
                                border:
                                  OrderSelected &&
                                  (OrderSelected.status === 4 ||
                                    OrderSelected.status === 3) &&
                                  OrderSelected.status === 4
                                    ? "1px solid #FF3366"
                                    : "1px solid #FFFFFF",
                                color: "#FFFFFF",
                              }}
                              disabled={
                                OrderSelected &&
                                (OrderSelected.status === 4 ||
                                  OrderSelected.status === 3) &&
                                venueDetail?.allow_review_of_settled_orders ===
                                  ACCEPT_ORDERS_WITHOUT_TAB
                              }
                              onClick={() => {
                                setOpen(true);

                                // dispatch(
                                //   updateReviewOrder(Freje{
                                //     venue_uuid:
                                //       "1039cc70-54d2-4b7c-b2ea-cf27dc395acb",
                                //     Authorization:
                                //       "32286b58a2d35ad88ec96c3fa0f006659c67308b",
                                //     Status: "4",
                                //     order_uuid: OrderSelected.order_uuid,
                                //   })
                                // );
                              }}
                            >
                              {OrderSelected && OrderSelected.status === 4
                                ? intl.formatMessage({
                                    id: "Rejected",
                                  })
                                : intl.formatMessage({
                                    id: "Reject",
                                  })}
                            </RejectButton>
                          </Grid>
                          <Grid item xs={6}>
                            {" "}
                            <AcceptButton
                              fullWidth
                              variant="contained"
                              color="primary"
                              // style={{color:OrderSelected && (OrderSelected.status === 4 || OrderSelected.status === 3)&&"#34CEB2"}}
                              style={{
                                background:
                                  OrderSelected &&
                                  (OrderSelected.status === 4 ||
                                    OrderSelected.status === 3) &&
                                  OrderSelected.status === 3
                                    ? "#34CEB2"
                                    : theme.colors.primary,
                                border:
                                  OrderSelected &&
                                  (OrderSelected.status === 4 ||
                                    OrderSelected.status === 3) &&
                                  OrderSelected.status === 3
                                    ? "1px solid #34CEB2"
                                    : "1px solid #FFFFFF",
                                color: "#FFFFFF",
                              }}
                              disabled={
                                OrderSelected &&
                                (OrderSelected.status === 4 ||
                                  OrderSelected.status === 3)
                              }
                              onClick={() => {
                                dispatch(
                                  updateReviewOrder({
                                    venue_uuid: venueDetail && venueDetail.uuid,
                                    Authorization: localStorage.AuthUserKey,
                                    Status: "3",
                                    order_uuid: OrderSelected.order_uuid,
                                  })
                                );
                                setOrderSelected({
                                  ...OrderSelected,
                                  status: 3,
                                });
                                setTempOrderCount(tempOrderCount - 1);
                              }}
                            >
                              {OrderSelected && OrderSelected.status === 3
                                ? intl.formatMessage({
                                    id: "Accepted",
                                  })
                                : intl.formatMessage({
                                    id: "Accept",
                                  })}
                            </AcceptButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid className={classes.OrderGridInner}>
                      <Grid container justify="space-between">
                        <Grid item className={classes.SelectedOrder}></Grid>
                        <Grid item>
                          <Grid container>
                            <Grid item></Grid>
                            <Grid item>
                              <div
                                style={{
                                  border: "1px solid #44507B",
                                  borderRadius: "5px",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "40px",
                                  width: "40px",
                                }}
                              >
                                <img
                                  alt={intl.formatMessage({
                                    id: "no_img",
                                  })}
                                  src={ExpandIcon}
                                  onClick={() =>
                                    props.setfullScreenView(
                                      !props.fullScreenView
                                    )
                                  }
                                  className={classes.FullScreenIcon}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          height: "350px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          {OrderSelected?.request_type === 2 ? (
                            <ConfirmVerificationTabDialog
                              OrderSelected={OrderSelected}
                            />
                          ) : (
                            <>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {OrderSelected?.table_number && (
                                    <Typography
                                      className={classes.requestTitle}
                                    >
                                      {intl.formatMessage({
                                        id: "table",
                                      })}
                                      : {OrderSelected?.table_number}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography className={classes.requestTitle}>
                                    {OrderSelected?.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Typography className={classes.requestType}>
                                    {OrderSelected?.request_type === 2
                                      ? intl.formatMessage({
                                          id: "Tab_Created",
                                        })
                                      : OrderSelected?.request_type === 1
                                      ? intl.formatMessage({
                                          id: "Service_request",
                                        })
                                      : OrderSelected?.payment_method ==
                                        "room_charge"
                                      ? `${intl.formatMessage({
                                          id: "Bill_request",
                                        })} - ${intl.formatMessage({
                                          id: "charge_room",
                                        })}`
                                      : `${intl.formatMessage({
                                          id: "Bill_request_Pay_by",
                                        })} ${OrderSelected?.payment_method}`}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Grid container justify="center">
                                    <div
                                      className={classes.time}
                                      style={{
                                        color: "#FFFFFF ",
                                      }}
                                    >
                                      {timeCounter ? (
                                        <TimeCounter
                                          submitted_for_review={
                                            OrderSelected?.created_at
                                          }
                                          timeCounter={timeCounter}
                                          settimeCounter={settimeCounter}
                                        />
                                      ) : (
                                        <TimeCounter
                                          submitted_for_review={
                                            OrderSelected?.created_at
                                          }
                                          timeCounter={timeCounter}
                                          settimeCounter={settimeCounter}
                                        />
                                      )}
                                    </div>
                                    <HourglassFullTwoToneIcon
                                      className={classes.loadingIcon}
                                      style={{
                                        color: "#FFFFFF ",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <p className={classes.noOrder}></p>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid className={classes.OrderGridInner}>
                  <Grid container justify="space-between">
                    <Grid item className={classes.SelectedOrder}>
                      {intl.formatMessage({
                        id: "Selected_Order",
                      })}
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item></Grid>
                        <Grid item>
                          <div
                            style={{
                              border: "1px solid #44507B",
                              borderRadius: "5px",
                              marginLeft: "10px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: "40px",
                            }}
                          >
                            <img
                              alt={intl.formatMessage({
                                id: "no_img",
                              })}
                              src={ExpandIcon}
                              onClick={() =>
                                props.setfullScreenView(!props.fullScreenView)
                              }
                              className={classes.FullScreenIcon}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      height: "350px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p className={classes.noOrder}>
                        {intl.formatMessage({
                          id: "Select_an_order_to_view_it",
                        })}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={1}>
              <RejectDialog
                open={open}
                status={OrderSelected && OrderSelected.status}
                setOpen={setOpen}
                order_uuid={OrderSelected && OrderSelected.order_uuid}
                reference_id={OrderSelected && OrderSelected.reference_id}
                venue_uuid={venueDetail && venueDetail.uuid}
                setTempOrderCount={setTempOrderCount}
                tempOrderCount={tempOrderCount}
                setOrderSelected={setOrderSelected}
                OrderSelected={OrderSelected}
              />
              <VerifyTabDialog
                verifyDialog={verifyDialog}
                setVerifyDialog={setVerifyDialog}
                OrderSelected={OrderSelected}
                setOrderSelected={setOrderSelected}
                cardClicked={cardClicked}
                setcardClicked={setcardClicked}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* :<Grid container justify="center">
<CircularProgress size={40} color="primary" />
</Grid> 
} */}
      </Grid>
    </div>
  );
}

export default IncomingOrders;
