import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TabDetails from "./TabDetails";
import TabOrders from "../../containers/TabOrders/TabOrders";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F9FAFC",
  },

  tabs: {
    minWidth: "0px",
    marginRight: "30px",
    padding: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
    color: "#A3A9BC",
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#F66761",
    },
  },

  indicator: {
    backgroundColor: "#F66761",
  },
  selected: {
    color: "#F66761",
  },
  select: {
    color: "#A3A9BC",
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#F66761",
      minWidth: "0px",
      marginRight: "30px",
      padding: "5px",
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "none",
    },
  },
  headerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  tabTitle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
}));

export default function TabBar(props) {
  const intl = useIntl();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [unPaidAmount, setUnPaidAmount] = useState(0);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let tabOrders = useSelector((state) => state.VenueOrderReducer.tabOrders);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBack = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    if (tabOrders) {
      const ordersList = [tabOrders?.orders];
      setUnPaidAmount(tabOrders?.cash_sum?.subtotal_amount);
      setPaidAmount(tabOrders?.card_sum?.subtotal_amount);
      setTipAmount(tabOrders?.card_sum?.tip_amount);
    }
  }, [tabOrders]);
  return (
    <div className={classes.root}>
      <Grid container style={{ paddingTop: "39px", marginBottom: "7px" }}>
        <Grid item xs={1}></Grid>

        <Grid item className={classes.itemCenter}>
          <ArrowBackIosIcon
            style={{ color: "#303A5F", cursor: "pointer" }}
            onClick={handleBack}
          />
        </Grid>
        <Grid item className={classes.itemCenter}>
          <Typography
            className={classes.tabTitle}
          >{`${props?.OrderSelected?.table_number} - ${props?.OrderSelected?.name}`}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <Grid>
            <Tabs
              classes={{
                indicator: classes.indicator,
                selected: classes.selected,
              }}
              className={classes.select}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              aria-label="scrollable prevent tabs example"
            >
              <Tab
                className={classes.tabs}
                label={intl.formatMessage({
                  id: "Tab_orders",
                })}
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabs}
                label={intl.formatMessage({
                  id: "Tab_details",
                })}
                {...a11yProps(1)}
              />
            </Tabs>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.itemCenter}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.headerText}>
                <b> {intl.formatMessage({
                  id: "Paid",
                })}</b>
                {` : ${parseFloat(paidAmount)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: venueDetail && venueDetail.currency,
                  })
                  .toString()} (inc. ${parseFloat(tipAmount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: venueDetail && venueDetail.currency,
                  }
                )} ${intl.formatMessage({
                  id: "tip",
                })} )`}
              </Typography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Typography className={classes.headerText}>
                <b>{intl.formatMessage({
                  id: "Not_paid",
                })}</b>
                {` : ${parseFloat(unPaidAmount).toLocaleString("en-US", {
                  style: "currency",
                  currency: venueDetail && venueDetail.currency,
                })}`}
              </Typography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography className={classes.headerText}>
                <b> {intl.formatMessage({
                  id: "Total",
                })}</b>
                {` : ${parseFloat(paidAmount + unPaidAmount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: venueDetail && venueDetail.currency,
                  }
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0} style={{ padding: "0px" }}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <TabOrders
              OrderSelected={props?.OrderSelected}
              setOrderSelected={props?.setOrderSelected}
              open={props?.open}
              setOpen={props?.setOpen}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <TabDetails
              OrderSelected={props?.OrderSelected}
              setOrderSelected={props?.setOrderSelected}
              open={props?.open}
              setOpen={props?.setOpen}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}
