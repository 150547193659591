import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { TextButtonWhite, TextButton } from "../../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useIntl } from "react-intl";

export default function DeleteItem(props) {
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };
  useEffect(() => {
    setName(props?.item?.item?.name);
  }, [props]);

  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item xs={10} style={{ display: "flex" }}>
              <Typography className={classes.title}>
                {intl.formatMessage({
                  id: "confirm_delete_item",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0px" }}>
          <Grid container>
            <Typography className={classes.headingTest}>
            {intl.formatMessage({
                  id: "delete_item_message",
                })}
              
            </Typography>
          </Grid>
        </DialogContent>
        <Divider
          style={{ margin: "20px 24px", color: "#EDF0F4", height: "1px" }}
        />
        <DialogActions style={{ padding: "18px 24px 30px 8px" }}>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item>
              <TextButtonWhite onClick={handleClose}>{intl.formatMessage({
                  id: "Cancel",
                })}</TextButtonWhite>
            </Grid>
            <Grid item>
              <TextButton onClick={() => props?.confirmDelete()}>
              {intl.formatMessage({
                  id: "Delete_item",
                })}
                
              </TextButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
}));

// background: #FFFFFF;
// /* Input field */

// border: 1px solid #EDF0F4;
// box-sizing: border-box;
// border-radius: 5px;
