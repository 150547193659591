import {
  GET_HOTEL_GUESTS_LIST,
  ADD_HOTEL_GUESTS,
  UPDATE_HOTEL_GUESTS,
  DELETE_HOTEL_GUESTS,
  GET_VENUE_TABLES,
  UPDATE_VENUE_TABLE,
  DELETE_VENUE_TABLE,
  ADD_VENUE_TABLE,
  REORDER_TABLES,
  ADD_TABLES,
} from "../constant/types";

const initialState = {
  venueTablesList: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VENUE_TABLES: {
      return {
        ...state,
        venueTablesList: payload,
      };
    }
    case ADD_TABLES: {
      let tablesList = [...state.venueTablesList.results, ...payload.results];

      return {
        ...state,
        venueTablesList: { ...payload, results: [...tablesList] },
      };
    }
    case ADD_VENUE_TABLE: {
      let list = [...state.venueTablesList?.results, { ...payload }];

      return {
        ...state,
        venueTablesList: {
          ...state.venueTablesList,
          results: [...list],
        },
      };
    }
    case REORDER_TABLES: {
      console.log("data", payload.data);
      let list = [...payload.data];

      return {
        ...state,
        venueTablesList: {
          ...state.venueTablesList,
          results: [...list],
        },
      };
    }
    case UPDATE_VENUE_TABLE: {
      let list = [...state.venueTablesList?.results];
      let index = list.findIndex((n) => n?.id === payload.id);
      if (index > -1) {
        list[index] = payload;
        return {
          ...state,
          venueTablesList: {
            ...state.venueTablesList,
            results: [...list],
          },
        };
      }
    }
    case DELETE_VENUE_TABLE: {
      let list = [...state.venueTablesList?.results];
      let tables = list.filter((n) => n.id !== payload);
      return {
        ...state,
        venueTablesList: {
          ...state.venueTablesList,
          results: [...tables],
        },
      };
    }
    default:
      return state;
  }
}
