import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/auth";
import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { CircularProgress } from "@material-ui/core";

export default function ForgotPassword() {
  const intl = useIntl();

  let dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [confirm, setConfirm] = useState(false);


  const classes = useStyles();
  const [email, setEmail] = useState("");
  const handleEmail = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
    setErrorMessage(false)

  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && email !== null ) {
      handleLogin();
    }
  };

  useEffect(() => {
    localStorage.AuthUserKey && history.push("RestaurantsGroups");
  }, [history]);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleLogin = async () => {
    if(validateEmail(email)){
    setLoader(true)
    let res = await dispatch(forgotPassword({user:{ email: email }}));
    if (res?.status === 200) {
      setConfirm(true)
    }else{
      setErrorMessage(true)
    }
    setLoader(false)
  }else{
    setEmailError(true)
  }
  };
  return (
    <Grid container style={{ height: "90px" }}>
      <Link href="/Login" variant="body2" style={{textDecoration:"none"}}>
        <Grid container style={{ marginTop: "18px", marginLeft: "136px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowLeftIcon style={{color: "#303A5F"}}/>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography component="span" className={classes.backButton}>
              {" "}
              Go back
            </Typography>
          </Grid>
        </Grid>
      </Link>
{!confirm?
      <Grid container className={classes.centerContainer}>
        <Grid container justify="center">
          <Grid container justify="center">
            <Typography component="span" className={classes.headingText}>
              Forgot password
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography component="span" className={classes.body}>
              Please enter your email below associated with Blend Menu Manager.
            </Typography>
          </Grid>
          <Grid container justify="center" style={{marginTop:"190px",maxWidth:"361px"}}>
            <Grid container>
              <Typography component="span" className={classes.title}>
                Your email
              </Typography>
              <TextField
                onChange={handleEmail}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                placeholder="Your email"
                type="email"
                error={emailError ||errorMessage}
                helperText= {(errorMessage|| emailError)&&"Invalid Email"}
                value={email}
                style={{
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  border: "0px",
                }}
                className={classes.textField}
              />
              {/* {errorMessage&&} */}
            </Grid>
            {loader?<AcceptButton
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{ width: "142px", marginTop: "37px" }}
             >
                <CircularProgress size={25} style={{color:"#fff"}}/>
                
              </AcceptButton>:
            <AcceptButton
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleLogin()}
              style={{ width: "142px", marginTop: "37px" }}
            >
              {intl.formatMessage({
                id: "Continue",
              })}
            </AcceptButton>}
          </Grid>
        </Grid>
      </Grid>
    :
    <Grid container className={classes.centerContainer}>
    <Grid container justify="center">
      <Grid container justify="center">
        <Typography component="span" className={classes.headingText}>
        Email successfully sent!
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Typography component="span" className={classes.body}>
        Please check your email with instructions.
        </Typography>
      </Grid>
      <Grid container justify="center">
        <AcceptButton
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => history.push("Login")}
          style={{ width: "192px", marginTop: "37px",backgroundColor:"#A3A9BC",border:"1px solid #A3A9BC" }}
        >
          {intl.formatMessage({
            id: "Take me back",
          })}
        </AcceptButton>
      </Grid>
    </Grid>
  </Grid>
}
    </Grid>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  textField:{
    marginTop:"6px"
    ,
"& .MuiInputBase-root":{

}
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#303A5F",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    marginTop:"30px"
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  generalText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
}));
