import React from "react";
import QrPic from "../assets/image/qrcode.png";
import VerifyTabDialog from "./DialogBox/VerifyTabDialog";
// import { useEffect, useState, Component, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    background: "#F9FAFC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  QrCode: {
    margin: "20px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  webAddress: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#000000",
    marginTop: "0px",
    marginBottom: "0px",
  },
  downloadText: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
}));
function ViewOnlyMenu() {
  const intl = useIntl();

  const classes = useStyles();
  const handleOpenMail = () => {
    window.open("mailto:team@blend.app?subject=Upgrade to View and Order Menu");
  };
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            container
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className={classes.webAddress}>
              {intl.formatMessage({
                    id: "unavailable_feature",
                    defaultMessage: "This feature is available for menus with ordering fuctionality.",
                  })} 
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className={classes.webAddress}
                style={{
                  display: "inline",
                  color: "#f66761",
                  borderBottom: "1px solid #f66761",
                  cursor: "pointer",
                  paddingRight: "4px",
                }}
                onClick={handleOpenMail}
              >
                {intl.formatMessage({
                    id: "Contact_us",
                    defaultMessage: "Contact us",
                  })} 
                
              </span>
              <p className={classes.webAddress} style={{ display: "inline" }}>
              {intl.formatMessage({
                    id: "upgrade_your_menu",
                    defaultMessage: "to upgrade your menu",
                  })} 
               
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <VerifyTabDialog dialogOpen={true} setDialogOpen={() => {}} />
    </div>
  );
}

export default ViewOnlyMenu;
