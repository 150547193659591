import { element } from "prop-types";
import {
  GET_TRANSLATED_VENUE_DETAILS,
  ADD_MENU,
  UPDATE_TRANSLATED_MENU,
  DELETE_MENU,
  ADD_CATEGORY,
  UPDATE_TRANSLATED_CATEGORY,
  DELETE_CATEGORY,
  DELETE_ITEM,
  ADD_CUSTOMIZATION,
  UPDATE_CUSTOMIZATION,
  DELETE_CUSTOMIZATION,
  GET_TAGS_META,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_TAG,
  GET_CUSTOMIZATION_LIST,
  ADD_ITEM,
  UPDATE_TRANSLATED_ITEM,
  GET_ALL_TAGS,
  ADD_TAGS_TEMPLATE,
  DELETE_TAGS_TEMPLATE,
  REORDER_MENU,
  REORDER_CATEGORY,
  REORDER_ITEMS,
  REORDER_TABLES,
  REORDER_CUSTOMIZATIONS,
  REORDER_CUSTOMIZATIONS_OPTIONS,
  UPDATE_ALL_ITEM_CUSTOMIZATIONS,
  UPDATE_TRANSLATED_PRICES,
  UPDATE_LANGUAGE,
  UPDATE_TRANSLATED_TAG,
  UPDATE_TRANSLATED_CUSTOMIZATION,
} from "../constant/types";
import language from "../reducers/language";
import { store } from "../store/store";
import API from "../utils/API";
export const updateLanguage = ({ data }) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_LANGUAGE,
    payload: data,
  });
};
let venue_uuid = store.getState()?.VenueOrderReducer?.VenueDetail?.uuid;
export const getTranslatedMenuDetails = ({ venue_uuid, language }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };

  if (venue_uuid) {
    try {
      const response = await API.get(
        `api/business/menu/details/translated/`,
        config
      );
      let data = response?.data;
      let newData = [...data];
      let menus = [...newData];
      menus.forEach((element, index) => {
        let obj = menus[index];
        menus[index] = { ...obj, position: index };
      });
      newData["menus"] = menus;
      dispatch({ type: GET_TRANSLATED_VENUE_DETAILS, payload: newData });

      return 200;
    } catch (error) {
      return error?.response?.status;
    }
  } else {
    return 400;
  }
};
export const addMenu = ({ name, language }) => async (dispatch) => {
  const body = JSON.stringify({
    name: name,
  });
  let res = {
    id: null,
    name: name,
    description: "",
    slug: name,
    image: null,
    menu_categories: [],
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    const response = await API.post(
      `business/menu-manager/menu/`,
      body,
      config
    );
    let data = response?.data;
    // res.id = data?.id;
    // dispatch({
    //   type: ADD_MENU,
    //   payload: res,
    // });

    return { status: 200, id: data?.id };
  } catch (error) {
    return { status: 400 };
  }
};

export const deleteMenu = ({ id }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `/business/menu-manager/menu/${id}/`,
      config
    );
    //
    dispatch({
      type: DELETE_MENU,
      payload: id,
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateMenu = ({ id, name, language }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    const response = await API.patch(
      `/business/menu-manager/menu/${id}/`,
      { name: name },
      config
    );
    // "text": "λεπτομέριες","field": "description","language": "gr"
    let data = {
      text: response?.data?.name,
      field: "name",
      language: language,
    };
    dispatch({
      type: UPDATE_TRANSLATED_MENU,
      payload: { id: id, data: data },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCategory = ({ name, menuId }) => async (dispatch) => {
  const body = JSON.stringify({
    name: name,
    menu: menuId,
  });
  let res = {
    id: parseInt(Math.random() * (29 - 21) + 21),
    name: name,
    position: parseInt(Math.random() * (19 - 11) + 11),
    items: [],
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `/business/menu-manager/category/`,
      body,
      config
    );
    let data = response?.data;

    res.id = data?.id;
    res.position = data?.position;
    dispatch({
      type: ADD_CATEGORY,
      payload: { data: res, menuId: menuId },
    });

    return { status: 200, id: data?.id };
  } catch (error) {
    return { status: 400 };
  }
};

export const deleteCategory = ({ id, menuId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `business/menu-manager/category/${id}/`,
      config
    );

    dispatch({
      type: DELETE_CATEGORY,
      payload: { id: id, menuId: menuId },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateCategory = ({ id, name, menuId, language }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    const response = await API.patch(
      `business/menu-manager/category/${id}/`,
      { name: name,
      id:id },
      config
    );
    let data = {
      text: response?.data?.name,
      field: "name",
      language: language,
    };
    dispatch({
      type: UPDATE_TRANSLATED_CATEGORY,
      payload: { id: id, data: data, menuId: menuId },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteItem = ({ id, menuId, categoryId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `api/business/menu-manager/item/${id}/`,

      config
    );
    dispatch({
      type: DELETE_ITEM,
      payload: { id: id, menuId: menuId, categoryId: categoryId },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCustomization = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;

  const body = JSON.stringify({
    data,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    dispatch({
      type: ADD_CUSTOMIZATION,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateCustomization = ({
  id,
  menuId,
  categoryId,
  itemId,
  body,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let newObj = { ...body };
  newObj["id"] = newObj["item_customisation_id"];
  delete newObj["item_customisation_id"];
  delete newObj["customisation_options"];
  let newArr = [];
  body.customisation_options.forEach((n) => {
    newArr.push({
      name: n.name,
      position: n.position,
      pre_selected: n.pre_selected,
      price: n.price,
    });
  });
  newObj["item_customisation_options"] = [...newArr];

  try {
    const response = await API.patch(
      `/api/business/menu-manager/item/${itemId}/update-item-customisation/${id}/`,
      newObj,
      config
    );
    let resData = response?.data?.item_customisations;
    let indexCust = resData.findIndex((n) => n.id === id);
    if (indexCust > -1) {
      let updatedCust = resData[indexCust];
      let newObjCust = {
        ...updatedCust,
      };
      newObjCust["item_customisation_id"] = newObjCust["id"];
      delete newObjCust["id"];
      delete newObjCust["item_customisation_options"];
      let newArr2 = [];

      updatedCust.item_customisation_options.forEach((n) => {
        newArr2.push({
          customisation_option_id: n.id,
          name: n.name,
          position: n.position,
          pre_selected: n.pre_selected,
          customisation_template: n?.customisation_template,
          price: n.price,
        });
      });
      newObjCust["customisation_options"] = [...newArr2];
      dispatch({
        type: UPDATE_CUSTOMIZATION,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          itemId: itemId,
          data: newObjCust,
        },
      });
    }
    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteCustomization = ({
  id,
  menuId,
  categoryId,
  itemId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `/api/business/menu-manager/item/${itemId}/remove-item-customisation/${id}/`,
      config
    );
    dispatch({
      type: DELETE_CUSTOMIZATION,
      payload: {
        id: id,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const getTagsMeta = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(
      `api/business/menu-manager/tags/tag-images-colours/`,
      config
    );
    dispatch({
      type: GET_TAGS_META,
      payload: {
        response,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addTags = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;
  //
  if (itemId) {
    const config = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.post(
        `api/business/menu-manager/item/${itemId}/add-tag/`,
        { tag_id: data },
        config
      );

      let d = [...response?.data?.item_tags];

      let ind = d.findIndex((a) => a.tag_id === data);
      let newObj = d[ind];
      newObj.id = d[ind].tag_id;
      if (ind > -1) {
        dispatch({
          type: ADD_TAG,
          payload: {
            id: itemId,
            menuId: menuId,
            categoryId: categoryId,
            data: d[ind],
          },
        });
      }
      return 200;
    } catch (error) {
      return 400;
    }
  }
};
export const removeTagsFromItem = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;
  //
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    const response = await API.post(
      `api/business/menu-manager/item/${itemId}/remove-tag/`,
      { tag_id: data },
      config
    );

    return 200;
  } catch (error) {
    return 400;
  }
};
export const deleteTags = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const { itemId, menuId, categoryId, tag } = payload;
  try {
    dispatch({
      type: REMOVE_TAG,
      payload: {
        id: itemId,
        menuId: menuId,
        categoryId: categoryId,
        data: tag,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateTags = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const { itemId, menuId, categoryId, data, tag } = payload;
  try {
    dispatch({
      type: UPDATE_TAG,
      payload: {
        id: itemId,
        menuId: menuId,
        categoryId: categoryId,
        data: data,
        tag: tag,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const getCustomizationList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(
      `/business/menu-manager/customisation-template/`,
      config
    );
    let data = response?.data;

    dispatch({
      type: GET_CUSTOMIZATION_LIST,
      payload: data,
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCustomizationTemplate = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;

  let newObj = {
    name: data?.name,
    required: data?.required,
    max_selectable: data?.max_selectable,
    display_as_main_item: data?.display_as_main_item,
  };
  let newArr = [];

  data &&
    data.customisation_options &&
    data.customisation_options.forEach((element) => {
      newArr.push({
        name: element?.name,
        price: element?.price,
        pre_selected: element?.pre_selected,
      });
    });

  newObj["template_options"] = newArr;

  const body = JSON.stringify({
    ...newObj,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `business/menu-manager/customisation-template/`,
      body,
      config
    );
    let newData = { ...data };
    newData.item_customisation_id = response?.data?.id;
    newData.position = response?.data?.position;

    let newArr2 = [];
    response.data.template_options.forEach((n) => {
      newArr2.push({
        customisation_option_id: n?.id,
        name: n?.name,
        position: n?.position,
        pre_selected: n?.pre_selected,
        price: n?.price,
      });
    });
    newData["customisation_options"] = newArr2;

    dispatch({
      type: ADD_CUSTOMIZATION,
      payload: {
        data: newData,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });
    return { id: response?.data?.id, status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};

export const updateCustomizationTemplate = (payload) => async (dispatch) => {
  const {
    id,
    menuId,
    categoryId,
    itemId,
    data,
    customList,
    language,
  } = payload;
  let newObj = {
    name: data?.translations&&data?.translations[0]?.text?data?.translations[0]?.text:"",
    required: data?.required,
    max_selectable: data?.max_selectable,
    display_as_main_item: data?.display_as_main_item,
    id:data?.item_customisation_id
  };
  let newArr = [];

  data &&
    data.customisation_options &&
    data.customisation_options.forEach((element) => {
      newArr.push({
        name: element?.translations[0]?.text
          ? element?.translations[0]?.text
          : element?.name,
        price: element?.price,
        id:element?.option_template_id,
        pre_selected: element?.pre_selected,
      });
    });

  newObj["item_customisation_options"] = newArr;

  const body = JSON.stringify({
    ...newObj,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    const response = await API.patch(
      `/api/business/menu-manager/item/${itemId}/update-item-customisation-template/${id}/`,
      body,
      config
    );
    let resData = response?.data?.item_customisations;
    let indexCust = resData.findIndex((n) => n.id === id);
    // if (indexCust > -1) {
    dispatch({
      type: UPDATE_TRANSLATED_CUSTOMIZATION,
      payload: {
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
        data: customList,
      },
    });
    // }

    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteCustomizationTemplate = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, id } = payload;

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `business/menu-manager/customisation-template/${id}/`,
      config
    );

    return 200;
  } catch (error) {
    return 400;
  }
};
export const addPrice = ({ item, id, menuId, categoryId }) => async (
  dispatch
) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    let newObj = { amount: item?.amount, description: item?.description };
    let response = await API.post(
      `/business/menu-manager/item/${id}/add-price/`,
      JSON.stringify(newObj),
      config2
    );
    dispatch({
      type: UPDATE_TRANSLATED_PRICES,
      payload: {
        item: response.data.prices,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const removePrice = ({ item, id, menuId, categoryId }) => async (
  dispatch
) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    let response = await API.post(
      `/business/menu-manager/item/${id}/remove-price/`,
      JSON.stringify(item),
      config2
    );
    dispatch({
      type: UPDATE_TRANSLATED_PRICES,
      payload: {
        item: response.data.prices,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const updatePrice = ({
  item,
  id,
  itemId,
  menuId,
  categoryId,
  pricesList,
  language,
}) => async (dispatch) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    let itemWithId = {
      amount: item?.amount,
      description: item?.translations?.length>0?item?.translations[0]?.text:"",
      id: itemId,
    };
    let response = await API.patch(
      `/business/menu-manager/item/${id}/update-price/`,
      JSON.stringify(itemWithId),
      config2
    );
    dispatch({
      type: UPDATE_TRANSLATED_PRICES,
      payload: {
        item: pricesList,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const addItem = ({
  menuId,
  categoryId,
  data,
  data2,
  tagsList,
  pricesList,
}) => async (dispatch) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  const config = {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `api/business/menu-manager/item/`,
      data,
      config
    );
    let res = response?.data;
    let r = null;
    let id = res?.id;

    data2.forEach(async (element, i) => {
      let newObj2 = {
        template_id: element?.item_customisation_id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      r = k.data;
      r["item_id"] = r["id"];
      delete r["id"];
      r["tags"] = r["item_tags"];
      delete r["item_tags"];
      let cArr = [];
      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          customisation_template: elem?.customisation_template,
          position: elem?.position,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;

      // dispatch({
      //   type: UPDATE_ITEM,
      //   payload: {
      //     id: id,
      //     menuId: menuId,
      //     categoryId: categoryId,
      //     item: r,
      //   },
      // });
    });
    tagsList.forEach(async (element) => {
      await dispatch(
        addTags({
          itemId: id,
          menuId: menuId,
          categoryId: categoryId,
          data: element?.id,
        })
      );
    });

    res["item_id"] = res["id"];
    res["tags"] = res["item_tags"];
    delete res["id"];
    delete res["item_tags"];
    dispatch({
      type: ADD_ITEM,
      payload: { menuId: menuId, categoryId: categoryId, item: res },
    });
    pricesList.forEach((element, index) => {
      dispatch(
        addPrice({
          item: element,
          id: id,
          menuId: menuId,
          categoryId: categoryId,
        })
      );
    });
    return { status: 200, id: id };
  } catch (error) {
    return { status: 400 };
  }
};

export const updateItem = ({
  id,
  menuId,
  categoryId,
  data,
  data2,
  tagsList,
  pricesList,
  language,
}) => async (dispatch, getState) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      language: language,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  const config = {
    headers: {
      language: language,
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
  
    const response = await API.patch(
      `api/business/menu-manager/item/${id}/`,
      data,
      config
    );
    pricesList &&
    pricesList.map((item) => {
      dispatch(
        updatePrice({
          item: item,
          id: id,
          itemId: item?.id,
          menuId: menuId,
          language: language,
          categoryId: categoryId,
          pricesList: pricesList,
        })
      );
    });
    data2.forEach((element) => {
      dispatch(
        updateCustomizationTemplate({
          id: element?.item_customisation_id,
          menuId,
          categoryId,
          itemId: id,
          data: element,
          customList: data2,
          language,
        })
      );
    });

    // colour: {id: 6, bg_hex: "#2C98F0", text_hex: "#FFFFFF"}
    // id: 57
    // image: {image: "https://blend-menu-files-staging.s3.amazonaws.com/…a5NsuI19jVI4h1JaxrS%2B90zi8%3D&Expires=1619520590", id: 8}
    // name: "meat"
    // position: 0
    // tag_id: 15
    // translatable_fields: [Array(0)]
    // translations: Array(1)
    // 0: {text: "meat (fr)", field: "name", language: "fr"}

    tagsList.forEach((element) => {
      dispatch(
        updateTagsTemplate({
          id: element?.tag_id,
          data: {
            name: element?.translations[0]?.text,
            image: element?.image?.id,
            colour: element?.colour?.id,
          },
          menuId,
          categoryId,
          itemId: id,
          language,
          tagsList,
        })
      );
    });

    let dataLan = {
      text: response?.data?.name,
      field: "name",
      language: language,
    };
    let data2Lan = {
      text: response?.data?.description,
      field: "description",
      language: language,
    };

    dispatch({
      type: UPDATE_TRANSLATED_ITEM,
      payload: {
        id: id,
        menuId: menuId,
        categoryId: categoryId,
        item: data2Lan,
        data: dataLan,
      },
    });
    return { status: 200 };
  } catch (error) {

    return { status: 400 };
  }
};

export const addTagsTemplate = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const { data } = payload;

    const response = await API.post(
      `api/business/menu-manager/tags/`,
      data,
      config
    );

    dispatch({
      type: ADD_TAGS_TEMPLATE,
      payload: {
        data: response?.data,
      },
    });

    return { status: 200, data: response?.data };
  } catch (error) {
    return { status: 400 };
  }
};
export const updateTagsTemplate = (payload) => async (dispatch) => {
  const { id, data, language, tagsList,itemId, menuId, categoryId } = payload;

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language: language,
    },
  };
  try {
    const response = await API.patch(
      `api/business/menu-manager/tags/${payload?.id}/`,
      data,
      config
    );

    dispatch({
      type: UPDATE_TRANSLATED_TAG,
      payload: {
        data: tagsList,
        id:itemId, menuId, categoryId,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};
export const deleteTagsTemplate = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `api/business/menu-manager/tags/${payload?.id}/`,

      config
    );

    dispatch({
      type: DELETE_TAGS_TEMPLATE,
      payload: {
        id: payload?.id,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const getAlltags = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(`api/business/menu-manager/tags/`, config);

    dispatch({
      type: GET_ALL_TAGS,
      payload: {
        data: response?.data?.results,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateMenuOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/menu/change-order/`,
      arr,
      config
    );
    dispatch({ type: REORDER_MENU, payload: data });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateCategoryOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/category/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_CATEGORY,
      payload: { data: data, id: payload.id },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateItemOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/item/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_ITEMS,
      payload: { data: data, id: payload.id, category_id: payload.category_id },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateTablesOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/venue/tables/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_TABLES,
      payload: { data: data },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateItemCustomizationOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({
        id: element?.item_customisation_id,
        position: element?.position,
      });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `/business/menu-manager/parent-item-customisation/change-order/`,
      arr,
      config
    );
    const { itemId, menuId, categoryId } = payload?.item;

    dispatch({
      type: REORDER_CUSTOMIZATIONS,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateItemCustomizationOptionsOrder = (payload) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({
        id: element?.customisation_option_id,
        position: element?.position,
      });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `/business/menu-manager/child-item-customisation/change-order/`,
      arr,
      config
    );
    const { itemId, menuId, categoryId, customizationId } = payload?.item;

    dispatch({
      type: REORDER_CUSTOMIZATIONS_OPTIONS,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
        id: customizationId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateAddCustomization = ({
  id,
  menuId,
  categoryId,
  templete,
}) => async (dispatch) => {
  if (id) {
    const config2 = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      let newObj2 = {
        template_id: templete?.id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      let r = k.data;
      let cArr = [];

      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          position: elem?.position,
          customisation_template: elem?.customisation_template,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;

      dispatch({
        type: UPDATE_ALL_ITEM_CUSTOMIZATIONS,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: cArr,
        },
      });
      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  }
};

export const updateAddTags = ({ id, menuId, categoryId, templete }) => async (
  dispatch
) => {
  if (id) {
    const config2 = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      let newObj2 = {
        template_id: templete?.id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      let r = k.data;
      let cArr = [];

      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          position: elem?.position,
          customisation_template: elem?.customisation_template,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;
      dispatch({
        type: UPDATE_ALL_ITEM_CUSTOMIZATIONS,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: cArr,
        },
      });
      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  }
};
