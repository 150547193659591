import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import VenueProfile from "../../containers/VenueProfile/VenueProfile";
import OrderSetting from "../../containers/OrderSetting/OrderSetting";
import VenueQRCode from "../../containers/VenueQRCode/VenueQRCode";
import VenueTables from "../../containers/VenueTables/VenueTables";
import { useDispatch, useSelector } from "react-redux";
import { getMenuDetails } from "../../actions/menu";
import ViewOnlyMenu from "../../Components/ViewOnlyMenu";
import { useIntl } from "react-intl";
import { permissionKeys } from "../../constant/config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabBar(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [dataLoading, setDataLoading] = useState(false);

  let dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);
  useEffect(() => {
    try {
      if (UserDetail?.user_venue_permissions[0]?.permission_screen_keys) {
        setPermissions(
          UserDetail?.user_venue_permissions[0]?.permission_screen_keys
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    try {
      if (UserDetail?.user_venue_permissions[0]?.permission_screen_keys) {
        setPermissions(
          UserDetail?.user_venue_permissions[0]?.permission_screen_keys
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [UserDetail]);
  const getMenuData = async () => {
    setDataLoading(true);
    let res = await dispatch(getMenuDetails({ venue_uuid: venueDetail?.uuid }));
    setDataLoading(false);
  };
  useEffect(() => {
    getMenuData();
    // eslint-disable-next-line
  }, []);
  const verifyPermission = (key) => {
    let index = permissions.findIndex((i) => i === key);
    return index > -1 ? true : false;
  };
  return (
    <div className={classes.root}>
      <AppBar color="white" position="static" className={classes.AppBar}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid>
              {!props.fullScreenView && (
                <Tabs
                  classes={{
                    indicator: classes.indicator,
                    selected: classes.selected,
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  {verifyPermission(permissionKeys.VENUE_DETAILS) && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Venue_details",
                        defaultMessage: "Venue details",
                      })}
                      value={0}
                      {...a11yProps(0)}
                    />
                  )}
                  {verifyPermission(permissionKeys.ORDER_SETTINGS) && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Order_settings",
                        defaultMessage: "Order settings",
                      })}
                      value={1}
                      {...a11yProps(1)}
                    />
                  )}
                  {verifyPermission(permissionKeys.TABLE_NUMBERS) && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Table_numbers",
                        defaultMessage: "Table numbers",
                      })}
                      value={2}
                      {...a11yProps(2)}
                    />
                  )}
                  {verifyPermission(permissionKeys.QR_CODES) && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "QR_code",
                        defaultMessage: "QR code",
                      })}
                      value={3}
                      {...a11yProps(3)}
                    />
                  )}
                </Tabs>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </AppBar>
      {verifyPermission(permissionKeys.VENUE_DETAILS) && (
        <TabPanel value={value} index={0} style={{ padding: "0px" }}>
          <VenueProfile />
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.ORDER_SETTINGS) && (
        <TabPanel value={value} index={1}>
          {venueDetail?.read_only_menu ? <ViewOnlyMenu /> : <OrderSetting />}
        </TabPanel>
      )}

      {verifyPermission(permissionKeys.TABLE_NUMBERS) && (
        <TabPanel value={value} index={2}>
          {venueDetail?.read_only_menu ? <ViewOnlyMenu /> : <VenueTables />}
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.QR_CODES) && (
        <TabPanel value={value} index={3}>
          <VenueQRCode />
        </TabPanel>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F9FAFC",
  },
  AppBar: {
    boxShadow: "none",
    backgroundColor: "#F9FAFC",
    paddingTop: "20px",
  },
  tabs: {
    minWidth: "0px",
    marginRight: "30px",
    padding: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
    color: "#A3A9BC",
  },
  indicator: {
    backgroundColor: "#F66761",
  },
  selected: {
    color: "#F66761",
  },
}));
