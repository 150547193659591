import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useIntl } from "react-intl";
import { ReactComponent as VenueIcon } from "../../assets/image/venue.svg";
import { ReactComponent as HotelIcon } from "../../assets/image/hotel.svg";
import { ReactComponent as TickIcon } from "../../assets/image/tick.svg";
import { ReactComponent as MultiVendorIcon } from "../../assets/image/multi-vendor.svg";
export default function DeleteUser(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.model}
      >
        <DialogTitle
          style={{
            paddingBottom: "0px",
            height: "40px",
            paddingTop: "0px",
            backgroundColor: "#F3F5F8",
          }}
        >
          <Grid container>
            <Grid item xs={10} />
            <Grid
              item
              xs={2}
              style={{
                justifyContent: "flex-end",
                minHeight: "40px",

                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CloseIcon
                onClick={handleClose}
                style={{ cursor: "pointer", color: "#A3A9BC" }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0px" }}>
          <Grid container style={{marginTop:"25px"}}>
            <Grid
              item
              justify="center"
              className={classes.imgGrid}
              style={{ paddingLeft: "16px", paddingRight: "49px" }}
            >
              <VenueIcon width="56px" height="60px" />
            </Grid>
            <Grid item style={{ width: "calc(100% - 122px)" }}>
              <Grid container justify="flex-start">
                <Typography className={classes.title}>
                  Independent venue
                </Typography>
              </Grid>
              <Grid container justify="flex-start">
                <Typography className={classes.description}>
                  You can create multiple venues under the same account but each
                  venue is independent. Ideal for single restaurants or
                  restaurant chains with different locations.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid 
           style={{marginTop:"5px"}}
          container>
            <Grid
              item
              justify="center"
              className={classes.imgGrid}
              style={{ paddingLeft: "16px", paddingRight: "40px" }}
            >
              <MultiVendorIcon height="60px" width="66px" />
            </Grid>
            <Grid item style={{ width: "calc(100% - 122px)" }}>
              <Grid container justify="flex-start">
                <Typography className={classes.title}>Multi vendor</Typography>
              </Grid>
              <Grid container justify="flex-start">
                <Typography className={classes.description}>
                  Create multiple independent venues that share a common landing
                  page. Ideal for large venues that want to separate restaurant
                  and bar areas.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
           style={{marginTop:"5px"}}
          container>
            <Grid
              item
              justify="center"
              className={classes.imgGrid}
              style={{ paddingLeft: "16px", paddingRight: "40px" }}
            >
              <HotelIcon width="66px" height="66px" />
            </Grid>
            <Grid item style={{ width: "calc(100% - 122px)" }}>
              <Grid container justify="flex-start">
                <Typography className={classes.title}>Hotel</Typography>
              </Grid>
              <Grid container justify="flex-start">
                <Typography className={classes.description}>
                  Digitise all menus in a hotel including room service. Hotel
                  login feature identifies hotel guests throughout their stay.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  model: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "441px",
    },
  },
  text: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  iconGrid: {
    height: "30px",
    width: "30px",
    border: "2px solid #D8DCE9",
    borderRadius: "50%",
    margin: "15px",
  },
  selectedIconGrid: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    background: "#34CEB2",
    margin: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgGrid: {
    margin: "11px 0px 25px 0px",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#303A5F",
    padding: "10px 0px 0px 0px",
  },
  selectedRootCard: {
    background: "#FFFFFF",
    border: "3px solid #34CEB2",
    boxSizing: "border-box",
    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    maxWidth: "298px",
    height: "298px",
    marginTop: "20px",
    padding: "0px !important",
    cursor: "pointer",
  },
  cardRoot: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    maxWidth: "298px",
    height: "298px",
    marginTop: "20px",
    padding: "0px !important",
    cursor: "pointer",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#303A5F",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },

  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
    textDecoration: "none",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  generalText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    marginBottom: "90px",
  },
}));
