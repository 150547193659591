import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TextButtonAdd, TextButton } from "../../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  addTags,
  deleteTagsTemplate,
  updateTags,
  getTagsMeta,
  addTagsTemplate,
  updateTagsTemplate,
} from "../../../actions/menu";
import { TextButtonNoColor } from "../../Buttons/Buttons";
import { ReactComponent as TickIcon } from "../../../assets/image/tick.svg";
import { useIntl } from "react-intl";

export default function TagsModal(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [name, setName] = React.useState("");
  const [selectedColor, setSelectedColor] = useState({});
  const [selectedTags, setSelectedTags] = useState({});
  const [dataLoader, setDataLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");

  const [errors, setErrors] = useState({ name: false, color: false });

  let tagsMeta = useSelector((state) => state?.menuReducer?.tagsMeta);

  const classes = useStyles();

  const handleClose = () => {
    props.handleClose(false);
  };
  useEffect(() => {
    if (!selectedColor?.id && tagsMeta?.colours) {
      setSelectedColor(tagsMeta?.colours[0]);
    }
    // eslint-disable-next-line
  }, [tagsMeta]);
  useEffect(() => {
    setName(props?.tagData?.name);
    setSelectedColor(props?.tagData?.colour);
    setSelectedTags(props?.tagData?.image);
    // eslint-disable-next-line
  }, [props]);
  const handleAdd = async () => {
    if (!name || name?.trim() === "" || !selectedColor?.id) {
      let obj = { ...errors };

      if (!name || name?.trim() === "") {
        obj.name = true;
      }
      if (!selectedColor?.id) {
        obj.color = true;
      }
      setErrors({ ...obj });
    } else {
      setLoading(true);

      let obj = {
        name: name,
        image: selectedTags?.id ? selectedTags?.id : "",
        colour: selectedColor?.id ? selectedColor?.id : "",
      };

      let res = null;
      if (props?.tagData?.id) {
        setUpdatingType("update");
        res = await dispatch(
          updateTagsTemplate({
            itemId: props?.item?.itemId,
            categoryId: props?.item?.categoryId,
            menuId: props?.item?.menuId,
            data: obj,
            id: props?.tagData?.id,
          })
        );
      } else {
        setUpdatingType("add");

        res = await dispatch(
          addTagsTemplate({
            itemId: props?.item?.itemId,
            categoryId: props?.item?.categoryId,
            menuId: props?.item?.menuId,
            data: obj,
          })
        );
        if (res?.status === 200) {
          dispatch(
            addTags({
              ...props?.item,
              data: res?.data?.id,
            })
          );
          props && props.handleCloseAndAdd(res?.data);
        }
      }
      if (res === 200) {
        props && props.handleClose();
      }
    }
    setLoading(false);
    setUpdatingType("");
  };

  const handleDelete = async () => {
    setLoading(true);
    setUpdatingType("delete");

    let res = await dispatch(
      deleteTagsTemplate({
        id: props?.tagData?.id,
      })
    );
    if (res === 200) {
      props && props.handleClose();
    }
    setLoading(false);
    setUpdatingType("");
  };

  useEffect(() => {
    (async () => {
      setDataLoader(true);
      let res = await dispatch(getTagsMeta());
      setDataLoader(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleClickColor = (item) => {
    let obj = { ...errors };
    setErrors({ ...obj, color: false });
    if (item?.id === selectedColor?.id) {
      setSelectedColor({});
    } else {
      setSelectedColor(item);
    }
  };
  const handleClickImage = (item) => {
    if (item?.id === selectedTags?.id) {
      setSelectedTags({});
    } else {
      setSelectedTags(item);
    }
  };

  const handleChangeName = (e) => {
    let obj = { ...errors };
    setErrors({ ...obj, name: false });
    setName(e.target.value);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Typography className={classes.headertext}>
                {props?.tagData?.name ? 
                 intl.formatMessage({
                  id: "Edit_tag",
                })
                 : 
                intl.formatMessage({
                  id: "Create_new_tag",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography className={classes.headingText}>
              {intl.formatMessage({
                  id: "Tag_name",
                })}
              
              <span style={{ fontWeight: "normal", textTransform: "lowercase" }}>&nbsp;({intl.formatMessage({
                  id: "Required",
                })})</span>
            </Typography>

            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Input_name_of_tag",
              })}
              fullWidth
              error={errors.name}
              helperText={errors.name && intl.formatMessage({
                id: "Field_is_required",
              })}
              value={name}
              onChange={handleChangeName}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              inputProps={{
                className: classes.TextInputStyle2,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.headingText}>
              {intl.formatMessage({
                id: "Tag_color",
              })}
                
                <span style={{ fontWeight: "normal" }}>&nbsp;({intl.formatMessage({
                  id: "Required",
                })})</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {errors?.color && (
                <small style={{ color: "#f44336", marginLeft: "14px" }}>
                  {intl.formatMessage({
                  id: "Color_is_Required",
                })}
                  
                </small>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {dataLoader ? (
                  <Grid container justify="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  tagsMeta?.colours?.map((items) => {
                    return (
                      <Grid
                        item
                        onClick={() => handleClickColor(items)}
                        className={classes.tagsColor}
                        style={{ backgroundColor: items?.bg_hex }}
                      >
                        {selectedColor?.id === items?.id && (
                          <TickIcon style={{ width: "15px", height: "15px" }} />
                        )}
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.headingText}>
              {intl.formatMessage({
                  id: "Tag_icon",
                })}
                
                <span style={{ fontWeight: "normal" }}> &nbsp; (
                  {intl.formatMessage({
                  id: "optional",
                })}
                  )</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {dataLoader ? (
                  <Grid container justify="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  tagsMeta?.images?.map((items) => {
                    return (
                      <Grid
                        item
                        className={classes.tagsColor}
                        style={{
                          backgroundColor:
                            selectedTags?.id === items?.id
                              ? selectedColor?.bg_hex
                              : "#DBDFE7",
                        }}
                        onClick={() => handleClickImage(items)}
                      >
                        <img
                          src={items?.image}
                          style={{ height: "15px", width: "15px" }}
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px 30px 8px" }}>
          {props?.tagData?.name ? (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <TextButton onClick={() => handleDelete()}>
                  {loading && updatingType === "update" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "optional",
                    })
                    
                  )}
                </TextButton>
              </Grid>

              <Grid item>
                <TextButtonAdd onClick={handleAdd}>
                  {loading && updatingType === "update" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                    })
                    
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <TextButtonNoColor onClick={() => handleClose()}>
                  {intl.formatMessage({
                      id: "Cancel",
                    })}
                  
                </TextButtonNoColor>
              </Grid>

              <Grid item>
                <TextButtonAdd onClick={handleAdd}>
                  {loading && updatingType === "add" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Create",
                    })
                    
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headertext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  tagsColor: {
    width: "27px",
    height: "27px",
    borderRadius: "5px",
    margin: "7.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },
  bodyText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));
