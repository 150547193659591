import {
  VENUE_ORDERS,
  TEMP_REVIEW_ORDER_LIST,
  REMOVE_ITEM_REVIEW_LIST,
  REVIEW_ORDER,
  ORDER_HISTORY,
  REVIEW_ORDER_LIST,
  LOGIN_USER,
  LOGIN_ERROR,
  USER_DETAIL,
  VENUE_DETAIL,
  ORDER_COUNT,
  GET_TAB_LIST,
  TABLE_TAB,
  GET_REPORT,
  GET_TAB_ORDERS,
  REMOVE_VENUE_DETAILS,
  ADD_LANGUAGE,
  ADD_VENUE,
  UPDATE_ORDER_STATUS_IN_HISTORY,
  UPDATE_ORDER_STATUS_IN_TAB
} from "../constant/types";

const initialState = {
  submittedOrder: [],
  report: null,
  orderHistory: [],
  AuthUserKey: null,
  LoginError: null,
  UserDetail: null,
  VenueDetail: null,
  reviewOrderList: [],
  tempReviewOrderList: [],
  orderCount: null,
  lastCount: null,
  allTabs: null,
  tabTables: null,
  tabOrders: null,
  pendingOrders: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VENUE: {
      console.log("addding reducer payload", payload);
      return {
        ...state,
        UserDetail: { ...state.UserDetail, venues: [...payload] },
      };
    }

    // tabOrders
    // orders
    case UPDATE_ORDER_STATUS_IN_TAB: {
      let results = state.tabOrders.orders;
      let index = results.findIndex(
        (item) => item?.order_uuid === payload?.order_uuid
      );
      let order = results[index];
      order.status = payload.status;
      results[index] = { ...order };
      return {
        ...state,
        tabOrders: { ...state.tabOrders, orders: [...results] },
      };
    }
    case UPDATE_ORDER_STATUS_IN_HISTORY: {
      let results = state.orderHistory.results;
      let index = results.findIndex(
        (item) => item?.order_uuid === payload?.order_uuid
      );
      let order = results[index];
      order.status = payload.status;
      results[index] = { ...order };
      return {
        ...state,
        orderHistory: { ...state.orderHistory, results: [...results] },
      };
    }
    case ADD_LANGUAGE:
      return {
        ...state,
        VenueDetail: {
          ...state?.VenueDetail,
          supported_translations: [...payload],
        },
      };
    case REMOVE_VENUE_DETAILS: {
      return {
        ...state,
        VenueDetail: null,
      };
    }
    case GET_TAB_ORDERS: {
      return {
        ...state,
        tabOrders: payload,
      };
    }
    case GET_REPORT: {
      return {
        ...state,
        report: payload,
      };
    }
    case VENUE_ORDERS:
      state.reviewOrderList = state.tempReviewOrderList;
      return {
        ...state,
        submittedOrder: payload,
      };
    case ORDER_HISTORY:
      return {
        ...state,
        orderHistory: payload,
      };
    case LOGIN_USER:
      return {
        ...state,
        AuthUserKey: payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        LoginError: payload,
      };
    case TABLE_TAB:
      return {
        ...state,
        tabTables: payload,
      };
    case ORDER_COUNT:
      let c = state.orderCount;

      return {
        ...state,
        orderCount: payload,
        lastCount: c,
      };

    case USER_DETAIL:
      return {
        ...state,
        UserDetail: payload,
      };
    case VENUE_DETAIL:
      return {
        ...state,
        VenueDetail: payload,
      };
    case "VENUE_DETAILS":
      return {
        ...state,
        VenueDetail: { ...state?.VenueDetail, ...payload },
      };
    case REVIEW_ORDER_LIST:
      return {
        ...state,
        reviewOrderList: state.tempReviewOrderList,
      };
    case TEMP_REVIEW_ORDER_LIST:
      return {
        ...state,
        tempReviewOrderList: [...state.tempReviewOrderList, payload],
      };
    case REVIEW_ORDER:
      let c1 = state.lastCount - 1;

      return {
        ...state,
        orderCount: c1,
        submittedOrder: state.submittedOrder.map((item, index) => {
          if (item.order_uuid === payload.order_uuid) {
            return payload;
          }
          return item;
        }),
      };
    case REMOVE_ITEM_REVIEW_LIST:
      if (payload?.autoSave) {
        return {
          ...state,
          submittedOrder: payload?.order
            ? payload?.order
            : state.submittedOrder,
          submittedOrder: payload?.order
            ? payload?.order
            : state.submittedOrder,
        };
      } else {
        return {
          ...state,
          tempReviewOrderList: payload?.order
            ? payload?.order
            : state.tempReviewOrderList,
          reviewOrderList: payload?.order
            ? payload?.order
            : state.reviewOrderList,
        };
      }
    case GET_TAB_LIST:
      return {
        ...state,
        allTabs: payload,
      };

    default:
      return state;
  }
}
