import {
  GET_MEUN_AVAILABILITY,
  UPDATE_MENU_AVAILABILITY_MENU,
  UPDATE_MENU_AVAILABILITY_CATEGORY,
  REMOVE_TAG,
  UPDATE_MENU_AVAILABILITY_ITEM,
  UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS,
  UPDATE_MENU_AVAILABILITY_CUSTOMIZATION,
} from "../constant/types";

const initialState = {
  menuDetails: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // menuId     (item)
  // categoryId
  // item
  // availabilityId
  // customization_id
  //customization_option_id
  switch (type) {
    case GET_MEUN_AVAILABILITY: {
      return {
        ...state,
        menuDetails: payload,
      };
    }
    case UPDATE_MENU_AVAILABILITY_MENU: {
      let menu = [...state.menuDetails];
      let ind = menu.findIndex(
        (n) => n.menu_id === action.payload?.item?.menuId
      );
      if (ind > -1) {
        menu[ind].availability = action.payload.data;
      }
      return {
        ...state,
        menuDetails: [...menu],
      };
    }
    case UPDATE_MENU_AVAILABILITY_CATEGORY: {
      let menu = [...state.menuDetails];
      let ind = menu.findIndex(
        (n) => n.menu_id === action.payload?.item?.menuId
      );
      let newMenuItem = [];
      if (ind > -1) {
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.menu_category_id === action.payload?.item?.categoryId
        );

        if (indCategory > -1) {
          newMenuItem[indCategory].availability = action.payload.data;
          menu[ind].menu_categories = [...newMenuItem];
          return {
            ...state,
            menuDetails: [...menu],
          };
        }
      }
    }

    case UPDATE_MENU_AVAILABILITY_ITEM: {
      const { item, menuId, categoryId, data } = action.payload;
      let menu = [...state.menuDetails];
      let ind = menu.findIndex(
        (n) => n.menu_id === action.payload?.item?.menuId
      );

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.menu_category_id === action.payload?.item?.categoryId
        );

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex(
            (n) => n.item_id === action.payload?.item?.item
          );
          if (indItem > -1) {
            itemsList[indItem].availability = action?.payload?.data;

            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];
            return {
              ...state,
              menuDetails: [...menu],
            };
          }
        }
      }
    }
    case UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS: {
      let menu = [...state.menuDetails];
      let ind = menu.findIndex(
        (n) => n.menu_id === action.payload?.item?.menuId
      );

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.menu_category_id === action.payload?.item?.categoryId
        );

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex(
            (n) => n.item_id === action.payload?.item?.item
          );
          if (indItem > -1) {
            let customization = itemsList[indItem].item_customisations;

            let indCust = customization.findIndex(
              (n) =>
                n.customisation_section_id ===
                action.payload?.item?.customization_id
            );
            if (indCust > -1) {
              let customizationOpt =
                customization[indCust].item_customisation_options;

              let indCustOption = customizationOpt.findIndex(
                (n) =>
                  n.customisation_option_id ===
                  action.payload?.item?.customization_option_id
              );
              if (indCustOption > -1) {
                customizationOpt[indCustOption].availability =
                  action.payload.data;
                customization[indCust].item_customisation_options = [
                  ...customizationOpt,
                ];
                itemsList[indItem].item_customisations = [...customization];
                newMenuItem[indCategory].items = [...itemsList];
                menu[ind].menu_categories = [...newMenuItem];
                return {
                  ...state,
                  menuDetails: [...menu],
                };
              }
            }
          }
        }
      }
    }

    case UPDATE_MENU_AVAILABILITY_CUSTOMIZATION: {
      let menu = [...state.menuDetails];
      let ind = menu.findIndex(
        (n) => n.menu_id === action.payload?.item?.menuId
      );

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.menu_category_id === action.payload?.item?.categoryId
        );

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex(
            (n) => n.item_id === action.payload?.item?.item
          );
          if (indItem > -1) {
            let customization = itemsList[indItem].item_customisations;

            let indCust = customization.findIndex(
              (n) =>
                n.customisation_section_id ===
                action.payload?.item?.customization_id
            );
            if (indCust > -1) {
              let customizationOpt =
                customization[indCust].item_customisation_options;

              let indCustOption = customizationOpt.findIndex(
                (n) =>
                  n.customisation_option_id ===
                  action.payload?.item?.customization_option_id
              );

              customization[indCust].availability = action.payload.data;
              itemsList[indItem].item_customisations = [...customization];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                menuDetails: [...menu],
              };
            }
          }
        }
      }
    }

    case REMOVE_TAG: {
      const { id, menuId, categoryId, data } = action.payload;
      let menu = [...state.menuDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            let tags = itemsList[indItem].tags;
            let tagIndex = tags.findIndex((n) => n.name === data);
            if (tagIndex > -1) {
              tags.splice(tagIndex, 1);
              itemsList[indItem].tags = [...tags];
              newMenuItem[indCategory].items = [...itemsList];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                menuDetails: {
                  ...state.menuDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }

    default:
      return state;
  }
}
