import React from "react";
import QrPic from "../assets/image/qrcode.png";
import VerifyTabDialog from "../Components/DialogBox/VerifyTabDialog";
// import { useEffect, useState, Component, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
const DownloadButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
    "&:focus": {
      // boxShadow: 'none',
      // backgroundColor: 'white',
      // borderColor: '#005cbf',
      backgroundColor: "#F9FAFC",
      color: "#34CEB2",
      border: "  1px solid #34CEB2",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    height: "88vh",
    background: "#F9FAFC",
  },
  QrCode: {
    margin: "20px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  webAddress: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  downloadText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
}));
function QRcode() {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid className={classes.QrCode}> QR codes </Grid>
          <Grid style={{ textAlign: "center" }}>
            <img src={QrPic} alt="no QR" width={"190px"}></img>
            <p className={classes.webAddress}>dinein.blend.app</p>
            <DownloadButton variant="contained" color="primary">
              Download QR code image{" "}
            </DownloadButton>
            <p className={classes.downloadText}>
              Download QR code print as pdf{" "}
            </p>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <VerifyTabDialog dialogOpen={true} setDialogOpen={() => {}} />
    </div>
  );
}

export default QRcode;
