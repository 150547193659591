import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { updateAddCustomization } from "../../actions/menu";
import { useIntl } from "react-intl";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#fff",
  },
  input: {
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #EDF0F4",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    maxHeight: "20px",
    color: "#303A5F",
    "&:focus": {
      borderRadius: 4,
      backgroundColor: "#fff",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {},
  item: {
    "& .MuiListItem-button:hover": {
      backgroundColor: "#fff !important",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#fff !important",
    },
    "& .MuiMenuItem-roots": {
      backgroundColor: "#fff !important",
    },
  },
  label: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    "& .MuiListItem-button:hover": {
      backgroundColor: "#fff !important",
    },
  },
  checkBox: {
    padding: "0px 9px",
    "& .MuiIconButton-root": {
      padding: "0px 9px",
      color: "red",
    },
    "& .PrivateSwitchBase-root": {
      padding: "0px 9px",
    },
    "& .MuiIconButton-root": {
      padding: "0px 9px",
    },
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const [option, setOption] = React.useState(0);
  const handleChange = (event) => {
    setOption(event.target.value);
    updateOptions(event.target.value);
  };

  const updateOptions = (value) => {
    let index = props?.data.findIndex((n) => n?.id === value);
    if (index > -1) {
      let obj = props?.data[index];
      dispatch(updateAddCustomization({ ...props?.ids, templete: obj }));
      let newObj = {
        name: obj?.name,
        required: obj?.required,
        max_selectable: obj?.max_selectable,
        display_as_main_item: obj?.display_as_main_item,
        item_customisation_id: obj?.id,
        position: obj?.position,
        customisation_template: obj?.id,
      };
      let newArr = [];
      obj &&
        obj.template_options &&
        obj.template_options.forEach((element) => {
          newArr.push({
            name: element?.name,
            price: element?.price,
            pre_selected: element?.pre_selected,
            position: element?.position,
            customisation_option_id: element?.id,
          });
        });

      newObj["customisation_options"] = newArr;
      props.updateItemsCustomization(newObj);
    }
  };
  useEffect(() => {
    setOption(0);
  }, [props?.item]);
  const findElement = (element) => {
    if (props.item_customisations) {
      let ind = props.item_customisations.findIndex(
        (n) => n?.customisation_template === element?.id
      );
      if (ind > -1) {
        return true;
      } else {
        return false;
      }
    }
  };
  const getItems = (name, n) => {
    let itemsStr = "";
    let itemsStr2 = "";
    if (n.length > 0) {
      itemsStr = "(";
      n.forEach(
        (a, i) =>
          i < 3 && (itemsStr = itemsStr + `${i > 0 ? ", " : ""}${a.name}`)
      );
    }
    itemsStr = name + ` ` + itemsStr;
    itemsStr2 = itemsStr;
    if (itemsStr2.length > 50) {
      itemsStr = itemsStr.slice(0, 50) + " ...";
    }
    if (n.length > 0) {
      itemsStr = itemsStr + ")";
    }
    return itemsStr;
  };
  return (
    <div>
      <FormControl className={classes.margin} fullWidth>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={option}
          onChange={handleChange}
          // multiple
          input={<BootstrapInput />}
          renderValue={(selected) => (
            <span value={0} className={classes.item}>
              <span className={classes.label}>
              {intl.formatMessage({
                      id: "choose_customization",
                    })}
                
              </span>
            </span>
          )}
        >
          {/* <MenuItem value={0}>
            <span className={classes.label}>
              Choose existing customization group or create a new
            </span>
          </MenuItem> */}

          {Array.isArray(props?.data) &&
            props?.data?.map((n) => {
              return (
                <MenuItem value={n.id}>
                  <Checkbox
                    checked={findElement(n)}
                    className={classes.checkBox}
                    style={{ color: findElement(n) ? "#F66761" : "#A3A9BC" }}
                  />
                  <span className={classes.label}>
                    {/* {n.name}
                    &nbsp; */}
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "17px",
                      }}
                    >
                      {getItems(n.name, n.assigned_items)}
                    </span>
                  </span>
                  {/* {n.name} */}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
