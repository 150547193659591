import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../../../../assets/image/right.svg";

const useStyles = makeStyles((theme) => ({
  TextInputStyleDisabled: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#A3A9BC",
    padding: "10px",
    // minWidth: "350px",
    background: "#F9FAFC !important",
    border: "1px solid #EDF0F4",
    
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [item, setItem] = useState({});
const [values,setValues]=useState("")
  useEffect(() => {
    setItem(props?.item ? props?.item : {});
    if(props?.item&&props?.item?.translations&&props?.item?.translations[0]?.text){
        setValues(props?.item?.translations[0]?.text)
      }else{
        setValues("")
      }
 // eslint-disable-next-line 
  }, [props,props?.item]);

 
  const handleChangeValues = (e) => {
      props.handleChangeValue(e,props.index)
    setValues(e.target.value)
  };

    
 return (
            <Grid
              container
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "Item_name",
                  })}
                  fullWidth
                  disabled
                  value={item?.name}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  inputProps={{
                    className: classes.TextInputStyleDisabled,
                    "aria-label": "search",
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "28px",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <RightIcon />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChangeValues(e)}
                  placeholder={item?.name}
                  value={values}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  inputProps={{
                    className: classes.TextInputStyle,
                    "aria-label": "search",
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                  }}
                />
              </Grid>
            </Grid>
          );
       
   
  
}
