import {
  GET_VENUE_TABLES,
  ADD_VENUE_TABLE,
  DELETE_VENUE_TABLE,
  UPDATE_VENUE_TABLE,
  ADD_TABLES,
  ADD_LANGUAGE,
  GET_ALL_LANGUAGES,
  SET_MENU_MANAGER_LANGUAGE
} from "../constant/types";
import language from "../reducers/language";
import API from "../utils/API";

export const deleteLanguage = (data1) => async (dispatch,getState) => {
  let venue_uuid= getState()?.VenueOrderReducer?.VenueDetail?.uuid;
  let languageReducer=getState()?.languageReducer;
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {...data1};

  try {
    const response = await API.post(`/business/venue/language/remove/`,body, config);
    // let data = response?.data;
    let data = response?.data;
    let arr=[];
    data.forEach(element => {
      arr.push(element?.code)
    });
    let index=arr.findIndex(a=>a===languageReducer?.menuManagerLanguage)
    
    dispatch({ type: ADD_LANGUAGE, payload: arr });

if(index<0){
  dispatch({ type: SET_MENU_MANAGER_LANGUAGE, payload: null });
}
    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }
};
export const addLanguage = (data1) => async (dispatch,getState) => {
  let venue_uuid= getState()?.VenueOrderReducer?.VenueDetail?.uuid;
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {...data1};

  try {
    const response = await API.post(`/business/venue/language/add/`,body, config);
    let data = response?.data;
    let arr=[];
    data.forEach(element => {
      arr.push(element?.code)
    });
    dispatch({ type: ADD_LANGUAGE, payload: arr });

    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }
};
export const getAllLanguages = () => async (dispatch,getState) => {
  
  let venue_uuid= getState()?.VenueOrderReducer?.VenueDetail?.uuid;
  let VenueDetail= getState()?.VenueOrderReducer;
  let languages= getState()?.languageReducer?.languagesList;

  if((venue_uuid)){
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    const response = await API.get(`/business/venue/language/`, config);
    let data = response?.data;
    dispatch({ type: GET_ALL_LANGUAGES, payload: data });

    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }}
};
export const setMenuManagerLanguage = (data) => async (dispatch,getState) => {
  
 
  try {
    dispatch({ type: SET_MENU_MANAGER_LANGUAGE, payload: data });

    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }
};
