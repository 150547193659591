import React, { useState, useEffect } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { applyDragMenu, generateItems } from "../../utils/utils";
import CreateIcon from "@material-ui/icons/Create";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

const TransitionDuration = (props) => {
  const theme = useTheme();

  let dispatch = useDispatch();

  const [items, setItems] = useState(
    generateItems(50, (index) => {
      return {
        id: index,
        data: "Draggable" + index,
        item: [...props.items],
      };
    })
  );
  useEffect(() => {
    let arr = [];

    props.items &&
      props.items.forEach((singleItem, index) => {
        arr.push({
          ...singleItem,
          data: singleItem.name,
          id:
            props?.selectionParam === "item"
              ? singleItem.item_id
              : singleItem.id,
        });
      });
    setItems(arr);
  }, [props]);
  const handleEdit = (p, parm, e) => {
    e.stopPropagation();
    props.handleEditClick(p, parm);
  };
  const handleSelect = (p, parm, e) => {
    e.stopPropagation();
    props.handleSelect(p, parm);
  };
  const getTranslatedName = (item) => {
    if (item?.translations?.length > 0) {
      let index = item?.translations.findIndex((i) => i.field === "name");

      if (index > -1) {
        return item?.translations[index]?.text;
      } else {
        return item?.data;
      }
    } else {
      return item?.data;
    }
  };
  const draps = (items, e, dispatch) => {
    const { addedIndex } = e;
    props.selectMenuByDrag(items[addedIndex]?.id, addedIndex);
    return applyDragMenu(items, e, dispatch, props?.selectionParam, props?.ids);
  };
  return (
    <Container
      animationDuration={500}
      lockAxis="y"
      onDrop={(e) =>
        setItems(draps(items, e, dispatch, props?.selectionParam, props?.ids))
      }
      dragClass={props.selectedClass}
      dropClass={props.class}
      style={{
        background: "#fff",
        fontFamily: theme.fontFamily,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#303A5F",
        width: "100%",
        alignItems: "center",
        cursor: "pointer",
        paddingBottom: "60px",
      }}
    >
      {items.map((p, index) => {
        return (
          <Draggable key={p.id}>
            <div
              className="draggable-item"
              style={{
                height: "60px",
                display: "flex",
                width: "100%",
                // justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                paddingLeft: "16px",
                backgroundColor:
                  props?.selectedMenuId === p.id ? "#F66761" : "",
                color: props?.selectedMenuId === p.id ? "#fff" : "",
              }}
              onClick={(e) => handleSelect(p.id, props?.selectionParam, e)}
            >
              {props?.selectedMenuId === p.id && (
                <div>
                  {props?.selectionParam !== "item" && (
                    <CreateIcon
                   
                      style={{fontSize:"15px", margin:"0px 12px 0px 2px" }}
                      onClick={(e) => handleEdit(p, props?.selectionParam, e)}
                    />
                  )}
                </div>
              )}
              <span
                style={{
                  paddingRight:
                    props?.selectionParam !== "item" ? "40px" : "16px",
                }}
              >
                {getTranslatedName(p)}
              </span>
             {props?.selectedMenuId === p.id && <Grid style={{ position: "absolute", right: 35 }}>
                <Grid>
                  {index > 0 && (
                    <ArrowDropUpIcon
                      style={{ color: "#fff" }}
                      onClick={(e) =>{
                        setItems(
                          draps(
                            items,
                            {
                              removedIndex: index,
                              addedIndex: index - 1,
                              payload: null,
                            },
                            dispatch,
                            props?.selectionParam,
                            props?.ids
                          )
                        )
                        e.preventDefault();
                        e.stopPropagation()
                      }
                      }
                    />
                  )}
                </Grid>
                <Grid>
                 {items.length>index+1 &&<ArrowDropDownIcon
                    style={{ color: "#fff" }}
                    onClick={(e) =>{
                      setItems(
                        draps(
                          items,
                          {
                            removedIndex: index,
                            addedIndex: index + 1,
                            payload: null,
                          },
                          dispatch,
                          props?.selectionParam,
                          props?.ids
                        )
                      )
                      e.preventDefault();
                      e.stopPropagation()
                    }
                    }
                  />}
                </Grid>
              </Grid>}
            </div>
          </Draggable>
        );
      })}
    </Container>
  );
};

export default TransitionDuration;
