import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

export default function ModelHeader() {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.subHeading}>
          <Typography className={classes.columnHeading}>
            {intl.formatMessage({
              id: "Venue permissions",
              defaultMessage:"Venue permissions"
            })}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
    heading:{
        marginTop:"14px"

    },
  columnHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  subHeading:{
      marginTop:"12px"
  }
}));
