import { GET_REPORT, CLEAR_REPORT } from "../constant/types";

const initialState = {
  report: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REPORT: {
      return {
        ...state,
        report: payload,
      };
    }
    case CLEAR_REPORT: {
      return {
        ...state,
        report: null,
      };
    }
    default:
      return state;
  }
}
