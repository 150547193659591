import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { TextButtonAdd, TextButton } from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { addMenu, deleteMenu, updateMenu } from "../../actions/menu";
import { addLanguage, getAllLanguages,deleteLanguage } from "../../actions/Languages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useIntl } from "react-intl";

export default function LanguageForm(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  let languagesList = useSelector(
    (state) => state?.languageReducer?.languagesList
  );
  // State VenueOrderReducer VenueDetail supported_translations
  let venueDetail = useSelector(
    (state) => state?.VenueOrderReducer?.VenueDetail
  );
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");
  const [errors, setErrors] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    console.log("event.target.value",event.target.value)
    setName(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };
  useEffect(() => {
    setName(props?.language);
  }, [props?.language]);
  useEffect(() => {
    setName(props?.language);
  }, []);
  useEffect(() => {
    dispatch(getAllLanguages());
  }, []);
  const handleAdd = async () => {
    if (name && name?.trim() !== "") {
      setLoading(true);
      setUpdatingType("add");
      let res = await dispatch(addLanguage({ code: name }));
      if (res === 200) {
        setName("");
        handleClose()
      } else {
        setErrors(true);
      }
      setLoading(false);
      setUpdatingType("");
    } else {

      setErrors(true);
    }
  };
  const handleUpdate = async () => {
    if (name && name?.trim() !== "") {
      setLoading(true);
      setUpdatingType("update");
      let res = await dispatch(
        updateMenu({ id: props?.item?.item?.id, name: name })
      );
      if (res === 200) {
        props && props.updateItem();
      }else{
      setErrors(true);
      }
      setLoading(false);
      setUpdatingType("");
    } else {
      setErrors(true);
    }
  };
  const handleDelete = async () => {
    setLoading(true);
    setUpdatingType("delete");
    setOpenDelete(false)
    let res = await dispatch(deleteLanguage({ code: name }));
    if (res === 200) {
      handleClose()
    }
    setLoading(false);
    setUpdatingType("");
  };
  const handleOpenDeleteModal = () => {
    setOpenDelete(true);
  };
  const findLanguage=(code)=>{
    let index=languagesList&& Object.keys(languagesList).findIndex(a=>a===code)
    // let index=languagesList&&languagesList.findIndex((n)=>n.code===code)

    // console.log("supported_translations",venueDetail?.supported_translations)
    // console.log("languagesList",languagesList)
    try{
    if(index<0){
      return true;
    }else{
      if(props?.language===code){
        return true;
      }else{
      return false;
    }
      
    }}
    catch(e){
      console.log("error=======>",e)
      return true;

    }
  }
  return (
    <div>
      {openDelete && (
        <ConfirmDeleteDialog
          open={openDelete}
          handleClose={setOpenDelete}
          confirmDelete={handleDelete}
          type="menu"
          name={props?.item?.item?.name}
        />
      )}
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              {/* {console.log("language",props)} */}
              {/* {props?.language} */}
              <Typography className={classes.headersTest}>
                {props?.language
                  ? intl.formatMessage({
                      id: "edit_language",
                      defaultMessage: "Edit language",
                    })
                  : intl.formatMessage({
                      id: "add_new_language",
                      defaultMessage: "Add new language",
                    })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography className={classes.headingTest}>
              {intl.formatMessage({
                id: "language",
              })}
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
              error={errors}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={name}
                // defaultValue={name}
                onChange={handleChange}
                // label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
               {languagesList&&Object.keys(languagesList).map((item) => {
                    return <MenuItem 
                    // style={{display:findLanguage(item)?"":"none"}}
                     value={item}>{languagesList[item]}</MenuItem>
                  })}
                {/* {venueDetail?.supported_translations &&
                  venueDetail?.supported_translations.map((item) => {
                    return <MenuItem style={{display:findLanguage(item)?"":"none"}} value={item}>{item}</MenuItem>
                  })} */}
              </Select>
              <FormHelperText>
            {errors && intl.formatMessage({
                id: "Field_is_required",
              })}

              </FormHelperText>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px  30px 24px " }}>
          {props?.language ? (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <TextButton disabled={loading} onClick={handleOpenDeleteModal}>
                  {loading && updatingType === "delete" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "delete_language",
                    })
                  )}
                </TextButton>
              </Grid>

              <Grid item>
                <TextButtonAdd disabled={loading} onClick={handleUpdate}>
                  {loading && updatingType === "update" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                    })
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <TextButtonAdd disabled={loading} onClick={handleAdd}>
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  intl.formatMessage({
                    id: "Save",
                  })
                )}
              </TextButtonAdd>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headersTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  formControl: {
    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
    "& :focus": {
      outline: "none",
      // border:"1px solid red",
    },
  },
}));

// background: #FFFFFF;
// /* Input field */

// border: 1px solid #EDF0F4;
// box-sizing: border-box;
// border-radius: 5px;
