import {SET_MENU_MANAGER_LANGUAGE, UPDATE_LANGUAGE,GET_ALL_LANGUAGES,ADD_LANGUAGE,UPDATE_VENUE_LANGUAGE,DELETE_LANGUAGE} from "../constant/types";

const initialState = {
  language: "en",
  languagesList:[],
  menuManagerLanguage:""
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LANGUAGES: {
      return {
        ...state,
        languagesList: payload,
      };
    }
    case SET_MENU_MANAGER_LANGUAGE: {
      return {
        ...state,
        menuManagerLanguage: payload,
      };
    }
    // case ADD_LANGUAGE: {
    //   return {
    //     ...state,
    //     languagesList: [...payload],
    //   };
    // }
    case UPDATE_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    default:
      return state;
  }
}
