import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSearch, getNextSearchTab, getAllTabs } from "../../actions/order";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import "./VenueTables.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import {
  getVenueTables,
  getVenueTablesNext,
  updateVenueTable,
  deleteVenueTable,
  addVenueTable,
} from "../../actions/VenueTables";
import CreateGuestSessionDialog from "../../Components/GuestSession/CreateGuestSession";
import { ReactComponent as Edit } from "../../assets/image/edit.svg";
import { ReactComponent as Download } from "../../assets/image/download.svg";
import CreateIcon from "@material-ui/icons/Create";
import { Container, Draggable } from "react-smooth-dnd";
import { applyDragMenu, generateItems } from "../../utils/utils";
import { ReactComponent as DeleteIcon } from "../../assets/image/delete.svg";
import CheckIcon from "@material-ui/icons/Check";
import DeleteTableDialog from "../../Components/VenueTables/DeleteTableDialog";
import EditTableDialog from "../../Components/VenueTables/EditTableDialog";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

function VenueTables(props) {
  const theme = useTheme();
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  const [value, setValue] = React.useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  let dispatch = useDispatch();
  const [tablesData, setTablesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState({ item: null, index: null });
  const [item, setItem] = useState({ item: null, index: null });

  let venueTablesList = useSelector(
    (state) => state.venueTables?.venueTablesList?.results
  );
  let next = useSelector((state) => state.venueTables);

  const [SearchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const fetchTablesList = async () => {
    setLoading(true);

    if (venueDetail?.uuid) {
      dispatch(getVenueTables({ venue_uuid: venueDetail?.uuid }));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 9000);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (venueTablesList) {
      setTablesData(venueTablesList);
    }
  }, [venueTablesList]);
  useEffect(() => {
    console.log("next", next?.venueTablesList);
    if (next?.venueTablesList?.next) {
      console.log("next2", next?.venueTablesList);

      dispatch(
        getVenueTablesNext({
          venue_uuid: venueDetail?.uuid,
          url: next?.venueTablesList?.next,
        })
      );
    }
    // eslint-disable-next-line
  }, [next]);
  // useEffect(() => {
  //   fetchTablesList();
  //   if (venueTablesList) {
  //     setTablesData(venueTablesList);
  //   }
  //   // eslint-disable-next-line
  // }, [venueDetail]);
  useEffect(() => {
    fetchTablesList();
    if (venueTablesList) {
      setTablesData(venueTablesList);
    }
    // eslint-disable-next-line
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && value !== null && value.trim() !== "") {
      handleAddTable();
    }
  };
  const handleUpdateTable = async () => {
    if (venueDetail?.uuid) {
      console.log(editItem);
      let res = await dispatch(
        updateVenueTable({
          venue_uuid: venueDetail?.uuid,
          data: {
            number: editItem?.item?.number,
          },
          id: editItem?.item?.id,
        })
      );
      if (res?.status === 200) {
        setEditItem({ item: null, index: null });
      }
    }
  };
  const handleAddTable = async () => {
    if (venueDetail?.uuid) {
      let res = await dispatch(
        addVenueTable({
          venue_uuid: venueDetail?.uuid,
          table_name: value,
          position: 1,
        })
      );
      if (res?.status === 200) {
        setValue("");
      } else {
        setErrorMessage(
          intl.formatMessage({
            id: "Something_went_wrong",
            defaultMessage: "Something went wrong",
          })
          );
      }
    }
  };
  const handleDeleteTable = async (item) => {
    setItem({ ...item });
    setOpenModel(true);
  };
  const confirmDelete = async () => {
    if (venueDetail?.uuid) {
      let res = await dispatch(
        deleteVenueTable({
          venue_uuid: venueDetail?.uuid,
          id: editItem?.item?.id,
        })
      );
      if (res?.status === 200) {
        setItem({ item: null, index: null });
        handleCloseModel();
        setEditItem({ item: null, index: null });
        setOpenEditModel(false);
      }
    }
  };

  const onRefresh = () => {};
  const fetchData = () => {
    setLoader(true);
    setTimeout(() => {
      if (venueTablesList?.length > tablesData?.length + 10) {
        setTablesData([
          ...tablesData,
          ...venueTablesList.slice(tablesData?.length, tablesData.length + 10),
        ]);
      } else {
        setTablesData([
          ...tablesData,
          ...venueTablesList.slice(tablesData?.length, venueTablesList?.length),
        ]);
      }
      setLoader(false);
    }, 0);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setItem({ item: null, index: null });
  };

  const handleEditClick = (item, index) => {
    setEditItem({ item: item, index: index });
    setOpenEditModel(true);
  };
  const handleChangeTableName = (e) => {
    setEditItem({
      ...editItem,
      item: { ...editItem.item, number: e.target.value },
    });
  };
  const [items, setItems] = useState(
    generateItems(50, (index) => {
      return {
        id: index,
        data: "Draggable" + index,
        item: [{ name: "abc" }],
      };
    })
  );
  const handleSelect = (p, parm, e) => {

    e.stopPropagation();
    props.handleSelect(p, parm);
  };
  const handleCloseEditModel = () => {
    setOpenEditModel(false);
    setEditItem({ item: null, index: null });
  };
  const download = (url, name) => {
    console.log(url);
    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + " qr-code.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {openModel && (
        <DeleteTableDialog
          selectedItem={editItem?.item?.number}
          confirmDelete={confirmDelete}
          open={openModel}
          handleCloseDialog={handleCloseModel}
        />
      )}
      {openEditModel && (
        <EditTableDialog
          open={openEditModel}
          item={editItem}
          confirmDelete={handleDeleteTable}
          handleCloseDialog={handleCloseEditModel}
          handleDeleteTable={handleDeleteTable}
        />
      )}
      <div>
        {venueDetail && venueDetail.uuid !== null ? (
          <Grid container className={classes.root} id="hideScroll">
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <div className={classes.TableGrid}>
                <Grid container className={classes.headingContainer}>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Typography className={classes.headingText}>
                    {intl.formatMessage({
                    id: "Table_name",
                    defaultMessage: "Table name",
                  })}
                      
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Typography className={classes.headingText}>
                    {intl.formatMessage({
                    id: "QR_Code",
                    defaultMessage: "QR Code",
                  })}
                      
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      variant="contained"
                      className={classes.printButton}
                      onClick={() => setOpenEditModel(true)}
                    >
                      {intl.formatMessage({
                    id: "Add_new_table",
                    defaultMessage: "Add new table",
                  })}
                      
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #EDF0F4",
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    marginTop: "20px",

                    borderBottom: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                  className="hideScroll"
                  id="scrollableDiv"
                >
                  {/* <InfiniteScroll
                    dataLength={tablesData.length}
                    next={fetchData}
                    hasMore={true}
                    loader={
                      loader && (
                        <Grid
                          container
                          justify="center"
                          style={{ height: "70px" }}
                        >
                          <CircularProgress size={40} color="primary" />
                        </Grid>
                      )
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>You have seen it all</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                    refreshFunction={onRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593; Release to refresh
                      </h3>
                    }
                    className={classes.infiniteScroll}
                  > */}
                  {loading ? (
                    <Grid
                      container
                      justify="center"
                      style={{ height: "70px", marginTop: "30px" }}
                    >
                      <CircularProgress size={40} color="primary" />
                    </Grid>
                  ) : (
                    <Grid container style={{}}>
                      <Container
                        animationDuration={500}
                        lockAxis="y"
                        onDrop={(e) =>
                          setTablesData(
                            applyDragMenu(
                              tablesData,
                              e,
                              dispatch,
                              "table",
                              props?.ids
                            )
                          )
                        }
                        dragHandleSelector=".column-drag-handle"
                        dropPlaceholder={{
                          animationDuration: 150,
                          showOnTop: true,
                          className: "drop-preview",
                        }}
                        style={{
                          fontFamily: theme.fontFamily,
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "18px",
                          color: theme.colors.primary,
                          width: "100%",
                          alignItems: "center",
                          paddingBottom: "60px",
                        }}
                        disableScrollOverlapDetection={true}
                        // dragClass="opacity-ghost"
                        // dropClass="opacity-ghost-drop"
                      >
                        {tablesData.map((n, index) => {
                          return (
                            <Draggable key={index}>
                              <div
                                className="draggable-item"
                                style={{
                                  display: "flex",
                                  width: "calc(100% - 10px)",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  position: "relative",
                                  margin: "5px",
                                  backgroundColor:
                                    index % 2 === 1 ? "#F6F6F8" : "#fff",
                                  borderRadius: "2px",
                                  height: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "5px",
                                }}
                              >
                                <Grid container justify="space-between">
                                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    {/* {editItem?.index === index ? (
                                      <TextField
                                        variant="outlined"
                                        placeholder="Enter name"
                                        fullWidth
                                        onChange={(e) =>
                                          handleChangeTableName(e)
                                        }
                                        value={editItem?.item?.number}
                                        style={{
                                          borderRadius: "5px",
                                          width: "100%",
                                        }}
                                        inputProps={{
                                          className: classes.TextInputStyle,
                                          "aria-label": "search",
                                        }}
                                        InputProps={{
                                          classes: {
                                            notchedOutline:
                                              classes.outlinedStyleBottom,
                                          },
                                        }}
                                      />
                                    ) : ( */}
                                    <Typography className={classes.headingText}>
                                      {n?.number}
                                    </Typography>
                                    {/* )} */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Typography className={classes.headingText}>
                                      <Download
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          download(n?.qr_image, n?.number)
                                        }
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Grid container justify="flex-end">
                                      <Grid
                                        item
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          className={classes.TableCellText}
                                        >
                                          <Edit
                                            className="column-drag-handle"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          paddingRight: "16px",
                                          paddingLeft: "16px",
                                        }}
                                      >
                                        <Typography
                                          className={classes.TableCellText}
                                        >
                                          <CreateIcon
                                            onClick={() =>
                                              handleEditClick(n, index)
                                            }
                                            style={{
                                              cursor: "pointer",

                                              color: theme.colors.primary,
                                              cursor: "pointer",
                                              fontSize: "18px",
                                            }}
                                          />
                                        </Typography>
                                      </Grid>

                                      {/* <Grid
                                        item
                                        style={{
                                          paddingRight: "12px",
                                          paddingLeft: "16px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          className={classes.TableCellText}
                                        >
                                          <DeleteIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleDeleteTable(n, index)
                                            }
                                          />
                                        </Typography>
                                      </Grid> */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </Draggable>
                          );
                        })}
                      </Container>
                    </Grid>
                  )}
                  {/* </InfiniteScroll> */}
                </Grid>
                {/* <Grid
                  container
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #EDF0F4",
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px",
                    overflow: "hidden",
                    borderTop: "0px",
                    paddingBottom: errorMessage !== "" ? "10px" : "",
                  }}
                >
                  <Grid container className={classes.tableRow}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter name"
                        fullWidth
                        onKeyDown={handleKeyDown}
                        error={errorMessage !== ""}
                        helperText={errorMessage}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        inputProps={{
                          className: classes.TextInputStyle,
                          "aria-label": "search",
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline:
                              errorMessage === "" ? classes.outlinedStyle : "",
                          },
                        }}
                      />
                    </Grid>
                
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={40} color="primary" />
          </Grid>
        )}
        <Grid item xs={1}></Grid>
      </div>
    </>
  );
}

export default VenueTables;

const useStyles = makeStyles((theme) => ({
  infiniteScroll: {
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "62vh",
    background: "#F9FAFC",
    height: "62vh",
    overflow: "auto",
    paddingBottom: "10px",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: theme.colors.primary,
    margin: "0px",
  },
  printButton: {
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 26px",
    "&:hover": {
      background: "#34CEB2",
    },
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
    // height: "60vh",
  },
  TableGrid: {
    overflowY: "auto",
    // height: "60vh",
    margin: "5px 0px 0px 0px",
    overflowX: "hidden",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  outlinedStyle: {
    border: "0px",
  },
  outlinedStyleBottom: {
    border: "0px",
    borderBottom: "1px solid #000",
    borderRadius: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
}));
