import { element } from "prop-types";
import {
  GET_VENUE_DETAILS,
  ADD_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_ITEM,
  ADD_CUSTOMIZATION,
  UPDATE_CUSTOMIZATION,
  DELETE_CUSTOMIZATION,
  GET_TAGS_META,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_TAG,
  GET_CUSTOMIZATION_LIST,
  ADD_ITEM,
  UPDATE_ITEM,
  GET_ALL_TAGS,
  ADD_TAGS_TEMPLATE,
  UPDATE_TAGS_TEMPLATE,
  DELETE_TAGS_TEMPLATE,
  REORDER_MENU,
  REORDER_CATEGORY,
  REORDER_ITEMS,
  REORDER_TABLES,
  REORDER_CUSTOMIZATIONS,
  REORDER_CUSTOMIZATIONS_OPTIONS,
  UPDATE_ALL_ITEM_CUSTOMIZATIONS,
  UPDATE_ITEM_PRICES,
  UPDATE_LANGUAGE
} from "../constant/types";
import { store } from "../store/store";
import API from "../utils/API";
export const updateLanguage= ({data}) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_LANGUAGE,
    payload: data,
  });
}
let venue_uuid = store.getState()?.VenueOrderReducer?.VenueDetail?.uuid;
export const getMenuDetails = ({ venue_uuid }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  if (venue_uuid) {
    try {
      const response = await API.get(
        `/api/business/venue/menu/details/${venue_uuid}/`,
        config
      );
      let data = response?.data;

      let newData = { ...data };
      let menus = [...newData.menus];
      menus.forEach((element, index) => {
        let obj = menus[index];
        menus[index] = { ...obj, position: index };
      });
      newData["menus"] = menus;
      dispatch({ type: GET_VENUE_DETAILS, payload: newData });

      return 200;
    } catch (error) {
      return error?.response?.status;
    }
  } else {
    return 400;
  }
};
export const addMenu = ({ name }) => async (dispatch) => {
  const body = JSON.stringify({
    name: name,
  });
  let res = {
    id: null,
    name: name,
    description: "",
    slug: name,
    image: null,
    menu_categories: [],
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `business/menu-manager/menu/`,
      body,
      config
    );
    let data = response?.data;
    res.id = data?.id;

    dispatch({
      type: ADD_MENU,
      payload: res,
    });

    return { status: 200, id: data?.id };
  } catch (error) {
    return { status: 400 };
  }
};

export const deleteMenu = ({ id }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `/business/menu-manager/menu/${id}/`,
      config
    );
    //
    dispatch({
      type: DELETE_MENU,
      payload: id,
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateMenu = ({ id, name }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.patch(
      `/business/menu-manager/menu/${id}/`,
      { name: name },
      config
    );
    dispatch({
      type: UPDATE_MENU,
      payload: { id: id, name: name },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCategory = ({ name, menuId }) => async (dispatch) => {
  const body = JSON.stringify({
    name: name,
    menu: menuId,
  });
  let res = {
    id: parseInt(Math.random() * (29 - 21) + 21),
    name: name,
    position: parseInt(Math.random() * (19 - 11) + 11),
    items: [],
  };
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `/business/menu-manager/category/`,
      body,
      config
    );
    let data = response?.data;

    res.id = data?.id;
    res.position = data?.position;
    dispatch({
      type: ADD_CATEGORY,
      payload: { data: res, menuId: menuId },
    });

    return { status: 200, id: data?.id };
  } catch (error) {
    return { status: 400 };
  }
};

export const deleteCategory = ({ id, menuId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `business/menu-manager/category/${id}/`,
      config
    );

    dispatch({
      type: DELETE_CATEGORY,
      payload: { id: id, menuId: menuId },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateCategory = ({ id, name, menuId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.patch(
      `business/menu-manager/category/${id}/`,
      { name: name },
      config
    );

    dispatch({
      type: UPDATE_CATEGORY,
      payload: { id: id, name: name, menuId: menuId },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteItem = ({ id, menuId, categoryId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `api/business/menu-manager/item/${id}/`,

      config
    );
    dispatch({
      type: DELETE_ITEM,
      payload: { id: id, menuId: menuId, categoryId: categoryId },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCustomization = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;

  const body = JSON.stringify({
    data,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    dispatch({
      type: ADD_CUSTOMIZATION,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateCustomization = ({
  id,
  menuId,
  categoryId,
  itemId,
  body,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let newObj = { ...body };
  newObj["id"] = newObj["item_customisation_id"];
  delete newObj["item_customisation_id"];
  delete newObj["customisation_options"];
  let newArr = [];
  body.customisation_options.forEach((n) => {
    newArr.push({
      name: n.name,
      position: n.position,
      pre_selected: n.pre_selected,
      price: n.price,
    });
  });
  newObj["item_customisation_options"] = [...newArr];

  try {
    const response = await API.patch(
      `/api/business/menu-manager/item/${itemId}/update-item-customisation/${id}/`,
      newObj,
      config
    );
    let resData = response?.data?.item_customisations;
    let indexCust = resData.findIndex((n) => n.id === id);
    if (indexCust > -1) {
      let updatedCust = resData[indexCust];
      let newObjCust = {
        ...updatedCust,
      };
      newObjCust["item_customisation_id"] = newObjCust["id"];
      delete newObjCust["id"];
      delete newObjCust["item_customisation_options"];
      let newArr2 = [];

      updatedCust.item_customisation_options.forEach((n) => {
        newArr2.push({
          customisation_option_id: n.id,
          name: n.name,
          position: n.position,
          pre_selected: n.pre_selected,
          customisation_template: n?.customisation_template,
          price: n.price,
        });
      });
      newObjCust["customisation_options"] = [...newArr2];
      dispatch({
        type: UPDATE_CUSTOMIZATION,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          itemId: itemId,
          data: newObjCust,
        },
      });
    }
    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteCustomization = ({
  id,
  menuId,
  categoryId,
  itemId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `/api/business/menu-manager/item/${itemId}/remove-item-customisation/${id}/`,
      config
    );
    dispatch({
      type: DELETE_CUSTOMIZATION,
      payload: {
        id: id,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const getTagsMeta = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(
      `api/business/menu-manager/tags/tag-images-colours/`,
      config
    );
    dispatch({
      type: GET_TAGS_META,
      payload: {
        response,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const addTags = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;
  //
  if (itemId) {
    const config = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.post(
        `api/business/menu-manager/item/${itemId}/add-tag/`,
        { tag_id: data },
        config
      );

      let d = [...response?.data?.item_tags];

      let ind = d.findIndex((a) => a.tag_id === data);
      let newObj = d[ind];
      newObj.id = d[ind].tag_id;
      if (ind > -1) {
        dispatch({
          type: ADD_TAG,
          payload: {
            id: itemId,
            menuId: menuId,
            categoryId: categoryId,
            data: d[ind],
          },
        });
      }
      
      return {status:200,data:d};
    } catch (error) {
      return 400;
    }
  }
};
export const removeTagsFromItem = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;
  //
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    const response = await API.post(
      `api/business/menu-manager/item/${itemId}/remove-tag/`,
      { tag_id: data },
      config
    );
      dispatch({
        type: REMOVE_TAG,
        payload: {
          id: itemId,
          menuId: menuId,
          categoryId: categoryId,
          data: data
        },
      });
    return 200;
  } catch (error) {
    return 400;
  }
};
export const deleteTags = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const { itemId, menuId, categoryId, tag } = payload;
  try {
    dispatch({
      type: REMOVE_TAG,
      payload: {
        id: itemId,
        menuId: menuId,
        categoryId: categoryId,
        data: tag,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateTags = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const { itemId, menuId, categoryId, data, tag } = payload;
  try {
    dispatch({
      type: UPDATE_TAG,
      payload: {
        id: itemId,
        menuId: menuId,
        categoryId: categoryId,
        data: data,
        tag: tag,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const getCustomizationList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(
      `/business/menu-manager/customisation-template/`,
      config
    );
    let data = response?.data;

    dispatch({
      type: GET_CUSTOMIZATION_LIST,
      payload: data,
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const addCustomizationTemplate = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, data } = payload;

  let newObj = {
    name: data?.name,
    required: data?.required,
    max_selectable: data?.max_selectable,
    display_as_main_item: data?.display_as_main_item,
  };
  let newArr = [];

  data &&
    data.customisation_options &&
    data.customisation_options.forEach((element) => {
      newArr.push({
        name: element?.name,
        price: element?.price,
        pre_selected: element?.pre_selected,
      });
    });

  newObj["template_options"] = newArr;

  const body = JSON.stringify({
    ...newObj,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `business/menu-manager/customisation-template/`,
      body,
      config
    );
    let newData = { ...data };
    newData.item_customisation_id = response?.data?.id;
    newData.position = response?.data?.position;

    let newArr2 = [];
    response.data.template_options.forEach((n) => {
      newArr2.push({
        customisation_option_id: n?.id,
        name: n?.name,
        position: n?.position,
        pre_selected: n?.pre_selected,
        price: n?.price,
      });
    });
    newData["customisation_options"] = newArr2;

    dispatch({
      type: ADD_CUSTOMIZATION,
      payload: {
        data: newData,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });
    return { id: response?.data?.id, status: 200 };
  } catch (error) {
    console.log("error", error);
    return { status: 400 };
  }
};

export const updateCustomizationTemplate = (payload) => async (dispatch) => {
  const { id, menuId, categoryId, itemId, data } = payload;
  let newObj = {
    name: data?.name,
    required: data?.required,
    max_selectable: data?.max_selectable,
    display_as_main_item: data?.display_as_main_item,
  };
  let newArr = [];

  data &&
    data.customisation_options &&
    data.customisation_options.forEach((element) => {
      newArr.push({
        name: element?.name,
        price: element?.price,
        pre_selected: element?.pre_selected,
      });
    });

  newObj["item_customisation_options"] = newArr;

  const body = JSON.stringify({
    ...newObj,
  });

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.patch(
      `/api/business/menu-manager/item/${itemId}/update-item-customisation-template/${id}/`,
      body,
      config
    );
    let resData = response?.data?.item_customisations;
    let indexCust = resData.findIndex((n) => n.id === id);
    if (indexCust > -1) {
      let updatedCust = resData[indexCust];
      let newObjCust = {
        ...updatedCust,
      };
      newObjCust["item_customisation_id"] = newObjCust["id"];
      delete newObjCust["id"];
      delete newObjCust["item_customisation_options"];
      let newArr2 = [];

      updatedCust.item_customisation_options.forEach((n) => {
        newArr2.push({
          customisation_option_id: n.id,
          name: n.name,
          position: n.position,
          pre_selected: n.pre_selected,
          price: n.price,
        });
      });
      newObjCust["customisation_options"] = [...newArr2];
      dispatch({
        type: UPDATE_CUSTOMIZATION,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          itemId: itemId,
          data: newObjCust,
        },
      });
    }
    dispatch(getCustomizationList());

    return 200;
  } catch (error) {
    return 400;
  }
};

export const deleteCustomizationTemplate = (payload) => async (dispatch) => {
  const { itemId, menuId, categoryId, id } = payload;

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `business/menu-manager/customisation-template/${id}/`,
      config
    );

    return 200;
  } catch (error) {
    return 400;
  }
};
export const addPrice = ({ item, id, menuId, categoryId }) => async (
  dispatch
) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    let newObj = { amount: item?.amount, description: item?.description };
    let response = await API.post(
      `/business/menu-manager/item/${id}/add-price/`,
      JSON.stringify(newObj),
      config2
    );
    dispatch({
      type: UPDATE_ITEM_PRICES,
      payload: {
        item: response.data.prices,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const removePrice = ({ item, id, menuId, categoryId }) => async (
  dispatch
) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    let response = await API.post(
      `/business/menu-manager/item/${id}/remove-price/`,
      JSON.stringify(item),
      config2
    );
    dispatch({
      type: UPDATE_ITEM_PRICES,
      payload: {
        item: response.data.prices,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const updatePrice = ({ item, id, itemId, menuId, categoryId }) => async (
  dispatch
) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    let itemWithId = {
      amount: item?.amount,
      description: item?.description,
      id: itemId,
    };
    let response = await API.patch(
      `/business/menu-manager/item/${id}/update-price/`,
      JSON.stringify(itemWithId),
      config2
    );
    dispatch({
      type: UPDATE_ITEM_PRICES,
      payload: {
        item: response.data.prices,
        id: id,
        menuId: menuId,
        categoryId: categoryId,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const addItem = ({
  menuId,
  categoryId,
  data,
  data2,
  tagsList,
  pricesList,
}) => async (dispatch) => {
  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  const config = {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `api/business/menu-manager/item/`,
      data,
      config
    );
    let res = response?.data;
    let r = null;
    let id = res?.id;

    data2.forEach(async (element, i) => {
      let newObj2 = {
        template_id: element?.item_customisation_id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      r = k.data;
      r["item_id"] = r["id"];
      delete r["id"];
      r["tags"] = r["item_tags"];
      delete r["item_tags"];
      let cArr = [];
      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          customisation_template: elem?.customisation_template,
          position: elem?.position,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;

      dispatch({
        type: UPDATE_ITEM,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: r,
        },
      });
    });
    tagsList.forEach(async (element) => {
      await dispatch(
        addTags({
          itemId: id,
          menuId: menuId,
          categoryId: categoryId,
          data: element?.id,
        })
      );
    });

    res["item_id"] = res["id"];
    res["tags"] = res["item_tags"];
    delete res["id"];
    delete res["item_tags"];
    dispatch({
      type: ADD_ITEM,
      payload: { menuId: menuId, categoryId: categoryId, item: res },
    });
    pricesList.forEach((element, index) => {
      dispatch(
        addPrice({
          item: element,
          id: id,
          menuId: menuId,
          categoryId: categoryId,
        })
      );
    });
    return { status: 200, id: id };
  } catch (error) {
    return { status: 400 };
  }
};

export const updateItem = ({
  id,
  menuId,
  categoryId,
  data,
  data2,
  tagsList,
  pricesList,
}) => async (dispatch,getState) => {

  const config2 = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  const config = {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    pricesList &&
      pricesList.map((item) => {
       if (!item?.id) {
          dispatch(
            addPrice({
              item: item,
              id: id,
              menuId: menuId,
              categoryId: categoryId,
            })
          );
        } else {
          dispatch(
            updatePrice({
              item: item,
              id: id,
              itemId: item?.id,
              menuId: menuId,
              categoryId: categoryId,
            })
          );
        }
      });
    const response = await API.patch(
      `api/business/menu-manager/item/${id}/`,
      data,
      config
    );
    let res = response?.data;
    let r = response?.data;
    let addTagsArr = [];
    let removeTagArr = [];
    let customizationsToAdd = [];
    data2.forEach((element) => {
      let index = res.item_customisations.findIndex(
        (i) => element?.item_customisation_id == i?.id
      );
      if (index === -1) {
        customizationsToAdd.push(element);
      }
    });

  
    customizationsToAdd.forEach(async (element, i) => {
      let newObj2 = {
        template_id: element?.item_customisation_id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      r = k.data;
      r["item_id"] = r["id"];
      delete r["id"];
      let tagList = [];
      r["item_tags"].forEach((element) => {
        tagList.push({ ...element, id: element?.tag_id });
      });
      r["tags"] = tagList;
      delete r["item_tags"];
      let cArr = [];
      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          position: elem?.position,
          customisation_template: elem?.customisation_template,
          required: elem?.required,
          customisation_options: [],
        };
        let c = [...elem?.item_customisation_options];
        let ar = [];
        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;
      dispatch({
        type: UPDATE_ITEM,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: r,
        },
      });
    });

    res["item_id"] = res["id"];
    let tagList = [];
    res["item_tags"].forEach((element) => {
      tagList.push({ ...element, id: element?.tag_id });
    });
    r["tags"] = tagList;
    delete res["id"];
    delete res["item_tags"];

    let cArr = [];
    res.item_customisations.forEach((elem) => {
      let arrs = {
        display_as_main_item: elem?.display_as_main_item,
        item_customisation_id: elem?.id,
        max_selectable: elem?.max_selectable,
        name: elem?.name,
        position: elem?.position,
        customisation_template: elem?.customisation_template,
        required: elem?.required,
        customisation_options: [],
      };
      let c = [...elem?.item_customisation_options];

      let ar = [];

      c.forEach((el) => {
        ar.push({
          customisation_option_id: el?.id,
          name: el?.name,
          position: el?.position,
          pre_selected: el?.pre_selected,
          price: el?.price,
        });
      });
      arrs.customisation_options = ar;
      cArr.push(arrs);
    });
    res["item_customisations"] = cArr;
    dispatch({
      type: UPDATE_ITEM,
      payload: {
        id: id,
        menuId: menuId,
        categoryId: categoryId,
        item: res,
      },
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};

export const addTagsTemplate = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const { data } = payload;

    const response = await API.post(
      `api/business/menu-manager/tags/`,
      data,
      config
    );

    dispatch({
      type: ADD_TAGS_TEMPLATE,
      payload: {
        data: response?.data,
      },
    });

    return { status: 200, data: response?.data };
  } catch (error) {
    return { status: 400 };
  }
};
export const updateTagsTemplate = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const { data } = payload;

    const response = await API.patch(
      `api/business/menu-manager/tags/${payload?.id}/`,
      data,
      config
    );

    dispatch({
      type: UPDATE_TAGS_TEMPLATE,
      payload: {
        id: response?.data?.id,
        data: response?.data,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};
export const deleteTagsTemplate = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.delete(
      `api/business/menu-manager/tags/${payload?.id}/`,

      config
    );

    dispatch({
      type: DELETE_TAGS_TEMPLATE,
      payload: {
        id: payload?.id,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const getAlltags = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(`api/business/menu-manager/tags/`, config);

    dispatch({
      type: GET_ALL_TAGS,
      payload: {
        data: response?.data?.results,
      },
    });
    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateMenuOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/menu/change-order/`,
      arr,
      config
    );
    dispatch({ type: REORDER_MENU, payload: data });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateCategoryOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/category/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_CATEGORY,
      payload: { data: data, id: payload.id },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateItemOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/menu-manager/item/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_ITEMS,
      payload: { data: data, id: payload.id, category_id: payload.category_id },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateTablesOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({ id: element?.id, position: element?.position });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `business/venue/tables/change-order/`,
      arr,
      config
    );
    dispatch({
      type: REORDER_TABLES,
      payload: { data: data },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateItemCustomizationOrder = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({
        id: element?.item_customisation_id,
        position: element?.position,
      });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `/business/menu-manager/parent-item-customisation/change-order/`,
      arr,
      config
    );
    const { itemId, menuId, categoryId } = payload?.item;

    dispatch({
      type: REORDER_CUSTOMIZATIONS,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};
export const updateItemCustomizationOptionsOrder = (payload) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    let arr = [];
    payload.data.forEach((element) => {
      arr.push({
        id: element?.customisation_option_id,
        position: element?.position,
      });
    });
    let data = payload.data.sort((a, b) => a.position - b.position);

    const response = await API.post(
      `/business/menu-manager/child-item-customisation/change-order/`,
      arr,
      config
    );
    const { itemId, menuId, categoryId, customizationId } = payload?.item;

    dispatch({
      type: REORDER_CUSTOMIZATIONS_OPTIONS,
      payload: {
        data: data,
        menuId: menuId,
        categoryId: categoryId,
        itemId: itemId,
        id: customizationId,
      },
    });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const updateAddCustomization = ({
  id,
  menuId,
  categoryId,
  templete,
}) => async (dispatch) => {
  if (id) {
    const config2 = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };
    
    try {
      let newObj2 = {
        template_id: templete?.id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      let r = k.data;
      let cArr = [];

      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          position: elem?.position,
          customisation_template: elem?.customisation_template,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;
      dispatch({
        type: UPDATE_ALL_ITEM_CUSTOMIZATIONS,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: cArr,
        },
      });
      return { status: 200 };
    } catch (error) {
      console.log("error", error);
      return { status: 400 };
    }
  }
};

export const updateAddTags = ({ id, menuId, categoryId, templete }) => async (
  dispatch
) => {
  if (id) {
    const config2 = {
      headers: {
        "Content-type": "application/json",
        "venue-uuid": store.getState()?.VenueOrderReducer?.VenueDetail?.uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };
    console.log(
      "id ,menuId,categoryId,templete,",
      id,
      menuId,
      categoryId,
      templete
    );
    try {
      let newObj2 = {
        template_id: templete?.id,
      };
      let k = await API.post(
        `api/business/menu-manager/item/${id}/add-customisation-section/`,
        JSON.stringify(newObj2),
        config2
      );
      let r = k.data;
      let cArr = [];

      r.item_customisations.forEach((elem) => {
        let arrs = {
          display_as_main_item: elem?.display_as_main_item,
          item_customisation_id: elem?.id,
          max_selectable: elem?.max_selectable,
          name: elem?.name,
          position: elem?.position,
          customisation_template: elem?.customisation_template,
          required: elem?.required,
          customisation_options: [],
        };

        let c = [...elem?.item_customisation_options];

        let ar = [];

        c.forEach((el) => {
          ar.push({
            customisation_option_id: el?.id,
            name: el?.name,
            position: el?.position,
            pre_selected: el?.pre_selected,
            price: el?.price,
          });
        });
        arrs.customisation_options = ar;
        cArr.push(arrs);
      });

      r["item_customisations"] = cArr;
      dispatch({
        type: UPDATE_ALL_ITEM_CUSTOMIZATIONS,
        payload: {
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: cArr,
        },
      });
      return { status: 200 };
    } catch (error) {
      console.log("error", error);
      return { status: 400 };
    }
  }
};
