import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateVenueDetails } from "../../actions/order";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import "./VenueProfile.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
  withTheme,
} from "@material-ui/core";
import { currenciesList } from "../../utils/currencies";
import moment from "moment-timezone";
import CreateGuestSessionDialog from "../../Components/GuestSession/CreateGuestSession";
import AddImage from "../../Components/AddImage/AddImage";
import AddCoverImage from "../../Components/AddImage/AddCoverImage";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import ColorPicker from "../../Components/ColorPicker/ColorPicker";
import CropImage from "../../Components/CropImage/CropImage";
import { VENUE_TYPES, HOTEL, SUPPORTED_LANGUAGES } from "../../utils/constants";
import { useParams } from "react-router-dom";

function VenueProfile() {
  const intl = useIntl();
  const params=useParams();
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);
  let business_account = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account
  );
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let location = useLocation();
  const [imageLogo, setImageLogo] = useState("");
  const [imageFileLogo, setImageFileLogo] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [language, setLanguage] = useState("");

  //types are "cover","logo"
  const [cropImageType, setCropImageType] = useState(null);
  const handleOpenCropImage = (type) => {
    setCropImageType(type);
    setOpenCrop(true);
  };
  const handleChangeLanguage = (e) => {
    setErrors({ ...errors, language: false });
    setLanguage(e.target.value);
  };
  const handleCloseCropImage = () => {
    setCropImageType(null);
    setOpenCrop(false);
  };
  const [imageIcon, setImageIcon] = useState("");
  const [imageFileIcon, setImageFileIcon] = useState("");
  const [filterValue, setFilterValue] = React.useState("ShowAll");
  const [openModel, setOpenModel] = useState(false);
  let dispatch = useDispatch();
  const classes = useStyles();
  const inputEl = useRef(null);
  const [domain, setDomain] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [currency, setCurrency] = useState("");
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [venueName, setVenueName] = useState("");
  const [loader, setLoader] = useState(false);
  const [themeColor, setThemeColor] = useState("");
  const [venueDescription, setVenueDescription] = useState("");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [colorError, setColorError] = useState(false);
  const timeZones = moment?.tz
    ?.names()
    ?.filter((tz) =>
      tz.match(
        /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/
      )
    );

  useEffect(() => {
    if (venueDetail) {
      setVenueName(venueDetail?.name);
      setVenueDescription(venueDetail?.description);
      setImageLogo(venueDetail?.logo);
      setImageIcon(venueDetail?.cover_image);
      setThemeColor(venueDetail?.colour?.replace("#", ""));
      setInstagramURL(venueDetail?.instagram_url);
      setFacebookURL(venueDetail?.facebook_url);
      setDomain(venueDetail?.domain?.replace(".blend.menu", ""));
      setCurrency(venueDetail?.currency);
      setSelectedTimeZone(venueDetail?.timezone);
      setAccountType(venueDetail?.type);
      setLanguage(venueDetail?.default_language);
    }
    console.log(venueDetail);
  }, [venueDetail]);
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
  };
  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const selectedFileLogo = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageLogo(URL.createObjectURL(event.target.files[0]));
      setImageFileLogo(event.target.files[0]);
      handleOpenCropImage("logo");
    }
  };

  const selectedFileIcon = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageIcon(URL.createObjectURL(event.target.files[0]));
      setImageFileIcon(event.target.files[0]);
      handleOpenCropImage("cover");
    }
  };

  const handleClearAppIcon = () => {
    setImageIcon("");
    setImageFileIcon("");
  };
  const handleClearLogo = () => {
    setImageLogo("");
    setImageFileLogo("");
  };
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    domain: false,
    timeZones: false,
    instagramURL: false,
    facebookURL: false,
  });
  function is_url(str) {
    // console.log("str",str)
    let regexp =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

    if (regexp.test(str)) {
      console.log("URL", str, "response : TRUE");

      return true;
    } else {
      console.log("URL", str, "response : FALSE");

      return false;
    }
  }
  const handleSubmit = async () => {
    let regValidateHexColor = /^#[0-9A-F]{6}$/i.test(
      `#${themeColor?.replace("#", "")}`
    );
    let objError = {
      name: false,
      description: false,
      domain: false,
      timeZones: false,
      instagramURL: false,
      facebookURL: false,
    };
    let validFbUrl = false;
    let validInstaUrl = false;
    if (facebookURL?.trim() !== "") {
      if (!is_url(facebookURL)) {
        validFbUrl = true;
        objError["facebookURL"] = true;
      }
    }

    if (instagramURL?.trim() !== "") {
      if (!is_url(instagramURL)) {
        validInstaUrl = true;
        objError["instagramURL"] = true;
      }
    }
    if (
      validFbUrl ||
      validInstaUrl ||
      !regValidateHexColor ||
      venueName === null ||
      venueName === undefined ||
      venueName === ""
    ) {
      if (!regValidateHexColor) {
        setColorError(true);
      } else {
        setColorError(false);
      }
      if (venueName === null || venueName === undefined || venueName === "") {
        objError["name"] = true;
      }
      //   if (
      //     venueDescription === null ||
      //     venueDescription === undefined ||
      //     venueDescription === ""
      //   ) {
      //     objError["description"] = true;
      //  }
      setErrors({ ...errors, ...objError });
    } else {
      setLoader(true);
      console.log("data");
      let formData = new FormData();
      if (imageFileLogo) {
        formData.append("logo", imageFileLogo);
      }
      console.log(typeof imageFileIcon);
      if (imageFileIcon) {
        formData.append("cover_image", imageFileIcon);
      }

      // if (instagramURL !== "") {
      formData.append("instagram_url", instagramURL);
      // }
      // if (facebookURL !== "") {
      formData.append("facebook_url", facebookURL);
      formData.append("default_language", language);
      formData.append("type", accountType);
      // }
      if (selectedTimeZone !== "") {
        formData.append("timezone", selectedTimeZone);
      }
      // if (domain !== "") {
      //   formData.append("domain", domain);
      // }
      if (currency !== "") {
        formData.append("currency", currency);
      }

      formData.append("name", venueName);
      formData.append("colour", `#${themeColor}`);
      formData.append("description", venueDescription);
      if (venueDetail?.uuid) {
        await dispatch(
          updateVenueDetails({
            body: formData,
            venue_uuid: venueDetail?.uuid,
            slug: params?.id,
          })
        );
      }
      setLoader(false);
    }
  };
  const handleChangeColor = (value) => {
    setThemeColor(value);

    setColorError(false);
  };
  const handleChangeDescription = (e) => {
    setErrors({ ...errors, description: false });
    setVenueDescription(e.target.value);
  };
  const handleChangeName = (e) => {
    setErrors({ ...errors, name: false });
    setVenueName(e.target.value);
  };
  const handleChangeDomain = (e) => {
    setErrors({ ...errors, domain: false });
    setDomain(e.target.value);
  };
  const handleChangeTimeZone = (e) => {
    setErrors({ ...errors, timeZones: false });
    setSelectedTimeZone(e.target.value);
  };
  const handleChangeInstagramURL = (e) => {
    setErrors({ ...errors, instagramURL: false });
    setInstagramURL(e.target.value);
  };
  const handleChangeFacebookURL = (e) => {
    setErrors({ ...errors, facebookURL: false });
    setFacebookURL(e.target.value);
  };
  const handleChangeCurrency = (e) => {
    setErrors({ ...errors, currency: false });
    setCurrency(e.target.value);
  };
  const handleOpenColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };
  const handleCloseColorPicker = (e) => {
    e.stopPropagation();
    setOpenColorPicker(false);
  };
  const setFile = (f, url) => {
    if (cropImageType === "cover") {
      setImageFileIcon(f);
      setImageIcon(url);
    } else {
      setImageFileLogo(f);
      setImageLogo(url);
    }
    handleCloseCropImage();
  };

  const handleChangeType = (e) => {
    setErrors({ ...errors, type: false });
    setAccountType(e.target.value);
  };
  return (
    <>
      {openModel && (
        <CreateGuestSessionDialog
          open={openModel}
          handleClose={handleCloseModel}
        />
      )}
      {openCrop && (
        <CropImage
          files={cropImageType === "cover" ? imageFileIcon : imageFileLogo}
          setImage={setFile}
          handleClose={handleCloseCropImage}
          type={cropImageType}
        />
      )}
      <div>
        {venueDetail && venueDetail.uuid !== null ? (
          <Grid container className={classes.root}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid
                container
                spacing={3}
                style={{ marginTop: "15px", minHeight: "40px" }}
              >
                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Venue_name",
                      defaultMessage: "Venue name",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Venue_name",
                      defaultMessage: "Venue name",
                    })}
                    fullWidth
                    error={errors?.name}
                    helperText={
                      errors?.name &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    onChange={(e) => handleChangeName(e)}
                    value={venueName}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item>
                      <Typography
                        className={classes.headingTest}
                        style={{ paddingBottom: "0px", marginBottom: "10px" }}
                      >
                        {intl.formatMessage({
                          id: "venue_type",
                          defaultMessage: "Venue type",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>

                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "select_type",
                      defaultMessage: "Select type",
                    })}
                    fullWidth
                    error={errors?.type}
                    helperText={
                      errors?.type &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    select
                    onChange={(e) => handleChangeType(e)}
                    value={accountType}
                    defaultValue={accountType}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                    className={classes.selectRounded}
                  >
                    {business_account?.type === HOTEL
                      ? VENUE_TYPES.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : VENUE_TYPES.filter((i) => i.id !== 3).map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Description",
                      defaultMessage: "Description",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Venue_description",
                      defaultMessage: "Venue description",
                    })}
                    fullWidth
                    error={errors?.description}
                    helperText={
                      errors?.description &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    onChange={(e) => handleChangeDescription(e)}
                    value={venueDescription}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{
                      paddingBottom: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    {intl.formatMessage({
                      id: "venue_domain",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "enter_venue_domain",
                      defaultMessage: "Enter venue domain",
                    })}
                    fullWidth
                    error={errors?.domain}
                    helperText={
                      errors?.domain &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    onChange={(e) => handleChangeDomain(e)}
                    value={domain}
                    disabled={true}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      backgroundColor: "#F9FAFC",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                      endAdornment: (
                        <InputAdornment
                          style={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "13px",
                            lineHeight: "18px",
                            color: "#303A5F",
                            paddingLeft: "10px",
                          }}
                          position="start"
                        >
                          .blend.menu
                        </InputAdornment>
                      ),
                    }}
                    className={classes.classEndronment}
                  />
                </Grid>
              </Grid>

              {/* new fields */}
              <Grid
                container
                spacing={3}
                style={{ marginTop: "15px", minHeight: "40px" }}
              >
                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "currency",
                      defaultMessage: "Currency",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "select_currency",
                      defaultMessage: "Select Currency",
                    })}
                    fullWidth
                    error={errors?.currency}
                    helperText={
                      errors?.currency &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    select
                    onChange={(e) => handleChangeCurrency(e)}
                    value={currency}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  >
                    {currenciesList?.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "time_zone",
                      defaultMessage: "Time zone",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "select_time_zone",
                      defaultMessage: "Select Time zone",
                    })}
                    fullWidth
                    error={errors?.timeZones}
                    helperText={
                      errors?.timeZones &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    select
                    onChange={(e) => handleChangeTimeZone(e)}
                    value={selectedTimeZone}
                    defaultValue={selectedTimeZone}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  >
                    {timeZones?.map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>
              {/* <Grid
                container
                spacing={3}
                style={{ marginTop: "15px", minHeight: "40px" }}
              >
                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "facebook_link",
                      defaultMessage: "Facebook link",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "enter_facebook_link",
                      defaultMessage: "Enter Facebook Link",
                    })}
                    fullWidth
                    error={errors?.facebookURL}
                    helperText={
                      errors?.facebookURL &&
                      intl.formatMessage({
                        id: "invalid_url",
                        defaultMessage: "Invalid URL",
                      })
                    }
                    onChange={(e) => handleChangeFacebookURL(e)}
                    value={facebookURL}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "instagram_link",
                      defaultMessage: "Instagram link",
                    })}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "enter_instagram_link",
                      defaultMessage: "Enter Instagram Link",
                    })}
                    fullWidth
                    error={errors?.instagramURL}
                    helperText={
                      errors?.instagramURL &&
                      intl.formatMessage({
                        id: "invalid_url",
                        defaultMessage: "Invalid URL",
                      })
                    }
                    onChange={(e) => handleChangeInstagramURL(e)}
                    value={instagramURL}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </Grid>
              </Grid>
             */}
              <Grid
                container
                spacing={3}
                style={{ marginTop: "15px", minHeight: "40px" }}
              >
                <Grid item xs={12} lg={6} sm={12} md={6} xl={6}>
                  <Typography
                    className={classes.headingTest}
                    style={{
                      paddingBottom: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    {intl.formatMessage({
                      id: "Venue default language",
                      defaultMessage: "Venue default language",
                    })}
                  </Typography>

                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Select default language",
                      defaultMessage: "Select default language",
                    })}
                    fullWidth
                    error={errors?.type}
                    helperText={
                      errors?.type &&
                      intl.formatMessage({
                        id: "Field_is_required",
                        defaultMessage: "Field is required",
                      })
                    }
                    select
                    onChange={(e) => handleChangeLanguage(e)}
                    value={language}
                    defaultValue={language}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                    className={classes.selectRounded}
                  >
                    
                    {UserDetail?.supported_languages_by_blend&&Object.keys(UserDetail?.supported_languages_by_blend).map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {UserDetail?.supported_languages_by_blend[item]}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              {/*end  new fields */}

              <Grid container style={{ marginTop: "30px" }}>
                <Grid item style={{ marginRight: "50px" }}>
                  <Typography
                    className={classes.headingTest}
                    style={{ marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Logo_image",
                      defaultMessage: "Logo image",
                    })}
                  </Typography>
                  <AddImage
                    image={imageLogo}
                    selectedFile={selectedFileLogo}
                    handleClearImage={handleClearLogo}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.headingTest}
                    style={{ marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Cover_photo",
                      defaultMessage: "Cover photo",
                    })}
                  </Typography>
                  <AddCoverImage
                    image={imageIcon}
                    selectedFile={selectedFileIcon}
                    handleClearImage={handleClearAppIcon}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "30px" }}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Venue_theme_color",
                      defaultMessage: "Venue theme color",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      <TextField
                        variant="outlined"
                        placeholder={intl.formatMessage({
                          id: "Select_Color",
                          defaultMessage: "Select Color",
                        })}
                        value={themeColor}
                        onChange={(e) => handleChangeColor(e.target.value)}
                        inputProps={{
                          className: classes.TextInputStyle90,
                          "aria-label": "search",
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.outlinedStyle,
                          },
                        }}
                        style={{
                          borderRadius: "5px",
                        }}
                        error={colorError}
                        helperText={
                          colorError &&
                          intl.formatMessage({
                            id: "Invalid_color_code",
                            defaultMessage: "Invalid color code",
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20px",
                        maxHeight: "40px",
                      }}
                    >
                      <div
                        onClick={handleOpenColorPicker}
                        style={{
                          borderRadius: "5px",
                          height: "27px",
                          width: "27px",
                          marginRight: "11px",
                          backgroundColor: `#${
                            themeColor?.length > 2
                              ? themeColor?.replace("#", "")
                              : "fff"
                          }`,
                        }}
                      />
                      {openColorPicker && (
                        <ColorPicker
                          handleCloseColorPicker={handleCloseColorPicker}
                          color={themeColor}
                          handleChangeColor={handleChangeColor}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: "100px", paddingBottom: "74px" }}
              >
                <Button
                  variant="contained"
                  className={classes.printButton}
                  disabled={false}
                  onClick={handleSubmit}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={20} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                      defaultMessage: "Save",
                    })
                  )}
                </Button>
                <Button
                  // variant="contained"
                  className={classes.cancelButton}
                  disabled={false}
                  // onClick={() => handleTab()}
                >
                  {intl.formatMessage({
                    id: "Cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={40} color="primary" />
          </Grid>
        )}
        <Grid item xs={1}></Grid>
      </div>
    </>
  );
}

export default VenueProfile;

const useStyles = makeStyles((theme) => ({
  classEndronment: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "14px !important",
      background: "#EAECEE !important",
      boxSizing: "border-box",
      borderRadius: "5px !important",
    },
  },
  cancelButton: {
    marginLeft: "10px",
    marginTop: "10px",
    textTransform: "none",
    background: "#F9FAFC",
    color: "#303A5F",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    border: "0px solid #F9FAFC",
    "&:hover": {
      backgroundColor: "#F9FAFC",
    },
  },
  printButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#20BDA1",
      // border: "  1px solid #20BDA1",
    },
  },
  infiniteScroll: {
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "88vh",
    paddingTop: "22px",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: "#303A5F",
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },
  TextInputStyle90: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "100px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#303A5F",
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: "#303A5F",
  },
  outlinedStyle: {
    border: "0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
}));
