import React,{useState,useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as LogoImage } from "../../assets/image/BlendMenu.svg";
import { ReactComponent as SplashLogo } from "../../assets/image/splashLogo.svg";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Introduction from "../Introduction/Index"
import Intro1 from "../../assets/image/intro1.png";
import Intro2 from "../../assets/image/intro2.png";
import Intro3 from "../../assets/image/intro3.png";
import Intro21 from "../../assets/image/intro21.png";

export default function Login() {
    const [splash,setSplash]=useState(true)
    const [images,setImages]=useState([])
  const classes = useStyles();
  useEffect(()=>{

    let imageList = [Intro1, Intro2,Intro21, Intro3]
    let arr = []
    imageList.forEach((image) => {
      arr.push(new Image().src = image)

    });
    setImages([...arr])
setTimeout(() => {
    setSplash(false)
}, 2000);
  },[])
  // this.props.images.forEach(image => (new Image().src = image.src));

  return (
      <>
      {!splash?<Introduction 
      images={images}
      />:
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "96vh" }}
    >
      <Grid container justify="center">
        <Grid container justify="center">
          <SplashLogo style={{ borderRadius: "15px", marginBottom: "10px" }} />
        </Grid>
        <Grid container justify="center">
          <LogoImage />
        </Grid>
      </Grid>
    </Grid>}
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  logoText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#F66761",
  },
  root: {
    flexGrow: 1,
  },
}));
