import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  fontFamily: "Open Sans",
  fontSemiBold: "600",
  fontSemiBold700: "700",
  colors: {
    primary: "#303a5f",
    secondary: "#F66761",
  },
});
export default theme;
