import {
  GET_MEUN_AVAILABILITY,
  UPDATE_MENU_AVAILABILITY_ITEM,
  UPDATE_MENU_AVAILABILITY_CATEGORY,
  UPDATE_MENU_AVAILABILITY_MENU,
  UPDATE_MENU_AVAILABILITY_CUSTOMIZATION,
  UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS,
} from "../constant/types";
import API from "../utils/API";
export const getMenuAvailability = ({ venue_uuid }) => async (dispatch,getState) => {
  if(venue_uuid){
  let language=getState()?.languageReducer?.language;

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
      language:language

    },
  };

  let body = {};

  try {
    const response = await API.get(`api/business/availability/`, config);
    let data = response?.data;

    dispatch({ type: GET_MEUN_AVAILABILITY, payload: data });
  } catch (error) {
    return error?.response?.status;
  }}
};

export const updateAvailability = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": payload?.venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = { ...payload.data };

  try {
    const response = await API.patch(
      `/business/availability/update/${payload?.item?.availabilityId}/`,
      body,
      config
    );
    let data = response?.data;
    switch (payload?.level) {
      case 0:
        dispatch({
          type: UPDATE_MENU_AVAILABILITY_MENU,
          payload: { data: data, item: payload.item },
        });
        break;
      case 1:
        dispatch({
          type: UPDATE_MENU_AVAILABILITY_CATEGORY,
          payload: { data: data, item: payload.item },
        });
        break;
      case 2:
        dispatch({
          type: UPDATE_MENU_AVAILABILITY_ITEM,
          payload: { data: data, item: payload.item },
        });
        break;
      case 4:
        dispatch({
          type: UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS,
          payload: { data: data, item: payload.item },
        });
        break;
      case 30:
        dispatch({
          type: UPDATE_MENU_AVAILABILITY_CUSTOMIZATION,
          payload: { data: data, item: payload.item },
        });
      default:
        break;
    }
  } catch (error) {
    return error?.response?.status;
  }
};
