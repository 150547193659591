import { useDispatch } from "react-redux";
import {
  updateMenuOrder,
  updateCategoryOrder,
  updateItemOrder,
  updateItemCustomizationOptionsOrder,
  updateItemCustomizationOrder,
  updateTablesOrder,
} from "../actions/menu";
export const applyDrag = (arr, dragResult, dispatch, ids) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  result.forEach((s, index) => {
    result[index].position = index;
  });
  dispatch(updateMenuOrder(result));
  return result;
};
export const applyDragMenu = (arr, dragResult, dispatch, type, ids) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  if (type === "menu") {
    result.forEach((s, index) => {
      result[index].position = index;
    });
  } else {
    let newArr = [];
    result.forEach((s, index) => {
      newArr.push(result[index].position);
    });
    let sortedArr = newArr.sort((a, b) => a - b);

    result.forEach((s, index) => {
      result[index].position = sortedArr[index];
    });
  }
  if (type === "menu") {
    dispatch(updateMenuOrder(result));
  }
  if (type === "category") {
    dispatch(updateCategoryOrder({ data: result, id: ids.menu }));
  }
  if (type === "item") {
    dispatch(
      updateItemOrder({ data: result, id: ids.menu, category_id: ids.category })
    );
  }
  if (type === "table") {
    dispatch(
      updateTablesOrder({
        data: result,
      })
    );
  }
  return result;
};

export const applyDragCustomization = (arr, dragResult, item, dispatch) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  let newArr = [];
  result.forEach((s, index) => {
    newArr.push(result[index]?.position);
  });
  let sortedArr = newArr.sort((a, b) => a - b);
  result.forEach((s, index) => {
    result[index].position = sortedArr[index];
  });

  dispatch(updateItemCustomizationOrder({ item: item, data: result }));
  return result;
  // result.forEach((s, index) => {
  //   result[index].position = index;
  // });
  // return result;
};

export const applyDragCustomizationOptions = (
  arr,
  dragResult,
  item,
  dispatch
) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  // let newArr = [];
  // result.forEach((s, index) => {
  //   newArr.push(result[index]?.position);
  // });
  // let sortedArr = newArr.sort((a, b) => a - b);

  result.forEach((s, index) => {
    result[index].position = index;
    // result[index].position = sortedArr[index];
  });
// console.log("res---------------",result,sortedArr)
  dispatch(updateItemCustomizationOptionsOrder({ item: item, data: result }));
  return result;
  // result.forEach((s, index) => {
  //   result[index].position = index;
  // });
  // return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};
