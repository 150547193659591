import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { getMenuDetails } from "../actions/menu";
import MenuMangerDnD from "./DragnDrop/MenuManger";
import AppBar from "./AppBar/AppBar";
import ItemDetails from "./ItemDetails/ItemDetails";
// import ItemDetailsForSelectedLanguage from "./ItemDetails/SelectedLanguage/ItemDetailsForSelectedLanguage";
import ItemDetailsForSelectedLanguage from "./ItemDetails/ItemDetails";
import MenuForm from "./Menu/MenuForm";
import CategoryForm from "./Menu/CategoryForm";
import { useIntl } from "react-intl";
// import LanguagesHeader from "../Components/LanguagesHeader/index";
function MenuManager() {
  const [categoryLength, setCategoryLength] = useState(-1);
  const [itemsLength, setItemsLength] = useState(-1);
  const intl = useIntl();

  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [selectedMenuId, setSelectedMenuId] = useState(-1);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedItemId, setSelectedItemId] = useState(-1);

  // // //testing state
  // const [selectedMenu, setSelectedMenu] = useState(0);
  // const [selectedMenuId, setSelectedMenuId] = useState(32);
  // const [selectedCategory, setSelectedCategory] = useState(0);
  // const [selectedCategoryId, setSelectedCategoryId] = useState(88);
  // const [selectedItem, setSelectedItem] = useState(0);
  // const [selectedItemId, setSelectedItemId] = useState(84);
  // // //testing state

  const [dataLoading, setDataLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [editedItem, setEditedItem] = useState({});
  let dispatch = useDispatch();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let menuDetails = useSelector(
    (state) => state?.menuReducer?.venueDetails?.menus
  );

  const classes = useStyles();
  const getMenuData = async () => {
    setDataLoading(true);
    let res = await dispatch(getMenuDetails({ venue_uuid: venueDetail?.uuid }));
    setDataLoading(false);
  };
  useEffect(() => {
    if (venueDetail?.uuid) {
      getMenuData();
    }
    // eslint-disable-next-line
  }, [venueDetail]);
  const selectMenuByDrag = () => {
    setSelectedMenu(-1);
    setSelectedMenuId(-1);
    setSelectedCategoryId(-1);
    setSelectedCategory(-1);
    setSelectedItem(-1);
    setSelectedItemId(-1);
    setEditedItem(null);
    setOpenMenu(false);
    setCategoryLength(
       null
    );
  };
  const selectCategoryByDrag = () => {
    setSelectedCategoryId(-1);
    setSelectedCategory(-1);
    setSelectedItem(-1);
    setSelectedItemId(-1);
    setEditedItem(null);
    setOpenMenu(false);
    setCategoryLength(
       null
    );
  };
  const selectItemByDrag = () => {
    setSelectedItem(-1);
    setSelectedItemId(-1);
    setEditedItem(null);
    setOpenMenu(false);
    setCategoryLength(
       null
    );
  };
  const handleSelect = (index, type) => {
    switch (type) {
      case "menu":
        let index1 =
          menuDetails && menuDetails.findIndex((n) => n.id === index);

        if (index1 > -1) {
          setSelectedMenu(index1);
        } else {
          setSelectedMenu(menuDetails?.length);
        }
        setSelectedMenuId(index);
        setSelectedCategoryId(-1);
        setSelectedCategory(-1);
        setSelectedItem(-1);
        setSelectedItemId(-1);
        setEditedItem(null);
        setOpenMenu(false);
        setCategoryLength(
          menuDetails[index1]?.menu_categories?.length
            ? menuDetails[index1]?.menu_categories?.length
            : 0
        );

        break;
      case "category":
        let index2 =
          menuDetails &&
          menuDetails[selectedMenu].menu_categories.findIndex(
            (n) => n.id === index
          );
        setSelectedCategoryId(index);
        setSelectedCategory(index2);
        setSelectedItem(-1);
        setSelectedItemId(-1);
        setEditedItem(null);
        setOpenMenu(false);
        setCategoryLength(-1);
        break;
      case "item":
        let index3 =
          menuDetails &&
          menuDetails[selectedMenu].menu_categories[
            selectedCategory
          ].items.findIndex((n) => n.item_id === index);
        setSelectedItem(index3);
        setSelectedItemId(index);
        setEditedItem(null);
        setOpenMenu(false);
        setCategoryLength(-1);
        break;
      default:
        break;
    }
  };
  const handleRemoveItem = () => {
    setSelectedItem(-1);
    setSelectedItemId(-1);
    setOpenMenu(false);
  };
  const HandleReopenItem = (newItemId) => {
    let index3 =
      menuDetails &&
      menuDetails[selectedMenu].menu_categories[
        selectedCategory
      ].items.findIndex((n) => n.item_id === newItemId);
    setSelectedItem(index3);
    setSelectedItemId(newItemId);
    if (index3 > -1) {
      const items = {
        item: menuDetails[selectedMenu].menu_categories[selectedCategory].items[
          index3
        ],
        type: "item",
      };

      setEditedItem(items);
    }
  };

  const handleEditClick = (item, type) => {
    const items = { item: item, type: type };

    setEditedItem(items);
    setOpenMenu(true);
  };
  const handleUpdateItem = () => {
    const items = { item: null, type: null };
    setEditedItem(items);
    setOpenMenu(false);
  };

  const handleOpenMenu = (type) => {
    setSelectedItem(-1);
    setSelectedItemId(-1);
    const items = { item: null, type: type };
    setEditedItem(items);
    setOpenMenu(true);
  };

  return (
    <div>
      {/* <LanguagesHeader/> */}
      {openMenu && editedItem?.type === "menu" && (
        <MenuForm
          open={openMenu}
          handleClose={setOpenMenu}
          item={editedItem}
          updateItem={handleUpdateItem}
          handleSelectMenu={handleSelect}
        />
      )}
      {openMenu && editedItem?.type === "category" && selectedMenu > -1 && (
        <CategoryForm
          open={openMenu}
          handleClose={setOpenMenu}
          item={editedItem}
          updateItem={handleUpdateItem}
          menuId={selectedMenuId}
          selectedMenu={selectedMenu}
          handleSelectMenu={handleSelect}
        />
      )}

      <Grid
        container
        style={{
          backgroundColor: "#f9fafc",
          minHeight: "81.5vh",
          height: `${
            parseInt(window?.innerHeight) -
            parseInt(document.getElementById("tabBar")?.clientHeight) -
            parseInt(document.getElementById("root2")?.clientHeight) -
            10
          }px`,
          maxHeight: `${
            parseInt(window?.innerHeight) -
            parseInt(document.getElementById("tabBar")?.clientHeight) -
            10 -
            parseInt(document.getElementById("root2")?.clientHeight)
          }px`,
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            backgroundColor: "#fff",
            marginRight: "13px",
            minHeight: "81.5vh",
            height: `${
              parseInt(window?.innerHeight) -
              parseInt(document.getElementById("tabBar")?.clientHeight) -
              10 -
              parseInt(document.getElementById("root2")?.clientHeight)
            }px`,
            maxHeight: `${
              (parseInt(window?.innerHeight) - 10,
              parseInt(document.getElementById("tabBar")?.clientHeight) -
                parseInt(document.getElementById("root2")?.clientHeight))
            }px`,
            overflow: "auto",
            // marginBottom: "50px",
          }}
          className={classes.customScrollBar}
        >
          <Grid container>
            {dataLoading ? (
              <Grid container justify="center">
                <CircularProgress size={30} style={{ color: "#303A5F" }} />
              </Grid>
            ) : (
              menuDetails && (
                <MenuMangerDnD
                  class={classes.menuItem}
                  selectedClass={classes.menuItemSelected}
                  selectedMenuIndex={selectedMenu}
                  selectedMenuId={selectedMenuId}
                  handleSelect={handleSelect}
                  selectionParam={"menu"}
                  ids={{ menu: selectedMenuId }}
                  items={menuDetails}
                  selectMenuByDrag={selectMenuByDrag}
                  handleEditClick={handleEditClick}
                />
              )
            )}
          </Grid>
          <AppBar
            title={intl.formatMessage({
              id: "Add_new_menu",
            })}
            handleOpen={handleOpenMenu}
            type={"menu"}
            open={openMenu}
            selection={editedItem?.type}
            editedItem={editedItem}
            length={menuDetails?.length}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            backgroundColor: "#fff",
            marginRight: "13px",
            // marginBottom: "50px",
            minHeight: "81.5vh",
            height: `${
              parseInt(window?.innerHeight) -
              parseInt(document.getElementById("tabBar")?.clientHeight) -
              10 -
              parseInt(document.getElementById("root2")?.clientHeight)
            }px`,
            maxHeight: `${
              (parseInt(window?.innerHeight) - 10,
              parseInt(document.getElementById("tabBar")?.clientHeight) -
                parseInt(document.getElementById("root2")?.clientHeight))
            }px`,
            overflow: "auto",
          }}
          className={classes.customScrollBar}
        >
          <Grid container>
            {selectedMenu > -1 &&
              menuDetails &&
              menuDetails[selectedMenu]?.menu_categories && (
                <MenuMangerDnD
                  ids={{ menu: selectedMenuId, category: selectedCategoryId }}
                  class={classes.menuItem}
                  selectedClass={classes.menuItemSelected}
                  selectedMenuIndex={selectedCategory}
                  selectedMenuId={selectedCategoryId}
                  handleSelect={handleSelect}
                  selectionParam={"category"}
                  items={menuDetails[selectedMenu]?.menu_categories}
                  handleEditClick={handleEditClick}
                  selectMenuByDrag={selectCategoryByDrag}

                />
              )}
          </Grid>

          <AppBar
            title={intl.formatMessage({
              id: "Add_new_category",
            })}
            handleOpen={handleOpenMenu}
            type={"category"}
            open={openMenu}
            length={categoryLength}
            editedItem={editedItem}
            selection={selectedMenu > -1 && editedItem?.type}
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            backgroundColor: "#fff",
            marginRight: "13px",
            minHeight: "81.5vh",
            height: `${
              parseInt(window?.innerHeight) -
              parseInt(document.getElementById("tabBar")?.clientHeight) -
              10 -
              parseInt(document.getElementById("root2")?.clientHeight)
            }px`,
            maxHeight: `${
              (parseInt(window?.innerHeight) - 10,
              parseInt(document.getElementById("tabBar")?.clientHeight) -
                parseInt(document.getElementById("root2")?.clientHeight))
            }px`,
            // marginBottom: "50px",
            overflow: "auto",
          }}
          className={classes.customScrollBar}
        >
          <Grid container>
            {selectedMenu > -1 &&
              selectedCategory > -1 &&
              menuDetails &&
              menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                ?.items && (
                <MenuMangerDnD
                  ids={{ menu: selectedMenuId, category: selectedCategoryId }}
                  class={classes.menuItem}
                  selectedClass={classes.menuItemSelected}
                  selectedMenuIndex={selectedMenu}
                  selectedMenuId={selectedItemId}
                  handleSelect={handleSelect}
                  selectionParam={"item"}
                  items={
                    menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                      ?.items
                  }
                  handleEditClick={handleEditClick}
                  selectMenuByDrag={selectItemByDrag}
                />
              )}
          </Grid>
          <AppBar
            title={intl.formatMessage({
              id: "Add_new_item",
            })}
            handleOpen={handleOpenMenu}
            type={"item"}
            length={
              selectedMenu > -1 &&
              selectedCategory > -1 &&
              menuDetails &&
              menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                ?.items?.length
            }
            open={openMenu}
            selection={
              selectedMenu > -1 && selectedCategory > -1 && editedItem?.type
            }
            editedItem={editedItem}
          />
        </Grid>
        {selectedMenu > -1 && selectedCategory > -1 && selectedItemId > -1 ? (
          <Grid
            item
            xs={5}
            style={{
              backgroundColor: "#fff",
              marginRight: "10px",
              minHeight: "81.5vh",
              height: `${
                parseInt(window?.innerHeight) -
                parseInt(document.getElementById("tabBar")?.clientHeight) -
                10 -
                parseInt(document.getElementById("root2")?.clientHeight)
              }px`,
              maxHeight: `${
                (parseInt(window?.innerHeight) - 10,
                parseInt(document.getElementById("tabBar")?.clientHeight) -
                  parseInt(document.getElementById("root2")?.clientHeight))
              }px`,
              overflow: "auto",
              // marginLeft: "10px",
              //minWidth: "45.666667%",
            }}
            id="itemDetails1"
            className={classes.customScrollBar}
          >
            {menuDetails &&
              menuDetails[selectedMenu] &&
              menuDetails[selectedMenu]?.menu_categories &&
              menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                ?.items && (
                <ItemDetailsForSelectedLanguage
                selectMenuByDrag={selectItemByDrag}

                  selectedMenuIndex={selectedMenu}
                  selectedMenuId={selectedItemId}
                  items={
                    menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                      ?.items
                  }
                  menuId={selectedMenuId}
                  categoryId={selectedCategoryId}
                  handleRemoveItem={handleRemoveItem}
                  HandleReopenItem={HandleReopenItem}
                />
              )}
            {/* {menuDetails &&
              menuDetails[selectedMenu] &&
              menuDetails[selectedMenu]?.menu_categories &&
              menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                ?.items && (
                <ItemDetails
                  selectedMenuIndex={selectedMenu}
                  selectedMenuId={selectedItemId}
                  items={
                    menuDetails[selectedMenu]?.menu_categories[selectedCategory]
                      ?.items
                  }
                  menuId={selectedMenuId}
                  categoryId={selectedCategoryId}
                  handleRemoveItem={handleRemoveItem}
                  HandleReopenItem={HandleReopenItem}
                />
              )} */}
          </Grid>
        ) : openMenu &&
          editedItem?.type === "item" &&
          selectedMenu > -1 &&
          selectedCategory > -1 ? (
          <Grid
            item
            xs={5}
            style={{
              backgroundColor: "#fff",
              marginRight: "10px",
              minHeight: "81.5vh",
              height: `${
                parseInt(window?.innerHeight) -
                parseInt(document.getElementById("tabBar")?.clientHeight) -
                10 -
                parseInt(document.getElementById("root2")?.clientHeight)
              }px`,
              maxHeight: `${
                (parseInt(window?.innerHeight) - 10,
                parseInt(document.getElementById("tabBar")?.clientHeight) -
                  parseInt(document.getElementById("root2")?.clientHeight))
              }px`,
              overflow: "auto",
              // marginLeft: "10px",
              //minWidth: "45.666667%",
            }}
            id="itemDetails2"
            className={classes.customScrollBar}
          >
            {menuDetails &&
              menuDetails[selectedMenu] &&
              menuDetails[selectedMenu]?.menu_categories && (
                <ItemDetails
                  selectedMenuIndex={selectedMenu}
                  selectedMenuId={selectedItemId}
                  items={null}
                  menuId={selectedMenuId}
                  categoryId={selectedCategoryId}
                  handleRemoveItem={handleRemoveItem}
                  HandleReopenItem={HandleReopenItem}
                />
              )}
          </Grid>
        ) : (
          <Grid
            item
            xs={5}
            style={{
              marginRight: "10px",

              overflow: "auto",
              // marginLeft: "10px",
              //minWidth: "45.666667%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={classes.customScrollBar}
          >
            <Typography className={classes.textMessage}>
              {intl.formatMessage({
                id: "adding_your_menu",
              })}
              ,
              <br />
              {intl.formatMessage({
                id: "individual_items",
              })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default MenuManager;

const useStyles = makeStyles((theme) => ({
  textMessage: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#A3A9BC",
  },
  customScrollBar: {
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#EDF0F4",
      width: "5px", //for horizontal scrollbar
      height: "5px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#EDF0F4",
    },
    "&::-webkit-scrollbar-button": {
      width: "50px", //for horizontal scrollbar
      height: "70vh", //for vertical scrollbar
    },
  },
  menuItemSelected: {
    height: "60px",
    background: "#F66761",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  menuItem: {
    height: "60px",
    background: "#fff",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  alignButtonSelected: {
    width: "40px",
    height: "40px",
    background: "#F66761",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  alignButton: {
    width: "40px",
    height: "40px",
    background: "#F9FAFC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "350px",
  },
}));
