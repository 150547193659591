import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSearch, getNextSearchTab, getAllTabs } from "../../actions/order";
import { useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import "./OrderSetting.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import AddImage from "../../Components/AddImage/AddImage";
import AddCoverImage from "../../Components/AddImage/AddCoverImage";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import stripePic from "../../assets/image/stripe.png";
import { updateVenueDetails } from "../../actions/order";
import { useIntl } from "react-intl";
import { useLocation,useParams } from "react-router-dom";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 15,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#34CEB2",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "8px",
    opacity: 1,

    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles2 = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto #F66761",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#F66761",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#F66761",
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles2();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const DownloadButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",
    maxHeight: "28px",
    borderRadius: "5px",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
    "&:focus": {
      // boxShadow: 'none',
      // backgroundColor: 'white',
      // borderColor: '#005cbf',
      // backgroundColor: "#F9FAFC",
      // color: "#34CEB2",
      // border: "  1px solid #34CEB2",
    },
  },
}))(Button);

function OrderSetting() {
  const intl = useIntl();
  const params=useParams()

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let location = useLocation();

  let dispatch = useDispatch();
  const [tab, setTab] = useState(false);
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [autoAcceptOrders, setAutoAcceptOrders] = useState(false);
  const [waiterService, setWaiterService] = useState(false);
  const [orderSetting, setOrderSetting] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setTab(!venueDetail?.accept_orders_without_tab);
    setOrderSetting(!venueDetail?.read_only_menu);
    setWaiterService(venueDetail?.show_request_waiter_action);
    setTab3(venueDetail?.allow_tabs_to_have_same_table);
    setTab1(venueDetail?.auto_accept_orders);
    setTab2(venueDetail?.auto_accept_orders_from_unverified_tabs);
    if (
      venueDetail?.accept_card === true &&
      venueDetail?.accept_cash === false
    ) {
      setPaymentOption("mandatory");
    } else if (
      venueDetail?.accept_card === false &&
      venueDetail?.accept_cash === true
    ) {
      setPaymentOption("disabled");
    } else if (
      venueDetail?.accept_card === true &&
      venueDetail?.accept_cash === true
    ) {
      setPaymentOption("optional");
    }
    // eslint-disable-next-line
  }, []);
  const handleCheckAcceptOrders = () => {
    if (!loader) {
      setAutoAcceptOrders(!autoAcceptOrders);
      let obj = { auto_accept_orders: !autoAcceptOrders };
      handleSubmit(obj);
    }
  };
  const handleCheckWaiter = () => {
    console.log("checked", waiterService);
    // disabled={!venueDetail?.read_only_menu}
    if (!loader) {
      setWaiterService(!waiterService);
      let obj = { show_request_waiter_action: !waiterService };
      handleSubmit(obj);
    }
  };

  const handleSubmit = async (obj) => {
    if (venueDetail?.uuid) {
      setLoader(true);
      let res = await dispatch(
        updateVenueDetails({
          body: obj,
          venue_uuid: venueDetail?.uuid,
          slug: params?.id,
        })
      );
      if (res?.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };
  const handleRadioChange = (e) => {
    if (!venueDetail?.read_only_menu) {
      let currentValue = e.target.value;

      setPaymentOption(currentValue);
      if (currentValue === "mandatory") {
        let obj = { accept_card: true, accept_cash: false };
        handleSubmit(obj);
      } else if (currentValue === "disabled") {
        let obj = { accept_card: false, accept_cash: true };
        handleSubmit(obj);
      } else if (currentValue === "optional") {
        let obj = { accept_card: true, accept_cash: true };
        handleSubmit(obj);
      }
    }
  };

  const handleChange = (event) => {
    setWaiterService(!waiterService);
    let obj = { show_request_waiter_action: !waiterService };
    handleSubmit(obj);
  };
  const handleChangeTab = (event) => {
    setTab(event.target.checked);
    let obj = { accept_orders_without_tab: !event.target.checked};
    handleSubmit(obj);
  };
  const handleChangeOrder = (event) => {
    setOrderSetting(!event.target.checked);
    let obj = { read_only_menu: !event.target.checked };
    handleSubmit(obj);
  };
  const handleChangeTab1 = (event) => {
    setTab1(!tab1);

    let obj = { auto_accept_orders: !tab1 };
    handleSubmit(obj);
  };
  const handleChangeTab2 = (event) => {
    setTab2(!tab2);
    let obj = { auto_accept_orders_from_unverified_tabs: !tab2 };
    handleSubmit(obj);
  };
  const handleChangeTab3 = (event) => {
    setTab3(!tab3);
    let obj = { allow_tabs_to_have_same_table: !tab3 };
    handleSubmit(obj);
  };

  return (
    <>
      <div>
        {venueDetail && venueDetail.uuid !== null ? (
          <Grid container className={classes.root}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid container style={{ marginTop: "15px" }}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    {intl.formatMessage({
                      id: "Request waiter service",
                      defaultMessage: "Request waiter service",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.subHeading}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    {intl.formatMessage({
                      id: "Allow customers to request waiter service from the menu. ",
                      defaultMessage:
                        "Allow customers to request waiter service from the menu. ",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* <AntSwitch
                    checked={waiterService}
                    onClick={handleChange}
                    disableRipple
                  /> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={waiterService}
                      onChange={handleChange}
                    />
                    <span
                      className={`${
                        waiterService ? "slider" : "sliderchecket"
                      } round`}
                    ></span>
                  </label>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "15px" }}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    {intl.formatMessage({
                      id: "Order Settings",
                      defaultMessage: "Order Settings",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.subHeading}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    Allow you guest to place and order using their phone.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* <AntSwitch
                    checked={orderSetting}
                    onClick={handleChangeOrder}
                    disableRipple
                  /> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={orderSetting}
                      onChange={handleChangeOrder}
                    />
                    <span
                      className={`${
                        orderSetting ? "slider" : "sliderchecket"
                      } round`}
                    ></span>
                  </label>
                </Grid>
              </Grid>
              {orderSetting&&<>
              <Grid container style={{ marginTop: "15px" }}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    {intl.formatMessage({
                      id: "Tab settings",
                      defaultMessage: "Tab settings",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.subHeading}
                    style={{ paddingBottom: "0px", marginBottom: "13px" }}
                  >
                    Allow guests to open a Tab at their table. Usefull for
                    guests that place multiple orders and would like to pay at
                    the end.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {/* <AntSwitch
                    checked={tab}
                    // onClick={() => setTab(!tab)}
                    onClick={handleChangeTab}
                    disableRipple
                  /> */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={tab}
                      onChange={handleChangeTab}
                    />
                    <span
                      className={`${tab ? "slider" : "sliderchecket"} round`}
                    ></span>
                  </label>
                </Grid>
              </Grid>
              {tab && (
                <Grid container style={{ marginTop: "15px" }}>
                  <ListItem
                    style={{
                      padding: "0px",
                      marginBottom: "16px",
                      marginLeft: "-3px",
                    }}
                    button
                    onClick={handleChangeTab1}
                  >
                    <Checkbox
                      checked={tab1}
                      disableRipple
                      className={tab1 ? classes.checkBox : classes.unCheckBox}
                    />

                    <ListItemText className={classes.checkBoxText}>
                      Auto-accept orders from verified tab. Once a Tab is
                      verified after placing its first order, the rest of the
                      orders are auto-accepted so your guest don’t have to wait
                      for review.
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    style={{
                      padding: "0px",
                      marginBottom: "16px",
                      marginLeft: "-3px",
                    }}
                    button
                    onClick={handleChangeTab2}
                  >
                    <Checkbox
                      checked={tab2}
                      disableRipple
                      className={tab2 ? classes.checkBox : classes.unCheckBox}
                    />

                    <ListItemText className={classes.checkBoxText}>
                      Auto-accept orders from unverified Tabs. Use this setting
                      if you are not always near the tablet and you dont want
                      guest to be waiting for a confirmation.{" "}
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    style={{
                      padding: "0px",
                      marginBottom: "16px",
                      marginLeft: "-3px",
                    }}
                    button
                    onClick={handleChangeTab3}
                  >
                    <Checkbox
                      checked={tab3}
                      disableRipple
                      className={tab3 ? classes.checkBox : classes.unCheckBox}
                    />

                    <ListItemText className={classes.checkBoxText}>
                      Allow mulitple tabs to be opened on the same table. Use
                      this if you are using communal tables where multiple
                      people might be seating.
                    </ListItemText>
                  </ListItem>
                </Grid>
              )}
              <Grid container style={{ minHeight: "40px", marginTop: "16px" }}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Payment_options",
                      defaultMessage: "Payment options",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      aria-label="Payment"
                      name="Payment"
                      style={{ marginLeft: "3px" }}
                      value={paymentOption}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        className={classes.formControl}
                        value="disabled"
                        control={<StyledRadio />}
                        label={intl.formatMessage({
                          id: "Payment_Disabled",
                          defaultMessage: "Payment Disabled",
                        })}
                        disabled={venueDetail?.read_only_menu}
                      />
                      <FormControlLabel
                        value="optional"
                        control={<StyledRadio />}
                        label={intl.formatMessage({
                          id: "Payment_Optional",
                          defaultMessage: "Payment Optional",
                        })}
                        disabled={venueDetail?.read_only_menu}
                      />
                      <FormControlLabel
                        disabled={venueDetail?.read_only_menu}
                        value="mandatory"
                        control={<StyledRadio />}
                        label={intl.formatMessage({
                          id: "Payment_Mandatory",
                          defaultMessage: "Payment Mandatory",
                        })}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "40px",
                  marginTop: "30px",
                  marginBottom: "40px",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "Payment_provider",
                      defaultMessage: "Payment provider",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={stripePic}
                    alt={intl.formatMessage({
                      id: "no_img",
                      defaultMessage: "no img",
                    })}
                    style={{ marginTop: "11px" }}
                    width={"100px"}
                  ></img>

                  <Grid item xs={9} style={{}}>
                    <p
                      className={classes.StripeInfoText}
                      style={{ marginBottom: "18px" }}
                    >
                      {intl.formatMessage({
                        id: "creating_stripe_credit_card",
                        defaultMessage:
                          "Creating a Stripe account enables receiving orders via credit card,",
                      })}
                      {intl.formatMessage({
                        id: "IDEAL_Bancontact",
                        defaultMessage: "IDEAL, Bancontact",
                      })}
                    </p>
                    <DownloadButton
                      variant="contained"
                      color="primary"
                      // disabled={venueDetail && venueDetail.stripe_account_ref}
                      onClick={() =>
                        venueDetail &&
                        venueDetail.stripe_account_ref &&
                        window.open(venueDetail.stripe_merchant_signup_url)
                      }
                    >
                      {venueDetail && venueDetail.stripe_account_ref
                        ? intl.formatMessage({
                            id: "Stripe_Connected",
                            defaultMessage: "Stripe Connected",
                          })
                        : intl.formatMessage({
                            id: "Connect_Stripe",
                            defaultMessage: "Connect Stripe",
                          })}
                    </DownloadButton>
                    <p
                      className={classes.StripeInfoText}
                      style={{ marginTop: "18px" }}
                    >
                      {intl.formatMessage({
                        id: "Register_account_and_agree",
                        defaultMessage:
                          "By registering your account, you agree to the",
                      })}
                      <u>
                        {intl.formatMessage({
                          id: "Stripe_Services_Agreement",
                          defaultMessage: "Stripe Services Agreement",
                        })}
                      </u>
                      {intl.formatMessage({
                        id: "and_the",
                        defaultMessage: "and the",
                      })}
                      <br />
                      <u>
                        {intl.formatMessage({
                          id: "Stripe_connected_Account_Agreement",
                          defaultMessage: "Stripe connected Account Agreement",
                        })}
                      </u>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
             </>}
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={40} color="primary" />
          </Grid>
        )}
        <Grid item xs={1}></Grid>
      </div>
    </>
  );
}

export default OrderSetting;

const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  StripeInfoText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  formControl: {
    "& .MuiTypography-body1": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#303A5F",
    },
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    margin: "30px 0px 30px 0px",
  },
  checkBoxIcon: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px !important",
    },
    "& .MuiListItemText-root": {
      minWidth: "40px !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
  },
  checkBox: {
    "&.MuiCheckbox-colorSecondary": {
      color: "#F66761",
      padding: "0px !important",
    },
    "&.PrivateSwitchBase-root-47": {
      padding: "0px !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  unCheckBox: {
    "&.MuiCheckbox-colorSecondary": {
      color: "#A3A9BC",
      padding: "0px !important",
    },
    "&.PrivateSwitchBase-root-47": {
      padding: "0px !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  checkBoxText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "0px",
    margin: "auto",
    color: "#303A5F",
    minWidth: "40px",
    padding: "2px",
    paddingLeft: "9px",
    "& .MuiTypography-body1": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#303A5F",
    },
    "&.MuiListItemText-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "0px",
      margin: "auto",
      color: "#303A5F",
      minWidth: "40px",
      padding: "2px",
      paddingLeft: "9px",
    },
  },
  cancelButton: {
    marginLeft: "10px",
    marginTop: "10px",
    textTransform: "none",
    background: "#F9FAFC",
    color: "#303A5F",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    border: "0px solid #F9FAFC",
    "&:hover": {
      backgroundColor: "#F9FAFC",
    },
  },
  printButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#20BDA1",
      // border: "  1px solid #20BDA1",
    },
  },
  infiniteScroll: {
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "88vh",
    paddingTop: "22px",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: "#303A5F",
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },
  TextInputStyle90: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "100px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#303A5F",
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: "#303A5F",
  },
  outlinedStyle: {
    border: "0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
}));
