//order
export const VENUE_ORDERS = "VENUE_ORDERS";
export const REVIEW_ORDER = "REVIEW_ORDER";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const USER_DETAIL = "USER_DETAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const VENUE_DETAIL = "VENUE_DETAIL";
export const REVIEW_ORDER_LIST = "REVIEW_ORDER_LIST";
export const TEMP_REVIEW_ORDER_LIST = "TEMP_REVIEW_ORDER_LIST";
export const REMOVE_ITEM_REVIEW_LIST = "REMOVE_ITEM_REVIEW_LIST";
export const ORDER_COUNT = "ORDER_COUNT";
export const CREATE_TAB = "CREATE_TAB";
export const SETTLE_TAB = "SETTLE_TAB";
export const GET_TAB_LIST = "GET_TAB_LIST";
export const PAST_TAB = "PAST_TAB";
export const ACTIVE_TAB = "ACTIVE_TAB";
export const UPDATE_TAB = "UPDATE_TAB";
export const SEARCH_TAB = "SEARCH_TAB";
export const TABLE_TAB = "TABLE_TAB";
export const VERIFY_TAB = "VERIFY_TAB";
export const DECLINE_TAB = "DECLINE_TAB";
export const DISMISS_ORDER = "DISMISS_ORDER";
export const GET_TAB_ORDERS = "GET_TAB_ORDERS";
export const GET_REPORT = "GET_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";
export const PENDING_ORDERS = "PENDING_ORDERS";

export const GET_VENUE_DETAILS = "GET_VENUE_DETAILS";

//menu

export const ADD_MENU = "ADD_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

export const ADD_CUSTOMIZATION = "ADD_CUSTOMIZATION";
export const UPDATE_CUSTOMIZATION = "UPDATE_CUSTOMIZATION";
export const DELETE_CUSTOMIZATION = "DELETE_CUSTOMIZATION";

export const GET_TAGS_META = "GET_TAGS_META";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";

export const GET_CUSTOMIZATION_LIST = "GET_CUSTOMIZATION_LIST";
export const ADD_CUSTOMIZATION_IN_LIST = "ADD_CUSTOMIZATION_IN_LIST";
export const UPDATE_CUSTOMIZATION_IN_LIST = "UPDATE_CUSTOMIZATION_IN_LIST";
export const DELETE_CUSTOMIZATION_IN_LIST = "DELETE_CUSTOMIZATION_IN_LIST";

//menu availability

export const GET_MEUN_AVAILABILITY = "GET_MEUN_AVAILABILITY";
export const UPDATE_MENU_AVAILABILITY_ITEM = "UPDATE_MENU_AVAILABILITY_ITEM";
export const UPDATE_MENU_AVAILABILITY_CATEGORY =
  "UPDATE_MENU_AVAILABILITY_CATEGORY";
export const UPDATE_MENU_AVAILABILITY_MENU = "UPDATE_MENU_AVAILABILITY_MENU";
export const UPDATE_MENU_AVAILABILITY_CUSTOMIZATION =
  "UPDATE_MENU_AVAILABILITY_CUSTOMIZATION";
export const UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS =
  "UPDATE_MENU_AVAILABILITY_CUSTOMIZATION_OPTIONS";

export const DEFAULT_STATUS = "DEFAULT_STATUS";
export const GET_ALL_TAGS = "GET_ALL_TAGS";

export const ADD_TAGS_TEMPLATE = "ADD_TAGS_TEMPLATE";
export const UPDATE_TAGS_TEMPLATE = "UPDATE_TAGS_TEMPLATE";
export const DELETE_TAGS_TEMPLATE = "DELETE_TAGS_TEMPLATE";

//hotel guests

export const GET_HOTEL_GUESTS_LIST = "GET_HOTEL_GUESTS_LIST";
export const ADD_HOTEL_GUESTS = "ADD_HOTEL_GUESTS";
export const UPDATE_HOTEL_GUESTS = "UPDATE_HOTEL_GUESTS";
export const DELETE_HOTEL_GUESTS = "DELETE_HOTEL_GUESTS";

export const UPDATE_VENUE_SETTING = "UPDATE_VENUE_SETTING";

//tables

export const GET_VENUE_TABLES = "GET_VENUE_TABLES";
export const ADD_VENUE_TABLE = "ADD_VENUE_TABLE";
export const DELETE_VENUE_TABLE = "DELETE_VENUE_TABLE";
export const UPDATE_VENUE_TABLE = "UPDATE_VENUE_TABLE";
export const ADD_TABLES = "ADD_TABLES";

//hotel details

export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
export const UPDATE_HOTEL_DETAILS = "UPDATE_HOTEL_DETAILS";

//dragnDrop menu manager

export const REORDER_MENU = "REORDER_MENU";
export const REORDER_CATEGORY = "REORDER_CATEGORY";
export const REORDER_ITEMS = "REORDER_ITEMS";
export const REORDER_CUSTOMIZATIONS = "REORDER_CUSTOMIZATIONS";
export const REORDER_CUSTOMIZATIONS_OPTIONS = "REORDER_CUSTOMIZATIONS_OPTIONS";
export const REORDER_TABLES = "REORDER_TABLES";
export const REMOVE_VENUE_DETAILS = "REMOVE_VENUE_DETAILS";
export const UPDATE_VENUE_ORDER_STATUS = "UPDATE_VENUE_ORDER_STATUS";
export const UPDATE_ALL_ITEM_CUSTOMIZATIONS = "UPDATE_ALL_ITEM_CUSTOMIZATIONS";
export const UPDATE_ITEM_PRICES = "UPDATE_ITEM_PRICES";
export const UPDATE_LANGUAGE="UPDATE_LANGUAGE";

export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const UPDATE_VENUE_LANGUAGE = "UPDATE_VENUE_LANGUAGE";
export const SET_MENU_MANAGER_LANGUAGE = "SET_MENU_MANAGER_LANGUAGE";


//localization
export const GET_TRANSLATED_VENUE_DETAILS = "GET_TRANSLATED_VENUE_DETAILS";
export const ADD_TRANSLATED_MENU = "ADD_TRANSLATED_MENU";
export const UPDATE_TRANSLATED_PRICES = "UPDATE_TRANSLATED_PRICES";
export const UPDATE_TRANSLATED_MENU = "UPDATE_TRANSLATED_MENU";
export const ADD_TRANSLATED_CATEGORY = "ADD_TRANSLATED_CATEGORY";
export const UPDATE_TRANSLATED_CATEGORY = "UPDATE_TRANSLATED_CATEGORY";
export const ADD_TRANSLATED_ITEM = "ADD_TRANSLATED_ITEM";
export const UPDATE_TRANSLATED_ITEM = "UPDATE_TRANSLATED_ITEM";
export const ADD_TRANSLATED_CUSTOMIZATION = "ADD_TRANSLATED_CUSTOMIZATION";
export const UPDATE_TRANSLATED_CUSTOMIZATION = "UPDATE_TRANSLATED_CUSTOMIZATION";
export const ADD_TRANSLATED_TAG = "ADD_TRANSLATED_TAG";
export const UPDATE_TRANSLATED_TAG = "UPDATE_TRANSLATED_TAG";
export const ADD_VENUE = "ADD_VENUE";

export const UPDATE_ORDER_STATUS_IN_HISTORY = "UPDATE_ORDER_STATUS_IN_HISTORY";
export const UPDATE_ORDER_STATUS_IN_TAB= "UPDATE_ORDER_STATUS_IN_TAB";

//user permissions
export const GET_USER_PERMISSIONS= "GET_USER_PERMISSIONS";
export const ADD_USER_PERMISSIONS= "ADD_USER_PERMISSIONS";
export const UPDATE_USER_PERMISSIONS= "UPDATE_USER_PERMISSIONS";
export const DELETE_USER_PERMISSIONS= "DELETE_USER_PERMISSIONS";
export const GET_PERMISSION_ROLES= "GET_PERMISSION_ROLES";
export const GET_AVAILABLE_VENUES= "GET_AVAILABLE_VENUES";
