import API from "../utils/API";

export const signupUser =
  ({ user }) =>
  async (dispatch, getState) => {
    // set body
    const body = JSON.stringify({
      ...user,
    });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const response = await API.post(
        `/api/business/create-business-account/`,
        body,
        config
      );
      return { status: response?.status, data: response };
    } catch (error) {
     

      return { status: 400, data: error?.response?.data };
    }
  };


  export const forgotPassword = ({ user }) => async (dispatch,getState) => {
    // set body
    const body = JSON.stringify({
     ...user
    });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const response = await API.post(`/auth-accounts/password-reset/`, body, config);
      return {status:200,data:response}
    } catch (error) {
      
      return {status:400,data:error};
    }
  };
  export const resetPassword = ({ user }) => async (dispatch,getState) => {
    // set body
    const body = JSON.stringify({
     ...user
    });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const response = await API.post(`/auth-accounts/password-reset/confirm/`, body, config);
      return {status:200,data:response}
    } catch (error) {
      
      return {status:400,data:error?.response?.data};
    }
  };
