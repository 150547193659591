import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { TextButtonAdd, TextButton } from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch,useSelector } from "react-redux";
import { addMenu, deleteMenu, updateMenu } from "../../actions/Localization";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useIntl } from "react-intl";
import { ReactComponent as RightIcon } from "../../assets/image/right.svg";

export default function MenuForm(props) {
  let menuDetails = useSelector(
    (state) => state?.translatedMenu?.venueDetails
  );
  const dispatch = useDispatch();
  const intl = useIntl();
  let languageReducer = useSelector((state) => state?.languageReducer);

  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");
  const [errors, setErrors] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };
  useEffect(() => {
    // menuDetails.findIndex((i)=>i.id===translations[0]?.text)
    console.log("props",props)
    setName(props?.item?.item?.translations[0]?.text);
  }, [props]);
  const handleAdd = async () => {
    if (name && name?.trim() !== "") {
      setLoading(true);
      setUpdatingType("add");
      let res = await dispatch(addMenu({ name: name,language:languageReducer?.menuManagerLanguage }));
      if (res.status === 200) {
        props && props.updateItem();
        setTimeout(() => {
          props && props.handleSelectMenu(res?.id, "menu");
        }, 400);
      }
      setLoading(false);
      setUpdatingType("");
    } else {
      setErrors(true);
    }
  };
  const handleUpdate = async () => {
    if (name && name?.trim() !== "") {
      setLoading(true);
      setUpdatingType("update");
      let res = await dispatch(
        updateMenu({ id: props?.item?.item?.id, name: name,language:languageReducer?.menuManagerLanguage })
      );
      if (res === 200) {
        props && props.updateItem();
      }
      setLoading(false);
      setUpdatingType("");
    } else {
      setErrors(true);
    }
  };
  const handleDelete = async () => {
    setLoading(true);
    setUpdatingType("delete");
    let res = await dispatch(deleteMenu({ id: props?.item?.item?.id }));
    if (res === 200) {
      props && props.updateItem();
    }
    setLoading(false);
    setUpdatingType("");
  };
  const handleOpenDeleteModal = () => {
    setOpenDelete(true);
  };

  return (
    <div>
      {openDelete && (
        <ConfirmDeleteDialog
          open={openDelete}
          handleClose={setOpenDelete}
          confirmDelete={handleDelete}
          type="menu"
          name={props?.item?.item?.name}
        />
      )}
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Typography className={classes.headersTest}>
                {props?.item?.item?.id ? 
                intl.formatMessage({
                  id: "Edit_menu",
                })
                 : intl.formatMessage({
                  id: "Add_new_menu",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          
        <Grid container>
        <Grid item xs={12}>
        <Typography className={classes.headingTest}>
              {intl.formatMessage({
                  id: "Name",
                })}
              </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
        <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Menu_name",
              })}
              disabled={true}
              fullWidth
              value={props?.item?.item?.name}
              
              error={errors}
              helperText={errors && intl.formatMessage({
                id: "Field_is_required",
              })}
             
              inputProps={{
                className: classes.TextInputStyleDisable,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
              style={{
                borderRadius: "5px",
                width: "100%",
                color:"#A3A9BC !important"
              }}
            />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            width: "28px",
            justifyContent: "center",
          }}
        >
          <RightIcon />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
        <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Menu_name",
              })}
              fullWidth
              value={name}
              onChange={(e) => {
                setErrors(false);
                setName(e.target.value);
              }}
              error={errors}
              helperText={errors && intl.formatMessage({
                id: "Field_is_required",
              })}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              inputProps={{
                className: classes.TextInputStyle2,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
        </Grid>
      </Grid>
          
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px  30px 24px " }}>
          {props?.item?.item?.id ? (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                {/* <TextButton disabled={loading} onClick={handleOpenDeleteModal}>
                  {loading && updatingType === "delete" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Delete",
                    })
                    
                  )}
                </TextButton> */}
              </Grid>

              <Grid item>
                <TextButtonAdd disabled={loading} onClick={handleUpdate}>
                  {loading && updatingType === "update" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                    })
                    
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <TextButtonAdd disabled={loading} onClick={handleAdd}>
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  intl.formatMessage({
                    id: "Save",
                  })
                )}
              </TextButtonAdd>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headersTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  TextInputStyleDisable: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#A3A9BC",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#F9FAFC",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
}));

// background: #FFFFFF;
// /* Input field */

// border: 1px solid #EDF0F4;
// box-sizing: border-box;
// border-radius: 5px;
