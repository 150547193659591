import {
  GET_HOTEL_GUESTS_LIST,
  ADD_HOTEL_GUESTS,
  UPDATE_HOTEL_GUESTS,
  DELETE_HOTEL_GUESTS,
} from "../constant/types";

const initialState = {
  guestsList: [],
  count: 0,
  next: null,
  previous: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOTEL_GUESTS_LIST: {
      return {
        ...state,
        guestsList: payload?.results,
        count: payload?.count,
        next: payload?.next,
        previous: payload?.previous,
      };
    }
    case ADD_HOTEL_GUESTS: {
      let gList = [...state.guestsList, payload];
      let counts = state?.count + 1;
      return {
        ...state,
        guestsList: gList,
        count: counts,
      };
    }
    case UPDATE_HOTEL_GUESTS: {
      let gList = [...state.guestsList];

      let index = gList.findIndex((item) => item.id === payload?.id);
      if (index > -1) {
        gList[index] = payload;
        return {
          ...state,
          guestsList: [...gList],
        };
      }
    }
    case DELETE_HOTEL_GUESTS: {
      let gList = [...state.guestsList];
      let counts = state?.count;
      let index = gList.findIndex((item) => item.id === payload);
      if (index > -1) {
        counts = counts - 1;
        gList.splice(index, 1);
        return {
          ...state,
          guestsList: [...gList],
          count: counts,
        };
      }
    }
    default:
      return state;
  }
}
