import React from "react";
import moment from "moment";
import copy from "copy-to-clipboard";

import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteSessionDialog from "./DeleteSessionDialog";
import { MobileDateTimePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";

import { addGuest, deleteGuest, updateGuest } from "../../actions/hotelGuests";
import {
  Grid,
  Button,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import "../../style/home.css";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CreateGuestSession(props) {
  console.log("props",props)
  let dispatch = useDispatch();
  let theme = useTheme();
  const intl = useIntl();

  const { item } = props;
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { open, handleClose, OrderSelected, setOrderSelected } = props;
  const classes = useStyles();
  const [expireDateError, setExpireDateError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [verified, setVerified] = React.useState("no");
  const [verifiedError, setVerifiedError] = useState(false);

  const [checkIn, setcheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(
    new Date(moment().add(1, "minutes"))
  );
  const [email, setEmail] = useState();
  const [sendCheckBox, setSendCheckBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customTabLink, setCustomTabLink] = useState(null);
  const [roomNumber, setRoomNumber] = useState("");
  const [surName, setSurName] = useState("");
  const [errorSurName, setErrorSurName] = useState(false);
  const [errorRoomNumber, setErrorRoomNumber] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 8000);
    }
  }, [isCopy]);
  useEffect(() => {
    if (item) {
      setcheckIn(item?.check_in_datetime);
      setCheckOut(item?.check_out_datetime);
      setEmail(item?.guest_email);
      setRoomNumber(item?.room_number);
      setSurName(item?.surname);
      setSendCheckBox(item?.send_session_link_to_guest ? true : false);
      setCustomTabLink(item?.session_url);
      setVerified(item?.verified?"yes":"no")
    }
    // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 9000);
  }, [errorMessage]);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );
  const handleDelete = () => {
    setDialogOpen(true);
  };

  function handleEmail(e) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(e.target.value)) {
      setEmail(e.target.value);
      setEmailError(false);
    } else if (e.target.value.length === 0) {
      setEmailError(false);
      setEmail(e.target.value);
    } else {
      setEmailError(true);
      setEmail(e.target.value);
    }
  }
  const handleRoomNumber = (e) => {
    setRoomNumber(e.target.value);
    setErrorRoomNumber(false);
  };
  const handleSurName = (e) => {
    setSurName(e.target.value);
    setErrorSurName(false);
  };
  const handleConfirmDelete = async () => {
    let res = await dispatch(
      deleteGuest({
        business_account_uuid: User_uuid,
        id: item?.id,
      })
    );
    if (res?.status === 200) {
      handleClose();
    }
  };
  const handleCreateSession = async () => {
    if ((sendCheckBox &&(email === undefined || email === null || email?.trim() === ""))||
      roomNumber === undefined ||
      roomNumber === null ||
      roomNumber?.trim() === "" ||
     
      surName?.trim() === "" ||
      surName === null ||
      surName === undefined
    ) {
      if (email === undefined || email === null || email?.trim() === "") {
        setEmailError(true);
      }
      if (
        roomNumber === undefined ||
        roomNumber === null ||
        roomNumber?.trim() === ""
      ) {
        setErrorRoomNumber(true);
      }
    } else {
      if (OrderSelected) {
      } else {
        const obj = {
          guest_email: email,
          room_number: roomNumber,
          check_in_datetime: checkIn,
          check_out_datetime: checkOut,
          send_session_link_to_guest: sendCheckBox,
          surname: surName,
          verified:verified==="yes"?true:false
        };
        if (item) {
          setLoading(true);
          let res = await dispatch(
            updateGuest({
              business_account_uuid: User_uuid,
              data: obj,
              id: item?.id,
            })
          );
          setLoading(false);
          if (res?.status === 200) {
            handleClose();
          } else {
            setErrorMessage(res?.message);
          }
        } else {
          setLoading(true);

          let res = await dispatch(
            addGuest({
              business_account_uuid: User_uuid,
              data: obj,
            })
          );
          setLoading(false);
          if (res?.status === 200) {
            handleClose();
          } else {
            setErrorMessage(res?.message);
          }
        }
      }
    }
  };
  const handleCopy = (customTabLink) => {
    copy(customTabLink);
    setIsCopy(true);
  };
  const handleChange = (event) => {
    setVerified(event.target.value);
    setVerifiedError(false);
  };
  return (
    <div>
      <DeleteSessionDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        OrderSelected={OrderSelected}
        setAlertOpen={setAlertOpen}
        setOrderSelected={setOrderSelected}
        setOpen={handleClose}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Dialog
        open={true}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent className="dialogContext">
          <Grid item xs={12}>
            <Grid className={classes.OrderGridInner}>
              <Grid container>
              <Grid item xs={2}/>

                <Grid item xs={8} className={classes.PreviousOrder}>
                  {item
                    ? intl.formatMessage({
                        id: "Edit_guest_session",
                      })
                    : intl.formatMessage({
                        id: "Create_guest_session",
                      })}
                </Grid>
                <Grid item xs={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
                >
                <ClearIcon
                    className={classes.CrossIcon}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Grid>

                {/* <Grid item xs={4} style={{ textAlign: "end" }}>
                 
                </Grid> */}
              </Grid>

              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xs={6}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Room_number",
                    })}
                  </p>

                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Enter_room_number",
                    })}
                    required
                    fullWidth
                    id="room_number"
                    name="room_number"
                    type="text"
                    error={errorRoomNumber ? true : false}
                    helperText={
                      errorRoomNumber &&
                      intl.formatMessage({
                        id: "Field_is_required",
                      })
                    }
                    fullWidth
                    value={roomNumber}
                    onChange={handleRoomNumber}
                    classes={{}}
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "surname",
                    })}
                  </p>

                  <TextField
                    variant="outlined"
                    //  className={classes.fieldtext}
                    placeholder={intl.formatMessage({
                      id: "enterSurname",
                    })}
                    required
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    error={errorSurName ? true : false}
                    autoComplete="email"
                    fullWidth
                    value={surName}
                    onChange={handleSurName}
                    classes={{}}
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justify={"space-between"}
                style={{ marginTop: "6px" }}
              >
                <Grid item xs={6}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Check_in",
                    })}
                  </p>
                  <MobileDateTimePicker
                    onError={(e) => {
                      e ? setExpireDateError(true) : setExpireDateError(false);
                    }}
                    // disablePast
                    placeholder={new Date().toString()}
                    value={checkIn}
                    helper
                    ampm={false}
                    onChange={(newValue) => {
                      setcheckIn(newValue);
                      moment(newValue).isAfter(moment(checkOut))
                        ? setExpireDateError(true)
                        : setExpireDateError(false);
                    }}
                    inputFormat={"dddd - DD/MM/YY - HH:mm "}
                    InputProps={{
                      classes: {
                        input: classes.rangeInputPadding,
                      },
                    }}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        error={expireDateError}
                        placeholder={moment()
                          .format("dddd - DD/MM/YY - HH:mm ")
                          .toString()}
                        variant="outlined"
                        {...props}
                      />
                    )}
                  />
                  {expireDateError && (
                    <small style={{ color: "#f44336" }}>
                      {intl.formatMessage({
                        id: "Invalid_Check_in_range",
                      })}
                    </small>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Check_out",
                    })}
                  </p>
                  <MobileDateTimePicker
                    onError={(e) => {
                      e ? setExpireDateError(true) : setExpireDateError(false);
                    }}
                    // disablePast
                    value={checkOut}
                    helper
                    ampm={false}
                    onChange={(newValue) => {
                      setCheckOut(newValue);
                      moment(checkIn).isAfter(moment(newValue))
                        ? setExpireDateError(true)
                        : setExpireDateError(false);
                    }}
                    inputFormat={"dddd - DD/MM/YY - HH:mm "}
                    InputProps={{
                      classes: {
                        input: classes.rangeInputPadding,
                      },
                    }}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        placeholder={intl.formatMessage({
                          id: "Expires_here",
                        })}
                        variant="outlined"
                        {...props}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "6px" }}>
              <Grid item xs={6}>
                  <p className={classes.textLabel}>{intl.formatMessage({
                          id: "Verified",
                        })}</p>

                  <Select
                    value={verified}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: "Verified",
                    })}
                    className={classes.verified}
                    error={verifiedError}
                  >
                    {/* <MenuItem value="">
                      <em>{intl.formatMessage({
                          id: "None",
                        })}</em>
                    </MenuItem> */}
                    <MenuItem value={"yes"}>{intl.formatMessage({
                          id: "Yes",
                        })}</MenuItem>
                    <MenuItem value={"no"}>{intl.formatMessage({
                          id: "No",
                        })}</MenuItem>
                  </Select>

                  <FormHelperText>
                    {verifiedError ? intl.formatMessage({
                          id: "Field_is_required",
                        }) : ""}
                  </FormHelperText>
                </Grid>
            
              <Grid item xs={6}>
              <Grid container spacing={2} >
                <Grid item xs={9}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Guest_session_link",
                    })}
                  </p>
                  <TextField
                    variant="outlined"
                    //  placeholder="Month"
                    //  className={classes.fieldtext}
                    value={customTabLink}
                    onChange={(e) => setCustomTabLink(e.target.value)}
                    disabled={true}
                    fullWidth
                    style={{
                      background: "#fff",
                      borderRadius: "5px",
                      // border: "0px",
                    }}
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Link_creation",
                    })}
                    InputProps={{
                      classes: classes.outlinedStyle,
                    }}
                    inputProps={{
                      // defaultValue: OrderSelected.table_number,
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
                <Grid item xs={3} style={{ textAlign: "end" }}>
                  <Button
                    className={classes.copyButton}
                    onClick={() => handleCopy(customTabLink)}
                  >
                    {intl.formatMessage({
                      id: "Copy",
                    })}
                  </Button>
                </Grid>
              </Grid>
              </Grid>

            </Grid>
              <Grid container spacing={2} style={{ marginTop: "6px" }}>
                <Grid item xs={12}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Share_link_via_email",
                    })}
                  </p>

                  <TextField
                    variant="outlined"
                    //  className={classes.fieldtext}
                    placeholder={intl.formatMessage({
                      id: "Enter_Email",
                    })}
                    required
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    error={emailError ? true : false}
                    autoComplete="email"
                    fullWidth
                    value={email}
                    onChange={handleEmail}
                    classes={{}}
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container justify="center">
                <small
                  style={{ color: theme.colors.primary, paddingTop: "10px" }}
                >
                  {isCopy &&
                    intl.formatMessage({
                      id: "Link_copied",
                    })}
                </small>
              </Grid>
              <ListItem
                style={{
                  padding: "0px",
                  marginTop: "12px",
                  marginBottom: "16px",
                }}
                button
                onClick={() => setSendCheckBox(!sendCheckBox)}
              >
                <ListItemIcon className={classes.checkBoxIcon}>
                  <Checkbox
                    // edge="start"
                    checked={sendCheckBox}
                    disableRipple
                    className={classes.checkBox} // inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.checkBoxText}>
                  {intl.formatMessage({
                    id: "send_session_link",
                  })}
                </ListItemText>
              </ListItem>
              <Grid container justify="center">
                {<small style={{ color: "#f44336" }}>{errorMessage}</small>}
              </Grid>
              <Grid
                container
                justify={"space-between"}
                style={{ marginbottom: "5px" }}
              >
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  {item && (
                    <Button
                      variant="contained"
                      className={classes.settleButton}
                      onClick={handleDelete}
                    >
                      {intl.formatMessage({
                        id: "Delete",
                      })}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "Right" }}>
                  <Button
                    variant="contained"
                    className={classes.printButton}
                    disabled={
                      roomNumber &&
                      // checkIn !== null &&
                      expireDateError === false &&
                      (emailError === false || sendCheckBox === false) &&
                      (email !== null || sendCheckBox === false)
                        ? false
                        : true
                    }
                    onClick={() => handleCreateSession()}
                  >
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    ) : item ? (
                      intl.formatMessage({
                        id: "Save",
                      })
                    ) : (
                      intl.formatMessage({
                        id: "Create",
                      })
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity="error">
          {intl.formatMessage({
            id: "Some_Error_Happen_Try_again",
          })}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CreateGuestSession;

const useStyles = makeStyles((theme) => ({
  verified: {
    height: "40px",
    background: "#FFFFFF",
    // border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.colors.primary,
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  root: {
    padding: "0px",
  },
  checkBox: {
    "&.MuiCheckbox-colorSecondary": { color: "#F66761" },
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  checkBoxText: {
    "&.MuiListItemText-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "0px",
      margin: "auto",
      color: "#485379",
      minWidth: "40px",
      padding: "2px",
    },
  },
  copyButton: {
    marginTop: "37px",
    textTransform: "none",
    background: "#EDF0F4",
    color: "#A3A9BC",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      color: "#EDF0F4",
      background: "#A3A9BC",
    },
  },
  printButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#20BDA1",
    },
  },
  settleButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#FF3366",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#FF3366",
    },
  },

  checkBoxIcon: {
    "&.MuiListItemText-root": {
      minWidth: "40px",
    },
  },

  OrderGridInner: {
    padding: "20px 0px",
  },
  gridList: {
    width: 320,
    height: "100vh",
  },
  cardList: {
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "20px",
  },
  cardStyle: {
    marginTop: "28px",
    boxShadow: "none",
    borderRadius: "5px",
  },
  tableNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",

    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  orderId: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "5px 5px 5px 5px",
  },
  time: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "0px",
    color: theme.colors.primary,
    padding: "10px  0px 0px 0px",
  },

  PreviousOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    margin: "0px",
    color: theme.colors.primary,
    padding: "0px 0px 30px 0px",
    textAlign: "right",
    display:"flex",justifyContent:"center"
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "none",
    color: theme.colors.primary,
    margin: "7px 0px",
  },
  CrossIcon: {
    cursor: "pointer",
    color: theme.colors.primary,
  },
}));
