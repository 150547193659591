import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as MenuIcon } from "../../assets/image/menu.svg";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NestedMenuItem from "material-ui-nested-menu-item";
import { getVenueDetail } from "../../actions/order";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [venuesList, setVenuesList] = useState([]);

  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [menuPosition, setMenuPosition] = useState(null);

  const handleRightClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleItemClick = (event) => {
    setMenuPosition(null);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    try {
      if (UserDetail?.venues) {
        let venuesPermissions = UserDetail?.user_venue_permissions;
        let arr = [];
        UserDetail.venues.forEach((element) => {
          let index = venuesPermissions[0].venues.findIndex(
            (i) => i === element.id
          );
          if (index > -1) {
            arr.push(element);
          }
        });
        setVenuesList([...arr]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [UserDetail?.venues]);
  return (
    <>
      <MenuIcon
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        //   onClick={handleToggle}
        onClick={handleRightClick}
      />
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <NestedMenuItem
          label={intl.formatMessage({
            id: "preview_your_menu",
            defaultMessage: "Preview your menu",
          })}
          parentMenuOpen={!!menuPosition}
          // onClick={handleItemClick}
        >
          {console.log("venuesList",venuesList)}
          {venuesList &&
            venuesList.map((venue, i) => (
              <>
              <a        target="_blank"
              style={{textDecoration:"none",color:"unset"}}
                  href={`https://${venue?.domain}`}>
                <MenuItem
                 
                >
                  {venue?.name}
                </MenuItem>
                </a>
              </>
            ))}
        </NestedMenuItem>
        <MenuItem onClick={props.handleShowChatButton}>
          {intl.formatMessage({
            id: "help_chat",
            defaultMessage: "Help chat",
          })}
        </MenuItem>
        <MenuItem onClick={props.handleLogOut}>
          {`${props?.venueName} - ${intl.formatMessage({
            id: "Logout",
          })}`}
        </MenuItem>
      </Menu>
    </>
  );
}
