import {
  VENUE_ORDERS,
  ORDER_HISTORY,
  ORDER_COUNT,
  REVIEW_ORDER_LIST,
  REMOVE_ITEM_REVIEW_LIST,
  CREATE_TAB,
  UPDATE_TAB,
  TABLE_TAB,
  ACTIVE_TAB,
  SETTLE_TAB,
  SEARCH_TAB,
  GET_TAB_LIST,
  DISMISS_ORDER,
  UPDATE_VENUE_SETTING,
  GET_HOTEL_DETAILS,
  GET_TAB_ORDERS,
  REMOVE_VENUE_DETAILS,
  PENDING_ORDERS,
  UPDATE_VENUE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_IN_HISTORY,
  UPDATE_ORDER_STATUS_IN_TAB
} from "../constant/types";


import moment from "moment";
import API from "../utils/API";
import axios from "axios";
import { element } from "prop-types";

const Authorization = {
  Authorization: "Token " + localStorage.AuthUserKey,
};

export const updateReviewOrderFromHistory= ({
  order_uuid,
  venue_uuid,
  Status,
  from
}) => async (dispatch,getState) => {
  // set body
  const body = JSON.stringify({
    status: Status,
  });
  const config = {
    headers: {
      "Content-type": "application/json",

      "venue-uuid": venue_uuid,
      Authorization: "Token " +localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.post(
      `/business/review-order/${order_uuid}/`,
      body,
      config
    );
    if(from==="tab"){
    dispatch({
      type: UPDATE_ORDER_STATUS_IN_TAB,
      payload:{status:Status,order_uuid},
    })}else{
    dispatch({
      type: UPDATE_ORDER_STATUS_IN_HISTORY,
      payload:{status:Status,order_uuid},
    })}
    // UPDATE_ORDER_STATUS_IN_HISTORY
    return {status:200,data:""}
  } catch (error) {
    return {status:400,data:error.message}

    console.log("error here", error);
  }
};

export const getTabOrdersList = ({ venue_uuid, id }) => async (dispatch,getState) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(`/api/business/tabs/${id}/`, config);
    dispatch({
      type: GET_TAB_ORDERS,
      payload: response.data,
    });
    return { status: 200, message: "" };
  } catch (error) {
    return { status: 400, message: error?.message };
  }
};

export const updateVenueDetails = ({ venue_uuid, body, slug }) => async (
  dispatch,getState
) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.patch(
      `/business/venue/${slug}/update/`,
      body,
      config
    );
    dispatch({
      type: "VENUE_DETAILS",
      payload: response.data,
    });
    return { status: 200, message: "" };
  } catch (error) {
    return { status: 400, message: error?.message };
  }
};
export const dismissRequest = ({
  order_uuid,
  venue_uuid,
  Authorization,
  remove,
}) => async (dispatch,getState) => {
  // set body
let language=getState()?.languageReducer?.language;
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
      // language:language
    },
  };
  try {
    const response = await API.post(
      `/api/business/live-requests/${order_uuid}/dismiss/`,
      {},
      config
    );
    dispatch({
      type: REMOVE_ITEM_REVIEW_LIST,
      payload: { order: remove.order, autoSave: remove.autoSave },
    });

    dispatch({
      type: "DISMISS_ORDER",
      payload: order_uuid,
    });
  } catch (error) {}
};
export const dismissOrder = ({
  order_uuid,
  venue_uuid,
  Authorization,
  remove,
}) => async (dispatch,getState) => {
  // set body

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
    },
  };
  try {
    const response = await API.post(
      `/business/dismiss-order/${order_uuid}/`,
      {},
      config
    );
    dispatch({
      type: REMOVE_ITEM_REVIEW_LIST,
      payload: { order: remove.order, autoSave: remove.autoSave },
    });

    dispatch({
      type: "DISMISS_ORDER",
      payload: order_uuid,
    });
  } catch (error) {}
};

export const getVenueOrder = ({ venue_uuid, Authorization }) => async (
  dispatch,getState
) => {
let language=getState()?.languageReducer?.language;

  const config = {
    headers: {
      language:language,
      // "Content-Type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
    },
  };
  // set body
  try {
    const response = await API.get(`/business/live-orders/`, config);
    const response2 = await API.get(`/business/live-requests/`, config);

    let mapArr = [];
    response2.data.results.forEach((elementn) => {
      mapArr.push({ ...elementn, submitted_for_review: elementn.created_at });
    });
    let newArr = [...response?.data, ...mapArr];
    let newArrs = newArr.sort(function (a, b) {
      return (
        new Date(a?.submitted_for_review) - new Date(b?.submitted_for_review)
      );
    });
    let pendingOrders = newArrs?.filter(
      (n) => n?.request_type > -1 || n?.status === 2
    );
    let acceptedOrders = newArrs?.filter((n) => n?.status === 3);
    let NewArr = [...pendingOrders, ...acceptedOrders];
    if (response && response.data) {
      dispatch({ type: VENUE_ORDERS, payload: NewArr });
      dispatch({ type: ORDER_COUNT, payload: NewArr.length });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getOrderHistory = ({ venue_uuid, Authorization }) => async (
  dispatch,getState
) => {
  // dispatch({ type: VENUE_ORDERS, payload:orderList });
let  language=getState()?.languageReducer?.language
  const config = {
    headers: {
      // "Content-Type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
      language:language
    },
  };
  // set body
  try {
    const response = await API.get(`/business/order-history/`, config);
    if (response && response.data) {
      dispatch({ type: ORDER_HISTORY, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateReviewOrder = ({
  order_uuid,
  venue_uuid,
  Authorization,
  Status,
}) => async (dispatch,getState) => {
  // set body
  const body = JSON.stringify({
    status: Status,
  });
  const config = {
    headers: {
      "Content-type": "application/json",

      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
    },
  };
  try {
    const response = await API.post(
      `/business/review-order/${order_uuid}/`,
      body,
      config
    );
    dispatch({
      type: "REVIEW_ORDER",

      payload: response.data,
    });
    dispatch({
      type: "TEMP_REVIEW_ORDER_LIST",

      payload: response.data,
    });
    return {status:200,data:response.data}
  } catch (error) {
    return {status:400,data:error.message}

    console.log("error here", error);
  }
};
export const loginUser = ({ email, password }) => async (dispatch,getState) => {
  // set body
  const body = JSON.stringify({
    email: email,
    password: password,
  });
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    localStorage.clear();
    const response = await API.post(`/auth-accounts/login/`, body, config);
    localStorage.setItem("AuthUserKey", response.data.key);

    dispatch({
      type: "LOGIN_USER",
      payload: response.data.key,
    });
    dispatch({
      type: "LOGIN_ERROR",
      payload: "",
    });
    return response?.status;
  } catch (error) {
    dispatch({
      type: "LOGIN_ERROR",
      payload: error,
    });
    return 400;
  }
};
export const logoutUser = () => async (dispatch,getState) => {
  // set body

  const config = {
    headers: {
      "Content-type": "application/json",

      // "venue-uuid":venue_uuid,
      ...Authorization,
    },
  };
  try {
    const response = await API.post(`/auth-accounts/logout/`, config);

    localStorage.clear();
  } catch (error) {}
};
export const getUserDetail = () => async (dispatch,getState) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(`/auth-accounts/details/`, config);
    localStorage.setItem("UserName", `${response?.data?.user?.first_name}`);
    dispatch({
      type: "USER_DETAIL",
      payload: response.data,
    });
    dispatch({
      type: GET_HOTEL_DETAILS,
      payload: response.data,
    });
  } catch (error) {
    console.log("error here", error);
  }
};

export const getVenueDetail = ({ venue_uuid, Authorization, slug }) => async (
  dispatch,getState
) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.get(`/business/venue/${slug}/details/`, config);

    dispatch({
      type: "VENUE_DETAIL",
      payload: response.data,
    });
  } catch (error) {}
};

export const SetUserEmpty = () => (dispatch,getState) => {
  dispatch({
    type: "LOGIN_ERROR",
    payload: null,
  });
  dispatch({
    type: "USER_DETAIL",
    payload: null,
  });
  dispatch({
    type: "VENUE_ORDERS",
    payload: null,
  });

  dispatch({
    type: "USER_DETAIL",
    payload: null,
  });
};
export const getSearchByPage = ({
  venue_uuid,
  Authorization,
  from_date,
  until_date,
  search,
  page,
}) => async (dispatch,getState) => {
let  language=getState()?.languageReducer?.language

  const config = {
    headers: {
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
      language:language
    },
  };
  // set body
  try {
    if (from_date === null) {
      const response = await API.get(
        `business/order-history/?from_date=&until_date=&search=${search}&page=${page}`,
        config
      );
      if (response && response.data) {
        dispatch({ type: ORDER_HISTORY, payload: response.data });
      }
    } else {
      let url = `business/order-history/?from_date=${from_date}&until_date=${until_date}&search=${search}&page=${page}`;

      const response = await API.get(url, config);
      if (response && response.data) {
        dispatch({ type: ORDER_HISTORY, payload: response.data });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
export const getSearch = ({
  venue_uuid,
  Authorization,
  from_date,
  until_date,
  search,
}) => async (dispatch,getState) => {
  let  language=getState()?.languageReducer?.language

  const config = {
    headers: {
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
      language:language
    },
  };
  // set body
  try {
    if (from_date === null) {
      const response = await API.get(
        `business/order-history/?from_date=&until_date=&search=${search}`,
        config
      );
      if (response && response.data) {
        dispatch({ type: ORDER_HISTORY, payload: response.data });
      }
    } else {
      let url = `business/order-history/?from_date=${from_date}&until_date=${until_date}&search=${search}`;

      const response = await API.get(url, config);
      if (response && response.data) {
        dispatch({ type: ORDER_HISTORY, payload: response.data });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
export const getNextSearch = ({ venue_uuid, Authorization, apiCall }) => async (
  dispatch,getState
) => {
  const config = {
    headers: {
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
    },
  };
  // set body
  try {
    const response = await axios.get(`${apiCall}`, config);
    if (response && response.data) {
      dispatch({ type: ORDER_HISTORY, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const setReviewOrderList = ({ order }) => async (dispatch,getState) => {
  dispatch({ type: REVIEW_ORDER_LIST, payload: order });
};

export const removeOrderList = (order) => async (dispatch,getState) => {
  dispatch({
    type: REMOVE_ITEM_REVIEW_LIST,
    payload: { order: order.order, autoSave: order.autoSave },
  });
};

export const createTab = ({
  table_number,
  email,
  phone_number,
  expiry_datetime,
  name,
  send_link,
  venue_uuid,
  verified,
}) => async (dispatch,getState) => {
  let  language=getState()?.languageReducer?.language

  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      language:language,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  let body = {};
  if (phone_number !== null && email !== null) {
    body = JSON.stringify({
      table_number: table_number,
      email: email,
      verified: verified,
      name: name,
      phone_number: phone_number,
      expiry_datetime: expiry_datetime,
      send_link: send_link,
    });
  } else if (phone_number == null && email !== null) {
    body = JSON.stringify({
      table_number: table_number,
      email: email,
      verified: verified,
      name: name,
      expiry_datetime: expiry_datetime,
      send_link: send_link,
    });
  } else if (phone_number !== null && email === null) {
    body = JSON.stringify({
      table_number: table_number,
      name: name,
      verified: verified,
      phone_number: phone_number,
      expiry_datetime: expiry_datetime,
      send_link: send_link,
    });
  } else if (phone_number == null && email === null) {
    body = JSON.stringify({
      table_number: table_number,
      name: name,
      verified: verified,
      expiry_datetime: expiry_datetime,
      send_link: send_link,
    });
  }

  try {
    const response = await API.post(`business/tabs/`, body, config);
    if (response && response.data) {
      return response.data;

      dispatch({ type: CREATE_TAB, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateTab = ({
  table_number,
  email,
  phone_number,
  expiry_datetime,
  name,
  send_link,
  created_tab_id,
  Authorization,
  password,
  venue_uuid,
  verified,
}) => async (dispatch,getState) => {
  let  language=getState()?.languageReducer?.language
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      language:language,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  // set body
  let body = {};
  body = JSON.stringify({
    table_number: `${table_number}`,
    email: email ? email : "",
    password: password,
    name: name,
    verified: verified,
    phone_number: phone_number,
    expiry_datetime: expiry_datetime,
    send_link: send_link,
    phone_number: phone_number ? phone_number : "",
  });

  try {
    
    const response = await API.patch(
      `business/tabs/${created_tab_id}/`,
      body,
      config
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const settleTab = ({
  created_tab_id,
  venue_uuid,
  Authorization,
}) => async (dispatch,getState) => {
  const config = {
    headers: {
      Authorization: "Token " + Authorization,

      "venue-uuid": venue_uuid,
    },
  };
  // set body

  try {
    const response = await API.post(
      `business/tabs/${created_tab_id}/settle_tab/`,
      JSON.stringify({}),
      config
    );
    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllTabs = ({ venue_uuid, Authorization, search }) => async (
  dispatch,getState
) => {
  let  language=getState()?.languageReducer?.language

  const config = {
    headers: {
      Authorization: "Token " + Authorization,
      language:language,
      "venue-uuid": venue_uuid,
    },
  };
  // set body

  try {
    const response = await API.get(
      `business/tabs/open_tabs/?search=${search ? search : ""}`,
      config
    );
    if (response && response.data) {
      dispatch({ type: GET_TAB_LIST, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTabTables = ({ venue_uuid, Authorization }) => async (
  dispatch,getState
) => {
  let  language=getState()?.languageReducer?.language

  const config = {
    headers: {
      Authorization: "Token " + Authorization,
      "venue-uuid": venue_uuid,
      language:language,

    },
  };
  // set body

  try {
    const response = await API.get(
      `business/venue/tables/available_tables/`,
      config
    );
    if (response && response.data) {
      dispatch({ type: TABLE_TAB, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNextSearchTab = ({
  venue_uuid,
  Authorization,
  apiCall,
}) => async (dispatch,getState) => {
  const config = {
    headers: {
      "venue-uuid": venue_uuid,
      Authorization: "Token " + Authorization,
    },
  };
  // set body
  try {
    const response = await axios.get(`${apiCall}`, config);
    if (response && response.data) {
      dispatch({ type: GET_TAB_LIST, payload: response.data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const verifyTab = ({ tab_id, venue_uuid }) => async (dispatch,getState) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,

      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  // set body
  let body = {};

  try {
    const response = await API.post(
      `business/review_tab/${tab_id}/verify_tab/`,
      body,
      config
    );

    if (response && response.data) {
      return response.status;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.status;
  }
};

export const declineTab = ({ tab_id, venue_uuid }) => async (dispatch,getState) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,

      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  // set body
  let body = {};

  try {
    const response = await API.post(
      `business/review_tab/${tab_id}/decline_tab/`,
      body,
      config
    );

    if (response && response.data) {
      return response.status;
    }
  } catch (error) {
    console.log(error);
    return error.response.status;
  }
};
export const removeVenueDetails = () => async (dispatch,getState) => {
  try {
    dispatch({ type: REMOVE_VENUE_DETAILS });
  } catch (error) {
    console.log(error);
  }
};

export const getPendingOrders = ({ venue_uuid }) => async (dispatch,getState) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  if (localStorage?.AuthUserKey) {
    try {
      const response = await API.get(`/api/business/pending-orders/`, config);
      dispatch({
        type: PENDING_ORDERS,
        payload: response.data,
      });
      let orders=0;
      response.data.forEach(element => {
        orders=orders+element?.pending_orders
      });
      return { status: 200, message: "",count:orders };
    } catch (error) {
      return { status: 400, message: error };
    }
  } else {
    return { status: 400 };
  }
};

export const updateVenueOrdersStatus = ({ venue_uuid, body, slug }) => async (
  dispatch,getState
) => {
  // set body
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };
  try {
    const response = await API.patch(
      `/business/venue/${slug}/update/`,
      body,
      config
    );
    dispatch({
      type: UPDATE_VENUE_ORDER_STATUS,
      payload: response.data,
    });
    return { status: 200, message: "" };
  } catch (error) {
    return { status: 400, message: error?.message };
  }
};
