import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Tree from "./Tree/Tree";
import { getMenuAvailability } from "../actions/menuAvailability";
import { ReactComponent as Icon2 } from "../assets/image/alignCenter.svg";
import { ReactComponent as Icon1 } from "../assets/image/align.svg";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    height: "60px",
    background: "#F66761",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  menuItem: {
    height: "60px",
    background: "#fff",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  alignButtonSelected: {
    width: "32px",
    height: "32px",
    background: "#F66761",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  alignButton: {
    width: "32px",
    height: "32px",
    background: "#F9FAFC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "350px",
  },
}));
function MenuAvailability(props) {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState("collapse");
  let dispatch = useDispatch();
  let languageReducer = useSelector((state) => state.languageReducer);
 
  useEffect(() => {
    ApiCall()
    // eslint-disable-next-line
  }, [languageReducer?.language]);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let reportReducerState = useSelector((state) => state?.reportReducer?.report);

  let OrderHistoryReducer = useSelector((state) => state.VenueOrderReducer);
  let menuDetails = useSelector((state) => state.menuAvailability.menuDetails);

  const classes = useStyles();
  const ApiCall = async () => {
    setLoading(true);
    let res = await dispatch(
      getMenuAvailability({ venue_uuid: venueDetail?.uuid })
    );
    if (res === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getDataInBackground = async () => {
    let res = await dispatch(
      getMenuAvailability({ venue_uuid: venueDetail?.uuid })
    );
  };
  useEffect(() => {
    if (!(venueDetail?.length > 0)) {
      ApiCall();
    }
    if (venueDetail?.length > 0) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [venueDetail]);
  const handleSelect = (index) => {
    setSelectedMenu(index);
  };
  const changeExpandCollapse = (key) => {
    setCollapse(key);
  };
  const addMenu = () => {
    props.handleChangePage();
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={2} style={{ backgroundColor: "#fff" }}>
          <Grid container>
            {menuDetails?.map((n, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    handleSelect(index);
                  }}
                >
                  <Typography
                    className={
                      index === selectedMenu
                        ? classes.menuItemSelected
                        : classes.menuItem
                    }
                  >
                    {n.name}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={10} style={{ backgroundColor: "#F9FAFC" }}>
          {loading ? (
            <Grid container>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ minHeight: "90vh" }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid
                item
                xs={11}
                style={{
                  minHeight: "60px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "24px",
                }}
              >
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    {/* <Typography className={classes.header}>
                      Menu availability
                    </Typography> */}
                  </Grid>
                  <Grid item>
                    {menuDetails === null || menuDetails === undefined ? (
                      <Grid container>
                        <TextField
                          variant="outlined"
                          placeholder=
                          {intl.formatMessage({
                            id: "Search_item_category_customization",
                          })}
                          
                          fullWidth
                          // onChange={(e) => handleSearch(e)}
                          classes={{}}
                          style={{
                            background: "#F9FAFC",
                            borderRadius: "5px",
                            border: "1px solid #E5E5E5",
                          }}
                          inputProps={{
                            className: classes.TextInputStyle,
                            "aria-label": "search",
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.outlinedStyle,
                            },
                            endAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {}}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item>
                          <div
                            className={
                              collapse === "expand"
                                ? classes.alignButtonSelected
                                : classes.alignButton
                            }
                            onClick={() => changeExpandCollapse("expand")}
                          >
                            <Icon1
                              width="16.6px"
                              height="21.4px"
                              fill={collapse === "expand" ? "#fff" : "#303A5F"}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          style={{ marginLeft: "10px" }}
                          onClick={() => changeExpandCollapse("collapse")}
                        >
                          <div
                            className={
                              collapse === "collapse"
                                ? classes.alignButtonSelected
                                : classes.alignButton
                            }
                          >
                            <Icon2
                              width="16.6px"
                              height="21.4px"
                              fill={
                                collapse === "collapse" ? "#fff" : "#303A5F"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <Tree
                  addMenu={addMenu}
                  menuDetails={menuDetails}
                  collapse={collapse}
                  selectedMenu={selectedMenu}
                  getLatestAvalibility={getDataInBackground}
                  changeExpandCollapse={changeExpandCollapse}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MenuAvailability;
