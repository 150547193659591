import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import QRcode from "../QRcode";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IncomingOrders from "../IncomingOrders";
import StripeAccount from "../StripeAccount";
import ManagerTabs from "../ManagerTabs";
import OrderHistory from "../OrderHistory";
import Reports from "../Reports";
import MenuAvailability from "../MenuAvailability";
import MenuManager from "../MenuManager";
import Localization from "../Localization";
import ViewOnlyMenu from "../ViewOnlyMenu";
import VenueSetting from "../../pages/VenueSetting/VenueSetting";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { permissionKeys } from "../../constant/config";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  AppBar: {
    borderBottom: "1px solid #EDF0F4",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 2px 0px rgba(0,0,0,0.12)",
  },
  tabs: {
    minWidth: "0px",
    marginRight: "30px",
    padding: "5px",
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",

    fontSize: "13px",
    lineHeight: "18px",
    textTransform: "none",
    color: "#A3A9BC",
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: theme.colors.secondary,
    },
  },

  indicator: {
    backgroundColor: theme.colors.secondary,
    // display:"none"
  },
  selected: {
    color: theme.colors.secondary,
  },
  select: {
    color: "#A3A9BC",
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: theme.colors.secondary,
      minWidth: "0px",
      marginRight: "30px",
      padding: "5px",
      fontFamily: theme.fontFamily,
      fontWeight: theme.fontSemiBold,
      fontStyle: "normal",

      fontSize: "13px",
      lineHeight: "18px",
      textTransform: "none",
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TabBar(props) {
  const intl = useIntl();
  let query = useQuery();
  let history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [permissions, setPermissions] = useState([]);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);
  useEffect(() => {
    try {
      if (UserDetail?.user_venue_permissions[0]?.permission_screen_keys) {
        setPermissions(
          UserDetail?.user_venue_permissions[0]?.permission_screen_keys
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    try {
      if (UserDetail?.user_venue_permissions[0]?.permission_screen_keys) {
        setPermissions(
          UserDetail?.user_venue_permissions[0]?.permission_screen_keys
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [UserDetail]);
  console.log(UserDetail?.user_venue_permissions[0]?.permission_screen_keys);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        // // history.push({
        //   pathname: `/venue/${venue.slug}`,
        //   state: { detail: venue },
        // });

        history.push({
          pathname: `${window.location.pathname}?tab=incoming-orders`,
          state: { detail: location?.state?.detail },
        });
        break;
      case 1:
        history.push({
          pathname: `${window.location.pathname}?tab=order-history`,
          state: { detail: location?.state?.detail },
        });
        break;
      case 2:
        history.push({
          pathname: `${window.location.pathname}?tab=manage-tabs`,
          state: { detail: location?.state?.detail },
        });

        break;
      case 3:
        history.push({
          pathname: `${window.location.pathname}?tab=menu-availability`,
          state: { detail: location?.state?.detail },
        });

        break;
      case 4:
        history.push({
          pathname: `${window.location.pathname}?tab=menu-manager`,
          state: { detail: location?.state?.detail },
        });

        break;
      case 5:
        history.push({
          pathname: `${window.location.pathname}?tab=localization`,
          state: { detail: location?.state?.detail },
        });
        break;
      case 6:
        history.push({
          pathname: `${window.location.pathname}?tab=reports`,
          state: { detail: location?.state?.detail },
        });

        break;
      case 7:
        history.push({
          pathname: `${window.location.pathname}?tab=venue-settings`,
          state: { detail: location?.state?.detail },
        });

        break;
      default:
        history.push({
          pathname: `${window.location.pathname}?tab=incoming-orders`,
          state: { detail: location?.state?.detail },
        });

        break;
    }
    setValue(newValue);
    props.setTabType(newValue);
  };
  useEffect(() => {
    // props.setTabType(value);
    console.log("aaaaaaa",value)
    switch (value) {
      case 0:
        setValue(0);
        props.setTabType(0);
        if (verifyPermission(permissionKeys.INCOMING_ORDERS)) {
          break;
        }

      
      case 1:
        setValue(1);
        props.setTabType(1);
        if (verifyPermission(permissionKeys.ORDER_HISTORY)) {
          break;
        }
        case 2:
        setValue(2);
        props.setTabType(2);
        if (verifyPermission(permissionKeys.MANAGE_TABS)) {
          break;
        }
      case 3:
        setValue(3);
        props.setTabType(3);
        if (verifyPermission(permissionKeys.MENU_AVAILABILITY)) {
          break;
        }

      case 4:
        setValue(4);
        props.setTabType(4);
        if (verifyPermission(permissionKeys.MENU_MANAGER)) {
          break;
        }
  
      case 5:
        setValue(5);
        props.setTabType(5);
        if (verifyPermission(permissionKeys.LOCALISATION)) {
          break;
        }
      case 6:
        setValue(6);
        props.setTabType(6);
        if (verifyPermission(permissionKeys.REPORTS)) {
          break;
        }
      case 7:
        setValue(7);
        props.setTabType(7);
        break;
      default:
        setValue(0);
        props.setTabType(0);
        break;
    }


    // eslint-disable-next-line
  }, [permissions]);
  useEffect(() => {
    props.setTabType(value);
   
    // eslint-disable-next-line
  }, [props]);
  useEffect(() => {
    switch (query.get("tab")) {
      case "order-history":
        setValue(1);
        props.setTabType(1);
        break;

      default:
        break;
    }
    // props.setTabType(value);
  }, []);
  const handleSwitchToMenuManager = () => {
    setValue(4);
    props.setTabType(4);
  };
  useEffect(() => {
    if (value === 0) {
      if (venueDetail?.read_only_menu) {
        setValue(4);
        props.setTabType(4);
      } else {
        switch (query.get("tab")) {
          case "order-history":
            setValue(1);
            props.setTabType(1);
            break;
          case "manage-tabs":
            setValue(2);
            props.setTabType(2);
            break;
          case "menu-availability":
            setValue(3);
            props.setTabType(3);
            break;
          case "menu-manager":
            setValue(4);
            props.setTabType(4);
            break;
          case "localization":
            setValue(5);
            props.setTabType(5);
            break;
          case "reports":
            setValue(6);
            props.setTabType(6);
            break;
          case "venue-settings":
            setValue(7);
            props.setTabType(7);
            break;
          default:
            setValue(0);
            props.setTabType(0);
            break;
        }
      }
    }
    // eslint-disable-next-line
  }, [venueDetail]);
  const verifyPermission = (key) => {
    let index = permissions.findIndex((i) => i === key);
    return index > -1 ? true : false;
  };
  return (
    <div className={classes.root}>
      <AppBar
        color="white"
        position="static"
        className={classes.AppBar}
        id="tabBar"
      >
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid>
              {!props.fullScreenView && (
                <Tabs
                  classes={{
                    indicator: classes.indicator,
                    selected: classes.selected,
                  }}
                  className={classes.select}
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="off"
                  aria-label="scrollable prevent tabs example"
                >
                  {verifyPermission(permissionKeys.INCOMING_ORDERS) && (
                    <Tab
                      value={0}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Your_Incoming_Order",
                      })}
                      {...a11yProps(0)}
                    />
                  )}
                  {verifyPermission(permissionKeys.ORDER_HISTORY) && (
                    <Tab
                      value={1}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Order_history",
                      })}
                      {...a11yProps(1)}
                    />
                  )}
                  {verifyPermission(permissionKeys.MANAGE_TABS) && (
                    <Tab
                      value={2}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Manage_Tabs",
                      })}
                      {...a11yProps(2)}
                    />
                  )}
                  {verifyPermission(permissionKeys.MENU_AVAILABILITY) && (
                    <Tab
                      value={3}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Menu_availability",
                      })}
                      {...a11yProps(3)}
                    />
                  )}
                  {verifyPermission(permissionKeys.MENU_MANAGER) && (
                    <Tab
                      value={4}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Menu_manager",
                      })}
                      {...a11yProps(4)}
                    />
                  )}
                  {verifyPermission(permissionKeys.LOCALISATION) && (
                    <Tab
                      value={5}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Localization",
                      })}
                      {...a11yProps(5)}
                    />
                  )}

                  {verifyPermission(permissionKeys.REPORTS) && (
                    <Tab
                      value={6}
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Reports",
                      })}
                      {...a11yProps(6)}
                    />
                  )}
                  {verifyPermission(permissionKeys.VENUE_DETAILS) && (
                  <Tab
                    value={7}
                    className={classes.tabs}
                    label={intl.formatMessage({
                      id: "Venue_settings",
                    })}
                    {...a11yProps(7)}
                  />
                   )} 
                </Tabs>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </AppBar>
      {verifyPermission(permissionKeys.INCOMING_ORDERS) && (
        <TabPanel value={value} index={0} style={{ padding: "0px" }}>
          {venueDetail?.read_only_menu ? (
            <ViewOnlyMenu />
          ) : (
            <IncomingOrders
              fullScreenView={props.fullScreenView}
              setfullScreenView={props.setfullScreenView}
            />
          )}
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.ORDER_HISTORY) && (
        <TabPanel value={value} index={1}>
          {venueDetail?.read_only_menu ? <ViewOnlyMenu /> : <OrderHistory />}
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.MANAGE_TABS) && (
        <TabPanel value={value} index={2}>
          {venueDetail?.read_only_menu ? <ViewOnlyMenu /> : <ManagerTabs />}
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.MENU_AVAILABILITY) && (
        <TabPanel value={value} index={3}>
          <MenuAvailability handleChangePage={handleSwitchToMenuManager} />
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.MENU_MANAGER) && (
        <TabPanel value={value} index={4}>
          <MenuManager />
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.LOCALISATION) && (
        <TabPanel value={value} index={5}>
          <Localization />
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.REPORTS) && (
        <TabPanel value={value} index={6}>
          {venueDetail?.read_only_menu ? <ViewOnlyMenu /> : <Reports />}
        </TabPanel>
      )}
      {verifyPermission(permissionKeys.VENUE_DETAILS) && (
      <TabPanel value={value} index={7}>
        <VenueSetting />
      </TabPanel>
      )}
    </div>
  );
}
