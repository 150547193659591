import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { addVenueTable, updateVenueTable } from "../../actions/VenueTables";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  textHeader: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.colors.primary,
    marginBottom: "10px",
    marginTop: "15px",
  },
  outlinedStyleBottom: {
    border: "1px solid #EDF0F4",
    borderRadius: "5px",
    height: "40px",
  },
  TextInputStyle: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
  },
  OneTextInputStyle: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
  root: {
    padding: "0px",
  },
  pendingOrder: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
    background: "#FF3366",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
    "&:hover": {
      background: "#FF3366",
    },
  },
  RejectOrder: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "18px",
    background: "#34CEB2",
    color: "#ffffff",
    "&:hover": {
      background: "##34CEB2",
    },
  },
  Description: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  cancelBotton: {
    cursor: "pointer",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    padding: "0px 0px 0px 20px",
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: theme.colors.primary,
  },
}));

function EditTableDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState("");
  let dispatch = useDispatch();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const { open, handleCloseDialog, selectedItem, confirmDelete } = props;
  useEffect(() => {
    setValue(props?.item?.item?.number ? props?.item?.item?.number : "");
  }, [props]);
  const handleChangeTableNumber = (e) => {
    setValue(e.target.value);
    setError(false);
  };
  const handleClose = () => {
    handleCloseDialog(false);
  };
  const handleAdd = () => {
    if (value.trim() === "") {
      setError(true);
    } else {
      if (props?.item?.item?.id) {
        handleUpdateTable();
      } else {
        handleAddTable();
      }
    }
  };
  const handleUpdateTable = async () => {
    if (venueDetail?.uuid) {
      let res = await dispatch(
        updateVenueTable({
          venue_uuid: venueDetail?.uuid,
          data: {
            number: value,
          },
          id: props?.item?.item?.id,
        })
      );
      if (res?.status === 200) {
        setValue("");
        handleClose();
      }
    }
  };
  const handleAddTable = async () => {
    if (venueDetail?.uuid) {
      let res = await dispatch(
        addVenueTable({
          venue_uuid: venueDetail?.uuid,
          table_name: value,
          position: 1,
        })
      );
      if (res?.status === 200) {
        setValue("");
      } else {
        setErrorMessage(intl.formatMessage({
          id: "Something_went_wrong",
        }));
      }
    }
  };
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 9000);
    }
  }, [errorMessage]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" ) {
    
      handleAdd();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          borderRadius: "5px !important",
        }}
      >
        <Grid item xs={12} style={{ padding: "20px", width: "600px" }}>
          <Grid container justify="space-between">
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography className={classes.header}>
                {props?.item?.item ? 
                
                intl.formatMessage({
                  id: "Edit_table_number",
                }) : intl.formatMessage({
                  id: "Add_table_number",
                })}
              </Typography>
              <Typography>{errorMessage}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ClearIcon
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.textHeader}>
              {intl.formatMessage({
                  id: "Name",
                })}
              </Typography>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Enter_name",
              })}
              fullWidth
              onChange={(e) => handleChangeTableNumber(e)}
              value={value}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              onKeyDown={handleKeyDown}
              inputProps={{
                className: classes.TextInputStyle,
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyleBottom,
                },
              }}
              error={error}
              helperText={error && intl.formatMessage({
                id: "Field_is_required",
              })}
            />
          </Grid>

          <Grid
            container
            justify="space-between"
            style={{ paddingTop: "47px" }}
          >
            <Grid item>
              {props?.item?.item && (
                <Button
                  variant="contained"
                  className={classes.printButton}
                  onClick={() => {
                    confirmDelete();
                  }}
                >
                  {intl.formatMessage({
                id: "Delete",
              })}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.RejectOrder}
                onClick={() => handleAdd()}
              >
                 {intl.formatMessage({
                id: "Save",
              })}
                
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default EditTableDialog;
