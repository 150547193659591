import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { TextButtonAdd, TextButton } from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  deleteCategory,
  updateCategory,
} from "../../actions/menu";
import { DEFAULT_STATUS } from "../../constant/types";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useIntl } from "react-intl";

export default function CategoryForm(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  let menuStatus = useSelector((state) => state?.menuReducer?.status);

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };
  useEffect(() => {
    setName(props?.item?.item?.name);
    // eslint-disable-next-line
  }, [props]);
  useEffect(() => {
    if (menuStatus?.added || menuStatus?.updated || menuStatus?.deleted) {
      dispatch({ type: DEFAULT_STATUS, payload: "" });
      handleClose();
    }
    // eslint-disable-next-line
  }, [menuStatus]);
  const handleAdd = async () => {
    setLoading(true);
    setUpdatingType("add");
    let res = await dispatch(
      addCategory({ name: name, menuId: props?.menuId })
    );
    if (res.status === 200) {
      props && props.updateItem();
      props && props.handleSelectMenu(res.id, "category");
    }
    setLoading(false);
    setUpdatingType("");
  };
  const handleUpdate = async () => {
    setLoading(true);
    setUpdatingType("update");

    let res = await dispatch(
      updateCategory({
        id: props?.item?.item?.id,
        name: name,
        menuId: props?.menuId,
      })
    );
    if (res === 200) {
      props && props.updateItem();
    }
    setLoading(false);
    setUpdatingType("");
  };
  const handleDelete = async () => {
    setLoading(true);
    setUpdatingType("delete");

    let res = await dispatch(
      deleteCategory({ id: props?.item?.item?.id, menuId: props?.menuId })
    );
    if (res === 200) {
      props && props.updateItem();
    }
    setLoading(false);
    setUpdatingType("");
  };
  const handleOpenDeleteModal = () => {
    setOpenDelete(true);
  };

  return (
    <div>
      {openDelete && (
        <ConfirmDeleteDialog
          open={openDelete}
          handleClose={setOpenDelete}
          confirmDelete={handleDelete}
          type="category"
          name={props?.item?.item?.name}
        />
      )}
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Typography className={classes.headersTest}>
                {props?.item?.item?.id ? 
                intl.formatMessage({
                  id: "Edit_category",
                })
                 :intl.formatMessage({
                  id: "Add_new_category",
                }) }
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography className={classes.headingTest}>
              {intl.formatMessage({
                  id: "Name",
                })}
              </Typography>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Category_name",
              })}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              inputProps={{
                className: classes.TextInputStyle2,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px  30px 8px " }}>
          {props?.item?.item?.id ? (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <TextButton disabled={loading} onClick={handleOpenDeleteModal}>
                  {loading && updatingType === "delete" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Delete",
                    })
                    
                  )}
                </TextButton>
              </Grid>

              <Grid item>
                <TextButtonAdd disabled={loading} onClick={handleUpdate}>
                  {loading && updatingType === "update" ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                    })
                    
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <TextButtonAdd disabled={loading} onClick={handleAdd}>
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  intl.formatMessage({
                    id: "Save",
                  })
                )}
              </TextButtonAdd>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headersTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
}));

// background: #FFFFFF;
// /* Input field */

// border: 1px solid #EDF0F4;
// box-sizing: border-box;
// border-radius: 5px;
