/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import PlusIcon from "../../assets/image/add.svg";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { TextButtonNoColor } from "../Buttons/Buttons";
import { addTags, removeTagsFromItem } from "../../actions/menu";
import { useIntl } from "react-intl";

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")`
  width: 300px;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;

  &:hover {
  }

  // &.focused {
  //   // border-color: #40a9ff;
  //   // box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  //   border: 0px solid #fff;
  // }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ intl, label, obj, onDelete, ...props }) => (
  <div
    {...props}
    style={{
      backgroundColor: obj?.colour?.bg_hex,
      color: obj?.colour?.text_hex,
      borderRadius: "2px",
    }}
  >
    {obj?.image?.image && (
      <img
        src={obj?.image?.image}
        style={{
          marginRight: "5px",

          height:
            obj?.name ===
            intl.formatMessage({
              id: "Create_new_tag",
            })
              ? "15px"
              : "15px",
          width:
            obj?.name ===
            intl.formatMessage({
              id: "Create_new_tag",
            })
              ? "15px"
              : "15px",
        }}
      />
    )}
    <span>{label}</span>
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 6px 0 6px;
  outline: 0;
  overflow: hidden;

  // &:focus {
  //   border-color: #40a9ff;
  //   background-color: #e6f7ff;
  // }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 5px;
    display: flex;
    & span {
      flex-grow: 1;
    }
    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;
    & svg {
      color: #fff;
    }
  }

  & li[data-focus="true"] {
    cursor: pointer;
    & svg {
      color: #fff;
    }
  }
`;

export default function CustomizedHook(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [values, setValues] = useState([]);
  const [opens, setOpens] = useState(false);
  const [tagsList, setTagsList] = useState([
    {
      name: intl.formatMessage({
        id: "Create_new_tag",
      }),
      image: { image: PlusIcon },
      colour: {
        bg_hex: "#fff",
        text_hex: "#303A5F",
      },
    },
  ]);

  useEffect(() => {
    setTagsList([
      ...props?.tags,
      {
        name: intl.formatMessage({
          id: "Create_new_tag",
        }),
        image: { image: PlusIcon },
        colour: {
          bg_hex: "#fff",
          text_hex: "#303A5F",
        },
      },
    ]);
  }, [props, props?.tags]);
  useEffect(() => {
    setValues([...props?.oldTags]);
    // eslint-disable-next-line
  }, [props, props?.oldTags]);
  useEffect(() => {
    if (props?.newAddedTag) {
      let n = [{ ...props?.newAddedTag }, ...values];
      props.setTagsToItem(n);
      setValues([...n]);
      props && props.handleClearNewTag();
    }
    // eslint-disable-next-line
  }, [props?.newAddedTag]);

  const handleClick = (e, value, reason) => {};
  const handleChange = async (e, value, reason) => {
    setOpens(false);

    if (reason === "select-option") {
      if (
        intl.formatMessage({
          id: "Create_new_tag",
        }) === value[value.length - 1].name
      ) {
        props.handleOpenTagsModal();
      } else {
        let newValues = [...values];
        let newVal = value[value.length - 1];
        console.log("newValues", newValues);
        console.log("newVal", newVal);
        let ind = newValues.findIndex(
          (newElement) => newElement.tag_id === newVal.id
        );
        if (!props?.item?.itemId) {
          ind = newValues.findIndex(
            (newElement) => newElement.id === newVal.id
          );
        }
        if (ind > -1) {
          newValues.splice(ind, 1);
          if (props?.item?.itemId) {
            let res = await dispatch(
              removeTagsFromItem({
                ...props?.item,
                data: value[ind]?.tag_id,
              })
            );
          }
          setValues(newValues);
          props.setTagsToItem(newValues);
        } else {
          if (props?.item?.itemId) {
            let res = await dispatch(
              addTags({
                ...props?.item,
                data: value[value.length - 1]?.id,
              })
            );
            console.log("newValues", newValues);
            console.log("ind res------------", res);
            newValues.push({ ...value[value.length - 1] });
            if (res?.status === 200) {
              setValues(res?.data);
              props.setTagsToItem(res?.data);
            }
          } else {
            newValues.push({ ...value[value.length - 1] });
            setValues(newValues);
            props.setTagsToItem(newValues);
          }
          // setValues(newValues);
          // props.setTagsToItem(newValues);
        }
      }
    } else {
      let newValues = [...values];
      let remIndex = -1;
      newValues.forEach((element, index) => {
        let i = value.findIndex(
          (newElement) => newElement.name === element.name
        );
        if (i < 0) {
          remIndex = index;
        }
      });
      if (remIndex > -1) {
        newValues.splice(remIndex, 1);
        setValues(newValues);
        props.setTagsToItem(newValues);
      }
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: tagsList,
    getOptionLabel: (option) => option?.name,
    onChange: handleChange,
    onClick: handleClick,
    defaultValue: props?.oldTags,
    value: values,
    open: opens,
    // open: true,
  });

  const handleChangeEdit = (e, tag) => {
    e.stopPropagation();
    props.handleEditTagsModal(tag);
  };
  const findTags = (t) => {
    console.log({ values });

    console.log({ t });
    if (props?.item?.itemId) {
      let ind1 = values.findIndex((n) => n.tag_id === t.id);
      if (ind1 > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      let ind1 = values.findIndex((n) => n.id === t.id);
      if (ind1 > -1) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <div>
      <div {...getRootProps()}>
        <Label
          {...getInputLabelProps()}
          style={{
            fontFamily: theme.fontFamily,
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "18px",
            color: "#303A5F",
            marginBottom: "10px",
          }}
        >
          {intl.formatMessage({
            id: "Mark_item_as",
          })}
        </Label>
        <Grid
          container
          style={{
            background: "#FFFFFF" /* Input field */,
            border: "1px solid #EDF0F4",
            boxSizing: "border-box",
            borderRadius: "5px",
            minHeight: "40px",
            caretColor: "transparent",
            cursor: "pointer",
          }}
        >
          <Grid
            item
            xs={10}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputWrapper
              ref={setAnchorEl}
              style={{
                minWidth: "100%",
                caretColor: "transparent",
                cursor: "pointer",
              }}
              onClick={() => setOpens(!opens)}
            >
              {values.map((option, index) => (
                <Tag
                  label={option?.name}
                  obj={option}
                  intl={intl}
                  {...getTagProps({ index })}
                />
              ))}

              <input {...getInputProps()} />
            </InputWrapper>
          </Grid>

          <Grid
            item
            xs={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <TextButtonNoColor onClick={() => setOpens(!opens)}>
              {intl.formatMessage({
                id: "Add",
              })}
            </TextButtonNoColor>
          </Grid>
        </Grid>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <Grid container style={{ margin: "0px", padding: "0px" }}>
                <Grid
                  item
                  xs={11}
                  style={{
                    backgroundColor: option?.colour?.bg_hex,
                    color: option?.colour?.text_hex,
                    borderRadius: "2px",
                    margin: "0px",
                    padding: "0px",
                    height: "37px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container justify="space-between">
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                            marginLeft: "7px",
                          }}
                        >
                          {option?.image?.image && (
                            <img
                              src={option?.image?.image}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                height:
                                  option?.name ===
                                  intl.formatMessage({
                                    id: "Create_new_tag",
                                  })
                                    ? "15px"
                                    : "15px",
                                width:
                                  option?.name ===
                                  intl.formatMessage({
                                    id: "Create_new_tag",
                                  })
                                    ? "15px"
                                    : "15px",
                                marginTop:
                                  option?.name ===
                                  intl.formatMessage({
                                    id: "Create_new_tag",
                                  })
                                    ? "5px"
                                    : "0px",
                                marginRight:
                                  option?.name ===
                                  intl.formatMessage({
                                    id: "Create_new_tag",
                                  })
                                    ? "1px"
                                    : "1px",
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item>
                          <span className={"spandiv"}>{option?.name}</span>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      {option?.name !==
                        intl.formatMessage({
                          id: "Create_new_tag",
                        }) && (
                        <CheckIcon
                          fontSize="small"
                          style={{
                            marginRight: "10px",
                            color: findTags(option) ? "#fff" : "",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {option?.name !==
                    intl.formatMessage({
                      id: "Create_new_tag",
                    }) && (
                    <EditIcon
                      onClick={(e) => handleChangeEdit(e, option)}
                      style={{ color: "#262F56", fontSize: "17px" }}
                    />
                  )}
                </Grid>
              </Grid>
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
}
