import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import {StyledCheckbox} from "./CustomCheckbox"
export default function DeleteUser(props) {

  const {Server,
    Kitchen,
    Manager,
    Admin,
    Cashier,
    border}=props.data;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.heading} style={border?{borderBottom:"1px solid #EDF0F4"}:{}}>
        <Grid item xs={4}>
          <div style={{display:"flex",alignItems:"center",height:"100%"}}>
        <Typography className={classes.columnHeading}>
               {props.title}
              </Typography>
              </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container justify="space-between">
            <Grid item>
             <StyledCheckbox checked={Admin}/>
            </Grid>
            <Grid item>
            <StyledCheckbox checked={Manager}/>

            </Grid>
            <Grid item>
            <StyledCheckbox checked={Cashier}/>

            </Grid>
            <Grid item>
            <StyledCheckbox checked={Kitchen}/>

            </Grid>
            <Grid item>
            <StyledCheckbox checked={Server}/>

            </Grid>
           
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
    heading:{
        marginTop:"0px"

    },
  columnHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
 
}));
