import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Pagination from "@material-ui/lab/Pagination";
import PreviousOrderDialog from "../Components/PreviousOrderDialog";
import { useSelector, useDispatch } from "react-redux";
import { getSearch, getNextSearch, getSearchByPage } from "../actions/order";
import { useEffect, useState, useRef } from "react";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import ArrowForwardIosIcon from "@material-ui/icons/ChevronRight";
import ArrowBackIosIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Fab,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import calendar from "../assets/image/calendar.svg";
import Pagination from "@material-ui/lab/Pagination";
import { dayStartTime } from "../constant/config";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,

    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
  },
  body: {
    color: theme.colors.primary,
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
  },
}))(TableCell);
let date = moment().startOf("day").add("hours", dayStartTime);
let date2 = moment().format("YYYY-MM-DD");
let todayStart = date.toString();

let todayEnd = moment(date2.toString() + " " + "05:59")
  .add("days", 1)
  .toString();

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F6F6F8",
      marginLeft: "10px",
    },

    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
}))(TableRow);

function OrderHistory() {
  const intl = useIntl();

  const fixedDates = [
    {
      name: intl.formatMessage({
        id: "Custom",
        defaultMessage: "Custom",
      }),
      from_date: null,
      until_date: null,
      value: "Group0",
    },
    {
      name: intl.formatMessage({
        id: "Today",
        defaultMessage: "Today",
      }),
      from_date: moment(todayStart).format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group1",
    },
    {
      name: intl.formatMessage({
        id: "Yesterday",
        defaultMessage: "Yesterday",
      }),
      from_date: moment(todayStart).add("day", -1).format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd).add("day", -1).format("YYYY-MM-DD HH:mm"),

      value: "Group2",
    },
    {
      name: intl.formatMessage({
        id: "This_Week",
        defaultMessage: "This Week",
      }),
      from_date: moment(todayStart)
        .startOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group3",
    },
    {
      name: intl.formatMessage({
        id: "Last_Week",
        defaultMessage: "Last Week",
      }),
      from_date: moment(todayStart)
        .add("day", -7)
        .startOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd)
        .add("day", -7)
        .endOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      value: "Group4",
    },
    {
      name: intl.formatMessage({
        id: "This_Month",
        defaultMessage: "This Month",
      }),
      from_date: moment(todayStart)
        .startOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group5",
    },

    {
      name: intl.formatMessage({
        id: "Last_Month",
        defaultMessage: "Last Month",
      }),
      from_date: moment(todayStart)
        .add("month", -1)
        .startOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd)
        .add("month", -1)
        .endOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      value: "Group6",
    },
  ];
  let dispatch = useDispatch();
  const theme = useTheme();
  const [Datevalue, setDatevalue] = useState([null, null]);
  const [fromDate, setfromDate] = useState("");
  const [untilDate, setuntilDate] = useState("");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  let OrderHistoryReducer = useSelector((state) => state.VenueOrderReducer);
  const [OrderList, setOrderList] = useState([]);
  const [TotalAccepted, setTotalAccepted] = useState(0);
  const [TotalRejected, setTotalRejected] = useState(0);
  const [SearchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const inputEl = useRef(null);
  const [OrderSelected, setOrderSelected] = useState(null);
  const [customGroup, setcustomGroup] = React.useState("Group1");
  const [open, setOpen] = useState(false);
  const [startDate1, setStartDate1] = useState(moment().startOf("day"));
  const [startDate2, setStartDate2] = useState(moment().startOf("day"));
  const [isSetDate, setIsSetDate] = useState(false);
  const [paging, setPaging] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayDots, setDisplayDots] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackDrop(true);
  };
  useEffect(() => {
    if (
      currentPage === 1 &&
      OrderHistoryReducer?.orderHistory?.count !== null &&
      OrderHistoryReducer?.orderHistory?.results?.length > 0
    ) {
      let totalPages =
        OrderHistoryReducer?.orderHistory?.count /
        OrderHistoryReducer?.orderHistory?.results?.length;
      Math.ceil(totalPages);
      let arr = [];
      if (Math.ceil(totalPages) > 4) {
        setDisplayDots(true);
      }
      for (let i = 1; i <= Math.ceil(totalPages); i++) {
        arr[i - 1] = i;
      }
      setPaging(arr);
    }
    // eslint-disable-next-line
  }, [OrderHistoryReducer]);

  const handleChange = (event) => {
    setcustomGroup(event.target.value);
    let dates1 = fixedDates?.filter((n) => n.value === event.target.value);

    if (event.target.value !== "Group0") {
      let dates = dates1[0];
      let end1 = dates?.until_date;
      let start1 = dates?.from_date;
      setStartDate1(moment(start1));
      setStartDate2(moment(end1));
      handleOpenBackDrop();
      dispatch(
        getSearch({
          venue_uuid: venueDetail && venueDetail.uuid,
          Authorization: localStorage.AuthUserKey,
          from_date: start1,
          until_date: end1,
          search: SearchValue,
        })
      ).then(() => handleCloseBackDrop());
      setIsSetDate(true);
    } else {
      setStartDate1(moment().startOf("day"));
      setStartDate2(moment().startOf("day"));
      setIsSetDate(false);
    }
  };

  useEffect(() => {
    if (venueDetail?.uuid) {
      fixedDates.map((date) => {
        if (date.value === customGroup) {
          setfromDate(date.from_date);
          setStartDate1(moment(date.from_date));
          setStartDate2(moment(date.until_date));
          setuntilDate(date.until_date);
          setDatevalue([date.from_date, date.until_date]);
          setIsSetDate(true);
          handleOpenBackDrop();

          dispatch(
            getSearch({
              venue_uuid: venueDetail && venueDetail.uuid,
              Authorization: localStorage.AuthUserKey,
              from_date: date.from_date,
              until_date: date.until_date,
              search: SearchValue,
            })
          ).then(() => handleCloseBackDrop());
        }
      });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (venueDetail?.uuid) {
      fixedDates.map((date) => {
        if (date.value === customGroup) {
          setfromDate(date.from_date);
          setStartDate1(moment(date.from_date));
          setStartDate2(moment(date.until_date));
          setuntilDate(date.until_date);
          setDatevalue([date.from_date, date.until_date]);
          setIsSetDate(true);
          handleOpenBackDrop();

          dispatch(
            getSearch({
              venue_uuid: venueDetail && venueDetail.uuid,
              Authorization: localStorage.AuthUserKey,
              from_date: date.from_date,
              until_date: date.until_date,
              search: SearchValue,
            })
          ).then(() => handleCloseBackDrop());
        }
      });
    }
    // eslint-disable-next-line
  }, [venueDetail?.uuid]);
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };
  const Search = () => {
    handleOpenBackDrop();
    dispatch(
      getSearch({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date: fromDate,
        until_date: untilDate,
        search: SearchValue,
      })
    ).then(() => handleCloseBackDrop());
  };

  useEffect(() => {
    setOrderList(OrderHistoryReducer.orderHistory.results);
    // eslint-disable-next-line
  }, [OrderHistoryReducer.orderHistory]);
  useEffect(() => {
    let acceptedTotal = 0;
    let rejectedTotal = 0;

    OrderHistoryReducer &&
      OrderHistoryReducer.orderHistory &&
      OrderHistoryReducer.orderHistory.results &&
      OrderHistoryReducer.orderHistory.results.map((order) => {
        if (order.status === 3) {
          acceptedTotal =
            parseFloat(order.order_price) + parseFloat(acceptedTotal);
          // ${parseFloat(OrderSelected.order_price) + parseFloat(OrderSelected.tip_amount)}
        } else {
          rejectedTotal =
            parseFloat(order.order_price) + parseFloat(rejectedTotal);
        }
      });
    acceptedTotal = Math.round(acceptedTotal * 100) / 100;
    rejectedTotal = Math.round(rejectedTotal * 100) / 100;

    setTotalAccepted(acceptedTotal);
    setTotalRejected(rejectedTotal);
    // eslint-disable-next-line
  }, [OrderHistoryReducer.orderHistory.results]);

  const handlePressKey = (event) => {
    if (event.keyCode === 13) {
      handleSearch(event);
      Search();
    }
  };

  let local = {
    format: "DD-MM-YYYY HH:mm",
    sundayFirst: false,
    cancel: intl.formatMessage({
      id: "Close",
      defaultMessage: "Close",
    }),
  };

  const applyCallback = (startDates1, endDate1) => {
    setStartDate1(startDates1);
    setStartDate2(endDate1);
    setcustomGroup("Group0");
    setDatevalue(startDates1);

    setfromDate(startDates1 ? startDates1.format("YYYY-MM-DD") : null);
    setuntilDate(endDate1 ? endDate1.format("YYYY-MM-DD") : null);

    setIsSetDate(true);
    handleOpenBackDrop();
    dispatch(
      getSearch({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date: startDates1
          ? startDates1.format("YYYY-MM-DD HH:mm:SS")
          : null,
        until_date: endDate1 ? endDate1.format("YYYY-MM-DD HH:mm:SS") : null,
        search: SearchValue,
      })
    ).then(() => handleCloseBackDrop());
  };
  const formatTime = () => {
    try {
      let sDate = new Date(startDate1);
      let endDate = new Date(startDate2);

      return `${sDate?.getDate()}/${
        sDate?.getMonth() + 1
      }/${sDate?.getFullYear()} [${
        sDate?.getHours() < 10 ? "0" + sDate?.getHours() : sDate?.getHours()
      }:${
        sDate?.getMinutes() < 10
          ? "0" + sDate?.getMinutes()
          : sDate?.getMinutes()
      }] - ${endDate?.getDate()}/${
        endDate?.getMonth() + 1
      }/${endDate?.getFullYear()} [${
        endDate?.getHours() < 10
          ? "0" + endDate?.getHours()
          : endDate?.getHours()
      }:${
        endDate?.getMinutes() < 10
          ? "0" + endDate?.getMinutes()
          : endDate?.getMinutes()
      }]`;
    } catch {
      let sDate = startDate1;
      let endDate = startDate2;

      return `${sDate?.getDate()}/${
        sDate?.getMonth() + 1
      }/${sDate?.getFullYear()} [${
        sDate?.getHours() < 10 ? "0" + sDate?.getHours() : sDate?.getHours()
      }:${
        sDate?.getMinutes() < 10
          ? "0" + sDate?.getMinutes()
          : sDate?.getMinutes()
      }] - ${endDate?.getDate()}/${
        endDate?.getMonth() + 1
      }/${endDate?.getFullYear()} [${
        endDate?.getHours() < 10
          ? "0" + endDate?.getHours()
          : endDate?.getHours()
      }:${
        endDate?.getMinutes() < 10
          ? "0" + endDate?.getMinutes()
          : endDate?.getMinutes()
      }]`;
    }
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setIsSetDate(false);

    setStartDate1(moment().startOf("day"));
    setStartDate2(moment());
    setcustomGroup("Group0");
    handleOpenBackDrop();
    dispatch(
      getSearch({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date: null,
        until_date: null,
        search: SearchValue,
      })
    ).then(() => handleCloseBackDrop());
  };
  const handleClearSelection = (e) => {
    e.stopPropagation();
    setIsSetDate(false);

    setStartDate1(moment().startOf("day"));
    setStartDate2(moment());
    setcustomGroup("Group0");
    handleOpenBackDrop();
    dispatch(
      getSearch({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date: null,
        until_date: null,
        search: SearchValue,
      })
    ).then(() => handleCloseBackDrop());
  };
  const handleClickPageNumber = (number) => {
    setCurrentPage(number);
    handleOpenBackDrop();
    dispatch(
      getSearchByPage({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date:
          startDate1 && isSetDate
            ? startDate1.format("YYYY-MM-DD HH:mm:SS")
            : null,
        until_date:
          startDate2 && isSetDate
            ? startDate2.format("YYYY-MM-DD HH:mm:SS")
            : null,

        search: SearchValue,
        page: number,
      })
    ).then(() => handleCloseBackDrop());
  };
  const handleApiCallNextPrevios = (api, method) => {
    if (method === "next" && currentPage < paging[paging.length - 1]) {
      let c = currentPage + 1;
      setCurrentPage(c);
    } else if (currentPage > 1 && method === "previous") {
      let c = currentPage - 1;
      setCurrentPage(c);
    }
    handleOpenBackDrop();
    dispatch(
      getNextSearch({
        venue_uuid: venueDetail && venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        apiCall: api,
      })
    ).then(() => handleCloseBackDrop());
  };

  const handlePaging = (e, page) => {
    handleClickPageNumber(page);
  };
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={openBackDrop}
        onClick={handleCloseBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {venueDetail && venueDetail.uuid !== null ? (
        <Grid container className={classes.root}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container justify="space-between">
              <Grid item xs={9} className={classes.GenralText}>
                {intl.formatMessage({
                  id: "Order_History",
                  defaultMessage: "Order History",
                })}
              </Grid>
            </Grid>
            <Grid container spacing={2} justify={"space-between"}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className={classes.cssApply}
              >
                <div style={{ width: "100%" }}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Custom_Dates",
                      defaultMessage: "Custom Dates",
                    })}{" "}
                  </p>
                  <DateTimeRangeContainer
                    ranges={[]}
                    start={startDate1}
                    end={startDate2}
                    local={local}
                    applyCallback={applyCallback}
                  >
                    <>
                      {isSetDate ? (
                        <Grid
                          container
                          className={classes.customTimePicker}
                          style={{
                            alignItems: "center",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                            }}
                          >
                            <img src={calendar} alt="calender" />
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                              maxWidth: "70%",
                            }}
                          >
                            <Typography
                              className={classes.displayDate}
                              style={{ paddingLeft: "0px" }}
                            >
                              {isSetDate && new Date(startDate1)
                                ? formatTime()
                                : intl.formatMessage({
                                    id: "Select_date_and_time",
                                    defaultMessage: "Select date and time",
                                  })}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            direction="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // alignSelf: "flex-end",
                            }}
                          >
                            <CloseIcon
                              onClick={(e) => handleClose(e)}
                              style={{
                                cursor: "pointer",
                                color: theme.colors.primary,
                                width: "40px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          className={classes.customTimePicker}
                          style={{
                            justifyContent: " space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={classes.displayDate}>
                              {intl.formatMessage({
                                id: "Select_date_and_time",
                                defaultMessage: "Select date and time",
                              })}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                            }}
                          >
                            <ArrowDropDownIcon
                              style={{
                                color: theme.colors.primary,
                                paddingRight: "10px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </DateTimeRangeContainer>
                </div>{" "}
              </Grid>

              {/* <Grid item xs={4}>
                <p className={classes.textLabel}>CUSTOM DATES </p>

                <div
                  style={{
                    background: "#fff",
                    border: "1px solid #EDF0F4",
                    boxSizing: "border-box",
                    borderRadius: "5px",
                  }}
                >
                  <DateRangePicker
                    startText=""
                    endText=""
                    inputFormat="DD/MM/YYYY"
                    value={Datevalue}
                    onChange={(newValue) => {
                      setcustomGroup("Group0");
                      setDatevalue(newValue);

                      setfromDate(
                        newValue[0] ? newValue[0].format("YYYY-MM-DD") : null
                      );
                      setuntilDate(
                        newValue[1] ? newValue[1].format("YYYY-MM-DD") : null
                      );
                      dispatch(
                        getSearch({
                          venue_uuid: venueDetail && venueDetail.uuid,
                          Authorization: localStorage.AuthUserKey,
                          from_date: newValue[0]
                            ? newValue[0].format("YYYY-MM-DD")
                            : null,
                          until_date: newValue[1]
                            ? newValue[1].format("YYYY-MM-DD")
                            : null,
                          search: SearchValue,
                        })
                      );
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField
                          placeholder="test"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.outlinedStyle,
                              input: classes.rangeInputPadding,
                            },
                          }}
                          {...startProps}
                          // value="12/12/12"

                          helperText=""
                        />
                        <DateRangeDelimiter> - </DateRangeDelimiter>

                        <TextField
                          InputProps={{
                            classes: {
                              notchedOutline: classes.outlinedStyle,

                              input: classes.rangeInputPadding,
                            },
                          }}
                          {...endProps}
                          helperText=""
                        />
                      </React.Fragment>
                    )}
                  />
                </div>
              </Grid> */}

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <p className={classes.textLabel}>
                  {intl.formatMessage({
                    id: "FIXED_DATES",
                    defaultMessage: "FIXED DATES",
                  })}
                </p>

                <TextField
                  variant="outlined"
                  fullWidth
                  value={customGroup}
                  onChange={handleChange}
                  select
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    border: "1px solid rgb(237, 240, 244)",
                  }}
                  inputProps={{
                    className: classes.OneTextInputStyle,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        {customGroup !== "Group0" && (
                          <CloseIcon
                            style={{
                              color: theme.colors.primary,
                              width: "40px",
                              cursor: "pointer",
                            }}
                            onClick={handleClearSelection}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  className={
                    customGroup !== "Group0"
                      ? classes.hideIcon
                      : classes.showIcon
                  }
                >
                  {fixedDates.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <p
                  className={classes.textLabel}
                  style={{ textTransform: "uppercase" }}
                >
                  {intl.formatMessage({
                    id: "Search",
                    defaultMessage: "Search",
                  })}
                </p>

                <TextField
                  variant="outlined"
                  //  placeholder="Month"
                  //  className={classes.fieldtext}
                  placeholder={intl.formatMessage({
                    id: "Search",
                    defaultMessage: "Search",
                  })}
                  fullWidth
                  classes={{}}
                  style={{
                    background: "#F9FAFC",
                    borderRadius: "5px",
                    border: "1px solid #E5E5E5",
                  }}
                  ref={inputEl}
                  onKeyUp={(e) => handlePressKey(e)}
                  onChange={handleSearch}
                  inputProps={{
                    className: classes.TextInputStyle,
                    "aria-label": "search",
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            Search();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div className={classes.TableGrid}>
              <TableContainer component={Paper} style={{ borderRadius: "5px" }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {intl.formatMessage({
                          id: "Date",
                          defaultMessage: "Date",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {intl.formatMessage({
                          id: "Order_ID",
                          defaultMessage: "Order ID",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {intl.formatMessage({
                          id: "Table_Number",
                          defaultMessage: "Table Number",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {intl.formatMessage({
                          id: "Payment_Status",
                          defaultMessage: "Payment Status",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {intl.formatMessage({
                          id: "Sub_Total",
                          defaultMessage: "Sub Total",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {intl.formatMessage({
                          id: "Tip",
                          defaultMessage: "Tip",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {intl.formatMessage({
                          id: "Total_Payment",
                          defaultMessage: "Total Payment",
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {intl.formatMessage({
                          id: "Status",
                          defaultMessage: "Status",
                        })}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {OrderList &&
                      OrderList.map((row) => (
                        <StyledTableRow
                          key={row.value}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderSelected(row);
                            setOpen(true);
                          }}
                        >
                          <StyledTableCell align="left">
                            <span style={{ fontWeight: "bold" }}>
                              {row.submitted_for_review
                                ? moment(row.submitted_for_review).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : " No date Available"}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.reference_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row?.tab_name
                              ? `${row.table_number} - ${row?.tab_name}`
                              : `${row.table_number}`}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.payment_method === "card"
                              ? intl.formatMessage({
                                  id: "Paid",
                                  defaultMessage: "Paid",
                                })
                              : intl.formatMessage({
                                  id: "Not_paid",
                                  defaultMessage: "Not Paid",
                                })}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* {row.order_price} */}
                            {parseFloat(
                              parseFloat(row.order_price) -
                                parseFloat(row.tip_amount)
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: venueDetail && venueDetail.currency,
                            })}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {/* {row.order_price} */}
                            {parseFloat(row.tip_amount).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: venueDetail && venueDetail.currency,
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {parseFloat(
                              parseFloat(row.order_price)
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: venueDetail && venueDetail.currency,
                            })}
                          </StyledTableCell>
                          {row.status === 3 ? (
                            <StyledTableCell align="right">
                              {intl.formatMessage({
                                id: "Accepted",
                                defaultMessage: "Accepted ",
                              })}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="right">
                              <span
                                style={{
                                  color: "#FF3366",
                                  paddingRight: "6px",
                                }}
                              >
                                {intl.formatMessage({
                                  id: "Declined",
                                  defaultMessage: "Declined ",
                                })}
                              </span>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {OrderList && !OrderList.length && (
              <Grid xs={12} style={{ textAlign: "center" }}>
                <Typography className={classes.NoOrder}>
                  {intl.formatMessage({
                    id: "No_Order_in_period",
                    defaultMessage: "No Order in this period ",
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
          {OrderList &&
            OrderList.length > 0 &&
            OrderHistoryReducer.orderHistory &&
            (OrderHistoryReducer.orderHistory.previous ||
              OrderHistoryReducer.orderHistory.next) && (
              <Grid container justify="center" style={{ padding: "20px 0px" }}>
                {paging?.length > 1 && (
                  <Pagination
                    count={paging.length}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePaging}
                    page={currentPage}
                    renderItem={(items) => console.log("items:", items)}
                    renderItem={(items) => (
                      <>
                        {items?.type === "next" ? (
                          <Grid
                            item
                            style={{
                              paddingLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ArrowForwardIosIcon
                              disabled={
                                OrderHistoryReducer.orderHistory &&
                                !OrderHistoryReducer.orderHistory.next
                              }
                              onClick={() => {
                                handleApiCallNextPrevios(
                                  OrderHistoryReducer?.orderHistory?.next,
                                  "next"
                                );
                              }}
                              style={{ color: theme.colors.primary }}
                            />
                          </Grid>
                        ) : items?.type === "previous" ? (
                          <Grid
                            item
                            style={{
                              paddingLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ArrowBackIosIcon
                              onClick={() => {
                                handleApiCallNextPrevios(
                                  OrderHistoryReducer?.orderHistory?.previous,
                                  "previous"
                                );
                              }}
                              disabled={
                                OrderHistoryReducer.orderHistory &&
                                !OrderHistoryReducer.orderHistory.previous
                              }
                              style={{ color: theme.colors.primary }}
                            />
                          </Grid>
                        ) : items?.type === "page" ? (
                          <Grid
                            item
                            key={items?.page}
                            onClick={() => handleClickPageNumber(items?.page)}
                          >
                            <div
                              className={
                                items?.page === currentPage
                                  ? classes.selectedPage
                                  : classes.unSelectedPage
                              }
                            >
                              {items?.page}
                            </div>
                          </Grid>
                        ) : (
                          <Grid item key={items?.page}>
                            <div
                              className={classes.unSelectedPage}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                              }}
                            >
                              {"..."}
                            </div>
                          </Grid>
                        )}
                      </>
                    )}
                  />
                )}
              </Grid>
            )}
        </Grid>
      ) : (
        <Grid container justify="center">
          <CircularProgress size={40} color="primary" />
        </Grid>
      )}
      <Grid item xs={1}></Grid>

      <PreviousOrderDialog
        open={open}
        setOpen={setOpen}
        from="history"
        OrderSelected={OrderSelected}
        currency={venueDetail && venueDetail.currency}
        venue_uuid={venueDetail?.uuid}
      />
    </div>
  );
}

export default OrderHistory;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  hideIcon: {
    "& .MuiSelect-icon": {
      paddingRight: "40px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiSelect-iconOpen": {
      paddingRight: "40px",
    },
  },
  showIcon: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  selectedPage: {
    background: "#F66761",
    height: "30px",
    width: "30px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    cursor: "pointer",
    margin: "0px 5px",
  },
  unSelectedPage: {
    background: "#EDF0F4",
    height: "30px",
    width: "30px",
    borderRadius: "5px",
    display: "flex",
    color: theme.colors.primary,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    margin: "0px 5px",
  },
  root: {
    minHeight: "88vh",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },

  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: theme.colors.primary,
    margin: "0px",
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
  },
  TableGrid: {
    overflowY: "auto",
    margin: "5px 0px 0px 0px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    color: theme.colors.primary,
    margin: "15px 0px 15px 0px",
  },
  outlinedStyle: {
    border: "0px",
  },
  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
  cssApply: {
    "& .daterangepicker": {
      "& .rangecontainer": {
        display: "none",
      },
      "& .inputDate": {
        border: "1px solid",
        width: "70%",
        marginLeft: "15%",
      },
      "& .activeNotifier": {
        display: "none",
      },
      "& .fromDateTimeContainer": {
        fontSize: "13px",
        width: "270px",
        margin: "4px",
        paddingBottom: "40px",
      },
      "& .applyButton": {
        borderColor: "#F66761",
        color: "#fff",
        fontSize: "12px",
        borderRadius: "3px",
        padding: "5px 10px",
        cursor: "pointer",
        marginRight: "4px",
        backgroundColor: "#F66761 !important",
        border: "1px solid #F66761",
      },
    },
  },

  customTimePicker: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  displayDate: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    paddingLeft: "15px",
    color: theme.colors.primary,
  },
}));
