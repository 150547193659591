import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Pagination from "@material-ui/lab/Pagination";
import NewTabDialog from "../Components/NewTabDialog";
import { useSelector, useDispatch } from "react-redux";
import { getSearch, getNextSearchTab } from "../actions/order";
import { useEffect, useState, useRef } from "react";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getAllTabs } from "../actions/order";
import verifyPIc from "../assets/image/verifyPIc.png";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Fab,
  Button,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import TabsHeader from "./Tabs/TabsHeader";
import { useTheme } from "@material-ui/core/styles";
import copy from "copy-to-clipboard";
import TabSettleDialog from "./DialogBox/TabSettleDialog";
import { useIntl } from "react-intl";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
  },
  body: {
    color: theme.colors.primary,
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
  },
  root: {
    border: "0px solid #fff",
  },
}))(TableCell);

const today = moment(new Date());

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F6F6F8",
      marginLeft: "10px",
    },
    border: "0px solid #fff",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    borderRadius: "50px !important",
    color: theme.colors.primary,
    maxWidth: "200px",
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  copyButton: {
    padding: "5px 10px",
    width: "55px",
    height: "28px",
    background: "#EDF0F4",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid  #A3A9BC",
  },
  copyButtonText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineeight: "18px",
    color: "#A3A9BC",
  },
  root: {
    minHeight: "88vh",

    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: theme.colors.primary,
    margin: "0px",
  },
  printButton: {
    marginTop: "18px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 26px",
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
    // height: "60vh",
  },
  TableGrid: {
    overflowY: "auto",
    // height: "60vh",
    margin: "5px 0px 0px 0px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: theme.colors.primary,
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
  deleteButton: {
    textTransform: "none",
    background: "#FF3366",
    color: "#FFFFFF",
    padding: "5px 26px",
    "&:hover": {
      background: "#FF3366",
    },
  },
}));
function ManagerTabs() {
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  const theme = useTheme();
  const intl = useIntl();


  let dispatch = useDispatch();

  const [copiedSessionId, setCoppiedSessionId] = useState("");

  var todayDate = moment(new Date());
  let OrderHistoryReducer = useSelector((state) => state.VenueOrderReducer);
  const [OrderList, setOrderList] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const inputEl = useRef(null);
  const [OrderSelected, setOrderSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    dispatch(
      getAllTabs({
        venue_uuid: venueDetail?.uuid,
        Authorization: localStorage.AuthUserKey,
        search: null,
      })
    );
    // eslint-disable-next-line
  }, [venueDetail]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
    } else {
      dispatch(
        getAllTabs({
          venue_uuid: venueDetail.uuid,
          Authorization: localStorage.AuthUserKey,
          search: null,
        })
      );
    }
  };
  const Search = () => {
    dispatch(
      getAllTabs({
        venue_uuid: venueDetail.uuid,
        Authorization: localStorage.AuthUserKey,
        search: SearchValue,
      })
    );
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && SearchValue !== null) {
      Search();
    }
  };
  useEffect(() => {
    setOrderList(OrderHistoryReducer.allTabs?.results);
    // eslint-disable-next-line
  }, [OrderHistoryReducer?.allTabs]);
  const hideDetails = () => {
    setOrderSelected(null);
    setShowDetails(false);
  };
  const handleCopy = (e, customTabLink, id) => {
    e.stopPropagation();
    copy(customTabLink);
    setCoppiedSessionId(id);
  };
  useEffect(() => {
    if (copiedSessionId !== "") {
      setTimeout(() => {
        setCoppiedSessionId("");
      }, 2000);
    }
  }, [copiedSessionId]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [item, setItem] = useState(null);
  const openClickSettleTab = (e, item) => {
    e.stopPropagation();
    setDialogOpen(true);
    setItem(item);
  };
  return (
    <>
      <TabSettleDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        OrderSelected={item}
        setAlertOpen={setAlertOpen}
        setOrderSelected={setItem}
        setOpen={setDialogOpen}
      />
      {showDetails ? (
        <TabsHeader
          open={openDetails}
          setOpen={hideDetails}
          OrderSelected={OrderSelected}
          setOrderSelected={setOrderSelected}
        />
      ) : (
        <div onKeyDown={handleKeyDown}>
          {venueDetail && venueDetail.uuid !== null ? (
            <Grid container className={classes.root}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  justify="space-between"
                  style={{ paddingTop: "10px", marginBottom: "5px" }}
                >
                  <Grid item xs={9} className={classes.GenralText}>
                  { intl.formatMessage({
                                id: "Manage_Tabs",
                              })}
                    
                  </Grid>
                </Grid>
                <Grid container justify={"space-between"}>
                  <Grid item xs={4}>
                    <p className={classes.textLabel} style={{textTransform:"uppercase"}}>
                    { intl.formatMessage({
                                id: "Search",
                              })}
                    </p>

                    <TextField
                      variant="outlined"
                      placeholder={ intl.formatMessage({
                        id: "Search",
                      })}
                      fullWidth
                      onChange={(e) => handleSearch(e)}
                      classes={{}}
                      style={{
                        background: "#F9FAFC",
                        borderRadius: "5px",
                        border: "1px solid #E5E5E5",
                      }}
                      ref={inputEl}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                Search();
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={8} style={{ textAlign: "end" }}>
                    <Button
                      variant="contained"
                      //   color="primary"
                      className={classes.printButton}
                      // eslint-disable-next-line no-restricted-globals
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      { intl.formatMessage({
                                id: "Create_Tab",
                              })}
                      
                    </Button>
                  </Grid>
                </Grid>
                <div className={classes.TableGrid}>
                  <TableContainer
                    component={Paper}
                    style={{ borderRadius: "5px" }}
                  >
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="start">
                          { intl.formatMessage({
                                id: "Table_number",
                              })}
                            
                          </StyledTableCell>
                          <StyledTableCell align="start">
                          { intl.formatMessage({
                                id: "Verified",
                              })}
                          </StyledTableCell>
                          <StyledTableCell align="start">
                          { intl.formatMessage({
                                id: "Tab_name",
                              })}
                          </StyledTableCell>
                          {/* <StyledTableCell align="start">Password</StyledTableCell> */}

                          {/* <StyledTableCell align="right">
                        Mobile # / Email
                      </StyledTableCell> */}
                          <StyledTableCell align="start">
                          { intl.formatMessage({
                                id: "Password",
                              })}</StyledTableCell>
                          <StyledTableCell align="start">
                          { intl.formatMessage({
                                id: "Expires_at",
                              })}
                            
                          </StyledTableCell>
                          <StyledTableCell align="right"></StyledTableCell>
                          {/* <StyledTableCell align="center">
                        Private link
                      </StyledTableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {OrderList &&
                          OrderList.sort((a,b)=>a.table_number<b.table_number?-1 :1).map((row) => (
                            <StyledTableRow
                              key={row.value}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOrderSelected(row);
                                setShowDetails(true);
                              }}
                            >
                              <StyledTableCell align="start">
                                <span style={{ fontWeight: "bold" }}>
                                  {row.table_number}
                                </span>
                              </StyledTableCell>
                              <StyledTableCell align="start">
                                {row.verified === true ? (
                                  <img
                                    src={verifyPIc}
                                    style={{ width: "20px", height: "20px" }}
                                    alt={ intl.formatMessage({
                                      id: "no_img",
                                    })}
                                  ></img>
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="start">
                                {row.name}
                              </StyledTableCell>
                              {/* <StyledTableCell align="start">
                            {row.password ? row.password : ""}
                          </StyledTableCell> */}
                              {/* <StyledTableCell align="right">
                            {row.phone_number && row.email
                              ? `${row.phone_number}/${row.email}`
                              : row.email
                              ? row.email
                              : row.phone_number}
                          </StyledTableCell> */}
                              <StyledTableCell align="start">
                              {row.password}
                              </StyledTableCell>
                              <StyledTableCell align="start">
                                <span
                                  style={{
                                    color:
                                      moment(row.expiry_datetime).diff(
                                        new Date()
                                      ) > 0 &&
                                      moment(row.expiry_datetime).diff(
                                        new Date()
                                      ) /
                                        60000 <=
                                        30
                                        ? "#FF3366"
                                        : theme.colors.primary,
                                  }}
                                >
                                  {row.expiry_datetime
                                    ? moment(row.expiry_datetime).format(
                                        "dddd - DD/MM/YY - HH:mm"
                                      )
                                    : intl.formatMessage({
                                      id: "No_date_Available",
                                    })}
                                </span>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Button
                                  variant="contained"
                                  className={classes.deleteButton}
                                  onClick={(e) => {
                                    openClickSettleTab(e, row);
                                  }}
                                >{intl.formatMessage({
                                  id: "Settle_tab",
                                })}
                                  
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                {OrderList && !OrderList.length && (
                  <Grid xs={12} style={{ textAlign: "center" }}>
                    <Typography className={classes.NoOrder}>
                      
                      {intl.formatMessage({
                                  id: "No_Tab_in_this_period",
                                })}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {OrderList &&
                OrderList.length > 0 &&
                OrderHistoryReducer.allTabs &&
                (OrderHistoryReducer.allTabs.previous ||
                  OrderHistoryReducer.allTabs.next) && (
                  <Grid
                    container
                    justify="center"
                    style={{ padding: "20px 0px" }}
                  >
                    <Grid item>
                      <Fab
                        size="small"
                        style={{
                          background:
                            OrderHistoryReducer.allTabs &&
                            !OrderHistoryReducer.allTabs.previous
                              ? "#cccccc"
                              : "#F4F4F4",
                          boxShadow: "none",
                          paddingLeft: "7px",
                        }}
                        onClick={() => {
                          dispatch(
                            getNextSearchTab({
                              venue_uuid: venueDetail && venueDetail.uuid,
                              Authorization: localStorage.AuthUserKey,
                              apiCall:
                                OrderHistoryReducer.allTabs &&
                                OrderHistoryReducer.allTabs.previous,
                            })
                          );
                        }}
                        disabled={
                          OrderHistoryReducer.allTabs &&
                          !OrderHistoryReducer.allTabs.previous
                        }
                      >
                        <ArrowBackIosIcon
                          variant="outline"
                          style={{ color: theme.colors.primary }}
                        />
                      </Fab>
                    </Grid>
                    <Grid item style={{ paddingLeft: "10px" }}>
                      <Fab
                        size="small"
                        style={{
                          background:
                            OrderHistoryReducer.allTabs &&
                            !OrderHistoryReducer.allTabs.next
                              ? "#cccccc"
                              : "#F4F4F4",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          dispatch(
                            getNextSearchTab({
                              venue_uuid: venueDetail && venueDetail.uuid,
                              Authorization: localStorage.AuthUserKey,
                              apiCall:
                                OrderHistoryReducer.allTabs &&
                                OrderHistoryReducer.allTabs.next,
                            })
                          );
                        }}
                        disabled={
                          OrderHistoryReducer.allTabs &&
                          !OrderHistoryReducer.allTabs.next
                        }
                      >
                        <ArrowForwardIosIcon
                          variant="outline"
                          style={{ color: theme.colors.primary }}
                        />
                      </Fab>{" "}
                    </Grid>
                  </Grid>
                )}
            </Grid>
          ) : (
            <Grid container justify="center">
              <CircularProgress size={40} color="primary" />
            </Grid>
          )}
          <Grid item xs={1}></Grid>
          {open && (
            <NewTabDialog
              open={open}
              setOpen={setOpen}
              OrderSelected={OrderSelected}
              setOrderSelected={setOrderSelected}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ManagerTabs;
