import { GET_HOTEL_DETAILS, UPDATE_HOTEL_DETAILS,
  ADD_VENUE

} from "../constant/types";
import API from "../utils/API";
let dd={"venues": [
  {
      "id": 1,
      "name": "Busaba",
      "uuid": "1039cc70-54d2-4b7c-b2ea-cf27dc395acb",
      "slug": "blend-test-bar-1",
      "type": 1,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/blend-test-bar-1/33513e04-a161-4dd8-929b-e45a2d47dafe_ufijf2m_mz1qq7o_pfgjlgo_qsjtvgp_2t_AELRveQ.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=oEhPnu2w7xao%2BffKp3pIFvcI8%2FE%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/blend-test-bar-1/33513e04-a161-4dd8-929b-e45a2d47dafe_ufijf2m_mz1qq7o_pfgjlgo_qsjtvgp_2t_Xc6LlUR.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=Vu5JBPsyOw7AKX5JI5QsTkxdMAM%3D&Expires=1621862083",
      "description": "Cocktail Bar Busabaa",
      "domain": "busaba.staging.blend.menu",
      "read_only_menu": false,
      "timezone": "Asia/Famagusta"
  },
  {
      "id": 10,
      "name": "Room service",
      "uuid": "875f5d64-e099-4b63-8496-c56047dfe535",
      "slug": "room-service",
      "type": 3,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/room-service/Vegetarian.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=0O3DJMlvbsl2AxFMsd1GNfcP3I8%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/room-service/Popular.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=pKKKYGGjBpYloRmRqSUeERuc0QU%3D&Expires=1621862083",
      "description": "Room service venue",
      "domain": "roomservice.staging.blend.menu",
      "read_only_menu": false,
      "timezone": "UTC"
  },
  {
      "id": 4,
      "name": "asd 22",
      "uuid": "f4830f8a-aa66-401d-9f5f-be7c8cec7601",
      "slug": "asd",
      "type": 0,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/asd/hippie_fish_logo_-_brown-min_71pbprs_yompof3_sg3c772_9lqo3ad_npsfxti_hZQoD12.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=s6Cbo8%2Buwa50WG3embZr%2BzYMPt0%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/asd/hippie_fish_logo_-_brown-min_rrjadwl_o3cjtph_5efawnm_fhb2nz9_ry0ftmb_k0a2yvi_TneBLVm.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=e68HGbzPvxaN%2Bk8Aw3kUEB3sHAs%3D&Expires=1621862083",
      "description": "asdasdasd q",
      "domain": "asd",
      "read_only_menu": true,
      "timezone": "UTC"
  },
  {
      "id": 7,
      "name": "Coffee",
      "uuid": "36cdc65a-41f4-46ca-b973-99fb1855e368",
      "slug": "coffee",
      "type": 3,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/coffee/coffee-512.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=RXdZARPFjfp1ya34qhuWtg14sas%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/coffee/coffee-512_eFQIrdy.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=ph4lP%2Fbn6vGSxNtJAUL4cqtEBBU%3D&Expires=1621862083",
      "description": "Coffee coffee coffee",
      "domain": "coffee.staging.blend.menu",
      "read_only_menu": true,
      "timezone": "UTC"
  },
  {
      "id": 6,
      "name": "standard2",
      "uuid": "184f0ff7-6113-4e6f-9bca-b6ce6b6075cc",
      "slug": "standard2",
      "type": 0,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/standard2/keo-330ml_raveeol_yvltpa8_c5ksozc_ktfxne7_h53qhyl_3pr7si7_9x2f0cy_frbxesi_ncd6x3h.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=DpAaW8NrJKTGm9JHe7OYphatJ1s%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/standard2/carlsberg_major_global_rebrand_2_1ch7z0u_bbqzxt7_vlmdolf_idssngt_e0tyunu_xoxta_n7abfph.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=wrdFt1eh7VKaSfegnZV6sfEAReg%3D&Expires=1621862083",
      "description": "asd",
      "domain": "standard2.staging.blend.menu",
      "read_only_menu": false,
      "timezone": "UTC"
  },
  {
      "id": 5,
      "name": "Standard Venue",
      "uuid": "a4afde34-719c-4959-9822-4155c7af1ddd",
      "slug": "standard-venue",
      "type": 1,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/standard-venue/keo-330ml_xqggwjk_vpfr8x2_wit9bhl_P2HA3IO.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=I2dKdsHiI3hlonnH3CH7HitFMEo%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/standard-venue/chips_100gr_nufka54_scmbitl_uf1u3kd_akBYIQD.jpg?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=YF4SXuIqeezMcOBehKHbV%2BwBqTo%3D&Expires=1621862083",
      "description": "Test test test",
      "domain": "standard.staging.blend.menu",
      "read_only_menu": false,
      "timezone": "UTC"
  },
  {
      "id": 3,
      "name": "Theo's Spicy club",
      "uuid": "12d918ce-fb60-4863-8f39-da8eba698ac2",
      "slug": "theos-spicy-club",
      "type": 1,
      "logo": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/theos-spicy-club/Rref_chilli-256.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=rr46rrF0b8eozhRJEOMAmX4%2FYqg%3D&Expires=1621862083",
      "cover_image": "https://blend-menu-files-staging.s3.amazonaws.com/media/private/theos-spicy-club/circle-cropped.png?AWSAccessKeyId=AKIAYB3LQXP3XDCVYTCC&Signature=CvmsccUuTtTN32OmkxTxrCCkDak%3D&Expires=1621862083",
      "description": "Spicy",
      "domain": "spicy.staging.blend.menu",
      "read_only_menu": false,
      "timezone": "UTC"
  },
  {
      "id": 13,
      "name": "Restaurant Name testing",
      "uuid": "bebe6f1e-eaca-4fc5-8477-0875e4c1be51",
      "slug": "restaurant-name-testing",
      "type": 1,
      "logo": null,
      "cover_image": null,
      "description": "",
      "domain": "restaurant.blend.menu",
      "read_only_menu": false,
      "timezone": "Africa/Abidjan"
  }
]}
export const addVenue = ({ business_account_uuid, body }) => async (
  dispatch,getState
) => {
  // set body
  const config = {
    headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      
    },
  };
  try {
    const response = await API.post(
      `/business/venue/create/`,
      body,
      config
    );
   dispatch({
      type: ADD_VENUE,
      payload: response?.data?.venues,
    });
    return { status: 200, message: "" };
  } catch (error) {
    return { status: 400, message: error?.message };
  }
};
export const updateHotelDetails = ({
  business_account_uuid,
  id,
  data,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "business-account-uuid": business_account_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    const response = await API.patch(
      `/api/business/business-account/${id}/`,
      data,
      config
    );
    let data2 = response?.data;
    dispatch({ type: UPDATE_HOTEL_DETAILS, payload: data2 });

    return { status: 200 };
  } catch (error) {
    return { status: 400, message: "Something went wrong" };
  }
};
