import {
  GET_TRANSLATED_VENUE_DETAILS,
  UPDATE_TRANSLATED_MENU,
  UPDATE_TRANSLATED_CATEGORY,
  UPDATE_TRANSLATED_ITEM,
  UPDATE_TRANSLATED_TAG,
  UPDATE_TRANSLATED_PRICES,
  UPDATE_TRANSLATED_CUSTOMIZATION,
} from "../constant/types";

const initialState = {
  venueDetails: null,
  tagsMeta: {},
  status: {
    added: false,
    updated: false,
    deleted: false,
  },
  menuCustomization: null,
  allTags: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_TRANSLATED_ITEM: {
      const { id, menuId, categoryId, item } = action.payload;
      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            let translationList = [...itemsList[indItem].translations];
            console.log({ translationList });
            console.log({ payload });
            let trnsIndex = translationList.findIndex(
              (itemTrans) =>
                itemTrans?.language === payload?.data?.language &&
                itemTrans?.field === payload?.data?.field
            );
            if (trnsIndex > -1) {
              translationList[trnsIndex] = payload?.data;
            } else {
              translationList.push(payload?.data);
            }
            console.log({ trnsIndex });

            let trnsIndex1 = translationList.findIndex(
              (itemTrans) =>
                itemTrans?.language === payload?.item?.language &&
                itemTrans?.field === payload?.item?.field
            );
            console.log({ trnsIndex1 });

            if (trnsIndex1 > -1) {
              translationList[trnsIndex1] = payload?.item;
            } else {
              translationList.push(payload?.item);
            }

            itemsList[indItem].translations = [...translationList];

            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: [...menu],
            };
          }
        }
      }
    }

    case GET_TRANSLATED_VENUE_DETAILS: {
      return {
        ...state,
        venueDetails: payload,
      };
    }

    case UPDATE_TRANSLATED_MENU: {
      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === action.payload?.id);
      if (ind > -1) {
        let translationList = [...menu[ind].translations];
        let trnsIndex = translationList.findIndex(
          (item) => item?.language === payload?.data?.language
        );
        if (trnsIndex > -1) {
          translationList[trnsIndex] = payload?.data;
        } else {
          translationList.push(payload?.data);
        }
        menu[ind].translations = [...translationList];
      }
      return {
        ...state,
        venueDetails: [...menu],
      };
    }

    case UPDATE_TRANSLATED_CATEGORY: {
      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === action.payload?.menuId);
      let newMenuItem = [];
      if (ind > -1) {
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.id === action.payload?.id
        );
        if (indCategory > -1) {
          let translationList = [...newMenuItem[indCategory].translations];
          let trnsIndex = translationList.findIndex(
            (item) => item?.language === payload?.data?.language
          );
          if (trnsIndex > -1) {
            translationList[trnsIndex] = payload?.data;
          } else {
            translationList.push(payload?.data);
          }
          newMenuItem[indCategory].translations = [...translationList];
          menu[ind].menu_categories = [...newMenuItem];
          return {
            ...state,
            venueDetails: [...menu],
          };
        }
      }
    }

    case UPDATE_TRANSLATED_TAG: {
      const { id, menuId, categoryId, data } = action.payload;
      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].tags = [...data];
            newMenuItem[indCategory].items = [...itemsList];
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];
            return {
              ...state,
              venueDetails: [...menu],
            };
          }
        }
      }
    }

    case UPDATE_TRANSLATED_CUSTOMIZATION: {
      const { id, menuId, categoryId, itemId, data } = action.payload;
      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === itemId);

          if (indItem > -1) {
            itemsList[indItem].item_customisations = [...data];
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];
            return {
              ...state,
              venueDetails: [...menu],
            };
          }
        }
      }
    }

    case UPDATE_TRANSLATED_PRICES: {
      const { id, menuId, categoryId, item } = action.payload;
      console.log("itemsList[indItem].prices = item", item);

      let menu = [...state.venueDetails];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].prices = [...item];
            console.log("itemsList[indItem].prices = item", itemsList[indItem]);
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: [...menu],
            };
          }
        }
      }
    }
    default:
      return state;
  }
}
