import React from "react";
import { Dialog, TextField } from "@material-ui/core";
import ReactCardFlip from "react-card-flip";
import { verifyTab, declineTab, getVenueOrder } from "../../actions/order";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, Component, useRef } from "react";
import "../../style/home.css";
// import { useReactToPrint } from "react-to-print";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Grid,
  Divider,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { settleTab } from "../../actions/order";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  LabelText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    margin: "8px 0px",

    color: theme.colors.primary,
  },
  PreviousOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    margin: "0px",
    color: theme.colors.primary,
    padding: "0px 0px 30px 50px",
    textAlign: "right",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#FF3366",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  verifyButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#34CEB2",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  RejectOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  Description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    margin: "57px",
    /* identical to box height, or 112% */

    /* Main */
  },
  cancelBotton: {
    cursor: "pointer",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    padding: "0px 0px 0px 20px",
  },
}));

function VerifyTabDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let tabTables = useSelector((state) => state.VenueOrderReducer.tabTables);
  const [isFlipped, setIsFlipped] = useState(false);

  const { verifyDialog, setVerifyDialog, OrderSelected } = props;
  let dispatch = useDispatch();
  const handleClose = () => {
    setVerifyDialog(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };
  return (
    <div>
      <Dialog
        open={verifyDialog}
        // onClose={handleClose}
        style={{
          // padding: "200px 300px 230px 350px ",
          borderRadius: "5px !important",
        }}
        // aria-labelledby="form-dialog-title"
        // className="MuiDialog-paper"
        // fullScreen={true}
        // fullWidth={true}
        // maxWidth={maxWidth}
      >
        {/* <DialogTitle id="form-dialog-title">Amount to tip</DialogTitle> */}
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
          <div>
            <Grid item xs={12} style={{ padding: "20px", width: "600px" }}>
              <Grid container>
                <Grid item xs={7} className={classes.PreviousOrder}>
                {intl.formatMessage({
                  id: "Verify_tab",
                })}
                  
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={4} style={{ textAlign: "end" }}>
                  <ClearIcon
                    className={classes.CrossIcon}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid>
                <p className={classes.LabelText}>
                {intl.formatMessage({
                  id: "Tab_name",
                })}
                  </p>
                <TextField
                  variant="outlined"
                  //  placeholder="Month"
                  //  className={classes.fieldtext}
                  value={
                    props.cardClicked?.tab_name
                      ? props.cardClicked?.tab_name
                      : props.cardClicked?.name
                  }
                  fullWidth
                  style={{
                    // background: "#F9FAFC",
                    borderRadius: "5px",
                    border: "1px solid #E5E5E5",
                  }}
                  inputProps={{
                    className: classes.TextInputStyle,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                  }}
                />
                {(props.cardClicked?.tab_uuid ||
                  props?.cardClicked?.tab?.table_number) && (
                  <>
                    <p className={classes.LabelText}>
                    {intl.formatMessage({
                  id: "Table_number",
                })}
                      </p>
                    {/* {console.log(props.cardClicked)} */}
                    <TextField
                      variant="outlined"
                      value={
                        props.cardClicked?.table_number
                          ? props.cardClicked?.table_number
                          : props?.cardClicked?.tab?.table_number
                      }
                      //  placeholder="Month"
                      //  className={classes.fieldtext}
                      // placeholder="Table..."
                      fullWidth
                      style={{
                        // background: "#F9FAFC",
                        borderRadius: "5px",
                        border: "1px solid #E5E5E5",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                  </>
                )}
              </Grid>

              <Grid
                container
                justify="space-between"
                style={{ paddingTop: "30px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    //   color="primary"
                    className={classes.printButton}
                    // eslint-disable-next-line no-restricted-globals
                    onClick={handleClick}
                  >
                    {intl.formatMessage({
                  id: "Decline",
                })}
                    
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    //   color="primary"
                    className={classes.verifyButton}
                    onClick={() => {
                      dispatch(
                        verifyTab({
                          venue_uuid: venueDetail?.uuid,
                          tab_id: props.cardClicked?.tab_uuid
                            ? props.cardClicked?.tab_uuid
                            : props.cardClicked?.tab?.short_uuid,
                        })
                      ).then((res) => {
                        if (res && res === 200) {
                          dispatch(
                            getVenueOrder({
                              venue_uuid: venueDetail?.uuid,
                              Authorization: localStorage.AuthUserKey,
                            })
                          );
                          props.setOrderSelected(props.cardClicked);
                          props.setcardClicked(
                            props.cardClicked.order_uuid
                              ? props.cardClicked.order_uuid
                              : props.cardClicked.id
                          );
                          setVerifyDialog(false);
                        } else if (res && res === 404) {
                          // setError("Error")
                        }
                      });
                    }}
                  >
                    {intl.formatMessage({
                  id: "Verify",
                })}
                    
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid item xs={12} style={{ padding: "20px", width: "600px" }}>
              <Grid container>
                <Grid item xs={7} className={classes.PreviousOrder}>
                {intl.formatMessage({
                  id: "Decline_tab",
                })}
                  
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={4} style={{ textAlign: "end" }}>
                  <ClearIcon
                    className={classes.CrossIcon}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid style={{ textAlign: "center" }}>
                <p className={classes.Description}>
                {intl.formatMessage({
                  id: "confirm_decline_tab_order",
                })}
                  
                  {props.cardClicked?.tab_uuid && ","}
                  {props.cardClicked?.table_number}
                  {props.cardClicked?.tab_uuid && "-"}
                  {props.cardClicked?.tab_name}?<br></br> 
                  {intl.formatMessage({
                  id: "automatically_Reject_order",
                })}
                  
                </p>
              </Grid>
              <Divider style={{ background: "#EDF0F4" }} />

              <Grid
                container
                justify="space-between"
                style={{ paddingTop: "30px" }}
              >
                <Grid item>
                  {" "}
                  <p className={classes.cancelBotton} onClick={handleClick}>
                    
                    {intl.formatMessage({
                  id: "Cancel",
                })}
                  </p>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    //   color="primary"
                    className={classes.printButton}
                    // eslint-disable-next-line no-restricted-globals
                    onClick={() => {
                      dispatch(
                        declineTab({
                          venue_uuid: venueDetail?.uuid,
                          tab_id: props.cardClicked?.tab_uuid
                            ? props.cardClicked?.tab_uuid
                            : props.cardClicked?.tab?.short_uuid,
                        })
                      ).then((res) => {
                        if (res && res === 200) {
                          dispatch(
                            getVenueOrder({
                              venue_uuid: venueDetail?.uuid,
                              Authorization: localStorage.AuthUserKey,
                            })
                          );

                          setVerifyDialog(false);
                          setIsFlipped(!isFlipped);
                          // setError(null)
                        } else if (res && res === 404) {
                          // setError("Error")
                        }
                      });
                    }}
                  >
                     {intl.formatMessage({
                  id: "Decline",
                })}
                    
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ReactCardFlip>
      </Dialog>
    </div>
  );
}

export default VerifyTabDialog;
