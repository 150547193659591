import React from "react";
import { Grid, Divider, Typography, Card, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "300px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "49px",
    color: "#F66761",
    padding: "20px 0px",
  },
  itemPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    justifyContent: "flex-end",
    color: "#303A5F",
  },
  item: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
}));
function Reports(props) {
  const intl = useIntl();

  const classes = useStyles();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  return (
    <>
      {/* <Card className={classes.root}> */}
      <Grid
        container
        className={classes.root}
        style={{
          backgroundColor: `${props?.heading === "Total" ? "#303A5F" : ""} `,
          justifyContent: "space-between",
        }}
        direction="column"
      >
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.heading}
                style={{
                  color: `${props?.heading === "Total" ? "#fff" : ""} `,
                }}
              >
                {props?.heading}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.price}>
                {parseFloat(props?.price).toLocaleString("en-US", {
                  style: "currency",
                  currency: venueDetail?.currency
                    ? venueDetail?.currency
                    : "usd",
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props?.tip && (
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.item}>
                  {intl.formatMessage({
                    id: "Tip",
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.itemPrice}>
                  {parseFloat(props?.tip).toLocaleString("en-US", {
                    style: "currency",
                    currency: venueDetail?.currency
                      ? venueDetail?.currency
                      : "usd",
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
          {props?.subTotal && <Divider style={{ margin: "15px 0px" }} light />}
          {props?.subTotal && (
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.item}>
                  {intl.formatMessage({
                    id: "Subtotal",
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.itemPrice}>
                  {parseFloat(props?.subTotal).toLocaleString("en-US", {
                    style: "currency",
                    currency: venueDetail?.currency
                      ? venueDetail?.currency
                      : "usd",
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Divider
            style={{
              margin: "15px 0px",
              //   border: " 1px solid #4C5D95",
              color: props?.heading === "Total" ? "#4C5D95" : "#EDF0F4",
            }}
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={classes.item}
                style={{
                  color: `${props?.heading === "Total" ? "#fff" : ""} `,
                }}
              >
                {intl.formatMessage({
                  id: "Orders",
                })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={classes.itemPrice}
                style={{
                  color: `${props?.heading === "Total" ? "#fff" : ""} `,
                }}
              >
                {props?.orders}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Card> */}
    </>
  );
}

export default Reports;
