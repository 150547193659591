import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as Edit } from "../../../../assets/image/edit.svg";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../../../assets/image/right.svg";
import TextFieldItemCustomization from "./TextField/TextFieldItemCustomization";
const useStyles = makeStyles((theme) => ({
  TextInputStyleDisabled: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#A3A9BC",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "##F9FAFC !important",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [item, setItem] = useState({});
  const [currency, setCurrency] = useState("EUR");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  useEffect(() => {
    setCurrency(venueDetail?.currency ? venueDetail?.currency : "EUR");
  }, [venueDetail]);
  useEffect(() => {
    setItem(props?.item ? props?.item : {});
 // eslint-disable-next-line 
  }, [props,props?.item?.item_customisation_id]);

  const handleEditClick = () => {
    props.handleEditCustmizationModal(item);
  };
  const handleChangeValue = (e, index) => {
    props.handleChangeCustomizationOption(
      props.custIndex,
      index,
      e.target.value
    );
  };
  const handleChangeValueCustomization = (e, index) => {
    props.handleChangeCustomizationName(
      
      index,
      e.target.value
    );
  };
  return (
    <Grid
      xs={12}
      className={classes.root}
    >
      <Grid container style={{ padding: "20px", paddingBottom: "10px" }}>
        <Grid item xs={8}>
          <Grid container>
            <Grid
              item
              style={{
                maxWidth: "calc(100% - 90px)",
              }}
            >
              <Typography className={classes.heading}>{item?.name}</Typography>
            </Grid>
           
          </Grid>
        </Grid>
      </Grid>
    
{/* availability: {id: 1940, out_of_stock: false}
customisation_options: Array(1)
0:
availability: {id: 2907, out_of_stock: false}
customisation_option_id: 2249
name: "trans (greek)"
option_template_id: 496
position: 234
pre_selected: false
price: "2.00"
translatable_fields: ["name"]
translations: []
__proto__: Object
length: 1
__proto__: Array(0)
customisation_template: 1
display_as_main_item: false
item_customisation_id: 351
max_selectable: 1
name: "Extra Ingedients"
position: 149
required: false
translatable_fields: ["name"]
translations: Array(1)
0: {text: "matériaux supplémentaires", field: "name", language: "fr"}
length: 1 */}
{console.log("item===========>",item)}
<TextFieldItemCustomization item={item} index={ props.custIndex} handleChangeValue={handleChangeValueCustomization}/>

      {item?.customisation_options
        ?.sort((a, b) => a.position - b.position)
        ?.map((n, index) => {
          return (
            <TextFieldItemCustomization item={n} index={index} handleChangeValue={handleChangeValue}/>
          );
        })}
    </Grid>
  );
}
