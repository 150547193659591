import React, { useEffect, useState } from "react";
import Introduction1 from "../../Components/Introduction/Index";
import Introduction2 from "../../Components/Introduction/Intro2";
import Introduction3 from "../../Components/Introduction/Intro3";
import { Grid } from "@material-ui/core";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import { Virtual } from "swiper";
import SwipeableViews from "react-swipeable-views";
import Intro1 from "../../assets/image/intro1.png";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as SteperSelected } from "../../assets/image/steperSelected.svg";
import { ReactComponent as Steper } from "../../assets/image/stepper.svg";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};

export default function Introdution(props) {
  const [component, setComponent] = useState(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const handleChangePage = (page) => {
    console.log(page)
    setCurrentPage(page);
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100vh", maxWidth: "100vw", overflowX: "hidden" }}
      >
        <Grid container justify="center">
          <Grid container justify="center">
            <Typography component="span" className={classes.heading}>
              {currentPage === 0?"Getting started":currentPage === 1?"How it works":" Contactless ordering"}
            </Typography>
          </Grid>
          <SwipeableViews
            enableMouseEvents
            onChangeIndex={(e) => setCurrentPage(e)}
            index={currentPage}
          >
            <div>
              <Grid container justify="center">
                <img
                  src={props?.images[0]}
                  style={{
                    height: "257.14px",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </div>
            <div>
              <Grid container justify="center">
                <img
                  src={props?.images[1]}
                  style={{
                    height: "257.14px",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </div>
            <div>
              <Grid container justify="center">
                <img
                  src={props?.images[3]}
                  style={{
                    height: "257.14px",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </div>
          </SwipeableViews>
        </Grid>

        <Grid
          container
          justify="center"
          style={{maxWidth:currentPage === 0?  "260px":"500px", marginTop: "25px" }}
        >
          <Typography component="span" className={classes.body} >
          {currentPage === 0?"Setup your online menu with ease. Instant updates at anytime":
          currentPage === 1?"Your customers scan a QR code and view your menu without having to download any app.":" Switch On ordering mode and allow your guest to place orders from their phones directly."}

            
          </Typography>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={1}
          style={{ maxWidth: "100vw", marginTop: "17px", marginBottom: "31px" }}
        >
          <Grid item>
            {currentPage === 0 ? (
              <SteperSelected
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(0)}
              />
            ) : (
              <Steper
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(0)}
              />
            )}
          </Grid>
          <Grid item>
            {currentPage === 1 ? (
              <SteperSelected
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(1)}
              />
            ) : (
              <Steper
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(1)}
              />
            )}
          </Grid>
          <Grid item>
            {currentPage === 2 ? (
              <SteperSelected
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(2)}
              />
            ) : (
              <Steper
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage(2)}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          <AcceptButton
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {currentPage<2?handleChangePage(currentPage ===0?1:2):history.push("/Login")}}
            style={{ width: "266px" }}
          >
            {currentPage !==2 ?
            intl.formatMessage({
              id: "Next",
            }):
            intl.formatMessage({
              id: "Got it",
            })}
          </AcceptButton>
        </Grid>
        <Grid container justify="center">
          <Typography
            component="span"
            className={classes.skip}
            onClick={() => (currentPage !== 2 ? history.push("/Login") : "")}
          >
            {currentPage !== 2 ? "Skip to login" : ""}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  skip: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
    marginTop: "19px",
    marginBottom: "55px",
    cursor: "pointer",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#080808",
    marginTop: "34px",
    marginBottom: "27px",
  },

  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#303A5F",
  },
  root: {
    flexGrow: 1,
  },
}));
