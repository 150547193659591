import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, Divider, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useIntl } from "react-intl";
import AddCircleIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "../../style/home.css";
const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];
function MenuAvailability(props) {
  const { handleClose, setTime, itemName, clearTime, availabilityTime } = props;
  const classes = useStyles();
  const intl = useIntl();
  let dispatch = useDispatch();
  const [itemType, setItemType] = useState(getItemTypes);
  const [showAddButton, setShowAddButton] = useState(false);
  const [startHours, setStartHours] = useState(
    availabilityTime?.startHours ? availabilityTime?.startHours : ""
  );
  const [startMin, setStartMin] = useState(
    availabilityTime?.startMin ? availabilityTime?.startMin : ""
  );
  const [endHours, setEndHours] = useState(
    availabilityTime?.endHours ? availabilityTime?.endHours : ""
  );
  const [endMin, setEndMin] = useState(
    availabilityTime?.endMin ? availabilityTime?.endMin : ""
  );
  //second time state start
  const [startHours_2, setStartHours_2] = useState(
    availabilityTime?.startHours_2 ? availabilityTime?.startHours_2 : ""
  );
  const [startMin_2, setStartMin_2] = useState(
    availabilityTime?.startMin_2 ? availabilityTime?.startMin_2 : ""
  );
  const [endHours_2, setEndHours_2] = useState(
    availabilityTime?.endHours_2 ? availabilityTime?.endHours_2 : ""
  );
  const [endMin_2, setEndMin_2] = useState(
    availabilityTime?.endMin_2 ? availabilityTime?.endMin_2 : ""
  );
  const [errors, setErrors] = useState({
    startMint: false,
    startHour: false,
    endMint: false,
    endHour: false,
    startMint_2: false,
    startHour_2: false,
    endMint_2: false,
    endHour_2: false,
  });
  // second time state end
  useEffect(() => {
    if (
      availabilityTime?.startHours_2 === "" &&
      availabilityTime?.startMin_2 === "" &&
      availabilityTime?.endHours_2 === "" &&
      availabilityTime?.endMin_2 === ""
    ) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  }, []);
  const handleChangeTime = (time, direction, type, disable) => {
    console.log(time, direction, type, disable);
    if (!disable) {
      let numTime = parseInt(time);
      if (direction === "up") {
        if (type === "startMin" || type === "endMin") {
          numTime = (numTime + 5) % 60;
        } else {
          numTime = numTime + 1;
        }
      } else {
        if (type === "startMin" || type === "endMin") {
          numTime = numTime - 5;
        } else {
          numTime = numTime - 1;
        }
      }
      if (numTime < 10) {
        numTime = `0${numTime}`;
      }
      switch (type) {
        case "startHours":
          setStartHours(numTime);
          break;
        case "startMin":
          setStartMin(numTime);
          break;
        case "endHours":
          setEndHours(numTime);
          break;
        case "endMin":
          setEndMin(numTime);
          break;

        default:
          break;
      }
    }
  };
  const handleChangeTime_2 = (time, direction, type, disable) => {
    console.log(time, direction, type, disable);
    if (!disable) {
      let numTime = parseInt(time);
      if (direction === "up") {
        if (type === "startMin" || type === "endMin") {
          numTime = (numTime + 5) % 60;
        } else {
          numTime = numTime + 1;
        }
      } else {
        if (type === "startMin" || type === "endMin") {
          numTime = numTime - 5;
        } else {
          numTime = numTime - 1;
        }
      }
      if (numTime < 10) {
        numTime = `0${numTime}`;
      }
      switch (type) {
        case "startHours":
          setStartHours_2(numTime);
          break;
        case "startMin":
          setStartMin_2(numTime);
          break;
        case "endHours":
          setEndHours_2(numTime);
          break;
        case "endMin":
          setEndMin_2(numTime);
          break;

        default:
          break;
      }
    }
  };
  const setTimeCallBack = (e) => {
    let secondTimeError = false;
    let errorObj = {
      startMint: false,
      startHour: false,
      endMint: false,
      endHour: false,
      startMint_2: false,
      startHour_2: false,
      endMint_2: false,
      endHour_2: false,
    };
    if (
      showAddButton === false &&
      (startHours_2 === "" ||
        startMin_2 === "" ||
        endHours_2 === "" ||
        endMin_2 === "")
    ) {
      secondTimeError = true;
      if (startHours_2 === "") {
        errorObj["startHour_2"] = true;
      }
      if (startMin_2 === "") {
        errorObj["startMint_2"] = true;
      }
      if (endHours_2 === "") {
        errorObj["endHour_2"] = true;
      }
      if (endMin_2 === "") {
        errorObj["endMint_2"] = true;
      }
    }

    if (
      secondTimeError ||
      startHours === "" ||
      startMin === "" ||
      endHours === "" ||
      endMin === ""
    ) {
      if (startHours === "") {
        errorObj["startHour"] = true;
      }
      if (startMin === "") {
        errorObj["startMint"] = true;
      }
      if (endHours === "") {
        errorObj["endHour"] = true;
      }
      if (endMin === "") {
        errorObj["endMint"] = true;
      }
      console.log(errorObj);
      setErrors({ ...errorObj });
    } else {
      setTime({
        startHours: startHours,
        startMin: startMin,
        endHours: endHours,
        endMin: endMin,
        startHours_2: startHours_2,
        startMin_2: startMin_2,
        endHours_2: endHours_2,
        endMin_2: endMin_2,
      });
    }
  };
  const handleClear = () => {
    setStartHours("00");
    setStartMin("00");
    setEndHours("00");
    setEndMin("00");
    setStartHours_2("00");
    setStartMin_2("00");
    setEndHours_2("00");
    setEndMin_2("00");
    clearTime();
  };
  function getItemTypes() {
    let t = "";
    switch (props?.level) {
      case 0:
        t = "menu";
        break;
      case 1:
        t = "category";

        //category
        break;
      case 2:
        //item
        t = "item";

        break;
      case 30:
        //customization
        t = "customization";

        break;

      case 4:
        t = "customization option";
        //customization option

        break;

      default:
        break;
    }
    return t;
  }
  const getItemType = () => {
    switch (props?.level) {
      case 0:
        setItemType("menu");
        break;
      case 1:
        setItemType("category");

        //category
        break;
      case 2:
        //item
        setItemType("item");

        break;
      case 30:
        //customization
        setItemType("customization");

        break;

      case 4:
        setItemType("customization item");
        //customization option

        break;

      default:
        break;
    }
  };
  React.useEffect(() => {
    getItemType();
    // eslint-disable-next-line
  }, [props?.level]);
  React.useEffect(() => {
    getItemType();
    // eslint-disable-next-line
  }, []);
  const [showPlaceholderStartHours, setShowPlaceholderStartHours] =
    useState(true);
  const [showPlaceholder2, setShowPlaceholder2] = useState(true);
  const [startHours_p, setStartHours_p] = useState(
    availabilityTime?.startHours ? false : true
  );
  const [startMin_p, setStartMin_p] = useState(
    availabilityTime?.startMin ? false : true
  );
  const [endHours_p, setEndHours_p] = useState(
    availabilityTime?.endHours ? false : true
  );
  const [endMin_p, setEndMin_p] = useState(
    availabilityTime?.endMin ? false : true
  );
  //second time state start
  const [startHours_2_p, setStartHours_2_p] = useState(
    availabilityTime?.startHours_2 ? false : true
  );
  const [startMin_2_p, setStartMin_2_p] = useState(
    availabilityTime?.startMin_2 ? false : true
  );
  const [endHours_2_p, setEndHours_2_p] = useState(
    availabilityTime?.endHours_2 ? false : true
  );
  const [endMin_2_p, setEndMin_2_p] = useState(
    availabilityTime?.endMin_2 ? false : true
  );
  const handleAddSecondTime = (type) => {
    switch (type) {
      case "add":
        setShowAddButton(false);
        break;
      case "remove":
        setShowAddButton(true);
        setStartHours_2("");
        setStartMin_2("");
        setEndHours_2("");
        setEndMin_2("");
        break;

      default:
        break;
    }
  };
  // const [showPlaceholder, setShowPlaceholder] = useState(value === "none");

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        style={{
          borderRadius: "5px !important",
          // background:"#fff"
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            padding: "20px",
            width: "600px",
            background: "#fff",
            borderRadius: "5px !important",
          }}
        >
          <Grid container justify="space-between">
            <Grid item xs={1}></Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "13px",
              }}
            >
              <p className={classes.RejectOrder}>{`${intl.formatMessage({
                id: "Choose_availability_of",
              })} ${itemName} ${itemType}`}</p>
            </Grid>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <ClearIcon
                onClick={(e) => handleClose(e)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Grid
                container
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography className={classes.title}>
                    {intl.formatMessage({
                      id: "from",
                    })}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "10px 0px",
                  }}
                >
                  <Grid item xs={6}>
                    <Select
                      // value={"-1"}
                      value={startHours===""?-1:startHours}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setErrors({ ...errors, startHour: false });
                        setStartHours(event.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "Verified",
                      })}
                      onFocus={(e) => setStartHours_p(false)}
                      onClose={(e) =>
                        setStartHours_p(e.target.value === undefined)
                      }
                      className={ startHours_p?classes.verifiedDisabled :classes.verified}
                      error={errors?.startHour}
                    >
                      <MenuItem 
                      className={!startHours_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Hrs</MenuItem>

                      {hours.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                    {errors?.startHour && (
                      <FormHelperText className={classes.errorMessage}>
                        Required
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Select
                      value={startMin===""?-1:startMin}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setStartMin(event.target.value);
                        setErrors({ ...errors, startMint: false });
                      }}
                      placeholder={intl.formatMessage({
                        id: "Verified",
                      })}
                      className={startMin_p?classes.verifiedDisabled :classes.verified}
                      error={errors?.startMint}
                      onFocus={(e) => setStartMin_p(false)}
                      onClose={(e) =>
                        setStartMin_p(e.target.value === undefined)
                      }
                    >
                      <MenuItem 
                      className={!startMin_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Mins</MenuItem>
                      {minutes.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                    {errors?.startMint && (
                      <FormHelperText className={classes.errorMessage}>
                        Required
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item style={{flexGrow:1}}></Grid> */}
            <Grid item xs={6}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingRight: "40px",
                }}
              >
                <Typography className={classes.title}>
                  {intl.formatMessage({
                    id: "until",
                  })}
                </Typography>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "10px 0px",
                }}
              >
                <Grid item xs={6}>
                  <Select
                    value={endHours===""?-1:endHours}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setEndHours(event.target.value);
                      setErrors({ ...errors, endHour: false });
                    }}
                    placeholder={intl.formatMessage({
                      id: "Verified",
                    })}
                    className={endHours_p?classes.verifiedDisabled :classes.verified}
                    error={errors?.endHour}
                    onFocus={(e) => setEndHours_p(false)}
                    onClose={(e) =>
                      setEndHours_p(e.target.value === undefined)
                    }
                  >
                    <MenuItem 
                      className={!endHours_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Hrs</MenuItem>
                    {hours.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                  {errors?.endHour && (
                    <FormHelperText className={classes.errorMessage}>
                      Required
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Select
                    value={endMin===""?-1:endMin}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setEndMin(event.target.value);
                      setErrors({ ...errors, endMint: false });
                    }}
                    placeholder={intl.formatMessage({
                      id: "Verified",
                    })}
                    className={endMin_p?classes.verifiedDisabled :classes.verified}
                    error={errors?.endMint}
                    onFocus={(e) => setEndMin_p(false)}
                    onClose={(e) =>
                      setEndMin_p(e.target.value === undefined)
                    }
                  >
                    <MenuItem 
                      className={!endMin_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Mins</MenuItem>
                    {minutes.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                  {errors?.endMint && (
                    <FormHelperText className={classes.errorMessage}>
                      Required
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showAddButton && (
            <Grid
              container
              style={{
                height: "100%",
                paddingTop: "10px",
                marginLeft: "10px",
              }}
            >
              <Typography
                component="span"
                className={classes.addButtonText}
                onClick={() => handleAddSecondTime("add")}
                style={{
                  cursor: "pointer",
                }}
              >
                <AddCircleIcon
                  onClick={() => handleAddSecondTime("add")}
                  style={{
                    cursor: "pointer",
                    height: "15px",
                    width: "15px",
                    color: "#303A5F",
                  }}
                />
                Add row
              </Typography>
            </Grid>
          )}
          {!showAddButton && (
            <Grid
              container
              spacing={5}
              style={{ marginTop: "0px", paddingTop: "0px" }}
            >
              <Grid item xs={6} style={{ paddingTop: "10px" }}>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Grid item xs={6}>
                      <Select
                        value={startHours_2===""?-1:startHours_2}
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          setStartHours_2(event.target.value);
                          setErrors({ ...errors, startHour_2: false });
                        }}
                        placeholder={intl.formatMessage({
                          id: "Verified",
                        })}
                        className={startHours_2_p?classes.verifiedDisabled :classes.verified}
                        error={errors?.startHour_2}
                        onFocus={(e) => setStartHours_2_p(false)}
                        onClose={(e) =>
                          setStartHours_2_p(e.target.value === undefined)
                        }
                      >
                        <MenuItem 
                      className={!startHours_2_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Hrs</MenuItem>
                        {hours.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                      {errors?.startHour_2 && (
                        <FormHelperText className={classes.errorMessage}>
                          Required
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <Select
                        value={startMin_2===""?-1:startMin_2}
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          setStartMin_2(event.target.value);
                          setErrors({ ...errors, startMint_2: false });
                        }}
                        placeholder={intl.formatMessage({
                          id: "Verified",
                        })}
                        className={startMin_2_p?classes.verifiedDisabled :classes.verified}
                        error={errors?.startMint_2}
                        onFocus={(e) => setStartMin_2_p(false)}
                        onClose={(e) =>
                          setStartMin_2_p(e.target.value === undefined)
                        }
                      >
                           <MenuItem 
                      className={!startMin_2_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Mins</MenuItem>
                        {minutes.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                      {errors?.startMint_2 && (
                        <FormHelperText className={classes.errorMessage}>
                          Required
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: "10px" }}>
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Grid item xs={6}>
                    <Select
                      value={endHours_2===""?-1:endHours_2}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setEndHours_2(event.target.value);
                        setErrors({ ...errors, endHour_2: false });
                      }}
                      placeholder={intl.formatMessage({
                        id: "Verified",
                      })}
                      className={endHours_2_p?classes.verifiedDisabled :classes.verified}
                      error={errors?.endHour_2}
                      onFocus={(e) => setEndHours_2_p(false)}
                        onClose={(e) =>
                          setEndHours_2_p(e.target.value === undefined)
                        }
                    >
                         <MenuItem 
                      className={!endHours_2_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Hrs</MenuItem>
                      {hours.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                    {errors?.endHour_2 && (
                      <FormHelperText className={classes.errorMessage}>
                        Required
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Select
                      value={endMin_2===""?-1:endMin_2}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setEndMin_2(event.target.value);
                        setErrors({ ...errors, endMint_2: false });
                      }}
                      placeholder={intl.formatMessage({
                        id: "Verified",
                      })}
                      className={endMin_2_p?classes.verifiedDisabled :classes.verified}
                      error={errors?.endMint_2}
                      onFocus={(e) => setEndMin_2_p(false)}
                        onClose={(e) =>
                          setEndMin_2_p(e.target.value === undefined)
                        }
                    >
                         <MenuItem 
                      className={!endMin_2_p && classes.menuitemhidden }
                       key="0" 
                      disabled value="-1" >Mins</MenuItem>
                      {minutes.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                    {errors?.endMint_2 && (
                      <FormHelperText className={classes.errorMessage}>
                        Required
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!showAddButton && (
            <Grid
              container
              style={{
                height: "100%",
                paddingTop: "24px",
                marginLeft: "10px",
              }}
            >
              <Typography
                component="span"
                className={classes.addButtonText}
                onClick={() => handleAddSecondTime("remove")}
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "px",
                  color: "#303A5F",
                }}
              >
                Remove row
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justify="space-between"
            style={{ paddingTop: "30px" }}
          >
            <Grid item>
              <Button
                variant="contained"
                //   color="primary"
                className={classes.ClearButton}
                onClick={() => handleClear()}
                // eslint-disable-next-line no-restricted-globals
              >
                {intl.formatMessage({
                  id: "Clear",
                })}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                //   color="primary"
                className={classes.printButton}
                onClick={(e) => setTimeCallBack(e)}
                // eslint-disable-next-line no-restricted-globals
              >
                {intl.formatMessage({
                  id: "Save",
                })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default MenuAvailability;

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: "#f44336",
  },
  menuitemhidden: {
    display: "none",
  },
  addButtonText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
  },
  verified: {
    height: "40px",
    background: "#FFFFFF",
    // border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.colors.primary,
    minWidth: "100px",

    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  verifiedDisabled: {
    height: "40px",
    background: "#FFFFFF",
    // border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: "#A3A9BC",
    minWidth: "100px",

    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  root: {
    padding: "0px",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#34CEB2",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
    "&.MuiButton-contained:hover": {
      background: "#34CEB2 !important",
    },
  },
  ClearButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#EDF0F4",
    marginBottom: "22px",
    color: "#303A5F",
    borderRadius: " 5px",
    "&.MuiButton-contained:hover": {
      background: "#EDF0F4 !important",
    },
  },
  RejectOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },

  cancelBotton: {
    cursor: "pointer",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    padding: "0px 0px 0px 20px",
  },
  timer: {
    height: "40px",
    width: "40px",
    background: "#FFFFFF",
    border: "1px solid #303A5F",
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icons: {
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
}));
