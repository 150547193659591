import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as Edit } from "../../../../assets/image/edit.svg";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../../../assets/image/right.svg";

const useStyles = makeStyles((theme) => ({
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    // background: "#FFFFFF",
    // border: "1px solid #EDF0F4",
    // boxSizing: "border-box",
    // borderRadius: "10px",
    // marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  TextInputStyle3: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    minWidth: "150px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    paddingLeft: "5px",
  },
  TextInputStyle3Disabled: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#A3A9BC",
    border: "0px",
    minWidth: "150px",
    background: "#F9FAFC",
    // border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    paddingLeft: "5px",
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  const handleChange = (index, value, item) => {
    let newItem = { ...item };
    newItem.translations[0].text = value;
    let pricess = [...props?.pricesList];
    pricess[index] = { ...newItem };
    props.setPricesList(pricess);
  };

  return (
    <Grid
      xs={12}
      className={classes.root}
      // style={{ cursor: "pointer" }}
      // onClick={handleEditClick}
    >
      {props?.pricesList[0]?.description&&props?.pricesList[0]?.description!==""&&
      <Grid container style={{ marginTop: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className={classes.headingTest}
              style={{ paddingBottom: "0px", marginBottom: "10px" }}
            >
              {intl.formatMessage({
                id: "Price_description",
              })}

              {/* (optional) */}
            </Typography>
          </Grid>
          {props?.pricesList.map((item, index) => {
            return (
              <Grid container style={{ marginTop: "20px" }}>
                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Description",
                    })}
                    fullWidth
                    // value={itemDetail?.price}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      border: "1px solid #EDF0F4",
                      backgroundColor: "#fff",
                    }}
                    value={item?.description}
                    type="text"
                    // onChange={(e) => {
                    //   setPriceDescriptionError(false);
                    //   setPriceDescription(e.target.value);
                    // }}
                    disabled={true}
                    inputProps={{
                      className: classes.TextInputStyle3Disabled,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                  {/* <FormHelperText
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
              }}
              className={classes.errorMessage}
            >
              {priceDescriptionError &&
                intl.formatMessage({
                  id: "Field_is_required",
                })}
            </FormHelperText> */}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "28px",
                    justifyContent: "center",
                  }}
                >
                  <RightIcon />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    variant="outlined"
                    placeholder={item?.description?item?.description:intl.formatMessage({
                      id: "Description",
                    })}
                    fullWidth
                    value={item?.translations[0]?.text}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      border: "1px solid #EDF0F4",
                      backgroundColor: "#fff",
                    }}
                    type="text"
                    onChange={(e) => {
                      handleChange(index, e.target.value, item);
                    }}
                    inputProps={{
                      className: classes.TextInputStyle3,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                  {/* <FormHelperText
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
              }}
              className={classes.errorMessage}
            >
              {priceDescriptionError &&
                intl.formatMessage({
                  id: "Field_is_required",
                })}
            </FormHelperText> */}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
 } </Grid>
  );
}
