import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    right: "unset",
    left: "unset",
    backgroundColor: "#fff",
    borderTop: "1px solid #EDF0F4",
    boxShadow: "none",
  },
  grow: {
    flexGrow: 1,
  },
  text: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
}));

export default function BottomAppBar(props) {
  const intl = useIntl();

  const classes = useStyles();
  const { selection, open, type, editedItem, length } = props;
  return (
    <React.Fragment>
      <AppBar
        onClick={() => props?.handleOpen(props?.type)}
        position="fixed"
        className={classes.appBar}
        style={{
          maxWidth: "inherit",
          cursor: "pointer",
          backgroundColor:
            length === 0
              ? "#34CEB2"
              : editedItem?.item === null && open && selection === type
              ? "#34CEB2"
              : "#FFFFFF",
          maxHeight: "60px",
          minHeight: "60px",
        }}
      >
        <Toolbar>
          <Typography
            className={classes.text}
            style={{
              color:
                length === 0
                  ? "#fff"
                  : editedItem?.item === null && open && selection === type
                  ? "#fff"
                  : "#A3A9BC",
            }}
          >
            {props.title}
          </Typography>
          <div className={classes.grow} />

          <IconButton edge="end">
            <AddIcon
              style={{
                color:
                  length === 0
                    ? "#fff"
                    : editedItem?.item === null && open && selection === type
                    ? "#fff"
                    : "#A3A9BC",
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
