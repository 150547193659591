import { CardContent, Grid, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import upload from "../../../assets/image/upload.png";
import { useIntl } from "react-intl";

const AddImage = (props) => {
  const { image, selectedFile, btnText } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.rootDiv}>
      <>
        {!image ? (
          <label className="profile_image">
            <input
              type="file"
              className="profile_image_input"
              style={{ display: "none" }}
              accept="image/*"
              onChange={selectedFile}
            />
            <div
              className={classes.container}
              style={{
                position: "relative",
                width: "100%",
                paddingTop: "66.66%",
              }}
            >
              <div
                className={classes.innerDiv}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed #A3A9BC",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <img alt={upload} src={upload} />
              </div>
            </div>
            {/* <CardContent className={classes.imageCardRoot}>
              <Grid
                container
                direction="column"
                justify="center"
                style={{
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img alt={upload} src={upload} />
                </Grid>
              </Grid>
            </CardContent>
        */}
          </label>
        ) : (
          <label className="profile_image">
            <input
              type="file"
              className="profile_image_input"
              style={{ display: "none" }}
              accept="image/*"
              onChange={selectedFile}
            />
            <div
              className={classes.container}
              style={{
                position: "relative",
                width: "100%",
                paddingTop: "66.66%",
              }}
            >
              <div
                className={classes.innerDiv}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: "4px",
                  backgroundImage: `url(${image})`,
                  backgroundColor: "#F9FAFC",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}
              ></div>
            </div>
            {/* <CardContent
              className={classes.imageCardRoot}
              style={{
                backgroundImage: `url(${image})`,
                backgroundColor: "#F9FAFC",
                // borderRadius: "20px",
                // backgroundSize: "contain !important",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: 0,
                paddingTop: "66.66%",
                position: "relative",
              }}
            >
              <Grid
                container
                justify="center"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "center",
                  lineHeight: "5em",
                  opacity: "1",
                }}
              ></Grid>
            </CardContent>
        */}
          </label>
        )}
      </>
    </div>
  );
};

export default AddImage;

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    cursor: "pointer",
    width: "100%",
    height: "auto",
    maxWidth: "183.73px",
    minWidth: "183.73px",
    maxHeight: "124.32px",
    minHeight: "124.32px",
  },
  imageCardRoot: {
    border: "1px dashed #A3A9BC",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    cursor: "pointer",
    maxWidth: "183.73px",
    minWidth: "183.73px",
    maxHeight: "124.32px",
    minHeight: "124.32px",
  },
  cardButtonSelected: {
    cursor: "pointer",
    width: "4em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "rgb(116, 103, 239)",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  cardButton: {
    cursor: "pointer",
    width: "18em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "rgb(116, 103, 239)",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
}));
