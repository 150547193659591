import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./App.css";
import PrivateRoute from "./routing/privateRoute";
import PublicRoute from "./routing/publicRoute";
import Home from "./Components/Home";
import Login from "./Components/Authorization/Login";
import Signup from "./Components/Authorization/Signup";
import ForgotPassword from "./Components/Authorization/ForgotPassword";
import NewPassword from "./Components/Authorization/NewPassword";
import Splash from "./pages/Splash/Index";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import { useSelector, useDispatch } from "react-redux";
import RestaurantsGroups from "./pages/Hotel/RestaurantsGroup";
import { getPendingOrders } from "./actions/order";
import { IntlProvider } from "react-intl";
import en from "./utils/locales/en";
import fr from "./utils/locales/fr";
import gr from "./utils/locales/gr";
import incomingBeep from "./assets/sound/incomingBeep.mp3";
import useSound from "use-sound";
let wakeLock = null;
let interval = null;

function App() {
  let dispatch = useDispatch();
  let trackingPendingOrders = null;
  const awake = async () => {
    try {
      if ("wakeLock" in navigator) {
        try {
          wakeLock = await navigator.wakeLock.request("screen");
        } catch (err) {
          console.log(`${err.name}, ${err.message}`);
        }
      }
    } catch (err) {
      // console.log(`${err.name}, ${err.message}`);
    }
  };
  useEffect(() => {
    awake();
  }, []);
  let hotelDetail = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail
  );
  const [orderIncomingBeep] = useSound(incomingBeep, {
    onend: () => {
      console.info("Sound ended!");
    },
    volume: 1,
    soundEnabled: true,
    interrupt: true,
  });
  const callSound = () => {
    orderIncomingBeep();
  };
  useEffect(() => {
    if (hotelDetail) {
      if (hotelDetail?.venues?.length > 0 && !interval) {
        console.log("interval", interval);
        if (interval) {
          clearInterval(interval);
        }
        interval = setInterval(async () => {
          console.log("interval", interval);

          let res = await dispatch(
            getPendingOrders({ venue_uuid: hotelDetail?.venues[0]?.uuid })
          );
          if (res?.status === 400) {
            clearInterval(interval);
          }
        }, 5000);
      }
    }
    // eslint-disable-next-line
  }, [hotelDetail?.venues]);

  return (
    <div>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Router>
          <Switch>
            <Route
              path="/Login"
              exact
              component={(restProps) => <Login {...restProps} />}
            />
            <PublicRoute
              path="/"
              exact
              component={(restProps) => <Splash {...restProps} />}
            />
            <Route
              path="/signup"
              exact
              component={(restProps) => <Signup {...restProps} />}
            />
            <Route
              path="/password-reset"
              exact
              component={(restProps) => <NewPassword {...restProps} />}
            />
            <Route
              path="/forgot-password"
              exact
              component={(restProps) => <ForgotPassword {...restProps} />}
            />

            <PrivateRoute
              path="/RestaurantsGroups"
              component={RestaurantsGroups}
            />
            <PrivateRoute path="/venue/:id/:venueid" component={Home} />
            <Redirect to="/Login" />
          </Switch>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

const msg = {
  en: en,
  fr: fr,
  gr: gr,
};
const NewApp = () => {
  let languageReducer = useSelector((state) => state.languageReducer);
  const [locale, setLocale] = useState(
    languageReducer?.language ? languageReducer?.language : "en"
  );
  useEffect(() => {
    setLocale(languageReducer?.language ? languageReducer?.language : "en");
    // eslint-disable-next-line
  }, [languageReducer?.language, localStorage.getItem("language")]);
  return (
    <IntlProvider locale={locale} messages={msg[locale]}>
      <App />
    </IntlProvider>
  );
};
export default NewApp;
