import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, Component, useRef } from "react";
import { getAllTabs } from "../../actions/order";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, Divider, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { settleTab } from "../../actions/order";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#FF3366",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  RejectOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  Description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    /* identical to box height, or 112% */

    /* Main */
  },
  cancelBotton: {
    cursor: "pointer",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    padding: "0px 0px 0px 20px",
  },
}));

function TabSettleDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let tabTables = useSelector((state) => state.VenueOrderReducer.tabTables);
  const { dialogOpen, setDialogOpen, setAlertOpen, OrderSelected } = props;
  let dispatch = useDispatch();
  const handleClose = () => {
    setDialogOpen(false);
  };
  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        style={{
          borderRadius: "5px !important",
        }}
      >
        <Grid item xs={12} style={{ padding: "20px", width: "600px" }}>
          <Grid container justify="space-between">
            <Grid item>
              <p className={classes.RejectOrder}>{`Settle Tab ${
                OrderSelected?.name ? OrderSelected?.name : ""
              } ?`}</p>
            </Grid>
            <Grid item>
              <ClearIcon
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <p className={classes.Description}>
            {intl.formatMessage({
                  id: "prevent_customers",
                })}
              
            </p>
          </Grid>
          <Divider style={{ background: "#EDF0F4" }} />

          <Grid
            container
            justify="space-between"
            style={{ paddingTop: "30px" }}
          >
            <Grid item>
              {" "}
              <p className={classes.cancelBotton} onClick={() => handleClose()}>
                {intl.formatMessage({
                  id: "Cancel",
                })}
              </p>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.printButton}
                // eslint-disable-next-line no-restricted-globals
                onClick={() => {
                  dispatch(
                    settleTab({
                      created_tab_id: OrderSelected.id,
                      venue_uuid: venueDetail && venueDetail.uuid,
                      Authorization: localStorage.AuthUserKey,
                    })
                  ).then((res) => {
                    if (res) {
                      setAlertOpen(false);
                      setDialogOpen(false);
                      dispatch(
                        getAllTabs({
                          venue_uuid: venueDetail.uuid,
                          Authorization: localStorage.AuthUserKey,
                        })
                      );
                      props.setOrderSelected(null);
                      props.setOpen(false);
                    } else {
                      setDialogOpen(false);
                      setAlertOpen(true);
                    }
                  });
                }}
              >
                {intl.formatMessage({
                  id: "Settle_tab",
                })}
                
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default TabSettleDialog;
