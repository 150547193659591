import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Grid, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";

import "../style/home.css";

const useStyles = makeStyles((theme) => ({
  TableDigit: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "18px",
    padding: " 0px",
    margin: "20px 0px 20px 10px",
  },
}));

function IncomingOrdersCard(props) {
  const intl = useIntl();

  const classes = useStyles();
  const {
    table_number,
    cardClicked,
    order_uuid,
    payment_method,
    request,
  } = props;
  const [colors, setColors] = useState(null);
  useEffect(() => {
    if (cardClicked === order_uuid) {
      setColors("#fff");
    } else if (request) {
      setColors("#34CEB2");
    } else if (payment_method === "card") {
      setColors("#F66761");
    } else {
      setColors("#6772E5");
    }
  }, [cardClicked, order_uuid, payment_method, request]);
  return (
    <Grid
      item
      className={classes.TableDigit}
      style={{
        color: colors,
      }}
    >
      {table_number}
    </Grid>
  );
}

export default IncomingOrdersCard;
