import React from "react";
import moment from "moment";

function TimeCounter(props) {
  // var date = moment.utc().format();

  var future = moment.utc(moment(props.submitted_for_review).toISOString());
  const currentDate = moment();
  // const future = moment(props.submitted_for_review);
  const timeLeft = moment
    .utc(moment(currentDate.diff(future)).toISOString())
    .format("HH:mm:ss");
  // props.settimeCounter(timeLeft)
  return (
    <div>
      <p style={{ margin: "0px" }}>{timeLeft}</p>
    </div>
  );
}

export default TimeCounter;
