import React, { useState, useEffect } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import {
  applyDragCustomizationOptions,
  generateItems,
} from "../../utils/utils";
import TextField from "@material-ui/core/TextField";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as Edit } from "../../assets/image/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/image/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

import "./menu.css";
const DragnDropItemCustomization = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [name, setName] = React.useState("");
  const [items, setItems] = useState([]);
  const [currency, setCurrency] = useState("EUR");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  useEffect(() => {
    setCurrency(venueDetail?.currency ? venueDetail?.currency : "EUR");
  }, [venueDetail]);
  useEffect(() => {
    let arr = [];
    props.options &&
      props.options.forEach((singleItem, index) => {
        arr.push({
          ...singleItem,
          data: singleItem.name,
          id: singleItem.customisation_option_id,
        });
      });
    setItems(arr);
  }, [props]);
  const handleRemoveOption = (id) => {
    props.handleRemoveOption(id);
  };

  return (
    <Container
      animationDuration={500}
      lockAxis="y"
      onDrop={(e) =>
        setItems(applyDragCustomizationOptions(items, e, props?.item, dispatch))
      }
      dragClass={props.selectedClass}
      dropClass={props.class}
      dragHandleSelector=".column-drag-handle"
      dropPlaceholder={{
        animationDuration: 150,
        showOnTop: true,
        className: "drop-preview",
      }}
      dropPlaceholderAnimationDuration={200}
      style={{
        background: "#fff",
        fontFamily: theme.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "18px",
        color: "#303A5F",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {console.log("item",items)}
      {items&& items
        .sort((a, b) => a.position - b.position)
        .map((p, index) => {
          return (
            <Draggable key={p.id}>
              <Grid item xs={12} style={{ margin: "8px 0px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "Option_name",
                      })}
                      fullWidth
                      value={p?.name}
                      onChange={(e) =>
                        props.updateOptionValues(p?.id, e.target.value, "name")
                      }
                      error={props?.errors[index]?.name}
                      helperText={
                        props?.errors[index]?.name && intl.formatMessage({
                          id: "Field_is_required",
                        })
                      }
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle2,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      minWidth: "22.666667%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "Price",
                      })}
                      fullWidth
                      value={p?.price}
                      onChange={(e) =>
                        props.updateOptionValues(p?.id, e.target.value, "price")
                      }
                      type="number"
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        border: "1px solid #EDF0F4",
                        backgroundColor: "#fff",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle3,
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                        startAdornment: (
                          <InputAdornment
                            style={{
                              width: "40px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            position="start"
                          >
                            {
                              Number()
                                .toLocaleString(undefined, {
                                  style: "currency",
                                  currency: currency,
                                })
                                .split("0.00")[0]
                            }
                          </InputAdornment>
                        ),
                      }}
                      className={classes.startIcon}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      minWidth: "18.666667%",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={p?.pre_selected}
                          onChange={(e) =>
                            props.updateOptionValues(
                              p?.id,
                              e.target.checked,
                              "pre_selected"
                            )
                          }
                          disabled={
                            p?.pre_selected === false &&
                            props?.maxSelectable === true &&
                            props?.maxSelectableCount <=
                              props?.currentSelectedCount
                          }
                          name="gilad"
                        />
                      }
                      label=""
                      className={classes.checkBoxLabel}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item>
                        <Typography className={classes.heading}>
                          <Edit className="column-drag-handle" />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.heading}>
                          <DeleteIcon
                            onClick={() =>
                              handleRemoveOption(p?.customisation_option_id)
                            }
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Draggable>
          );
        })}
    </Container>
  );
};

export default DragnDropItemCustomization;

const useStyles = makeStyles((theme) => ({
  startIcon: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
      background: "#EDF0F4",
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart ": {
      margin: 0,
    },
  },
  TextInputStyle3: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    minWidth: "50px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },
  bodyText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));
