import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../actions/auth";
import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import LoginPicture from "../../assets/image/loginPic.png";
import { useIntl } from "react-intl";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

export default function NewPassword() {
  const intl = useIntl();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  console.log("token", token);
  let dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errors, setErrors] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setErrors("");
  };
  const handlePassword1 = (event) => {
    setPassword1(event.target.value);
    setErrors("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && password !== null) {
      handleLogin();
    }
  };

  useEffect(() => {
    localStorage.AuthUserKey && history.push("RestaurantsGroups");
  }, [history]);

  const handleLogin = async () => {
    if (password.length < 10 || password1.length < 10) {
      setErrors("Password minimum length is 10");
    } else {
      if (password === password1) {
        setLoader(true);
        let res = await dispatch(
          resetPassword({ user: { token: token, password: password } })
        );
        setLoader(false);
        if (res?.status === 200) {
          history.push("/Login")
          setConfirm(true);
        } else {
          setErrors(
            res?.data?.password ? res?.data?.password : "Something went wrong"
          );
        }
      } else {
        setErrors("Entered password is not matched");
      }
    }
  };
  return (
    <Grid container style={{ height: "90px" }}>
      <Link href="/Login" variant="body2" style={{ textDecoration: "none" }}>
        <Grid container style={{ marginTop: "18px", marginLeft: "136px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowLeftIcon style={{ color: "#303A5F" }} />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography component="span" className={classes.backButton}>
              {" "}
              Go back
            </Typography>
          </Grid>
        </Grid>
      </Link>
      {!confirm ? (
        <Grid container className={classes.centerContainer}>
          <Grid container justify="center">
            <Grid container justify="center">
              <Typography component="span" className={classes.headingText}>
              Set your password
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography component="span" className={classes.body}>
              Keep this somewhere safe :)
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: "173px", maxWidth: "361px" }}
            >
              <Grid container style={{ paddingTop: "4px" }}>
                <Typography component="span" className={classes.title}>
                  New password
                </Typography>
                <TextField
                  onChange={handlePassword}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Enter new password "
                  type="password"
                  value={password}
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    border: "0px",
                  }}
                  className={classes.textField}
                />
              </Grid>
              <Grid container style={{ paddingTop: "4px" }}>
                <Typography component="span" className={classes.title}>
                  Confirm new password
                </Typography>
                <TextField
                  onChange={handlePassword1}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Enter confirm new password "
                  type="password"
                  value={password1}
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    border: "0px",
                  }}
                  className={classes.textField}
                />
              </Grid>

              {errors && <div className={classes.error}>{errors}</div>}
              {loader ? (
                <AcceptButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ width: "112px", marginTop: "37px" }}
                >
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                </AcceptButton>
              ) : (
                <AcceptButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => handleLogin()}
                  style={{ width: "112px", marginTop: "37px" }}
                >
                  {intl.formatMessage({
                    id: "Save",
                  })}
                </AcceptButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.centerContainer}>
          <Grid container justify="center">
            <Grid container justify="center">
              <Typography component="span" className={classes.headingText}>
                Successfully changed password!
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography component="span" className={classes.body}>
                Please login with your new password.
              </Typography>
            </Grid>
            <Grid container justify="center">
              <AcceptButton
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => history.push("Login")}
                style={{
                  width: "192px",
                  marginTop: "37px",
                  backgroundColor: "#A3A9BC",
                  border: "1px solid #A3A9BC",
                }}
              >
                {intl.formatMessage({
                  id: "Take me back",
                })}
              </AcceptButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: "6px",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#303A5F",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    marginTop: "30px",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  generalText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
}));
