import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from "../../actions/menu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 50,
    background: "#fff",
    maxHeight: "30px !important",
    // marginTop: "-6px",
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
    "& .MuiSelect-select.MuiSelect-select": {
      textTransform: "uppercase"
  }
  },

}));

export default function SimpleSelect() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [language, setLanguage] = React.useState("en");
  const languagesList = useSelector(
    state=>state?.menuReducer?.venueDetails?.supported_translations
  );
  console.log("languagesList============================>",languagesList)
  const languageReducer = useSelector(
    state=>state?.languageReducer?.language
  );
  useEffect(() => {
    setLanguage(
      languageReducer ? languageReducer : "en"
    );
  }, [languageReducer]);
  useEffect(() => {
    setLanguage(
      languageReducer ? languageReducer : "en"
    );
  }, []);
  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(updateLanguage({ data: event.target.value }));
    localStorage.setItem("language", event.target.value);
  };

  return (
    <div>
      <FormControl required className={classes.formControl}>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={language}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          {languagesList &&
            languagesList.length > 0 &&
            languagesList.map((item,index) => {
              return <MenuItem 
              style={{textTransform:"uppercase"}}
              key={index} value={item}>{item}</MenuItem>;
            })}
        </Select>
      </FormControl>
    </div>
  );
}
