import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signupUser } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import LoginPicture from "../../assets/image/loginPic.png";
import { useIntl } from "react-intl";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ReactComponent as VenueIcon } from "../../assets/image/venue.svg";
import { ReactComponent as HotelIcon } from "../../assets/image/hotel.svg";
import { ReactComponent as TickIcon } from "../../assets/image/tick.svg";
import { ReactComponent as MultiVendorIcon } from "../../assets/image/multi-vendor.svg";
import { HOSPITALITY_GROUP, HOTEL, MULTI_VENDOR } from "../../utils/constants";
export default function Signup(props) {
  const intl = useIntl();

  let dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(false);
  const [type, setType] = useState(null);
  const classes = useStyles();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    localStorage.AuthUserKey && history.push("RestaurantsGroups");
  }, [history]);

  const handleLogin = async () => {
    if (type !== null) {
      props.setType(type);

      props.setCurrentScreen("signup");
    }
  };
  useEffect(() => {
    setType(props.type);
  }, []);
  const handleChangeType = (e) => {
    setType(e);
  };
  return (
    <Grid container style={{ height: "90px" }}>
      <Link href="/Login" variant="body2" style={{ textDecoration: "none" }}>
        <Grid container style={{ marginTop: "18px", marginLeft: "136px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowLeftIcon style={{ color: "#303A5F" }} />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography component="span" className={classes.backButton}>
              Go back
            </Typography>
          </Grid>
        </Grid>
      </Link>
      <Grid container justify="center">
        <Grid item>
          <Container component="main" style={{ width: "80vw" }}>
            <CssBaseline />
            <div className={classes.paper}>
              <Typography className={classes.generalText} component="span">
                {intl.formatMessage({
                  id: "choose_account_type",
                  defaultMessage: "Choose account type",
                })}
              </Typography>
              <Typography className={classes.subHeading} component="span">
                {intl.formatMessage({
                  id: "change_this_later",
                  defaultMessage: "You can always change this later",
                })}
              </Typography>
              <Grid container className={classes.form} noValidate>
                <Grid container spacing={3} justify="space-around">
                  <Grid
                    item
                    className={
                      type === HOSPITALITY_GROUP
                        ? classes.selectedRootCard
                        : classes.cardRoot
                    }
                    onClick={() => handleChangeType(HOSPITALITY_GROUP)}
                  >
                    <Grid container justify="flex-end">
                      <div
                        className={
                          type === HOSPITALITY_GROUP
                            ? classes.selectedIconGrid
                            : classes.iconGrid
                        }
                      >
                        {type === HOSPITALITY_GROUP && <TickIcon />}
                      </div>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      className={classes.imgGrid}
                    >
                      <VenueIcon />
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.title}>
                        Independent venue
                      </Typography>
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.description}>
                        You can create multiple venues under the same account
                        but each venue is independent. Ideal for single
                        restaurants or restaurant chains with different
                        locations.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    onClick={() => handleChangeType(HOTEL)}
                    className={
                      type === HOTEL
                        ? classes.selectedRootCard
                        : classes.cardRoot
                    }
                  >
                    <Grid container justify="flex-end">
                      <div
                        className={
                          type === HOTEL
                            ? classes.selectedIconGrid
                            : classes.iconGrid
                        }
                      >
                        {type === HOTEL && <TickIcon />}
                      </div>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      className={classes.imgGrid}
                    >
                      <MultiVendorIcon />
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.title}>
                        Multi vendor
                      </Typography>
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.description}>
                        Create multiple independent venues that share a common
                        landing page. Ideal for large venues that want to
                        separate restaurant and bar areas.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    onClick={() => handleChangeType(MULTI_VENDOR)}
                    className={
                      type === MULTI_VENDOR
                        ? classes.selectedRootCard
                        : classes.cardRoot
                    }
                  >
                    <Grid container justify="flex-end">
                      <div
                        className={
                          type === MULTI_VENDOR
                            ? classes.selectedIconGrid
                            : classes.iconGrid
                        }
                      >
                        {type === MULTI_VENDOR && <TickIcon />}
                      </div>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      className={classes.imgGrid}
                    >
                      <HotelIcon />
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.title}>Hotel</Typography>
                    </Grid>
                    <Grid container justify="center">
                      <Typography className={classes.description}>
                        Digitise all menus in a hotel including room service.
                        Hotel login feature identifies hotel guests throughout
                        their stay.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  style={{ margin: "48px 0px 0px 0px" }}
                >
                  <Typography className={classes.text}>
                    Each venue within an account can be setup as <b>View Only</b> or
                   <b> View & Order</b>.
                  </Typography>
                </Grid>
                <Grid container justify="center">
                  <AcceptButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => handleLogin()}
                    style={{
                      width: "159px",
                      marginTop: "37px",
                      height: "40px",
                    }}
                  >
                    {intl.formatMessage({
                      id: "Continue",
                    })}
                  </AcceptButton>
                </Grid>
              </Grid>
            </div>
            <Box mt={8}></Box>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  iconGrid: {
    height: "30px",
    width: "30px",
    border: "2px solid #D8DCE9",
    borderRadius: "50%",
    margin: "15px",
  },
  selectedIconGrid: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    background: "#34CEB2",
    margin: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
  },
  imgGrid: {
    margin: "11px 0px 25px 0px",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#303A5F",
    padding: "10px 20px 0px 20px",
    textAlign: "center",
  },
  selectedRootCard: {
    background: "#FFFFFF",
    border: "3px solid #34CEB2",
    boxSizing: "border-box",
    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    maxWidth: "298px",
    height: "298px",
    marginTop: "20px",
    padding: "0px !important",
    cursor:"pointer"
  },
  cardRoot: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    maxWidth: "298px",
    height: "298px",
    marginTop: "20px",
    padding: "0px !important",
    cursor:"pointer"
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#303A5F",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },

  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
    textDecoration: "none",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  generalText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    marginBottom: "90px",
  },
}));
