import React from "react";
import Grid from "@material-ui/core/Grid";
import Intro1 from "../../assets/image/intro1.png";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as SteperSelected } from "../../assets/image/steperSelected.svg";
import { ReactComponent as Steper } from "../../assets/image/stepper.svg";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

export default function Login(props) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh",maxWidth:"100vw",overflowX:"hidden" }}
    >
      <Grid container justify="center">
        <Grid container justify="center">
          <Typography component="span" className={classes.heading}>
            Getting started
          </Typography>
        </Grid>{" "}
        <Grid container justify="center">
          <img
            // src={Intro1}
            src={props.image}

            style={{
              // height: "387.14px",
              height: "257.14px",
              borderRadius: "15px",
            }}
          />
        </Grid>
        <Grid container justify="center" style={{maxWidth:"260px",marginTop: "25px" }}>
          <Typography component="span" className={classes.body}>
            Setup your online menu with ease. Instant updates at anytime
          </Typography>
        </Grid>
        <Grid container justify="center" spacing={1} style={{maxWidth:"100vw", marginTop: "17px", marginBottom: "31px" }}>
          <Grid item>
            <SteperSelected  style={{cursor:"pointer"}} onClick={() => props.handleChangePage(0)}/>
          </Grid>
          <Grid item>
            <Steper  style={{cursor:"pointer"}} onClick={() => props.handleChangePage(1)}/>
          </Grid>
          <Grid item>
            <Steper  style={{cursor:"pointer"}} onClick={() => props.handleChangePage(2)}/>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <AcceptButton
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
           onClick={() => props.handleChangePage(1)}
            style={{ width: "266px"}}
          >
            {intl.formatMessage({
              id: "Next",
            })}
          </AcceptButton>
        </Grid>
        <Grid container justify="center">
          <Typography component="span" className={classes.skip}
          onClick={()=>history.push("/Login")}
          >
            Skip to login
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  skip: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
    marginTop: "19px",
    marginBottom: "55px",
    cursor:"pointer"
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#080808",
    marginTop:"34px",
    marginBottom:"27px"
  },

  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#303A5F",
  },
  root: {
    flexGrow: 1,
  },
}));
