import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getSearch,
  getNextSearchTab,
  getAllTabs,
  getTabOrdersList,
} from "../../actions/order";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import "./TabOrders.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import PreviousOrderDialog from "../../Components/PreviousOrderDialog";
import moment from "moment";
import { useIntl } from "react-intl";

function TabOrders(props) {
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let tabOrders = useSelector((state) => state.VenueOrderReducer.tabOrders);
  let dispatch = useDispatch();
  const [ordersListToDisplay, setDatavalue] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (tabOrders?.orders) {
      const ordersList = [...tabOrders?.orders];
      let newArr = [];
      console.log(ordersList);
      ordersList.forEach((element) => {
        if (element?.submitted_for_review) {
          newArr.push({
            ...element,
            id: element?.reference_id,
            is_paid: element?.payment_method,
            order_price: element?.order_price,
            submitted_for_review: element?.submitted_for_review,
            status: element.status,
          });
        }
      });
      setAllOrders([...newArr]);
      setDatavalue(newArr.slice(0, 20));
    }

    // eslint-disable-next-line
  }, [tabOrders]);
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [props?.OrderSelected, venueDetail]);
  const getData = async () => {
    if (props?.OrderSelected?.id && venueDetail?.uuid) {
      setLoadingData(true);
      let res = await dispatch(
        getTabOrdersList({
          venue_uuid: venueDetail?.uuid,
          id: props?.OrderSelected?.id,
        })
      );
      if (res) {
        setLoadingData(false);
      } else {
        setLoadingData(false);
      }
    }
  };
  const onRefresh = () => {
    console.log("refreshed");
  };
  const fetchData = () => {
    setLoader(true);
    setTimeout(() => {
      setDatavalue([
        ...ordersListToDisplay,
        ...allOrders.slice(
          ordersListToDisplay?.length,
          ordersListToDisplay.length + 10
        ),
      ]);

      setLoader(false);
    }, 2000);
  };

  const handleSelectOrder = (n) => {
    setSelectedOrder(n);
    setOpen(true);
  };
  return (
    <>
      <PreviousOrderDialog
        open={open}
        setOpen={setOpen}
        OrderSelected={selectedOrder}
        tabName={props?.OrderSelected?.name}
        currency={venueDetail && venueDetail.currency}
        from="tab"
        venue_uuid={ venueDetail?.uuid}

      />
      <div>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            {!loadingData ? (
              <div className={classes.TableGrid}>
                <Grid container className={classes.headingContainer}>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.headingText}>
                      Time
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.headingText}>
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Typography className={classes.headingText}>
                      Payment status
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Typography className={classes.headingText}>
                      Order status
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "7px",
                    }}
                  >
                    <Typography className={classes.headingText}>
                      Total
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #EDF0F4",
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    marginTop: "20px",
                    height: "55vh",
                    overflow: "auto",
                  }}
                  className="hideScroll"
                  id="scrollableDiv"
                >
                  {ordersListToDisplay?.length === 0 ? (
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item>
                        <Typography className={classes.emptyOrders}>
                          No Orders in this Tab
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <InfiniteScroll
                      style={{ width: "100%" }}
                      dataLength={ordersListToDisplay.length}
                      next={fetchData}
                      hasMore={true}
                      loader={
                        loader && (
                          <Grid
                            container
                            justify="center"
                            style={{ height: "70px" }}
                          >
                            <CircularProgress size={40} color="primary" />
                          </Grid>
                        )
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                      refreshFunction={onRefresh}
                      pullDownToRefresh
                      pullDownToRefreshThreshold={50}
                      // pullDownToRefreshContent={
                      //   <h3 style={{ textAlign: "center", }}>
                      //     &#8595; Pull down to refresh
                      //   </h3>
                      // }
                      releaseToRefreshContent={
                        <h3 style={{ textAlign: "center" }}>
                          &#8593; Release to refresh
                        </h3>
                      }
                      className={classes.infiniteScroll}
                    >
                      <Grid container style={{}}>
                        {ordersListToDisplay.map((n) => {
                          return (
                            <Grid
                              container
                              key={n.id}
                              className={classes.tableRow}
                              onClick={() => handleSelectOrder(n)}
                              style={{ cursor: "pointer" }}
                            >
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography className={classes.headingText}>
                                  {n?.submitted_for_review
                                    ? moment(n?.submitted_for_review).format(
                                        "HH:mm"
                                      )
                                    : "-"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography className={classes.TableCellText}>
                                  {n?.id}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography className={classes.TableCellText}>
                                  {n?.is_paid === "card" ? (
                                    <Typography className={classes.paidButton}>
                                      {intl.formatMessage({
                                        id: "Paid",
                                        defaultMessage: "Paid",
                                      })}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className={classes.notPaidButton}
                                    >
                                      {intl.formatMessage({
                                        id: "Not_paid",
                                        defaultMessage: "Not Paid",
                                      })}
                                    </Typography>
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography className={classes.TableCellText}>
                                  {n?.status === 3
                                      ?<Typography className={classes.accepted}>
                                    { intl.formatMessage({
                                          id: "Accepted",
                                          defaultMessage: "Paid",
                                        })}</Typography>
                                      : <Typography className={classes.rejected}> {intl.formatMessage({
                                          id: "Rejected",
                                          defaultMessage: "Paid",
                                        })}

                                  </Typography>}
                                  
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  paddingRight: "7px",
                                }}
                              >
                                <Typography className={classes.TableCellText}>
                                  {`${parseFloat(n?.order_price).toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency:
                                        venueDetail && venueDetail.currency,
                                    }
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </InfiniteScroll>
                  )}
                </Grid>
              </div>
            ) : (
              <Grid container justify="center">
                <CircularProgress size={40} color="primary" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default TabOrders;

const useStyles = makeStyles((theme) => ({
  accepted: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    paddingLeft:"5px"
  },
  rejected: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#FF3366",
    paddingLeft:"5px"

  },
  emptyOrders: {
    color: "#303A5F",
    fontSize: "20px",
    fontStyle: "normal",
    fontFamily: theme.fontFamily,
    fontWeight: "bold",
    lineHeight: "18px",
    paddingTop: "20px",
  },
  paidButton: {
    background: "#F66761",
    borderRadius: "2px",
    width: "70px",
    height: "28px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notPaidButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#6772E5",
    borderRadius: "2px",
    width: "70px",
    height: "28px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  infiniteScroll: {
    "& > .infinite-scroll-component__outerdiv": {
      width: "100% !important",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "88vh",
    paddingTop: "22px",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: "#303A5F",
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },
  printButton: {
    marginTop: "18px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 26px",
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
    // height: "60vh",
  },
  TableGrid: {
    overflowY: "auto",
    // height: "60vh",
    margin: "5px 0px 0px 0px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: "#303A5F",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
}));
