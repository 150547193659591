import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

import MainBar from "../Components/Restaurants/MainBar";
import { makeStyles } from "@material-ui/core";
import { useLocation,useHistory,useParams } from "react-router-dom";

import {
  getVenueOrder,
  getOrderHistory,
  getVenueDetail,
  getAllTabs,
} from "../actions/order";
import "../style/home.css";
import TabBar from "./Headers/TabBar";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#F9FAFC",
  },
}));
function Home() {
  let location = useLocation();
  const intl = useIntl();
  const params = useParams();
  const history = useHistory();

  const [fetchApi, setfetchApi] = useState(null);
  let dispatch = useDispatch();
  const [tabType, setTabType] = useState(0);

  let venueDetail = useSelector(
    (state) => state?.VenueOrderReducer?.VenueDetail
  );
  let user = useSelector(
    (state) => state?.VenueOrderReducer?.UserDetail?.user

  );
 useEffect(() => {
    if(params?.id&&params?.venueid){
    dispatch(
      getVenueOrder({
        venue_uuid: params?.venueid,
        Authorization: localStorage.AuthUserKey,
      })
    );
    dispatch(
      getOrderHistory({
        venue_uuid: params?.venueid,
        Authorization: localStorage.AuthUserKey,
      })
    );}else{
      history.push("/RestaurantsGroups")
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tabType === 0) {
      const timer = setTimeout(() => {
        setfetchApi(true);
        dispatch(
          getVenueOrder({
            venue_uuid: params?.venueid,
            Authorization: localStorage?.AuthUserKey,
          })
        );

       }, 3000);
      setfetchApi(false);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [fetchApi, tabType]);
  const [fullScreenView, setfullScreenView] = useState(false);
  const classes = useStyles();
  useEffect(() => {
   dispatch(
      getVenueDetail({
        venue_uuid: params?.venueid,
        slug: params?.id,
      })
    );
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className={classes.root}>
      {!fullScreenView && <MainBar venueDetail={venueDetail} />}
      <TabBar
        fullScreenView={fullScreenView}
        setfullScreenView={setfullScreenView}
        setTabType={setTabType}
        // setCurrentTab={setCurrentTab}
      />
    </div>
  );
}

export default Home;
