const messages = {
  Login_to_access_Dashboard: "Συνδεθείτε για πρόσβαση στον Πίνακα ελέγχου",
  Email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
  Your_Enter_Email_or_Password_Is_wrong_try_again:
    "Το Enter Email ή ο κωδικός πρόσβασής σας είναι λάθος Δοκιμάστε ξανά",
  Remember_me: "Θυμήσου με",
  Forgot_password: "Ξεχάσατε τον κωδικό?",
  no_img: "όχι img",
  Add_image: "Προσθέστε εικόνα",
  upload: "μεταφόρτωση",
  Orders: "Παραγγελίες",
  Tab_verified: "Η καρτέλα επαληθεύτηκε",
  Choose_availability_of: "Επιλέξτε μη διαθεσιμότητα του",
  available_from: "Μη διαθέσιμο από",
  available_until: "Μη διαθέσιμο έως",
  Ok: "Εντάξει",
  Reject_order: "Απόρριψη παραγγελίας",
  Are_you_sure_you_want_to_reject_the_order_number:
    "Είστε βέβαιοι ότι θέλετε να απορρίψετε τον αριθμό παραγγελίας",
  Cancel: "Ματαίωση",
  prevent_customers:
    "Αυτό θα κλείσει την καρτέλα και θα αποτρέψει τους πελάτες από την προσθήκη περαιτέρω παραγγελιών μέσω του συνδέσμου καρτέλας.",
  Verify_tab: "Επαλήθευση καρτέλας",
  Tab_name: "Όνομα καρτέλας",
  Table_number: "Αριθμός πίνακα",
  Verify: "Επαληθεύω",
  Decline_tab: "Απόρριψη καρτέλας",
  confirm_decline_tab_order:
    "Είστε βέβαιοι ότι θέλετε να απορρίψετε τη σειρά καρτελών",
  automatically_Reject_order:
    "Αυτό θα απορρίψει αυτόματα όλες τις εκκρεμείς παραγγελίες αυτής της καρτέλας.",
  Decline: "Πτώση",
  Edit_guest_session: "Επεξεργασία συνεδρίας επισκέπτη",
  Create_guest_session: "Δημιουργία συνεδρίας επισκέπτη",
  Room_number: "Αριθμός δωματίου",
  Enter_room_number: "Εισαγάγετε τον αριθμό δωματίου",
  Field_is_required: "Απαιτείται πεδίο",
  Guest_email: "Email επισκέπτη",
  Enter_Email: "Εισάγετε ηλεκτρονική διεύθυνση",
  Check_in: "Άφιξη",
  Invalid_Check_in_range: "Μη έγκυρο εύρος check-in",
  Check_out: "Ολοκλήρωση παραγγελίας",
  Expires_here: "Λήγει εδώ ..",
  Guest_session_link: "Σύνδεσμος συνεδρίας επισκέπτη",
  Link_creation: "Ο σύνδεσμος θα δημιουργηθεί κατά τη δημιουργία",
  No_date_Available: "Δεν υπάρχει ημερομηνία",
  Link_copied: "Ο σύνδεσμος αντιγράφηκε",
  send_session_link:
    "Στείλτε σύνδεσμο συνεδρίας στον επισκέπτη κατά τη δημιουργία",
  Delete: "Διαγράφω",
  Save: "Σώσει",
  Create: "Δημιουργώ",
  Some_Error_Happen_Try_again: "Παρουσιάστηκε κάποιο σφάλμα, δοκιμάστε ξανά",
  prevent_join_session_message:
    "Αυτό θα κλείσει τη συνεδρία και θα εμποδίσει τους πελάτες να προσθέσουν περαιτέρω παραγγελίες μέσω του συνδέσμου περιόδου σύνδεσης.",
  Username: "Όνομα χρήστη",
  Profile: "Προφίλ",
  Login: "Σύνδεση",
  SignOut: "Αποσύνδεση",
  Your_Incoming_Order: "Η εισερχόμενη παραγγελία σας",
  Order_history: "Ιστορικό παραγγελιών",
  Manage_Tabs: "Διαχείριση καρτελών",
  Menu_availability: "Διαθεσιμότητα μενού",
  Menu_manager: "Διαχειριστής μενού",
  Reports: "Αναφορές",
  Venue_settings: "Ρυθμίσεις χώρου",
  choose_customization:
    "Επιλέξτε την υπάρχουσα ομάδα προσαρμογής ή δημιουργήστε μια νέα",
  confirm_delete_item:
    "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το στοιχείο?",
  delete_item_message:
    "Αυτό θα καταργήσει όλες τις πληροφορίες και τις προσαρμογές που επισυνάπτονται σε αυτό το στοιχείο.",
  Delete_item: "Διαγραφή αντικειμένου",
  Required: "Απαιτείται",
  Not_more_than_1: "Όχι περισσότερο από 1",
  Item_name: "Ονομα προϊόντος",
  Item_Name_is_required: "Απαιτείται όνομα στοιχείου",
  Description: "Περιγραφή",
  Price: "Τιμή",
  Price_is_required: "Απαιτείται τιμή",
  Price_description: "Περιγραφή τιμής",
  Image: "Εικόνα",
  Add_Image: "Προσθέστε εικόνα",
  Customization: "Προσαρμογή",
  Add_New: "Προσθεσε νεο",
  Add_item: "Πρόσθεσε είδος",
  Create_new_tag: "Δημιουργία νέας ετικέτας",
  Add: "Προσθήκη",
  Mark_item_as: "Επισήμανση στοιχείου ως",
  Edit_tag: "Επεξεργασία ετικέτας",
  Tag_name: "Όνομα ετικέτας",
  Input_name_of_tag: "Εισαγωγή ονόματος ετικέτας",
  Tag_color: "Χρώμα ετικέτας",
  Color_is_Required: "Απαιτείται χρώμα",
  Tag_icon: "Εικονίδιο ετικέτας",
  optional: "προαιρετικός",
  Edit_category: "Επεξεργασία κατηγορίας",
  Name: "Ονομα",
  Category_name: "Ονομα κατηγορίας...",
  Are_you_sure_you_want_to_delete: "Είστε σίγουροι ότι θέλετε να διαγράψετε",
  remove_customization_from_item:
    "Αυτό θα καταργήσει την προσαρμογή από το στοιχείο.",
  remove_alert_message_menu:
    "Αυτό θα αφαιρέσει όλες τις πληροφορίες που επισυνάπτονται σε αυτό το μενού.",
  remove_alert_message_category:
    "Αυτό θα αφαιρέσει όλες τις πληροφορίες που επισυνάπτονται σε αυτήν την κατηγορία.",
  Apply_the_changes: "Εφαρμόστε τις αλλαγές",
  customisation_belongs_items:
    "Αυτή η προσαρμογή ανήκει επίσης στα παρακάτω στοιχεία",
  and: "και",
  more: "περισσότερο",
  item: "είδος",
  items: "αντικείμενα",
  confirm_update_all: "Θέλετε να ενημερώσετε όλα τα στοιχεία?",
  Update_this_item_only: "Ενημέρωση μόνο αυτού του στοιχείου",
  Update_all: "Ενημέρωση όλων",
  Option_name: "Όνομα επιλογής",
  Something_went_wrong: "Κάτι πήγε στραβά",
  Edit_customization: "Επεξεργασία προσαρμογής",
  New_customization: "Νέα προσαρμογή",
  Customization_title: "Τίτλος προσαρμογής",
  eg_sides: "Π.χ. πλευρές",
  Rules: "Κανόνες",
  show_separate_options:
    "Εμφάνιση επιλεγμένων επιλογών ως ξεχωριστών στοιχείων στις εισερχόμενες παραγγελίες",
  required_selection: "Απαιτήστε από τους πελάτες να επιλέξουν ένα είδος",
  mex_selection_title:
    "Ποιο είναι το μέγιστο ποσό αντικειμένων που μπορούν να επιλέξουν οι πελάτες?",
  Option: "Επιλογή",
  PreSelected: "Προεπιλεγμένο",
  Edit_menu: "Μενού επεξεργασίας",
  Add_new_menu: "Προσθήκη νέου μενού",
  Menu_name: "Όνομα μενού ...",
  Orders_enabled: "Οι παραγγελίες ενεργοποιήθηκαν",
  Orders_disabled: "Οι παραγγελίες απενεργοποιήθηκαν",
  No_pending_orders: "Δεν υπάρχουν εκκρεμείς παραγγελίες",
  Pending_orders: "Εκκρεμείς παραγγελίες",
  Logout: "Αποσύνδεση",
  Enter_table_number: "Εισαγάγετε τον αριθμό πίνακα",
  customer_name: "Εισαγάγετε όνομα π.χ. όνομα πελάτη",
  Expires_at: "Λήγει στις",
  Verified: "Επαληθεύτηκε",
  None: "None",
  Yes: "Ναί",
  No: "Οχι",
  Password: "Κωδικός πρόσβασης",
  Enter_password: "Εισάγετε τον κωδικό πρόσβασης",
  Customer_tab_link: "Σύνδεσμος καρτέλας πελάτη",
  generated_link: "Ο σύνδεσμος θα δημιουργηθεί κατά τη δημιουργία της καρτέλας",
  Copy: "αντίγραφο",
  Share_link_via_phone: "Κοινή χρήση συνδέσμου μέσω τηλεφώνου",
  Phone_Number: "Τηλεφωνικό νούμερο",
  Share_link_via_email: "Κοινή χρήση συνδέσμου μέσω email",
  Send_tab_link: "Αποστολή συνδέσμου καρτέλας στον πελάτη",
  Settle_tab: "Ρύθμιση καρτέλας",
  Some_Error_Happen: "Παρουσιάστηκε κάποιο σφάλμα, δοκιμάστε ξανά",
  Search: "Αναζήτηση",
  Create_Tab: "Δημιουργία καρτέλας",
  No_tab_message: "Δεν υπάρχει καρτέλα σε αυτήν την περίοδο",
  Tab_orders: "Παραγγελίες καρτέλας",
  Tab_details: "Λεπτομέρειες καρτέλας",
  Paid: "επί πληρωμή",
  tip: "Υπόδειξη",
  Not_paid: "Απληρωτος",
  Total: "Σύνολο",
  Out_Of_Stock: "Μη διαθέσιμο",
  Hide: "Hide",
  Available_until_from: "Μη διαθέσιμο από - έως",
  edit_menu_availability:
    "Προτού μπορέσετε να επεξεργαστείτε τη διαθεσιμότητα του μενού σας",
  create_your_first_menu: "πρέπει πρώτα να δημιουργήσετε το μενού σας",
  Take_me_there: "Πήγαινέ με εκεί",
  Delete_Table: "Διαγραφή πίνακα",
  Add_table_number: "Προσθήκη αριθμού πίνακα",
  Edit_table_number: "Επεξεργασία αριθμού πίνακα",
  Enter_name: "Εισαγάγετε όνομα",
  Order_is_going_to_removed: "Η παραγγελία θα καταργηθεί από τη λίστα",
  ACCEPTED: "ΑΠΟΔΕΚΤΟ",
  Rejected: "Απορρίφθηκε",
  Request: "Αίτηση",
  Selected_Order: "Επιλεγμένη παραγγελία",
  na: "α / α",
  payment_Status: "κατάσταση πληρωμής",
  Order_Note: "Σημείωση παραγγελίας",
  Subtotal: "ΜΕΡΙΚΟ ΣΥΝΟΛΟ",
  Reject: "Απορρίπτω",
  Tip: "Υπόδειξη",
  Accept: "Accept",
  Accepted: "Αποδεκτό",
  Bill_request_Pay_by: "Αίτημα λογαριασμού - Πληρωμή έως",
  Service_request: "Αίτηση υπηρεσίας",
  Tab_Created: "Δημιουργήθηκε καρτέλα",
  Select_an_order_to_view_it: "Επιλέξτε μια παραγγελία για να τη δείτε.",
  Link: "Σύνδεσμος",
  Copied: "Αντιγράφηκε",
  No_Tab_in_this_period: "Δεν υπάρχει καρτέλα σε αυτήν την περίοδο",
  Search_item_category_customization:
    "Αναζήτηση αντικειμένου, κατηγορίας ή προσαρμογής ...",
  Add_new_category: "Προσθήκη νέας κατηγορίας",
  Add_new_item: "Προσθήκη νέου αντικειμένου",
  adding_your_menu:
    "Ξεκινήστε προσθέτοντας το μενού σας και μετά τις κατηγορίες σας",
  individual_items: "στη συνέχεια τα μεμονωμένα αντικείμενα σας.",
  Edit_tab: "Επεξεργασία καρτέλας",
  Create_new_tab: "Δημιουργία νέας καρτέλας",
  Custom: "Εθιμο",
  Today: "Σήμερα",
  Yesterday: "Εχθές",
  This_Week: "Αυτές τις εβδομάδες",
  Last_Week: "Την προηγούμενη εβδομάδα",
  This_Month: "Αυτο το μηνα",
  Last_Month: "Τον προηγούμενο μήνα",
  Close: "Κλείσε",
  Order_History: "Ιστορικό παραγγελιών",
  Custom_Dates: "Προσαρμοσμένες ημερομηνίες",
  Select_date_and_time: "Επιλέξτε ημερομηνία και ώρα",
  FIXED_DATES: "ΗΜΕΡΟΜΗΝΙΕΣ",
  Date: "Ημερομηνία",
  Order_ID: "Αριθμός Παραγγελίας",
  Table_Number: "Αριθμός πίνακα",
  Payment_Status: "Κατάσταση πληρωμής",
  Sub_Total: "ΜΕΡΙΚΟ ΣΥΝΟΛΟ",
  Total_Payment: "Συνολική πληρωμή",
  Status: "Κατάσταση",
  Declined: "Απορρίφθηκε",
  No_Order_in_period: "Χωρίς παραγγελία σε αυτήν την περίοδο",
  View_previous_order: "Προβολή προηγούμενης παραγγελίας",
  Select_date_for_reports:
    "Επιλέξτε Προσαρμοσμένη ημερομηνία ή Σταθερή ημερομηνία για προβολή των Αναφορών",
  creating_stripe_credit_card:
    "Η δημιουργία λογαριασμού Stripe επιτρέπει τη λήψη παραγγελιών μέσω πιστωτικής κάρτας",
  IDEAL_Bancontact: "IDEAL, Bancontact",
  Stripe_Connected: "Συνδεδεμένη λωρίδα",
  Connect_Stripe: "Συνδέστε τη λωρίδα",
  Register_account_and_agree:
    "Με την εγγραφή του λογαριασμού σας, αποδέχεστε το",
  Stripe_Services_Agreement: "Σύμβαση υπηρεσιών Stripe",
  and_the: "και το",
  Stripe_connected_Account_Agreement: "Σύμβαση λογαριασμού με σύνδεση Stripe",
  unavailable_feature:
    "Αυτή η δυνατότητα είναι διαθέσιμη για μενού με λειτουργικότητα παραγγελίας.",
  Contact_us: "Επικοινωνήστε μαζί μας",
  upgrade_your_menu: "για να αναβαθμίσετε το μενού σας.",
  Venue_details: "Λεπτομέρειες χώρου",
  Order_settings: "Ρυθμίσεις παραγγελίας",
  Table_numbers: "Αριθμοί πίνακα",
  QR_code: "Κωδικός QR",
  Venues: "Χώροι",
  Hotel_guests: "Οι επισκέπτες του ξενοδοχείου",
  Hotel_profile: "Προφίλ ξενοδοχείου",
  Hotel_QR_code: "Κωδικός QR ξενοδοχείου",
  Show_active_sessions: "Εμφάνιση ενεργών συνεδριών",
  Show_up_coming: "Εμφάνιση επερχόμενων",
  Create_guest_session: "Δημιουργία συνεδρίας επισκέπτη",
  You_have_seen_it_all: "Ναι! Τα έχετε δει όλα",
  Release_to_refresh: "Αφήστε για ανανέωση",
  Logo_image: "Εικόνα λογοτύπου",
  App_icon: "Εικονίδιο εφαρμογής",
  Hotel_theme_color: "Χρώμα θέματος ξενοδοχείου",
  Select_Color: "Επιλέξτε Χρώμα",
  Download_QR_code_image: "Λήψη εικόνας κωδικού QR",
  auto_accept_orders: "Αυτόματη αποδοχή παραγγελιών",
  auto_accept_orders_from_tab:
    "Αυτόματη αποδοχή παραγγελιών από επαληθευμένες καρτέλες",
  Request_waiter_service: "Ζητήστε υπηρεσία σερβιτόρου",
  allow_waiters_service:
    "Επιτρέψτε στους πελάτες να ζητούν υπηρεσία σερβιτόρου από το μενού",
  Payment_options: "Επιλογές πληρωμής",
  Payment_Disabled: "Η πληρωμή απενεργοποιήθηκε",
  Payment_Optional: "Προαιρετική πληρωμή",
  Payment_Mandatory: "Υποχρεωτική πληρωμή",
  Payment_provider: "Πάροχος πληρωμών",
  Venue_name: "Όνομα χώρου",
  Invalid_color_code: "Μη έγκυρος κωδικός χρώματος",
  Select_Color: "Επιλέξτε Χρώμα",
  Venue_theme_color: "Χρώμα θεματικού χώρου",
  Cover_photo: "Φωτογραφία εξωφύλλου",
  Logo_image: "Εικόνα λογοτύπου",
  Venue_description: "Περιγραφή τόπου",
  Table_name: "Όνομα πίνακα",
  QR_Code: "Κωδικός QR",
  Add_new_table: "Προσθήκη νέου πίνακα",
  add_new_language: "Προσθέστε νέα γλώσσα",
  language: "Γλώσσα",
  edit_language: "Επεξεργασία γλώσσας",
  delete_language: "Διαγραφή γλώσσας",
  Add_Language: "Προσθήκη γλώσσας",
  Localization:"Εντοπισμός"

};

export default messages;
