import React, { useEffect, useState } from "react";
import {
  getVenueDetail,
  removeVenueDetails,
  updateVenueOrdersStatus,
} from "../../actions/order";
import { setMenuManagerLanguage } from "../../actions/Languages";

import EditIcon from "@material-ui/icons/Edit";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Plus } from "../../assets/image/plus.svg";
// import { updateReviewOrder } from "../actions/order";
import { Grid, Card, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./menubar.css";
import { useIntl } from "react-intl";
import VenueDialog from "../Venue/VenueDialog";
function Restaurants() {
  const classes = useStyles();
  const intl = useIntl();
  const [openAddVenue, setOpenAddVenue] = useState(false);
  const [venuesList, setVenuesList] = useState([]);
  const history = useHistory();
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);
  let pendingOrders = useSelector(
    (state) => state?.pendingOrdersReducer?.pendingOrders
  );

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(removeVenueDetails());

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    try {
      if (UserDetail?.venues) {
        let venuesPermissions = UserDetail?.user_venue_permissions;
        let arr = [];
        UserDetail.venues.forEach((element) => {
          for (let j = 0; j < venuesPermissions.length; j++) {
          
          let index = venuesPermissions[j].venues.findIndex(
            (i) => i === element.id
          );
          if (index > -1) {
            arr.push(element);
          }
        }
        });
        setVenuesList([...arr]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [UserDetail?.venues]);

  
  const findOrders = (id) => {
    let ind = pendingOrders?.findIndex((n) => n.id === id);
    if (ind > -1) {
      return pendingOrders[ind]?.pending_orders;
    } else {
      return 0;
    }
  };
  const handleUpdateVenueAcceptsOrders = (status, venue_uuid, slug) => {
    let obj = { is_currently_accepting_orders: status };

    dispatch(
      updateVenueOrdersStatus({ venue_uuid: venue_uuid, slug: slug, body: obj })
    );
  };
  const handleOpenAddVenue = () => {
    setOpenAddVenue(true);
  };
  const handleCloseAddVenue = () => {
    setOpenAddVenue(false);
  };
  return (
    <div
      style={{ background: "#F9FAFC", height: "82vh", overflow: "auto" }}
      className={classes.customScrollBar}
    >
      {openAddVenue && (
        <VenueDialog open={openAddVenue} handleClose={handleCloseAddVenue} />
      )}
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid
            container
            // justify="space-between"
            spacing={2}
            style={{ paddingTop: "40px" }}
          >
            {venuesList &&
              venuesList.map((venue, i) => (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Card className={classes.fullCard}>
                      <Grid
                        container
                        justify="flex-end"
                        onClick={() => {
                          dispatch(
                            getVenueDetail({
                              venue_uuid: venue.uuid,
                              slug: venue.slug,
                            })
                          );

                          history.push({
                            pathname: `/venue/${venue.slug}/${venue?.uuid}/`

                          });
                        }}
                      >
                        <Grid item className={classes.editButtonSpace}>
                          {findOrders(venue?.id) > 0 ? (
                            <Card className={classes.editButton}>
                              <Typography className={classes.ordersCount}>
                                {findOrders(venue?.id)}
                              </Typography>
                            </Card>
                          ) : (
                            <Card className={classes.editButton2}>
                              <Typography
                                className={classes.ordersCount}
                              ></Typography>
                            </Card>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        className={classes.TextSpace}
                        onClick={() => {
                          dispatch(
                            getVenueDetail({
                              venue_uuid: venue.uuid,
                              slug: venue.slug,
                            })
                          );
                          dispatch(setMenuManagerLanguage(null));

                          history.push({
                            pathname: `/venue/${venue.slug}/${venue?.uuid}/`,
                           
                          });
                        }}
                      >
                        <p className={classes.VenaeName}>{venue.name}</p>
                      
                      </Grid>
                      <Grid
                        item
                        style={{
                          backgroundColor: "#44507B",
                          maxHeight: "72px",
                          paddingLeft: "20px",
                        }}
                      >
                        <Grid container justify="space-between">
                          <Grid item className={classes.centerAlign}>
                            <Typography className={classes.footerText}>
                              {venue?.read_only_menu
                                ? ""
                                : venue?.is_currently_accepting_orders
                                ? intl.formatMessage({
                                    id: "Orders_enabled",
                                  })
                                : intl.formatMessage({
                                    id: "Orders_disabled",
                                  })}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            className={classes.centerAlign}
                            style={{
                              justifyContent: "flex-end",
                              marginRight: "-13px",
                              visibility: venue?.read_only_menu
                                ? "hidden"
                                : "display",
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`lol-checkbox-${venue?.uuid}`}
                              className={`lol-checkbox`}
                              checked={venue?.is_currently_accepting_orders}
                              onChange={() =>
                                handleUpdateVenueAcceptsOrders(
                                  !venue?.is_currently_accepting_orders,
                                  venue?.uuid,
                                  venue?.slug
                                )
                              }
                            />
                            <label
                              className="button"
                              for={`lol-checkbox-${venue?.uuid}`}
                            >
                              <div className="knob"></div>
                              <div className="subscribe"></div>
                              <div className="alright"></div>
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </>
              ))}
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <Card
                className={classes.fullCard}
                style={{
                  background: "#EDEEF2",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // minWidth: "364px",
                  minHeight: "212px",
                  boxShadow: "none",
                }}
                onClick={handleOpenAddVenue}
              >
                <div
                  style={{
                    background: "#F66761",
                    borderRadius: "10px",
                    width: "54px",
                    height: "54px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Plus />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default Restaurants;
const useStyles = makeStyles((theme) => ({
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  editButton: {
    textAlign: "center",
    background: theme.colors.secondary,
    width: "32px",
    height: "32px",
    boxShadow: " 0px 0px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: " 6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editButton2: {
    textAlign: "center",
    background: "transparent",
    width: "32px",
    height: "32px",
    boxShadow: "none",
  },
  ordersCount: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",

    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#F9FAFC",
  },
  addButton: {
    width: "40px",
    height: "40px",
    background: theme.colors.secondary,

    boxShadow: " 0px 0px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: " 6px",
  },
  editIcon: {
    color: "#FFFFFF",
  },
  addIcon: {
    color: "#FFFFFF",
    padding: "8px 0px 0px 8px",
  },
  fullCard: {
    background: theme.colors.primary,
    borderRadius: "10px",
    cursor: "pointer",
  },
  VenaeDescription: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: theme.colors.secondary,
    margin: "0px",
  },
  VenaeName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#FFFFFF",
    margin: "0px",
  },
  TextSpace: {
    padding: "20px 0px 50px 30px",
  },
  textSpaceFooter: {
    padding: "0px 0px 50px 30px",
  },
  editButtonSpace: {
    padding: "15px",
  },
  addCard: {
    padding: "65px 0px 75px 110px",
    background: "#EDEEF2",
    borderRadius: "10px",
    boxShadow: "none",
    cursor: "pointer",
  },
  footerText: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  customScrollBar: {
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#EDF0F4",
      width: "5px", //for horizontal scrollbar
      height: "5px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#EDF0F4",
    },
    "&::-webkit-scrollbar-button": {
      width: "50px", //for horizontal scrollbar
      height: "70vh", //for vertical scrollbar
    },
  },
}));
