import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSearch, getNextSearchTab, getAllTabs } from "../../actions/order";
import { getGuestsList,deleteGuest } from "../../actions/hotelGuests";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import "./HotelGuests.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import copy from "copy-to-clipboard";
import moment from "moment";
import CreateGuestSessionDialog from "../../Components/GuestSession/CreateGuestSession";
import DeleteSessionDialog from "../../Components/GuestSession/DeleteSessionDialog";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useIntl } from "react-intl";

import { ReactComponent as ArrowDown } from "../../assets/image/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/image/arrowUp.svg";
import verifyPIc from "../../assets/image/verifyPIc.png";

function HotelGuests() {
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );
  let guestsList = useSelector((state) => state.hotelGuestsReducer?.guestsList);

  let UserDetail = useSelector((state) => state.VenueOrderReducer?.UserDetail);
  const [filterValue, setFilterValue] = React.useState("all");
  const [openModel, setOpenModel] = useState(false);
  let dispatch = useDispatch();
  const [Datavalue, setDatavalue] = useState([]);
  const [gList, setGList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [item, setItem] = useState(null);

  var todayDate = moment(new Date());
  let OrderHistoryReducer = useSelector((state) => state.VenueOrderReducer);

  const [SearchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const inputEl = useRef(null);
  const getData = async () => {
    dispatch(
      getGuestsList({
        business_account_uuid: User_uuid,
        Authorization: localStorage.AuthUserKey,
        search:
          filterValue === "all"
            ? null
            : `${filterValue}_${new Date().toISOString()}`,
        room_number: SearchValue,
      })
    );
  };
  useEffect(() => {
    if (User_uuid) {
      getData();
    }
    // eslint-disable-next-line
  }, [UserDetail, filterValue]);

  useEffect(() => {
    setGList(guestsList);
  }, [guestsList]);
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };
  const Search = () => {
    if (User_uuid) {
      getData();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && SearchValue !== null) {
      Search();
    }
  };

  const onRefresh = () => {
    console.log("refreshed");
  };
  const fetchData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    // setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 0);
  };
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setEditItem(null);
  };
  const handleOpenModel = () => {
    setOpenModel(true);
  };
  const handleEditGuestSession = (item) => {
    setEditItem(item);
    handleOpenModel();
  };
  /* keys:check-out,room,surname
      sortType:asc,desc
  */
  const [sort, setSort] = useState({
    key: "check-out",
    sortType: "asc",
  });
  const sortCheckIn = (type) => {
    let list = [...gList];
    let sortedArray = [];
    if (type === "desc") {
      console.log("list",list)
      sortedArray = list.sort(
        (a, b) => a.surname.toString().localeCompare(b.surname)
         
      );
    } else {
      sortedArray = list.sort(
        (a, b) =>
        b.surname.toString().localeCompare(a.surname)
      );
    }
    setGList(sortedArray);
  };
  const sortCheckout = (type) => {
    let list = [...gList];
    let sortedArray = [];
    if (type === "desc") {
      sortedArray = list.sort(
        (a, b) =>
          new moment(b.check_out_datetime).format("YYYYMMDD") -
          new moment(a.check_out_datetime).format("YYYYMMDD")
      );
    } else {
      sortedArray = list.sort(
        (a, b) =>
          new moment(a.check_out_datetime).format("YYYYMMDD") -
          new moment(b.check_out_datetime).format("YYYYMMDD")
      );
    }
    setGList(sortedArray);
  };
  const sortRoomNumber = (type) => {
    let list = [...gList];
    let sortedArray = [];
    if (type === "desc") {
      sortedArray = list.sort((a, b) => b.room_number - a.room_number);
    } else {
      sortedArray = list.sort((a, b) => a.room_number - b.room_number);
    }
    setGList(sortedArray);
  };
  const sortList = (key) => {
    let obj = { key: "", sortType: "" };
    if (key === "room") {
      if (sort?.key === "room") {
        if (sort?.sortType === "asc") {
          obj.key = "room";
          obj.sortType = "desc";
        } else {
          obj.key = "room";
          obj.sortType = "asc";
        }
      } else {
        obj.key = "room";
        obj.sortType = "asc";
      }
    } else if (key === "surname") {
      if (sort?.key === "surname") {
        if (sort?.sortType === "asc") {
          obj.key = "surname";
          obj.sortType = "desc";
        } else {
          obj.key = "surname";
          obj.sortType = "asc";
        }
      } else {
        obj.key = "surname";
        obj.sortType = "asc";
      }
    } else if (key === "check-out") {
      if (sort?.key === "check-out") {
        if (sort?.sortType === "asc") {
          obj.key = "check-out";
          obj.sortType = "desc";
        } else {
          obj.key = "check-out";
          obj.sortType = "asc";
        }
      } else {
        obj.key = "check-out";
        obj.sortType = "asc";
      }
    }
    setSort(obj);
    if (obj.key === "room") {
      sortRoomNumber(obj?.sortType);
    } else if (obj.key === "surname") {
      sortCheckIn(obj?.sortType);
    } else if (obj.key === "check-out") {
      sortCheckout(obj?.sortType);
    }
  };
  const [copiedSessionId, setCoppiedSessionId] = useState("");
  const handleCopy = (e, customTabLink, id) => {
    e.stopPropagation();
    copy(customTabLink);
    setCoppiedSessionId(id);
  };
  useEffect(() => {
    if (copiedSessionId !== "") {
      setTimeout(() => {
        setCoppiedSessionId("");
      }, 2000);
    }
  }, [copiedSessionId]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDelete=(e,item)=>{
    e.stopPropagation()
    setItem(item)
    setDialogOpen(true)
  }
  const handleClose=()=>{
    setDialogOpen(false)
    setItem(null)
  }
  const handleConfirmDelete = async () => {
    let res = await dispatch(
      deleteGuest({
        business_account_uuid: User_uuid,
        id: item?.id,
      })
    );
    if (res?.status === 200) {
      handleClose();
    }
  };
  return (
    <>
     <DeleteSessionDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        OrderSelected={item}
        setAlertOpen={setAlertOpen}
        setOrderSelected={setItem}
        setOpen={handleClose}
        handleConfirmDelete={handleConfirmDelete}
      />
      {openModel && (
        <CreateGuestSessionDialog
          open={openModel}
          item={editItem}
          handleClose={handleCloseModel}
        />
      )}
      <div onKeyDown={handleKeyDown}>
        {User_uuid !== null ? (
          <Grid container className={classes.root}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid container justify="space-between">
                <Grid item xs={9} className={classes.GenralText}>
                {intl.formatMessage({
                        id: "Hotel_guests",
                        defaultMessage:
                          "Hotel guests",
                      })}
                  
                </Grid>
              </Grid>
              <Grid
                container
                justify={"space-between"}
                style={{ marginTop: "8px" }}
              >
                <Grid item xs={4}>
                  <p className={classes.textLabel} style={{textTransform:"uppercase"}}>{intl.formatMessage({
                        id: "Search",
                        defaultMessage:
                          "SEARCH",
                      })}</p>

                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Search",
                      defaultMessage:
                        "Search",
                    })}
                    fullWidth
                    onChange={(e) => handleSearch(e)}
                    classes={{}}
                    style={{
                      background: "#F9FAFC",
                      borderRadius: "5px",
                      border: "1px solid #E5E5E5",
                    }}
                    ref={inputEl}
                    // onChange={() => console.log("search Clicked")}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              Search();
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        marginRight: "28px",
                        display: "flex",
                        // alignItems: "center",
                        marginTop: "18px",
                      }}
                    >
                      <Select
                        value={filterValue}
                        onChange={handleChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={"all"}>
                        {intl.formatMessage({
                      id: "Show_all",
                      defaultMessage:
                        "Show all",
                    })}
                          </MenuItem>
                        <MenuItem value={"active"}>
                        {intl.formatMessage({
                      id: "Show_active_sessions",
                      defaultMessage:
                        "Show active sessions",
                    })}
                          
                        </MenuItem>
                        <MenuItem value={"upcoming"}> {intl.formatMessage({
                      id: "Show_up_coming",
                      defaultMessage:
                        "Show up-coming",
                    })}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item style={{ textAlign: "end" }}>
                      <Button
                        variant="contained"
                        //   color="primary"
                        className={classes.styledButton}
                        // eslint-disable-next-line no-restricted-globals
                        onClick={() => {
                          handleOpenModel();
                        }}
                      >
                        {intl.formatMessage({
                      id: "Create_guest_session",
                      defaultMessage:
                        "Create guest session",
                    })}
                        
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.TableGrid}>
                <Grid container className={classes.headingContainer}>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      style={{ cursor: "pointer" }}
                      onClick={() => sortList("room")}
                    >
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.headingText}>
                        {intl.formatMessage({
                      id: "Room_number",
                      defaultMessage:
                        "Room number",
                    })}
                          
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {sort?.key === "room" && sort?.sortType === "asc" && (
                          <ArrowDown />
                        )}
                        {sort?.key === "room" && sort?.sortType === "desc" && (
                          <ArrowUp />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      style={{ cursor: "pointer" }}
                   
                    >
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.headingText}>
                        {intl.formatMessage({
                      id: "Verified",
                      defaultMessage:
                        "Verified",
                    })}
                         
                        </Typography>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
                    <Grid
                      container
                      style={{ cursor: "pointer" }}
                      onClick={() => sortList("surname")}
                    >
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.headingText}>
                        {intl.formatMessage({
                      id: "surname",
                      defaultMessage:
                        "Surname",
                    })}
                         
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {sort?.key === "surname" &&
                          sort?.sortType === "asc" && <ArrowDown />}
                        {sort?.key === "surname" &&
                          sort?.sortType === "desc" && <ArrowUp />}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      style={{ cursor: "pointer" }}
                      onClick={() => sortList("check-out")}
                    >
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.headingText}>
                        {intl.formatMessage({
                      id: "Check_out",
                      defaultMessage:
                        "Check_out",
                    })}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {sort?.key === "check-out" &&
                          sort?.sortType === "asc" && <ArrowDown />}
                        {sort?.key === "check-out" &&
                          sort?.sortType === "desc" && <ArrowUp />}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                    <Typography className={classes.headingText}>
                    {intl.formatMessage({
                      id: "Link",
                      defaultMessage:
                        "Link",
                    })}
                      
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #EDF0F4",
                    boxSizing: "border-box",
                    borderRadius: "5px",
                    marginTop: "20px",
                    height: "55vh",
                    overflow: "auto",
                  }}
                  className="hideScroll"
                  id="scrollableDiv"
                >
                  <InfiniteScroll
                    dataLength={Datavalue.length}
                    next={fetchData}
                    hasMore={true}
                    loader={
                      loader && (
                        <Grid
                          container
                          justify="center"
                          style={{ height: "70px" }}
                        >
                          <CircularProgress size={40} color="primary" />
                        </Grid>
                      )
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>
                        {intl.formatMessage({
                      id: "You_have_seen_it_all",
                      defaultMessage:
                        "Yay! You have seen it all",
                    })}
                         </b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                    refreshFunction={onRefresh}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}

                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593;
                        {intl.formatMessage({
                      id: "Release_to_refresh",
                      defaultMessage:
                        "Release to refresh",
                    })}
                         
                      </h3>
                    }
                    className={classes.infiniteScroll}
                  >
                    <Grid container style={{}}>
                      {gList &&
                        gList.map((n) => {
                          return (
                            <Grid
                              container
                              className={classes.tableRow}
                              onClick={() => handleEditGuestSession(n)}
                              style={{ cursor: "pointer" }}
                            >
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography className={classes.headingText}>
                                  {n?.room_number}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography
                                  className={classes.TableCellText}
                                  style={{ paddingLeft: "5px" }}
                                >
                                 {n.verified === true ? (
                                  <img
                                    src={verifyPIc}
                                    style={{ width: "20px", height: "20px" }}
                                    alt={ intl.formatMessage({
                                      id: "no_img",
                                    })}
                                  ></img>
                                ) : (
                                  ""
                                )}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
                                <Typography
                                  className={classes.TableCellText}
                                  style={{ paddingLeft: "5px",overflow:"hidden" }}
                                >{n?.surname}
                                  {/* {moment(n?.check_in_datetime).format(
                                    "DD-MM-YY"
                                  )} */}
                                </Typography>
                              </Grid>
                            
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography
                                  className={classes.TableCellText}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  {moment(n?.check_out_datetime).format(
                                    "DD-MM-YY"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                <Grid
                                  className={classes.copyButton}
                                  onClick={(e) =>
                                    handleCopy(e, n?.session_url, n?.id)
                                  }
                                >
                                  <Typography
                                    className={classes.copyButtonText}
                                  >
                                    {copiedSessionId === n?.id
                                      ?intl.formatMessage({
                                        id: "Copied",
                                      })
                                      : 
                                      intl.formatMessage({
                                        id: "Copy",
                                      })}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}
                              style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}
                              >
                                   <Button
                                    variant="contained"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      handleOpenDelete(e,n);
                                    }}
                                  >
                                    {intl.formatMessage({
                      id: "Delete",
                      defaultMessage:
                        "Delete",
                    })}
                                      
                                  </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </InfiniteScroll>
                </Grid>
              </div>

              {/* {OrderList && !OrderList.length && (
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <Typography className={classes.NoOrder}>
                    No Tab in this period{" "}
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={40} color="primary" />
          </Grid>
        )}
        <Grid item xs={1}></Grid>
      </div>
    </>
  );
}

export default HotelGuests;

const useStyles = makeStyles((theme) => ({
  copyButton: {
    padding: "5px 10px",
    width: "55px",
    height: "28px",
    background: "#EDF0F4",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  copyButtonText: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineeight: "18px",
    color: "#A3A9BC",
  },
  infiniteScroll: {
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "88vh",
    paddingTop: "22px",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    paddingTop: "20px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  textLabel: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: theme.colors.primary,
    margin: "0px",
  },
  styledButton: {
    marginTop: "18px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 26px",
    "&:hover": {
      background: "#34CEB2",
    },
  },
  deleteButton: {
    textTransform: "none",
    background: "#FF3366",
    color: "#FFFFFF",
    padding: "5px 26px",
    "&:hover": {
      background: "#FF3366",
    },
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
  },
  TableGrid: {
    overflowY: "auto",
    margin: "5px 0px 0px 0px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: theme.colors.primary,
    margin: "15px 0px 15px 0px",
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
  },
  OneTextInputStyle: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
}));

