import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Restaurants from "../../Components/Restaurants/Restaurants";
import HotelGuests from "../HotelGuests/HotelGuests";
import HotelProfile from "../HotelProfile/HotelProfile";
import HotelQRCode from "../HotelQRCode/HotelQRcode";
import UsersPermissions from "../../pages/Hotel/UsersPermissions";
import { useSelector, useDispatch } from "react-redux";
import { getPendingOrders } from "../../actions/order";
import { useIntl } from "react-intl";
import { permissionKeys } from "../../constant/config";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabBar(props) {
  const classes = useStyles();
  let dispatch = useDispatch();
  const intl = useIntl();

  let business_account = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail?.business_account
  );
  let hotelDetail = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail
  );
  const [permissions, setPermissions] = useState([]);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);
  useEffect(() => {
    try {
      if (UserDetail?.user_venue_permissions[0]?.permission_screen_keys) {
        setPermissions(
          UserDetail?.user_venue_permissions[0]?.permission_screen_keys
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [UserDetail]);
  const handleChange = (event, newValue) => {
    props.setCurrentTab(newValue);
  };
  // remove it
  // useEffect(() => {
  //   props.setCurrentTab(9);
  // }, []);
  const verifyPermission = (key) => {
    let index = permissions.findIndex((i) => i === key);
    return index > -1 ? true : false;
  };
  return (
    <div className={classes.root}>
      <AppBar color="white" position="static" className={classes.AppBar}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid>
              {!props.fullScreenView && (
                <Tabs
                  classes={{
                    indicator: classes.indicator,
                    selected: classes.selected,
                  }}
                  value={props?.currentTab}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    className={classes.tabs}
                    label={intl.formatMessage({
                      id: "Venues",
                      defaultMessage: "Venues",
                    })}
                    value={0}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tabs}
                    label={intl.formatMessage({
                      id: "account_settings",
                      defaultMessage: "Account settings",
                    })}
                    value={9}
                    {...a11yProps(1)}
                  />

                  {verifyPermission(permissionKeys.MANAGE_USERS) && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "manage_users",
                        defaultMessage: "Manage users",
                      })}
                      value={1}
                      {...a11yProps(8)}
                    />
                  )}

                  {business_account?.type === 2 && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "QR_code",
                        defaultMessage: "QR code",
                      })}
                      value={3}
                      {...a11yProps(2)}
                    />
                  )}
                  {business_account?.type === 1 && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Hotel_guests",
                        defaultMessage: "Hotel guests",
                      })}
                      value={7}
                      {...a11yProps(1)}
                    />
                  )}

                  {business_account?.type === 1 && (
                    <Tab
                      className={classes.tabs}
                      label={intl.formatMessage({
                        id: "Hotel_QR_code",
                        defaultMessage: "Hotel QR code",
                      })}
                      value={3}
                      {...a11yProps(3)}
                    />
                  )}
                </Tabs>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </AppBar>
      <TabPanel value={props?.currentTab} index={0} style={{ padding: "0px" }}>
        <Restaurants />
      </TabPanel>
      <TabPanel value={props?.currentTab} index={1}>
        <UsersPermissions />
      </TabPanel>
      {business_account?.type === 1 && (
        <TabPanel value={props?.currentTab} index={7}>
          <HotelGuests />
        </TabPanel>
      )}
      <TabPanel value={props?.currentTab} index={9}>
        <HotelProfile />
      </TabPanel>
      <TabPanel value={props?.currentTab} index={3}>
        <HotelQRCode />
      </TabPanel>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  AppBar: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 2px 0px rgba(0,0,0,0.12)",
  },
  tabs: {
    minWidth: "0px",
    marginRight: "30px",
    padding: "5px",
    fontFamily: theme.fontFamily,

    fontWeight: theme.fontSemiBold,
    fontSize: "14px",
    lineHeight: "19px",
    fontStyle: "normal",
    textTransform: "none",
    color: "#A3A9BC",
  },
  indicator: {
    backgroundColor: "#F66761",
  },
  selected: {
    color: "#F66761",
  },
}));
