export const API_BASE_URL = "/api/venue/details/";
export const dayStartTime = 6;
export const ACCEPT_ORDERS_WITHOUT_TAB =false;

export const  permissionKeys = {
    MANAGE_USERS:"MANAGE_USERS",
    BILLING:"BILLING",
    INCOMING_ORDERS:"INCOMING_ORDERS",
    ORDER_HISTORY:"ORDER_HISTORY",
    MANAGE_TABS:"MANAGE_TABS",
    MENU_AVAILABILITY:"MENU_AVAILABILITY",
    MENU_MANAGER:"MENU_MANAGER",
    LOCALISATION:"LOCALISATION",
    REPORTS:"REPORTS",
    VENUE_DETAILS:"VENUE_DETAILS",
    ORDER_SETTINGS:"ORDER_SETTINGS",
    TABLE_NUMBERS:"TABLE_NUMBERS",
    QR_CODES:"QR_CODES",
};