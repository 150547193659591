import { GET_REPORT, CLEAR_REPORT } from "../constant/types";
import API from "../utils/API";
import axios from "axios";

const Authorization = {
  Authorization: "Token " + localStorage.AuthUserKey,
};

export const clearReports = () => async (dispatch) => {
  dispatch({ type: CLEAR_REPORT });
};
export const getReport = ({
  venue_uuid,
  Authorization,
  from_date,
  until_date,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.get(
      `api/business/reports/revenue-summary/?from_date=${from_date}&until_date=${until_date}`,
      config
    );
    let data = response?.data;

    const dataResponse = {
      paid: {
        total:
          parseFloat(data?.paid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.tip_amount),
        tip: data?.paid_summary?.tip_amount,
        subtotal: data?.paid_summary?.subtotal_amount,
        orders: data?.paid_summary?.order_count,
      },
      unpaid: {
        total: data?.unpaid_summary?.subtotal_amount,
        orders: data?.unpaid_summary?.order_count,
      },
      total: {
        total:
          parseFloat(data?.unpaid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.tip_amount),
        orders:
          data?.unpaid_summary?.order_count + data?.paid_summary?.order_count,
      },
    };
    dispatch({ type: GET_REPORT, payload: dataResponse });
    // if (response && response.data) {
    //   return response.status;
    // }
  } catch (error) {
    console.log("res=========>error:", error);

    return error?.response?.status;
  }
};

export const getAllDaysReport = ({ venue_uuid }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.get(
      `api/business/reports/revenue-summary/`,
      config
    );
    let data = response?.data;

    const dataResponse = {
      paid: {
        total:
          parseFloat(data?.paid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.tip_amount),
        tip: data?.paid_summary?.tip_amount,
        subtotal: data?.paid_summary?.subtotal_amount,
        orders: data?.paid_summary?.order_count,
      },
      unpaid: {
        total: data?.unpaid_summary?.subtotal_amount,
        orders: data?.unpaid_summary?.order_count,
      },
      total: {
        total:
          parseFloat(data?.unpaid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.subtotal_amount) +
          parseFloat(data?.paid_summary?.tip_amount),
        orders:
          data?.unpaid_summary?.order_count + data?.paid_summary?.order_count,
      },
    };
    dispatch({ type: GET_REPORT, payload: dataResponse });
  } catch (error) {
    return error?.response?.status;
  }
};
