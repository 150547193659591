import React, { useState,useEffect } from "react";
import { ChromePicker   } from "react-color";
import CloseIcon from '@material-ui/icons/Close';
import upload from "../../assets/image/addImages.png";

const ColorPicker = (props) => {
    const {color,handleChangeColor,handleCloseColorPicker}=props;
  const [background, setBackground] = useState("#fff");

  const handleChangeComplete = (color) => {
    setBackground(color.hex);
    props.handleChangeColor(color?.hex?.replace("#", ""))
  };
  useEffect(()=>{
if(color){
    setBackground(color)
}
  },[color])
  return (
    <div style={{position:"relative"}}> 
<div
onClick={(e)=>handleCloseColorPicker(e)}
style={{position:"absolute",
top:-15,
right:-15,
zIndex:"1200",
opacity: "0.5",

height: "24px",
width: "24px",
backgroundColor: "#000",
borderRadius: "4px",
display: "flex",
alignItems: "center",
justifyContent: "center",
cursor:"pointer"
}}>
              <CloseIcon style={{ color: "#fff", fontSize: "20px" }} />


 </div>
    <ChromePicker   
color={background}
    onChangeComplete={handleChangeComplete}
     />
     </div>
  );
};
export default  ColorPicker; 