import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  selectBox: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: " normal",
    fontSize: " 13px",
    lineHeight: " 18px",
    color: " #303A5F",
    
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
    "& .MuiSelect-select": {
      border: "1px solid #EDF0F4",
      borderRadius: "5px",
      outline: "none",
    height:"28px",
paddingLeft:"10px",
    },
    "& .MuiInput-underline": {
      borderBottom: "0px solid #fff !important",
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid #fff !important",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid #fff !important",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ venuesList, venues, setVenues }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setVenues(event.target.value);
  };
  const getSelectedValue = (id) => {
    let arr = [];
    id.forEach((element) => {
      let index = venuesList.findIndex((item) => item.id == element);
      if (index > -1) {
        arr.push(venuesList[index].name);
      }
    });
    return `${arr.join(",")}`;
  };
  return (
    <div>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        variant="outlined"
        value={venues}
        onChange={handleChange}
        input={<Input />}
        fullWidth
        renderValue={(selected) => getSelectedValue(selected)}
        MenuProps={MenuProps}
        className={classes.selectBox}
      >
        {venuesList.map((item) => (
          <MenuItem key={item?.id} value={item?.id}>
            <Checkbox checked={venues.indexOf(item?.id) > -1} />
            <ListItemText primary={item?.name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
