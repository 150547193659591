import {
 GET_USER_PERMISSIONS,
 GET_PERMISSION_ROLES,
 GET_AVAILABLE_VENUES,
 ADD_USER_PERMISSIONS,
 UPDATE_USER_PERMISSIONS,
 DELETE_USER_PERMISSIONS

} from "../constant/types";

const initialState = {
  permissions: [],
  roles:[],
  venues:[],
  count: 0,
  next: null,
  previous: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: payload?.results,
        count: payload?.count,
        next: payload?.next,
        previous: payload?.previous,
      };
    }
    case DELETE_USER_PERMISSIONS: {
      let permissionsArr=[...state.permissions];
      let index=permissionsArr.findIndex(permission=>permission?.id===payload);
      if(index>-1){
        permissionsArr.splice(index,1)
      return {
        ...state,
        permissions: [...permissionsArr]
      };
    }
    }
    case UPDATE_USER_PERMISSIONS: {
      let permissionsArr=[...state.permissions];
      let index=permissionsArr.findIndex(permission=>permission?.id===payload?.id);
      if(index>-1){
        permissionsArr[index]={...payload?.data}
      return {
        ...state,
        permissions: [...permissionsArr]
      };
    }
    }
  
    case GET_PERMISSION_ROLES: {
      return {
        ...state,
        roles: [...payload]
      };
    }
    case ADD_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: [...state.permissions,{...payload}]
      };
    }
    case GET_AVAILABLE_VENUES: {
      return {
        ...state,
        venues: [...payload]
      };
    }
  
    default:
      return state;
  }
}
