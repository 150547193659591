import VenueOrderReducer from "./order";
import reportReducer from "./report";
import menuReducer from "./menu";
import menuAvailability from "./menuAvailability";
import venueTables from "./VenueTables";
import hotelDetailsReducer from "./hotelDetails";
import hotelGuestsReducer from "./hotelGuests";
import pendingOrdersReducer from "./pendingOrders";
import languageReducer from "./language";
import translatedMenu from "./Localization";
import userPermissions from "./userPermissions";
export default {
  VenueOrderReducer,
  reportReducer,
  menuReducer,
  menuAvailability,
  venueTables,
  hotelDetailsReducer,
  hotelGuestsReducer,
  pendingOrdersReducer,
  languageReducer,
  translatedMenu,
  userPermissions
};
