import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextButton, TextButtonAdd, TextButtonWhite } from "../Buttons/Buttons";
import { ReactComponent as DeleteIcon } from "../../assets/image/delete.svg";
import { removePrice } from "../../actions/menu";
import { useDispatch } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useIntl } from "react-intl";

function PricesList(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [currency, setCurrency] = useState("EUR");
  const {
    pricesList,
    id,
    menuId,
    categoryId,
    setPricesErrorsList,
    pricesErrorsList,
    updatePricesError,
  } = props;
  // pricesList
  // setPricesList
  useEffect(() => {
    setCurrency(props?.currency ? props?.currency : "EUR");
    // eslint-disable-next-line
  }, [props?.currency]);
  const handleAddNewPrice = () => {
    let obj = { amount: null, description: "" };
    let objError = { amount: false, description: false };
    let priceList = [...pricesList, { ...obj }];
    let errorsList = [...pricesErrorsList, { ...objError }];
    props.setPricesErrorsList([...errorsList]);
    props.setPricesList([...priceList]);
  };
  const handleUpdateItemPrices = (e, index, key) => {
    let priceList = [...pricesList];
    let errorsList = [...pricesErrorsList];

    let updateItem = priceList[index];
    let updateItemError = errorsList[index];
    switch (key) {
      case "price":
        updateItem.amount = e.target.value;
        updateItemError.amount = false;
        break;
      case "desc":
        updateItem.description = e.target.value;
        updateItemError.description = false;
        break;

      default:
        break;
    }
    priceList[index] = updateItem;
    errorsList[index] = updateItemError;
    props.setPricesErrorsList([...errorsList]);

    props.setPricesList([...priceList]);
  };
  const handleRemove = (index) => {
    let priceList = [...pricesList];
    let removedPrice = priceList.splice(index, 1);
    let errorsList = [...pricesErrorsList];
    errorsList.splice(index, 1);

    if (removedPrice && removedPrice[0] && removedPrice[0]?.id) {
      dispatch(
        removePrice({
          id: id,
          menuId: menuId,
          categoryId: categoryId,
          item: { price_id: removedPrice[0]?.id },
        })
      );
      updatePricesError();
    }
    props.setPricesErrorsList([...errorsList]);
    props.setPricesList([...priceList]);
  };
  const classes = useStyles();
  return (
    <Grid container>
      {pricesList &&
        pricesList.map((item, index) => {
          return (
            <Grid container key={index} style={{ marginTop: "20px" }}>
              <Grid item xs={3} style={{ paddingRight: "10px" }}>
                <Grid container>
                  <TextField
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "Price",
                    })}
                    value={item?.amount}
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      border: "1px solid #EDF0F4",
                      backgroundColor: "#fff",
                    }}
                    type="number"
                    onChange={(e) => handleUpdateItemPrices(e, index, "price")}
                    inputProps={{
                      className: classes.TextInputStylePrice,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                      startAdornment: (
                        <InputAdornment
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          position="start"
                        >
                          {
                            Number()
                              .toLocaleString(undefined, {
                                style: "currency",
                                currency: currency,
                              })
                              .split("0.00")[0]
                          }
                        </InputAdornment>
                      ),
                    }}
                    className={classes.startIcon}
                  />
                  <FormHelperText
                    style={{
                      marginLeft: "0px !important",
                      marginRight: "0px !important",
                    }}
                    className={classes.errorMessage}
                  >
                    {pricesErrorsList[index].amount &&
                      intl.formatMessage({
                        id: "Price_is_required",
                      })}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: "10px" }}>
                <Grid container>
                  <Grid item style={{ flexGrow: "1" }}>
                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "Description",
                      })}
                      fullWidth
                      value={item?.description}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        border: "1px solid #EDF0F4",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                      onChange={(e) => handleUpdateItemPrices(e, index, "desc")}
                      inputProps={{
                        className: classes.TextInputStyle3,
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                    <FormHelperText
                      style={{
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                      }}
                      className={classes.errorMessage}
                    >
                      {pricesErrorsList[index].description &&
                        intl.formatMessage({
                          id: "Field_is_required",
                        })}
                    </FormHelperText>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon
                      onClick={() => handleRemove(index)}
                      style={{
                        paddingLeft: "16px",
                        paddingRight: "14px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      <Grid
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography
          className={classes.labelAddButton}
          style={{ cursor: "pointer" }}
          onClick={handleAddNewPrice}
        >
          {intl.formatMessage({
            id: "Add_New",
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PricesList;

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginLeft: "0px",
    marginRight: "0px",
    "&.MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#FF3366",
  },
  labelAddButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
    marginTop: "26px",
  },
  outlinedStyle: {
    border: "0px",
  },
  startIcon: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
      background: "#EDF0F4",
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart ": {
      margin: 0,
      minWidth: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  TextInputStylePrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  TextInputStyle3: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    minWidth: "150px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },

  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
}));
