import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, Divider, Button } from "@material-ui/core";
import { useDispatch,useSelector } from "react-redux";
import { updateReviewOrder } from "../../actions/order";
import "../../style/home.css";
import { useIntl } from "react-intl";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../../constant/config";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#FF3366",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  RejectOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  Description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    /* identical to box height, or 112% */

    /* Main */
  },
  cancelBotton: {
    cursor: "pointer",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    padding: "0px 0px 0px 20px",
  },
}));

function RejectDialog(props) {
  const intl = useIntl();

  const {
    open,
    setOpen,
    OrderSelected,
    setOrderSelected,
    tempOrderCount,
    setTempOrderCount,
    status
  } = props;
  const classes = useStyles();
  let dispatch = useDispatch();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  // const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleReject=async()=>{
    
      let res=await  dispatch(
          updateReviewOrder({
            venue_uuid: props.venue_uuid,
            Authorization: localStorage.AuthUserKey,
            Status: "4",
            order_uuid: props.order_uuid,
          })
        )
        if(res?.status===200){
          // setOrderSelected({ ...OrderSelected, status: 4 });
          setOrderSelected(null);
          setTempOrderCount(tempOrderCount - 1);
          handleClose()}else{
            console.log(res?.data)
          }
        // }).catch(error=>alert(error))
        
      
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          // padding: "200px 300px 230px 350px ",
          borderRadius: "5px !important",
        }}
        // aria-labelledby="form-dialog-title"
        // className="MuiDialog-paper"
        // fullScreen={true}
        // fullWidth={true}
        // maxWidth={maxWidth}
      >
        {/* <DialogTitle id="form-dialog-title">Amount to tip</DialogTitle> */}

        <Grid item xs={12} style={{ padding: "20px", width: "600px" }}>
          <Grid container justify="space-between">
            <Grid item>
              <p className={classes.RejectOrder}>
              {intl.formatMessage({
                  id: "Reject_order",
                })}
                </p>
            </Grid>
            <Grid item>
              <ClearIcon
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <p className={classes.Description}>
            {status===3&&
            venueDetail?.allow_review_of_settled_orders===ACCEPT_ORDERS_WITHOUT_TAB?
            intl.formatMessage({
                  id: "Are_you_sure_you_want_to_reject_the_order_number",
                }):<>
                Are you sure you want to reject this order?<br/> 
                You will have to inform the customer of the change.</>
                }
              
              {status===3&&
            
            venueDetail?.allow_review_of_settled_orders===ACCEPT_ORDERS_WITHOUT_TAB&&props.reference_id}
            </p>
          </Grid>
          <Divider style={{ background: "#EDF0F4" }} />

          <Grid
            container
            justify="space-between"
            style={{ paddingTop: "30px" }}
          >
            <Grid item>
              <p className={classes.cancelBotton} onClick={() => handleClose()}>
              {intl.formatMessage({
                  id: "Cancel",
                })}
              </p>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                //   color="primary"
                className={classes.printButton}
                // eslint-disable-next-line no-restricted-globals
                onClick={handleReject}
              >
                {intl.formatMessage({
                  id: "Reject_order",
                })}
                
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default RejectDialog;
