const messages = {
  Login_to_access_Dashboard: "Login to access Blend Menu",
  Email: "Email",
  Your_Enter_Email_or_Password_Is_wrong_try_again:
    "Your Enter Email or Password Is wrong try again",
  Remember_me: "Remember me",
  Forgot_password: "Forgot password?",
  no_img: "no img",
  Add_image: "Add image",
  upload: "upload",
  Orders: "Orders",
  Tab_verified: "Tab verified",
  Choose_availability_of: "Choose availability of",
  available_from: "Available from",
  available_until: "Available until",
  Ok: "Ok",
  Reject_order: "Reject order",
  Are_you_sure_you_want_to_reject_the_order_number:
    "Are you sure you want to reject the order number",
  Cancel: "Cancel",
  prevent_customers:
    "This will close the tab and prevent customers from adding any further orders through the tab link.",
  Verify_tab: "Verify tab",
  Tab_name: "Tab name",
  Table_number: "Table number",
  Verify: "Verify",
  Decline_tab: "Decline tab",
  confirm_decline_tab_order: "Are you sure you want to decline the tab order",
  automatically_Reject_order:
    "This will automatically Reject all pending orders of this tab.",
  Decline: "Decline",
  Edit_guest_session: "Edit guest session",
  Create_guest_session: "Create guest session",
  Room_number: "Room number",
  Enter_room_number: "Enter room number ",
  Field_is_required: "Field is required",
  Guest_email: "Guest email",
  Enter_Email: "Enter Email ",
  Check_in: "Check-in",
  Invalid_Check_in_range: "Invalid Check-in range",
  Check_out: "Check-out",
  Expires_here: "Expires here..",
  Guest_session_link: "Guest session link",
  Link_creation: "Link will be generated upon creation",
  No_date_Available: "No date Available",
  Link_copied: "Link copied",
  send_session_link: "Send session link to guest upon creation",
  Delete: "Delete",
  Save: "Save",
  Create: "Create",
  Some_Error_Happen_Try_again: "Some Error Happen, Try again",
  prevent_join_session_message:
    "This will close the Session and prevent customers from adding any further orders through the Session link.",
  Username: "Username",
  Profile: "Profile",
  Login: "Login",
  SignOut: "SignOut",
  Your_Incoming_Order: "Your Incoming Order",
  Order_history: "Order history",
  Manage_Tabs: "Manage Tabs",
  Menu_availability: "Menu availability",
  Menu_manager: "Menu manager",
  Reports: "Reports",
  Venue_settings: "Venue settings",
  choose_customization: "Choose existing customization or add new",
  confirm_delete_item: "Are you sure you want to delete this item?",
  delete_item_message:
    "This will remove all information and customizations attached to this item.",
  Delete_item: "Delete item",
  Required: "Required",
  Not_more_than_1: "Not more than 1",
  Item_name: "Item name",
  Item_Name_is_required: "Item Name is required",
  Description: "Description",
  Price: "Price",
  Price_is_required: "Price is required",
  Price_description: "Price description",
  Image: "Image",
  Add_Image: "Add Image",
  Customization: "Customization",
  Add_New: "Add New",
  Add_item: "Add item",
  Create_new_tag: "Create new tag",
  Add: "Add",
  Mark_item_as: "Mark item as",
  Edit_tag: "Edit tag",
  Tag_name: "Tag name",
  Input_name_of_tag: "Input name of tag",
  Tag_color: "Tag color",
  Color_is_Required: "Color is Required",
  Tag_icon: "Tag icon",
  optional: "optional",
  Edit_category: "Edit category",
  Name: "Name",
  Category_name: "Category name...",
  Are_you_sure_you_want_to_delete: "Are you sure you want to delete",
  remove_customization_from_item: "This will remove customization from item.",
  remove_alert_message_menu:
    "This will remove all information  attached to this menu.",
  remove_alert_message_category:
    "This will remove all information  attached to this category.",
  Apply_the_changes: "Apply the changes",
  customisation_belongs_items:
    "This customisation also belongs to the items below",
  and: "and",
  more: "more",
  item: "item",
  items: "items",
  confirm_update_all: "Would you like to update all items?",
  Update_this_item_only: "Update this item only",
  Update_all: "Update all",
  Option_name: "Option name",
  Something_went_wrong: "Something went wrong",
  Edit_customization: "Edit customization",
  New_customization: "New customization",
  Customization_title: "Customization title",
  eg_sides: "E.g. sides",
  Rules: "Rules",
  show_separate_options:
    "Show selected options as separate items in incoming orders",
  required_selection: "Require customers to select an item",
  mex_selection_title:
    "What’s the maximum amount of items customers can select?",
  Option: "Option",
  PreSelected: "Pre-selected",
  Edit_menu: "Edit menu",
  Add_new_menu: "Add new menu",
  Menu_name: "Menu name...",
  Orders_enabled: "Orders enabled",
  Orders_disabled: "Orders disabled",
  No_pending_orders: "No pending orders",
  Pending_orders: "Pending orders",
  Logout: "Logout",
  Enter_table_number: "Enter table number",
  customer_name: "Enter name e.g. name of customer",
  Expires_at: "Expires at",
  Verified: "Verified",
  None: "None",
  Yes: "Yes",
  No: "No",
  Password: "Password",
  Enter_password: "Enter password",
  Customer_tab_link: "Customer tab link",
  generated_link: "Link will be generated upon creation of the tab",
  Copy: "Copy",
  Share_link_via_phone: "Share link via phone",
  Phone_Number: "Phone Number",
  Share_link_via_email: "Share link via email",
  Send_tab_link: "Send tab link to customer",
  Settle_tab: "Settle tab",
  Some_Error_Happen: "Some Error Happen, Try again",
  Search: "Search",
  Create_Tab: "Create Tab",
  No_tab_message: "No Tab in this period",
  Tab_orders: "Tab orders",
  Tab_details: "Tab details",
  Paid: "Paid",
  tip: "tip",
  Not_paid: "Not paid",
  Total: "Total",
  Out_Of_Stock: "Out Of Stock",
  Hide: "Hide",
  available_until_from: "Available only from - until",
  edit_menu_availability: "Before you can edit your menu availability",
  create_your_first_menu: "you need to create your menu first",
  Take_me_there: "Take me there",
  Delete_Table: " Delete Table?",
  Add_table_number: "Add table number",
  Edit_table_number: "Edit table number",
  Enter_name: "Enter name",
  Order_is_going_to_removed: "Order is going to be removed from list",
  ACCEPTED: "ACCEPTED",
  Rejected: "Rejected",
  Request: "Request",
  Selected_Order: "Selected Order",
  na: "n/a",
  payment_Status: "payment Status",
  Order_Note: "Order Note",
  Subtotal: "Subtotal",
  Reject: "Reject",
  Tip: "Tip",
  Accept: "Accept",
  Accepted: "Accepted",
  Bill_request_Pay_by: "Bill request - Pay by",
  Service_request: "Service request",
  Tab_Created: "Tab Created",
  Select_an_order_to_view_it: "Select an order to view it.",
  Link: "Link",
  Copied: "Copied",
  No_Tab_in_this_period: "No Tab in this period",
  Search_item_category_customization:
    "Search for item, category or customization...",
  Add_new_category: "Add new category",
  Add_new_item: "Add new item",
  adding_your_menu: "Start by adding your menu, then your categories",
  individual_items: "thereafter your individual items.",
  Edit_tab: "Edit tab",
  Create_new_tab: "Create new tab",
  Custom: "Custom",
  Today: "Today",
  Yesterday: "Yesterday",
  This_Week: "This Weeks",
  Last_Week: "Last Week",
  This_Month: "This Month",
  Last_Month: "Last Month",
  Close: "Close",
  Order_History: "Order History",
  Custom_Dates: "Custom Dates",
  Select_date_and_time: "Select date and time",
  FIXED_DATES: "FIXED DATES",
  Date: "Date",
  Order_ID: "Order ID",
  Table_Number: "Table Number",
  Payment_Status: "Payment Status",
  Sub_Total: "Sub Total",
  Total_Payment: "Total Payment",
  Status: "Status",
  Declined: "Declined",
  No_Order_in_period: "No Order in this period",
  View_previous_order: "View previous order",
  Select_date_for_reports: "Select Custom date or Fixed date to view Reports",
  creating_stripe_credit_card:
    "Creating a Stripe account enables receiving orders via credit card",
  IDEAL_Bancontact: "IDEAL, Bancontact",
  Stripe_Connected: "Stripe Connected",
  Connect_Stripe: "Connect Stripe",
  Register_account_and_agree: "By registering your account, you agree to the",
  Stripe_Services_Agreement: "Stripe Services Agreement",
  and_the: "and the",
  Stripe_connected_Account_Agreement: "Stripe connected Account Agreement",
  unavailable_feature:
    "This feature is available for menus with ordering fuctionality.",
  Contact_us: "Contact us",
  upgrade_your_menu: "to upgrade your menu.",
  Venue_details: "Venue details",
  Order_settings: "Order settings",
  Table_numbers: "Table numbers",
  QR_code: "QR code",
  Venues: "Venues",
  Hotel_guests: "Hotel guests",
  Hotel_profile: "Hotel profile",
  Hotel_QR_code: "Hotel QR code",
  Show_active_sessions: "Show active sessions",
  Show_up_coming: "Show up-coming",
  Create_guest_session: "Create guest session",
  You_have_seen_it_all: " Yay! You have seen it all",
  Release_to_refresh: "Release to refresh",
  Logo_image: "Logo image",
  App_icon: "App icon",
  Hotel_theme_color: "Hotel theme color",
  Select_Color: "Select Color",
  Hotel_QR_code: "Hotel QR code",
  Download_QR_code_image: "Download QR code image",
  auto_accept_orders: "Auto-accept orders",
  auto_accept_orders_from_tab: " Auto-accept orders from verified tabs",
  Request_waiter_service: "Request waiter service",
  allow_waiters_service:
    "Allow customers to request waiter service from the menu",
  Payment_options: "Payment options",
  Payment_Disabled: "Payment Disabled",
  Payment_Optional: "Payment Optional",
  Payment_Mandatory: "Payment Mandatory",
  Payment_provider: "Payment provider",
  Venue_name: "Venue name",
  Invalid_color_code: "Invalid color code",
  Select_Color: "Select Color",
  Venue_theme_color: "Venue theme color",
  Cover_photo: "Cover photo",
  Logo_image: "Logo image",
  Venue_description: "Venue description",
  Table_name: "Table name",
  QR_Code: "QR Code",
  Add_new_table: "Add new table",
  add_new_language: "Add new language",
  language: "Language",
  edit_language: "Edit language",
  delete_language: "Delete language",
  Add_Language: "Add Language",
  Localization: "Localization",
  adding_language: "Start by adding a language, and then you can edit",
  individual_language: "each individual language input as desired.",
  //phase 3.8
  create_new_venue: "Create new venue",
  edit_venue: "Edit venue",
  venue_name: "Venue name",
  enter_venue_name: "Enter venue name",
  currency: "Currency",
  select_currency: "Select Currency",
  time_zone: "Time zone",
  venue_domain: "Venue domain",
  enter_venue_domain: "Enter venue domain",
  surname: "Surname",
  enterSurname: "Enter Surname",
  select_time_zone: "Select Time zone",
  instagram_link: "Instagram link",
  enter_instagram_link: "Enter Instagram link",
  facebook_link: "Facebook link",
  enter_facebook_link: "Enter Facebook link",
  invalid_url: "Invalid Url",
  hotel_name: "Hotel name",
  hotel_domain: "Hotel domain",
  table: "Table",
  domain: "Domain",
  name: "Name",
  theme_color: "Theme color",
  Are_you_sure: "Are you sure",
  this_will_permanently_delete_the_selected_language:
    "This will permanently delete the selected language.",
  until: "Until",
  from: "From",
  order_status: "Order Status",
  charge_room: "Charge room",
  Bill_request: "Bill request",

  //phase 3.14
  manage_users: "Manage users",
  users_permissions: "Users & permissions",
  add_new_user: "Add new user",
  new_user_details: "New user details",
  edit_user_details: "Edit user details",
  send_email_instruction: "Instruction email will be sent",
  admin_role: "Admin role",
  select_admin_role: "Select Admin role",
  venue_access: "Venue access",
  select_venue_access: "Select Venue access",
  delete_user: "Delete user",
  delete_user_message:
    "Are you sure you want to permanently delete the selected user?",
  reception: "Reception",
  admin: "Admin",
  manager: "Manager",
  cashier: "Cashier",
  Kitchen: "Kitchens",
  server: "Server",
  account_permissions: "Account permissions",
  //phase 3.15
  preview_your_menu:"Preview your menu",
  help_chat:"Help chat",
  logout:"Logout",
  choose_account_type:"Choose account type",
  change_this_later:"You can always change this later",
  account_settings:"Account settings",
  account_type:"Account type",

};

export default messages;
