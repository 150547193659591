import { PENDING_ORDERS } from "../constant/types";

const initialState = {
  pendingOrders: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PENDING_ORDERS: {
      return {
        ...state,
        pendingOrders: payload,
      };
    }
    default:
      return state;
  }
}
