import React from "react";
import moment from "moment";
import copy from "copy-to-clipboard";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getAllTabs } from "../actions/order";

import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TabSettleDialog from "./DialogBox/TabSettleDialog";
import {
  MobileDateTimePicker,
  DesktopDateTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import {
  createTab,
  settleTab,
  updateTab,
  getTabTables,
} from "../actions/order";
import { Grid, Button, Divider, DialogContent } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";

import "../style/home.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  verified: {
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.colors.primary,
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  root: {
    padding: "0px",
  },
  checkBox: {
    "&.MuiCheckbox-colorSecondary": { color: "#F66761" },
  },
  tableField: {
    "&.MuiAutocomplete-inputRoot[class*=`MuiOutlinedInput-root`]": {
      padding: "0px",
    },
  },

  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: theme.colors.primary,
  },
  checkBoxText: {
    "&.MuiListItemText-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "0px",
      margin: "auto",
      color: "#485379",
      minWidth: "40px",
      padding: "2px",
    },
  },
  copyButton: {
    marginTop: "37px",
    textTransform: "none",
    background: "#EDF0F4",
    color: "#A3A9BC",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      color: "#EDF0F4",
      background: "#A3A9BC",
      // border: "  1px solid #20BDA1",
    },
  },
  printButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#20BDA1",
      // border: "  1px solid #20BDA1",
    },
  },
  settleButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#FF3366",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#FF3366",
      // border: "  1px solid #20BDA1",
    },
  },

  checkBoxIcon: {
    "&.MuiListItemText-root": {
      minWidth: "40px",
    },
  },

  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },

  OrderGridInner: {
    padding: "20px 0px",
  },
  gridList: {
    width: 320,
    height: "100vh",
  },
  cardList: {
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "20px",
  },
  cardStyle: {
    marginTop: "28px",
    boxShadow: "none",
    borderRadius: "5px",
  },
  tableNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",
    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  Accepted: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  tickIcon: {
    color: "#FFFFFF",
    margin: "10px  0px 0px 3px",
    height: 15,
    width: 15,
  },

  TableDigit: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "18px",
    padding: " 0px",
    margin: "20px 0px 20px 10px",
    color: "#F66761",
  },
  orderIdCard: {
    borderRadius: "5px",
    width: "99px",
    margin: "30px 0px 20px 10px",
    boxShadow: "none",
  },
  orderId: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "5px 5px 5px 5px",
  },
  time: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "0px",
    color: theme.colors.primary,
    padding: "10px  0px 0px 0px",
  },
  loadingIcon: {
    width: "16px",
    height: "16px",
    padding: "10px  5px 0px 0px",
    color: theme.colors.primary,
  },
  SelectedOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    margin: "0px",
    color: theme.colors.primary,
    paddingTop: "10px",
  },
  PreviousOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    margin: "0px",
    color: theme.colors.primary,
    padding: "0px 0px 30px 50px",
    textAlign: "right",
  },
  outlinedStyle: {
    border: "0px",
  },

  FullScreenIcon: {
    width: "25px",
    height: "20px",
    color: "#FFFFFF",
    margin: "10px 0px 10px 10px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  TextInputStylePhone: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "22px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px 12px 12px 0px",
    marginLeft: "-5px",
  },
  TextInputStylePhoneIcon: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "22px",
    color: theme.colors.primary,
    border: "0px",
    padding: "0px",
  },

  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "none",
    color: theme.colors.primary,
    margin: "7px 0px",
  },

  CrossIcon: {
    cursor: "pointer",

    color: theme.colors.primary,
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NewTabDialog(props) {
  let dispatch = useDispatch();
  const intl = useIntl();

  const [verified, setVerified] = React.useState("yes");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { open, setOpen, OrderSelected, setOrderSelected } = props;
  const classes = useStyles();
  const [expireDateError, setExpireDateError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [tableNumber, setTableNumber] = useState(null);
  const [openTableList, setOpenTableList] = useState(true);
  const [OpenList, setOpenList] = useState(true);
  const [loader, setLoader] = useState(false);
  const [tableListView, settableListView] = useState([]);
  const [value, setValue] = React.useState(null);
  const [tabName, setTabName] = useState(null);
  const [expiresAt, setExpiresAt] = useState(null);
  const [email, setEmail] = useState(null);
  const [sendCheckBox, setSendCheckBox] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [verifiedError, setVerifiedError] = useState(false);
  const [customTabLink, setCustomTabLink] = useState(null);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let tabTables = useSelector((state) => state.VenueOrderReducer.tabTables);

  const handleSettle = () => {
    // setOpen(false)
    setDialogOpen(true);
  };

  
  useEffect(() => {
    if (open === true) {
      dispatch(
        getTabTables({
          venue_uuid: venueDetail && venueDetail.uuid,
          Authorization: localStorage.AuthUserKey,
        })
      );
    }
    // eslint-disable-next-line
  }, [open]);
  useEffect(() => {
    let today = moment(new Date());
    if (OrderSelected !== null) {
     
      setTableNumber(OrderSelected.table_number);

      setTabName(OrderSelected.name);
      setVerified(OrderSelected.verified ? "yes" : "no");
      setExpiresAt(OrderSelected.expiry_datetime);
      setEmail(OrderSelected.email);
      setSendCheckBox(false);
      if (OrderSelected?.national_number) {
        setPhoneNumber(OrderSelected.national_number);
        setPhoneCode(OrderSelected.country_code);
      } else {
        setPhoneNumber(OrderSelected.phone_number);
      }
      setCustomTabLink(OrderSelected.tab_link);
    } else {
     
      setTableNumber(null);
      setTabName(null);
      setExpiresAt(moment(today).add(5, "hours"));
      setEmail(null);
      setSendCheckBox(false);
      setPhoneNumber(null);
      setCustomTabLink(null);
      setEmailError(false);
      setPhoneError(false);
      setPhoneCode(null);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setLoader(true);
    let temp = [];
    if (OrderSelected !== null) {
      temp = [...temp, { id: `${OrderSelected.table_number}` }];
    }
    tabTables && tabTables.map((item) => (temp = [...temp, { id: `${item}` }]));
    settableListView(temp);
    setLoader(false);
    // eslint-disable-next-line
  }, [tabTables]);

  function handlePhoneCode(e) {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");

    setPhoneCode(onlyNums);
  }
  function handleChangePhone(e) {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");

    setPhoneNumber(onlyNums);
    if (onlyNums.length === 0) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }
  function handleEmail(e) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(e.target.value)) {
      setEmail(e.target.value);
      setEmailError(false);
    } else if (e.target.value.length === 0) {
      setEmailError(false);
      setEmail(e.target.value);
    } else {
      setEmailError(true);
      setEmail(e.target.value);
    }
  }

  const handleClose = () => {
    setOrderSelected(null);
    setOpen(false);
  };

  const handleChange = (event) => {
    setVerified(event.target.value);
    setVerifiedError(false);
  };
  const handleTab = () => {
    if (verified !== "") {
      if (OrderSelected) {
        dispatch(
          updateTab({
            table_number: tableNumber,
            email: email,
            verified: verified === "yes" ? true : false,
            phone_number:
              phoneNumber?.length > 0 && phoneCode?.length > 0
                ? `+${phoneCode}${phoneNumber}`
                : null,
            expiry_datetime: expiresAt,
            name: tabName,
            send_link: sendCheckBox,
            venue_uuid: venueDetail && venueDetail.uuid,
            created_tab_id: OrderSelected.id,
            Authorization: localStorage.AuthUserKey,
          })
        ).then((res) => {
          if (res) {
            setOpen(false);
            dispatch(
              getAllTabs({
                venue_uuid: venueDetail.uuid,
                Authorization: localStorage.AuthUserKey,
              })
            );
            setOrderSelected(null);
          } else {
            setAlertOpen(true);
            email && setPhoneError(false);
            phoneNumber && setEmailError(false);
          }
        });
      } else {
        dispatch(
          createTab({
            table_number: tableNumber,
            email: email,
            verified: verified === "yes" ? true : false,
            phone_number:
              phoneNumber?.length > 0 ? `+${phoneCode}${phoneNumber}` : null,
            expiry_datetime: expiresAt,
            name: tabName,
            send_link: sendCheckBox,
            venue_uuid: venueDetail && venueDetail.uuid,
          })
        ).then((res) => {
          if (res) {
            setOpen(false);
            dispatch(
              getAllTabs({
                venue_uuid: venueDetail.uuid,
                Authorization: localStorage.AuthUserKey,
              })
            );
            setOrderSelected(null);
          } else {
            setAlertOpen(true);
            email && setPhoneError(false);
            phoneNumber && setEmailError(false);
          }
        });
      }
    } else {
      setVerifiedError(true);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "20px  280px" }}
        fullScreen={true}
      >
        <DialogContent className="dialogContext">
          <Grid item xs={12}>
            <Grid className={classes.OrderGridInner}>
              <Grid container>
                <Grid item xs={7} className={classes.PreviousOrder}>
                  {OrderSelected ? 
                  intl.formatMessage({
                    id: "Edit_tab",
                  })
                   :intl.formatMessage({
                    id: "Create_new_tab",
                  }) }
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={4} style={{ textAlign: "end" }}>
                  <ClearIcon
                    className={classes.CrossIcon}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xs={12}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                    id: "Table_number",
                  })}
                    </p>
                 <Autocomplete
                    options={tableListView}
                    loading={loader}
                    // disabled={OrderSelected ? true : false}
                    // open={OpenList}
                    getOptionLabel={(option) => option.id}
                    value={{
                      id: tableNumber,
                    }}
                    onChange={(event, newValue) => {
                      setOpenTableList(newValue);
                      setTableNumber(newValue && newValue.id);
                      // setOpenList(false)
                      // setValue(newValue);
                    }}
                    placeholder={intl.formatMessage({
                      id: "Enter_table_number",
                    })}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Table Number"
                        // onClick={() => setOpenList(true)
                        // }
                        // value={tableNumber }
                        className={classes.tableField}
                        variant="outlined"
                        style={{
                          background: "#FFFFFF",
                          borderRadius: "5px",
                          border: "0px",
                          padding: "0px !important",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xs={12}>
                  <p className={classes.textLabel}>
                  {intl.formatMessage({
                      id: "Tab_name",
                    })}
                    </p>

                  <TextField
                    variant="outlined"
                    //  className={classes.fieldtext}
                    fullWidth
                    classes={{}}
                    value={tabName}
                    onChange={(e) => setTabName(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: "customer_name",
                    })}
                    inputProps={{
                      // defaultValue: OrderSelected.table_number,
                      className: classes.TextInputStyle,
                    }}
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    inputProps={{
                      // defaultValue: OrderSelected.table_number,
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xs={6}>
                  <p className={classes.textLabel}>{intl.formatMessage({
                      id: "Expires_at",
                    })}</p>
                  <MobileDateTimePicker
                    onError={(e) => {
                      e ? setExpireDateError(true) : setExpireDateError(false);
                    }}
                    disablePast
                    value={expiresAt}
                    helper
                    ampm={false}
                    onChange={(newValue) => {
                      setExpiresAt(newValue);
                    }}
                    inputFormat={"dddd - DD/MM/YY - HH:mm "}
                    InputProps={{
                      classes: {
                        // notchedOutline: classes.outlinedStyle,
                        input: classes.rangeInputPadding,
                      },
                    }}
                    // label="24h clock"
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        placeholder={intl.formatMessage({
                          id: "Expires_here",
                        })}
                        variant="outlined"
                        {...props}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <p className={classes.textLabel}>{intl.formatMessage({
                          id: "Verified",
                        })}</p>

                  <Select
                    value={verified}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: "Verified",
                    })}
                    className={classes.verified}
                    error={verifiedError}
                  >
                    <MenuItem value="">
                      <em>{intl.formatMessage({
                          id: "None",
                        })}</em>
                    </MenuItem>
                    <MenuItem value={"yes"}>{intl.formatMessage({
                          id: "Yes",
                        })}</MenuItem>
                    <MenuItem value={"no"}>{intl.formatMessage({
                          id: "No",
                        })}</MenuItem>
                  </Select>

                  <FormHelperText>
                    {verifiedError ? intl.formatMessage({
                          id: "Field_is_required",
                        }) : ""}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <p className={classes.textLabel}>{intl.formatMessage({
                          id: "Customer_tab_link",
                        })}</p>
                  <TextField
                    variant="outlined"
                    //  placeholder="Month"
                    //  className={classes.fieldtext}
                    value={customTabLink}
                    onChange={(e) => setCustomTabLink(e.target.value)}
                    disabled={true}
                    fullWidth
                    style={{
                      background: customTabLink ? "none" : "#F4F4F4",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    placeholder={intl.formatMessage({
                      id: "generated_link",
                    })}
                    InputProps={{
                      classes: {
                        notchedOutline: customTabLink
                          ? ""
                          : classes.outlinedStyle,
                      },
                    }}
                    inputProps={{
                      // defaultValue: OrderSelected.table_number,
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "end" }}>
                  <Button
                    className={classes.copyButton}
                    onClick={() => copy(customTabLink)}
                  >
                    {intl.formatMessage({
                      id: "Copy",
                    })}
                    
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xs={12}>
                  <p className={classes.textLabel}>#
                  {intl.formatMessage({
                      id: "Share_link_via_phone",
                    })}
                   </p>

                  <Grid container spacing={2} justify={"space-between"}>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        //  className={classes.fieldtext}
                        fullWidth
                        value={phoneCode ? `${phoneCode}` : ""}
                        onChange={handlePhoneCode}
                        classes={{}}
                        style={{
                          background: "#FFFFFF",
                          borderRadius: "5px",
                          border: "0px",
                        }}
                        inputProps={{
                          inputMode: "numeric",
                          className: classes.TextInputStylePhone,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <p className={classes.TextInputStylePhoneIcon}>
                                +
                              </p>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        placeholder={intl.formatMessage({
                          id: "Phone_Number",
                        })}
                        variant="outlined"
                        // type="number"
                        //  className={classes.fieldtext}
                        onChange={handleChangePhone}
                        value={phoneNumber}
                        fullWidth
                        error={phoneError}
                        classes={{}}
                        onInput={(e) => {
                          // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,8)
                        }}
                        style={{
                          background: "#FFFFFF",
                          borderRadius: "5px",
                          border: "0px",
                        }}
                        inputProps={{
                          inputMode: "numeric",
                          className: classes.TextInputStyle,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <p className={classes.textLabel}>{intl.formatMessage({
                      id: "Share_link_via_email",
                    })}</p>

                  <TextField
                    variant="outlined"
                    //  className={classes.fieldtext}
                    placeholder={intl.formatMessage({
                      id: "Enter_Email",
                    })}
                    required
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    error={emailError ? true : false}
                    autoComplete="email"
                    fullWidth
                    value={email}
                    onChange={handleEmail}
                    classes={{}}
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "5px",
                      border: "0px",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                    }}
                  />
                </Grid>
              </Grid>

              <ListItem
                style={{ padding: "0px" }}
                button
                onClick={() => setSendCheckBox(!sendCheckBox)}
              >
                <ListItemIcon className={classes.checkBoxIcon}>
                  <Checkbox
                    // edge="start"
                    checked={sendCheckBox}
                    disableRipple
                    className={classes.checkBox} // inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.checkBoxText}>
                {intl.formatMessage({
                      id: "Send_tab_link",
                    })}
                 
                </ListItemText>
              </ListItem>
              <Grid container justify={"flex-end"}>
                <Button
                  variant="contained"
                  className={classes.printButton}
                  disabled={
                    tableNumber &&
                    tabName !== null &&
                    expiresAt !== null &&
                    expireDateError === false &&
                    (phoneError === false ||
                      emailError === false ||
                      sendCheckBox === false) &&
                    ((phoneNumber !== null && phoneCode !== null) ||
                      email !== null ||
                      sendCheckBox === false)
                      ? false
                      : true
                  }
                  onClick={() => handleTab()}
                >
                  {OrderSelected ? 
                  intl.formatMessage({
                    id: "Save",
                  })
                   : intl.formatMessage({
                    id: "Create",
                  })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity="error">
          {intl.formatMessage({
                    id: "Some_Error_Happen",
                  })}
          
        </Alert>
      </Snackbar>
      <TabSettleDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        OrderSelected={OrderSelected}
        setAlertOpen={setAlertOpen}
        setOrderSelected={setOrderSelected}
        setOpen={setOpen}
      />
    </div>
  );
}

export default NewTabDialog;
