export const HOSPITALITY_GROUP = 0;
export const INDEPENDENT_VENUE = 0;
export const HOTEL = 1;
export const MULTI_VENDOR = 2;
export const ACCOUNT_TYPES = [
  { name: "Independent venue", id: 0 },
  { name: "Multi-venue", id: 2 },
  { name: "Hotel", id: 1 },
];

export const VENUE_TYPES = [
  { id: 0, name: "Restaurant" },
  { id: 1, name: "Cafe" },
  { id: 2, name: "Bar" },
  { id: 3, name: "Room Service" },
];
export const SUPPORTED_LANGUAGES = [
  { id: "en", name: "English" },
  { id: "gr", name: "Greek" },
 
];
