import React, { useState, useEffect } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { applyDragCustomization, generateItems } from "../../utils/utils";
import CreateIcon from "@material-ui/icons/Create";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as Edit } from "../../assets/image/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/image/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import "./menu.css";
import { useTheme } from "@material-ui/core/styles";
import DisplayItemCustomization from "../ItemDetails/DisplayItemCustomization";
import { useIntl } from "react-intl";

const DragnDropItemCustomization = (props) => {
  const theme = useTheme();
  const intl = useIntl();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const [items, setItems] = useState([]);
  const [currency, setCurrency] = useState("EUR");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  useEffect(() => {
    setCurrency(venueDetail?.currency ? venueDetail?.currency : "EUR");
  }, [venueDetail]);
  useEffect(() => {
    setItems(props?.item_customisations);
  }, [props]);
  const handleRemoveOption = (id) => {
    props.handleRemoveOption(id);
  };

  return (
    <Container
      animationDuration={500}
      lockAxis="y"
      onDrop={(e) =>
        setItems(applyDragCustomization(items, e, props.item, dispatch))
      }
      dragClass={props.selectedClass}
      dropClass={props.class}
      dragHandleSelector=".column-drag-handle"
      dropPlaceholder={{
        animationDuration: 150,
        showOnTop: true,
        className: "drop-preview",
      }}
      dropPlaceholderAnimationDuration={200}
      style={{
        fontFamily: theme.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "18px",
        color: "#303A5F",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {items
        .sort((a, b) => a.position - b.position)
        .map((itemCustom, index) => {
          return (
            <Draggable key={index}>
              <DisplayItemCustomization
                handleEditCustmizationModal={props.handleEditCustmizationModal}
                item={itemCustom}
              />
            </Draggable>
          );
        })}
    </Container>
  );
};

export default DragnDropItemCustomization;

const useStyles = makeStyles((theme) => ({
  startIcon: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
      background: "#EDF0F4",
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart ": {
      margin: 0,
    },
  },
  TextInputStyle3: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    minWidth: "50px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },
  bodyText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));
