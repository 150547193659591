const messages = {
  Login_to_access_Dashboard: "Connectez-vous pour accéder au tableau de bord",
  Email: "E-mail",
  Your_Enter_Email_or_Password_Is_wrong_try_again:
    "Votre adresse e-mail ou votre mot de passe est erroné, réessayez",
  Remember_me: "Souviens-toi de moi",
  Forgot_password: "Mot de passe oublié?",
  no_img: "pas d'img",
  Add_image: "Ajouter une image",
  upload: "télécharger",
  Orders: "Ordres",
  Tab_verified: "Onglet vérifié",
  Choose_availability_of: "Choisissez l'indisponibilité de",
  available_from: "Indisponible à partir de",
  available_until: "Indisponible jusqu'au",
  Ok: "D'accord",
  Reject_order: "Rejeter la commande",
  Are_you_sure_you_want_to_reject_the_order_number:
    "Êtes-vous sûr de vouloir refuser le numéro de commande",
  Cancel: "Annuler",
  prevent_customers:
    "Cela fermera l'onglet et empêchera les clients d'ajouter d'autres commandes via le lien de l'onglet.",
  Verify_tab: "Vérifier l'onglet",
  Tab_name: "Nom de l'onglet",
  Table_number: "Numéro de table",
  Verify: "Vérifier",
  Decline_tab: "Onglet Refuser",
  confirm_decline_tab_order:
    "Voulez-vous vraiment refuser l'ordre de tabulation",
  automatically_Reject_order:
    "Cela rejettera automatiquement toutes les commandes en attente de cet onglet.",
  Decline: "Déclin",
  Edit_guest_session: "Modifier la session d'invité",
  Create_guest_session: "Créer une session d'invité",
  Room_number: "Numéro de chambre",
  Enter_room_number: "Entrez le numéro de la chambre",
  Field_is_required: "Champ requis",
  Guest_email: "E-mail de l'invité",
  Enter_Email: "Entrez votre e-mail",
  Check_in: "Enregistrement",
  Invalid_Check_in_range: "Plage d'enregistrement non valide",
  Check_out: "Vérifier",
  Expires_here: "Expire ici..",
  Guest_session_link: "Lien de session d'invité",
  Link_creation: "Le lien sera généré lors de la création",
  No_date_Available: "Aucune date disponible",
  Link_copied: "Lien copié",
  send_session_link:
    "Envoyer le lien de session à l'invité lors de la création",
  Delete: "Effacer",
  Save: "Sauvegarder",
  Create: "Créer",
  Some_Error_Happen_Try_again: "Une erreur s'est produite, réessayez",
  prevent_join_session_message:
    "Cela fermera la session et empêchera les clients d'ajouter d'autres commandes via le lien Session.",
  Username: "Nom d'utilisateur",
  Profile: "Profil",
  Login: "Connexion",
  SignOut: "Se déconnecter",
  Your_Incoming_Order: "Votre commande entrante",
  Order_history: "Historique des commandes",
  Manage_Tabs: "Gérer les onglets",
  Menu_availability: "Disponibilité du menu",
  Menu_manager: "Gestionnaire de menu",
  Reports: "Rapports",
  Venue_settings: "Venue settings",
  choose_customization: "Choose existing customization or add new",
  confirm_delete_item: "Are you sure you want to delete this item?",
  delete_item_message:
    "This will remove all information and customizations attached to this item.",
  Delete_item: "Delete item",
  Required: "Required",
  Not_more_than_1: "Not more than 1",
  Item_name: "Item name",
  Item_Name_is_required: "Item Name is required",
  Description: "Description",
  Price: "Price",
  Price_is_required: "Price is required",
  Price_description: "Price description",
  Image: "Image",
  Add_Image: "Add Image",
  Customization: "Customization",
  Add_New: "Add New",
  Add_item: "Add item",
  Create_new_tag: "Create new tag",
  Add: "Add",
  Mark_item_as: "Mark item as",
  Edit_tag: "Edit tag",
  Tag_name: "Tag name",
  Input_name_of_tag: "Input name of tag",
  Tag_color: "Tag color",
  Color_is_Required: "Color is Required",
  Tag_icon: "Tag icon",
  optional: "optional",
  Edit_category: "Edit category",
  Name: "Name",
  Category_name: "Category name...",
  Are_you_sure_you_want_to_delete: "Are you sure you want to delete",
  remove_customization_from_item: "This will remove customization from item.",
  remove_alert_message_menu:
    "This will remove all information  attached to this menu.",
  remove_alert_message_category:
    "This will remove all information  attached to this category.",
  Apply_the_changes: "Apply the changes",
  customisation_belongs_items:
    "This customisation also belongs to the items below",
  and: "and",
  more: "more",
  item: "item",
  items: "items",
  confirm_update_all: "Would you like to update all items?",
  Update_this_item_only: "Update this item only",
  Update_all: "Update all",
  Option_name: "Option name",
  Something_went_wrong: "Something went wrong",
  Edit_customization: "Edit customization",
  New_customization: "New customization",
  Customization_title: "Customization title",
  eg_sides: "E.g. sides",
  Rules: "Rules",
  show_separate_options:
    "Show selected options as separate items in incoming orders",
  required_selection: "Require customers to select an item",
  mex_selection_title:
    "What’s the maximum amount of items customers can select?",
  Option: "Option",
  PreSelected: "Pre-selected",
  Edit_menu: "Edit menu",
  Add_new_menu: "Add new menu",
  Menu_name: "Menu name...",
  Orders_enabled: "Commandes activées",
  Orders_disabled: "Orders disabled",
  No_pending_orders: "Aucune commande en attente",
  Pending_orders: "Pending orders",
  Logout: "Logout",
  Enter_table_number: "Enter table number",
  customer_name: "Enter name e.g. name of customer",
  Expires_at: "Expires at",
  Verified: "Verified",
  None: "None",
  Yes: "Yes",
  No: "No",
  Password: "Password",
  Enter_password: "Enter password",
  Customer_tab_link: "Customer tab link",
  generated_link: "Link will be generated upon creation of the tab",
  Copy: "Copy",
  Share_link_via_phone: "Share link via phone",
  Phone_Number: "Phone Number",
  Share_link_via_email: "Share link via email",
  Send_tab_link: "Send tab link to customer",
  Settle_tab: "Settle tab",
  Some_Error_Happen: "Some Error Happen, Try again",
  Search: "Search",
  Create_Tab: "Create Tab",
  No_tab_message: "No Tab in this period",
  Tab_orders: "Tab orders",
  Tab_details: "Tab details",
  Paid: "Paid",
  tip: "tip",
  Not_paid: "Not paid",
  Total: "Total",
  Out_Of_Stock: "Out Of Stock",
  Hide: "Hide",
  Available_until_from: "Available only from - until",
  edit_menu_availability: "Before you can edit your menu availability",
  create_your_first_menu: "you need to create your menu first",
  Take_me_there: "Take me there",
  Delete_Table: " Delete Table?",
  Add_table_number: "Add table number",
  Edit_table_number: "Edit table number",
  Enter_name: "Enter name",
  Order_is_going_to_removed: "Order is going to be removed from list",
  ACCEPTED: "ACCEPTED",
  Rejected: "Rejected",
  Request: "Request",
  Selected_Order: "Selected Order",
  na: "n/a",
  payment_Status: "payment Status",
  Order_Note: "Order_Note",
  Subtotal: "Subtotal",
  Reject: "Reject",
  Tip: "Tip",
  Accept: "Accept",
  Accepted: "Accepted",
  Bill_request_Pay_by: "Bill request - Pay by",
  Service_request: "Service request",
  Tab_Created: "Tab Created",
  Select_an_order_to_view_it: "Select an order to view it.",
  Link: "Link",
  Copied: "Copied",
  No_Tab_in_this_period: "No Tab in this period",
  Search_item_category_customization:
    "Search for item, category or customization...",
  Add_new_category: "Add new category",
  Add_new_item: "Add new item",
  adding_your_menu: "Start by adding your menu, then your categories",
  individual_items: "thereafter your individual items.",
  Edit_tab: "Edit tab",
  Create_new_tab: "Create new tab",
  Custom: "Custom",
  Today: "Today",
  Yesterday: "Yesterday",
  This_Week: "This Weeks",
  Last_Week: "Last Week",
  This_Month: "This Month",
  Last_Month: "Last Month",
  Close: "Close",
  Order_History: "Order History",
  Custom_Dates: "Custom Dates",
  Select_date_and_time: "Select date and time",
  FIXED_DATES: "FIXED DATES",
  Date: "Date",
  Order_ID: "Order ID",
  Table_Number: "Table Number",
  Payment_Status: "Payment Status",
  Sub_Total: "Sub Total",
  Total_Payment: "Total Payment",
  Status: "Status",
  Declined: "Declined",
  No_Order_in_period: "No Order in this period",
  View_previous_order: "View previous order",
  Select_date_for_reports: "Select Custom date or Fixed date to view Reports",
  creating_stripe_credit_card:
    "Creating a Stripe account enables receiving orders via credit card",
  IDEAL_Bancontact: "IDEAL, Bancontact",
  Stripe_Connected: "Stripe Connected",
  Connect_Stripe: "Connect Stripe",
  Register_account_and_agree: "By registering your account, you agree to the",
  Stripe_Services_Agreement: "Stripe Services Agreement",
  and_the: "and the",
  Stripe_connected_Account_Agreement: "Stripe connected Account Agreement",
  unavailable_feature:
    "This feature is available for menus with ordering fuctionality.",
  Contact_us: "Contact us",
  upgrade_your_menu: "to upgrade your menu.",
  Venue_details: "Venue details",
  Order_settings: "Order settings",
  Table_numbers: "Table numbers",
  QR_code: "QR code",
  Venues: "Venues",
  Hotel_guests: "Hotel guests",
  Hotel_profile: "Hotel profile",
  Hotel_QR_code: "Hotel QR code",
  Show_active_sessions: "Show active sessions",
  Show_up_coming: "Show up-coming",
  Create_guest_session: "Create guest session",
  You_have_seen_it_all: " Yay! You have seen it all",
  Release_to_refresh: "Release to refresh",
  Logo_image: "Logo image",
  App_icon: "App icon",
  Hotel_theme_color: "Hotel theme color",
  Select_Color: "Select Color",
  Hotel_QR_code: "Hotel QR code",
  Download_QR_code_image: "Download QR code image",
  auto_accept_orders: "Auto-accept orders",
  auto_accept_orders_from_tab: " Auto-accept orders from verified tabs",
  Request_waiter_service: "Request waiter service",
  allow_waiters_service:
    "Allow customers to request waiter service from the menu",
  Payment_options: "Payment options",
  Payment_Disabled: "Payment Disabled",
  Payment_Optional: "Payment Optional",
  Payment_Mandatory: "Payment Mandatory",
  Payment_provider: "Payment provider",
  Venue_name: "Venue name",
  Invalid_color_code: "Invalid color code",
  Select_Color: "Select Color",
  Venue_theme_color: "Venue theme color",
  Cover_photo: "Cover photo",
  Logo_image: "Logo image",
  Venue_description: "Venue description",
  Table_name: "Table name",
  QR_Code: "QR Code",
  Add_new_table: "Add new table",
  add_new_language: "Add new language",
  language: "Language",
  edit_language: "Edit language",
  delete_language: "Delete language",
  Add_Language: "Add Language",
  Localization:"Localization"

};

export default messages;
