import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://api.staging.blend.menu"
      : process.env.REACT_APP_BASE_URL,
  responseType: "json",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrfoken"
});
