import React from "react";
import stripePic from "../assets/image/stripe.png";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
const DownloadButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
    "&:focus": {
      // boxShadow: 'none',
      // backgroundColor: 'white',
      // borderColor: '#005cbf',
      backgroundColor: "#F9FAFC",
      color: "#34CEB2",
      border: "  1px solid #34CEB2",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    height: "88vh",
    background: "#F9FAFC",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    margin: "30px 0px 30px 0px",
  },
  // downloadText:{
  //     fontFamily: theme.fontFamily,
  //     fontStyle: "normal",
  //     fontWeight: "500",
  //     fontSize: "13px",
  //     lineHeight: "18px",
  //     color: "#303A5F",
  // }
}));
function StripeAccount() {
  const classes = useStyles();
  const intl = useIntl();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={2}>
              <Grid className={classes.PaymentProvider}>
                {intl.formatMessage({
                  id: "Select_date_for_reports",
                  defaultMessage:
                    "Select Custom date or Fixed date to view Reports",
                })}
              </Grid>
              <img src={stripePic} alt="no pic found" width={"100px"}></img>
            </Grid>
            <Grid item xs={9} style={{ margin: "80px 0px 0px 0px" }}>
              <p className={classes.GenralText}>
                {intl.formatMessage({
                  id: "creating_stripe_credit_card",
                  defaultMessage:
                    "Creating a Stripe account enables receiving orders via credit card,",
                })}
                <br />
                {intl.formatMessage({
                  id: "IDEAL_Bancontact",
                  defaultMessage: "IDEAL, Bancontact",
                })}
              </p>
              <DownloadButton
                variant="contained"
                color="primary"
                disabled={venueDetail && venueDetail.stripe_account_ref}
                onClick={() =>
                  venueDetail &&
                  venueDetail.stripe_account_ref &&
                  window.open(venueDetail.stripe_merchant_signup_url)
                }
              >
                {venueDetail && venueDetail.stripe_account_ref
                  ? intl.formatMessage({
                      id: "Stripe_Connected",
                      defaultMessage: "Stripe Connected",
                    })
                  : intl.formatMessage({
                      id: "Connect_Stripe",
                      defaultMessage: "Connect Stripe",
                    })} 
              </DownloadButton>
              <p className={classes.GenralText}>
                {intl.formatMessage({
                  id: "Register_account_and_agree",
                  defaultMessage:
                    "By registering your account, you agree to the",
                })}
                <u>
                  {intl.formatMessage({
                    id: "Stripe_Services_Agreement",
                    defaultMessage: "Stripe Services Agreement",
                  })} 
                </u> 
                {intl.formatMessage({
                  id: "and_the",
                  defaultMessage: "and the",
                })} 
                <br />
                <u>
                   
                  {intl.formatMessage({
                    id: "Stripe_connected_Account_Agreement",
                    defaultMessage: "Stripe connected Account Agreement",
                  })} 
                </u>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default StripeAccount;
