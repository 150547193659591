import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSearch, getNextSearchTab, getAllTabs } from "../../actions/order";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import "./HotelProfile.css";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import CreateGuestSessionDialog from "../../Components/GuestSession/CreateGuestSession";
import { updateHotelDetails } from "../../actions/hotelDetails";
import AddImage from "../../Components/AddImage/AddImage";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import CropImage from "../../Components/CropImage/CropImage";
import ColorPicker from "../../Components/ColorPicker/ColorPicker";
import { ACCOUNT_TYPES, HOTEL, INDEPENDENT_VENUE } from "../../utils/constants";
import AccountTypeDetailsModel from "./AccountTypeDetailsModel";
function HotelProfile() {
  const intl = useIntl();

  const [imageLogo, setImageLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [imageFileLogo, setImageFileLogo] = useState("");

  const [imageIcon, setImageIcon] = useState("");
  const [imageFileIcon, setImageFileIcon] = useState("");
  const [openColorPicker, setOpenColorPicker] = useState(false);

  const [openCrop, setOpenCrop] = useState(false);
  //types are "cover","logo"
  const [cropImageType, setCropImageType] = useState(null);
  const handleOpenCropImage = (type) => {
    setCropImageType(type);
    setOpenCrop(true);
  };
  const handleCloseCropImage = () => {
    setCropImageType(null);
    setOpenCrop(false);
  };
  const handleOpenColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };
  const handleCloseColorPicker = (e) => {
    e.stopPropagation();
    setOpenColorPicker(false);
  };
  let business_account = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail?.business_account
  );
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  const [openModel, setOpenModel] = useState(false);
  let dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [themeColor, setThemeColor] = useState("");
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    description: false,
  });
  const [openInfoModel, setOpenInfoModel] = useState(false);
  const [name, setName] = useState("");
  const [hotelDomain, setHotelDomain] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [accountType, setAccountType] = useState("");

  const timeZones = moment?.tz
    ?.names()
    ?.filter((tz) =>
      tz.match(
        /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/
      )
    );
  useEffect(() => {
    console.log("business_account", business_account);
    if (business_account) {
      setName(business_account?.name);
      setDescription(business_account?.description);
      setImageLogo(business_account?.logo);
      setThemeColor(business_account?.colour.replace("#", ""));
      setHotelDomain(business_account?.domain);
      setImageIcon(business_account?.app_icon);

      setSelectedTimeZone(business_account?.timezone);
      setAccountType(business_account?.type);
    }
  }, [business_account]);

  const handleCloseModel = () => {
    setOpenModel(false);
  };
  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const selectedFileLogo = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageLogo(URL.createObjectURL(event.target.files[0]));
      setImageFileLogo(event.target.files[0]);
      handleOpenCropImage("logo");
    }
  };

  const selectedFileIcon = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageIcon(URL.createObjectURL(event.target.files[0]));
      setImageFileIcon(event.target.files[0]);
      handleOpenCropImage("cover");
    }
  };

  const handleClearAppIcon = () => {
    setImageIcon("");
    setImageFileIcon("");
  };
  const handleClearLogo = () => {
    setImageLogo("");
    setImageFileLogo("");
  };
  const handleChangeName = (v) => {
    setName(v);
  };
  const handleSubmit = async () => {
    let formData = new FormData();
    if (imageFileLogo) {
      formData.append("logo", imageFileLogo);
    }
    if (imageFileIcon) {
      formData.append("app_icon", imageFileIcon);
    }

    formData.append("name", name);
    if (themeColor) {
      formData.append("colour", `#${themeColor}`);
    }
    if (selectedTimeZone !== "") {
      formData.append("timezone", selectedTimeZone);
    }
    if (hotelDomain !== "") {
      formData.append("domain", hotelDomain);
    }
    if (accountType !== "") {
      formData.append("type", accountType);
    }
    formData.append("description", description);
    if (business_account?.id) {
      setLoading(true);
      let res = await dispatch(
        updateHotelDetails({
          data: formData,
          business_account_uuid: User_uuid,
          id: business_account?.id,
        })
      );
      if (res?.status === 200) {
        setLoading(false);
      } else {
        setErrorMessage(res?.message);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 9000);
  }, [errorMessage]);
  const handleChangeDescription = (e) => {
    setErrors({ ...errors, description: false });
    setDescription(e.target.value);
  };
  const handleChangeTimeZone = (e) => {
    setErrors({ ...errors, timeZones: false });
    setSelectedTimeZone(e.target.value);
  };
  const handleChangeType = (e) => {
    setErrors({ ...errors, type: false });
    setAccountType(e.target.value);
  };
  const handleChangeHotelDomain = (e) => {
    setErrors({ ...errors, hotelDomain: false });
    setHotelDomain(e.target.value);
  };
  const setFile = (f, url) => {
    if (cropImageType === "cover") {
      setImageFileIcon(f);
      setImageIcon(url);
    } else {
      setImageFileLogo(f);
      setImageLogo(url);
    }
    handleCloseCropImage();
  };
  const handleChangeColor = (value) => {
    setThemeColor(value);
  };
  const handleCloseInfoModel = () => {
    setOpenInfoModel(false);
  };
  return (
    <>
      <AccountTypeDetailsModel
        open={openInfoModel}
        handleClose={handleCloseInfoModel}
      />
      {openModel && (
        <CreateGuestSessionDialog
          open={openModel}
          handleClose={handleCloseModel}
        />
      )}
      {openCrop && (
        <CropImage
          files={cropImageType === "cover" ? imageFileIcon : imageFileLogo}
          setImage={setFile}
          type={"logo"}
          handleClose={handleCloseCropImage}
        />
      )}
      <div>
        {User_uuid !== null ? (
          <Grid container className={classes.root}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid container justify="space-between">
                <Grid item xs={9} className={classes.GenralText}>
                  {intl.formatMessage({
                    id: "account_settings",
                    defaultMessage: "Account settings",
                  })}
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "10px" }} spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6} xl={6} md={6}>
                    <Grid container>
                      <Grid item>
                        <Typography
                          className={classes.headingTest}
                          style={{ paddingBottom: "0px", marginBottom: "10px" }}
                        >
                          {intl.formatMessage({
                            id: "account_type",
                            defaultMessage: "Account type",
                          })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div
                          onClick={() => setOpenInfoModel(true)}
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginTop: "2px",
                            backgroundColor: "#F66761",
                            borderRadius: "50%",
                            height: "20px",
                            width: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          ?
                        </div>
                      </Grid>
                    </Grid>

                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "account_type",
                        defaultMessage: "Account type",
                      })}
                      fullWidth
                      error={errors?.type}
                      helperText={
                        errors?.type &&
                        intl.formatMessage({
                          id: "Field_is_required",
                          defaultMessage: "Field is required",
                        })
                      }
                      select
                      onChange={(e) => handleChangeType(e)}
                      value={accountType}
                      defaultValue={accountType}
                      disabled={business_account?.type === HOTEL}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                      className={classes.selectRounded}
                    >
                      {business_account?.type === HOTEL
                        ? ACCOUNT_TYPES.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : ACCOUNT_TYPES.filter((i) => i.id !== HOTEL)?.map(
                            (item) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                    </TextField>
                    <small className={classes.infoMessage}>
                      *to change to hotel venue please{" "}
                      <span
                        onClick={() => window.HelpCrunch("openChat")}
                        style={{ color: "#F66761", cursor: "pointer" }}
                      >
                        contact us
                      </span>
                    </small>
                  </Grid>
                </Grid>
                {accountType !== INDEPENDENT_VENUE && (
                  <Grid item xs={12} sm={12} lg={6} xl={6} md={6}>
                    <Typography
                      className={classes.headingTest}
                      style={{ paddingBottom: "0px", marginBottom: "10px" }}
                    >
                      {business_account?.type === 2
                        ? intl.formatMessage({
                            id: "veune_name",
                            defaultMessage: "Veune Name",
                          })
                        : intl.formatMessage({
                            id: "hotel_name",
                            defaultMessage: "Hotel name",
                          })}
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder={
                        business_account?.type === 2
                          ? intl.formatMessage({
                              id: "venue_name",
                              defaultMessage: "Veune Name",
                            })
                          : intl.formatMessage({
                              id: "hotel_name",
                              defaultMessage: "Hotel name",
                            })
                      }
                      fullWidth
                      // error={itemDetailsErrors?.name}
                      // helperText={
                      //   itemDetailsErrors?.name && "Item Name is required"
                      // }
                      value={name}
                      onChange={(e) => handleChangeName(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                  </Grid>
                )}
                {accountType !== INDEPENDENT_VENUE && (
                  <Grid item xs={12} sm={12} lg={6} xl={6} md={6}>
                    <Typography
                      className={classes.headingTest}
                      style={{ paddingBottom: "0px", marginBottom: "10px" }}
                    >
                      {intl.formatMessage({
                        id: "Description",
                        defaultMessage: "Description",
                      })}
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "Description",
                        defaultMessage: "Description",
                      })}
                      fullWidth
                      error={errors?.description}
                      helperText={
                        errors?.description &&
                        intl.formatMessage({
                          id: "Field_is_required",
                          defaultMessage: "Field is required",
                        })
                      }
                      onChange={(e) => handleChangeDescription(e)}
                      value={description}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {accountType !== INDEPENDENT_VENUE && (
                <Grid
                  container
                  style={{ minHeight: "40px", marginTop: "30px" }}
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} lg={8} xl={8} md={8}>
                    <Typography
                      className={classes.headingTest}
                      style={{ paddingBottom: "0px", marginBottom: "10px" }}
                    >
                      {business_account?.type === 2
                        ? intl.formatMessage({
                            id: "venue_domain",
                            defaultMessage: "Venue Domain",
                          })
                        : intl.formatMessage({
                            id: "hotel_domain",
                            defaultMessage: "Hotel Domain",
                          })}
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder={
                        business_account?.type === 2
                          ? intl.formatMessage({
                              id: "venue_domain",
                              defaultMessage: "Venue Domain",
                            })
                          : intl.formatMessage({
                              id: "hotel_domain",
                              defaultMessage: "Hotel Domain",
                            })
                      }
                      fullWidth
                      // error={itemDetailsErrors?.name}
                      // helperText={
                      //   itemDetailsErrors?.name && "Item Name is required"
                      // }
                      value={hotelDomain}
                      disabled={hotelDomain}
                      onChange={handleChangeHotelDomain}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    />
                    <small className={classes.infoMessage}>
                      *to change the venue domain please{" "}
                      <span
                        onClick={() => window.HelpCrunch("openChat")}
                        style={{ color: "#F66761", cursor: "pointer" }}
                      >
                        contact us
                      </span>
                    </small>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4} xl={4} md={4}>
                    <Typography
                      className={classes.headingTest}
                      style={{ paddingBottom: "0px", marginBottom: "10px" }}
                    >
                      {intl.formatMessage({
                        id: "time_zone",
                        defaultMessage: "Time zone",
                      })}
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "select_time_zone",
                        defaultMessage: "Select Time zone",
                      })}
                      fullWidth
                      error={errors?.timeZones}
                      helperText={
                        errors?.timeZones &&
                        intl.formatMessage({
                          id: "Field_is_required",
                          defaultMessage: "Field is required",
                        })
                      }
                      select
                      onChange={(e) => handleChangeTimeZone(e)}
                      value={selectedTimeZone}
                      defaultValue={selectedTimeZone}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                        "aria-label": "search",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.outlinedStyle,
                        },
                      }}
                    >
                      {timeZones?.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              )}
              {accountType !== INDEPENDENT_VENUE && (
                <Grid container style={{ marginTop: "30px" }}>
                  <Grid item style={{ marginRight: "50px" }}>
                    <Typography
                      className={classes.headingTest}
                      style={{ marginBottom: "10px" }}
                    >
                      {intl.formatMessage({
                        id: "Logo_image",
                        defaultMessage: "Logo image",
                      })}
                    </Typography>
                    <AddImage
                      image={imageLogo}
                      selectedFile={selectedFileLogo}
                      handleClearImage={handleClearLogo}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.headingTest}
                      style={{ marginBottom: "10px" }}
                    >
                      {intl.formatMessage({
                        id: "App_icon",
                        defaultMessage: "App icon",
                      })}
                    </Typography>
                    <AddImage
                      image={imageIcon}
                      selectedFile={selectedFileIcon}
                      handleClearImage={handleClearAppIcon}
                    />
                  </Grid>
                </Grid>
              )}{" "}
              {accountType !== INDEPENDENT_VENUE && (
                <Grid container style={{ marginTop: "30px" }}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.headingTest}
                      style={{ marginBottom: "10px" }}
                    >
                      {business_account?.type === 2
                        ? intl.formatMessage({
                            id: "theme_color",
                            defaultMessage: "Theme color",
                          })
                        : intl.formatMessage({
                            id: "Hotel_theme_color",
                            defaultMessage: "Hotel theme color",
                          })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          placeholder={intl.formatMessage({
                            id: "Select_Color",
                            defaultMessage: "Select Color",
                          })}
                          value={themeColor}
                          onChange={(e) => setThemeColor(e.target.value)}
                          inputProps={{
                            className: classes.TextInputStyle90,
                            "aria-label": "search",
                          }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.outlinedStyle,
                            },
                          }}
                          style={{
                            borderRadius: "5px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",

                          maxHeight: "40px",
                        }}
                      >
                        <div
                          onClick={handleOpenColorPicker}
                          style={{
                            borderRadius: "5px",
                            height: "27px",
                            width: "27px",
                            backgroundColor: `#${themeColor}`,
                          }}
                        />
                        {openColorPicker && (
                          <ColorPicker
                            handleCloseColorPicker={handleCloseColorPicker}
                            color={themeColor}
                            handleChangeColor={handleChangeColor}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                {
                  <small style={{ color: "#f44336", marginTop: "15px" }}>
                    {errorMessage}
                  </small>
                }
              </Grid>
              <Grid
                container
                style={{ marginTop: "50px", paddingBottom: "20px" }}
              >
                <Button
                  variant="contained"
                  className={classes.printButton}
                  disabled={false}
                  onClick={() => handleSubmit()}
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                      defaultMessage: "Save",
                    })
                  )}
                </Button>
                <Button
                  // variant="contained"
                  className={classes.cancelButton}
                  disabled={false}
                  // onClick={() => handleTab()}
                >
                  {intl.formatMessage({
                    id: "Cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <CircularProgress size={40} color="primary" />
          </Grid>
        )}
        <Grid item xs={1}></Grid>
      </div>
    </>
  );
}

export default HotelProfile;

const useStyles = makeStyles((theme) => ({
  infoMessage: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "14px",
    color: "#A3A9BC",
  },
  cancelButton: {
    marginLeft: "10px",
    marginTop: "10px",
    textTransform: "none",
    background: "#F9FAFC",
    color: theme.colors.primary,
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    border: "0px solid #F9FAFC",
    "&:hover": {
      backgroundColor: "#F9FAFC",
    },
  },
  printButton: {
    marginTop: "10px",
    textTransform: "none",
    background: "#34CEB2",
    color: "#FFFFFF",
    padding: "5px 15px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#20BDA1",
      // border: "  1px solid #20BDA1",
    },
  },
  infiniteScroll: {
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
  },
  tableRow: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    margin: "5px",
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F8",
      borderRadius: "2px",
    },
    // p:nth-child(2) {
    //     background: red;
    //   }
  },
  TableCellText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    paddingLeft: "5px",
  },
  headingContainer: {
    marginTop: "20px",
  },
  root: {
    minHeight: "88vh",
    paddingTop: "22px",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: theme.colors.primary,
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: theme.colors.primary,
    margin: "0px",
  },
  TextInputStyle90: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
    minWidth: "100px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  selectRounded: {
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "10px",
    minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.colors.primary,
    margin: "15px 0px 15px 0px",
  },

  outlinedStyle: {
    border: "0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.colors.primary,
  },
}));
