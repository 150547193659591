import {
  GET_USER_PERMISSIONS,
  GET_PERMISSION_ROLES,
  GET_AVAILABLE_VENUES,
  ADD_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
  DELETE_USER_PERMISSIONS,
} from "../constant/types";
import API from "../utils/API";
import { getUserDetail } from "./order";

export const getPermissionUsers =
  ({ business_account_uuid }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.get(
        `/api/business/permission-management/`,
        config
      );
      let data = response?.data;
      dispatch({ type: GET_USER_PERMISSIONS, payload: data });

      return 200;
    } catch (error) {
      return 400;
    }
  };

export const getAvailablePermissionRoles =
  ({ business_account_uuid }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.get(
        `/api/business/permission-management/list-available-permissions/`,
        config
      );
      let data = response?.data;
      dispatch({ type: GET_PERMISSION_ROLES, payload: data });

      return 200;
    } catch (error) {
      return 400;
    }
  };
export const getAvailableVenues =
  ({ business_account_uuid }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.get(
        `/api/business/permission-management/list-available-venues/`,
        config
      );
      let data = response?.data;
      dispatch({ type: GET_AVAILABLE_VENUES, payload: data });

      return 200;
    } catch (error) {
      return 400;
    }
  };

export const addNewUser =
  ({ business_account_uuid, data }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };
    const body = JSON.stringify(data);

    try {
      const response = await API.post(
        `/api/business/permission-management/`,
        body,
        config
      );
      let data = response?.data;
      dispatch({ type: ADD_USER_PERMISSIONS, payload: data });

      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  };
export const updateUser =
  ({ business_account_uuid, data, id }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };
    const body = JSON.stringify(data);

    try {
      const response = await API.patch(
        `/api/business/permission-management/${id}/`,
        body,
        config
      );

      let data = response?.data;
      dispatch({ type: UPDATE_USER_PERMISSIONS, payload: { data, id } });
      dispatch(getUserDetail());
      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  };

export const deleteUser =
  ({ business_account_uuid, id }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "business-account-uuid": business_account_uuid,
        Authorization: "Token " + localStorage.AuthUserKey,
      },
    };

    try {
      const response = await API.delete(
        `/api/business/permission-management/${id}/`,
        config
      );

      dispatch({ type: DELETE_USER_PERMISSIONS, payload: id });

      return { status: 200 };
    } catch (error) {
      return { status: 400 };
    }
  };
