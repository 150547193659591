import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store/store";
import theme from "./utils/theme";
import "opensans-npm-webfont";
import { ThemeProvider } from "@material-ui/core/styles";
import "@openfonts/open-sans_all/index.css";
// import { IntlProvider } from "react-intl";
// import en from "./utils/locales/en";
// // import es from "./utils/locales/es";
// import fr from "./utils/locales/fr";
require("dotenv").config();
// let locale = "en";
// const msg = {
//   en: en,
//   fr: fr,
// };
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <IntlProvider locale={locale} messages={msg[locale]}> */}
        <App />
      {/* </IntlProvider> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
