import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { TextButtonWhite, TextButtonAdd } from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export default function ConfirmUpdateCustomizationDialog(props) {
  const [items, setItems] = useState([]);
  const intl = useIntl();

  const { loading } = props;
  const classes = useStyles();
  let menuCustomization = useSelector(
    (state) => state.menuReducer?.menuCustomization
  );
  const handleClose = () => {
    props.handleClose(false);
  };
  useEffect(() => {
    menuCustomization &&
      menuCustomization.results &&
      menuCustomization.results.forEach((element) => {
        if (element?.name === props?.item?.name) {
          setItems(element?.assigned_items);
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item xs={10} style={{ display: "flex" }}>
              <Typography className={classes.title}>
                {intl.formatMessage({
                  id: "Apply_the_changes",
                })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0px" }}>
          <Grid container>
            <Grid container style={{ marginBottom: "10px" }}>
              <Typography className={classes.headingTest}>
                { intl.formatMessage({
                    id: "customisation_belongs_items",
                  })}
                :
              </Typography>
            </Grid>
            {items &&
              items.slice(0, 3).map((element, i) => {
                return (
                  <Grid container key={i}>
                    <Typography className={classes.headingTestItems}>
                      - {element?.name}
                    </Typography>
                  </Grid>
                );
              })}

            {items.length > 3 && (
              <Grid container>
                <Typography className={classes.headingTestItemsMore}>
                  {` ${ intl.formatMessage({
                    id: "and",
                  })}
                   ${items?.length - 3} ${ intl.formatMessage({
                    id: "more",
                  })} ${
                    items?.length > 4 ?  intl.formatMessage({
                      id: "items",
                    }):  intl.formatMessage({
                      id: "item",
                    })
                  }`}
                </Typography>
              </Grid>
            )}
            <Grid container style={{ marginTop: "10px" }}>
              <Typography className={classes.headingTest}>
                {intl.formatMessage({
                      id: "confirm_update_all",
                    })}
                
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider
          style={{ margin: "20px 24px", color: "#EDF0F4", height: "1px" }}
        />
        <Grid container justify="center">
          {loading.message}
        </Grid>
        <DialogActions style={{ padding: "18px 24px 30px 8px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            spacing={2}
          >
            <Grid item>
              {loading?.loader && loading?.loaderType == "update" ? (
                <TextButtonWhite>
                  <CircularProgress size={20} />
                </TextButtonWhite>
              ) : (
                <TextButtonWhite onClick={() => props?.handleUpdate()}>
                   {intl.formatMessage({
                      id: "Update_this_item_only",
                    })}
                  
                </TextButtonWhite>
              )}
            </Grid>
            <Grid item>
              {loading?.loader && loading?.loaderType === "updateAll" ? (
                <TextButtonAdd>
                  <CircularProgress size={20} />
                </TextButtonAdd>
              ) : (
                <TextButtonAdd onClick={props.handleUpdateAll}>
                  {intl.formatMessage({
                      id: "Update_all",
                    })}
                  
                </TextButtonAdd>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTestItems: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
  headingTestItemsMore: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
}));
