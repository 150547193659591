import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signupUser } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import LoginPicture from "../../assets/image/loginPic.png";
import { useIntl } from "react-intl";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ChooseAccountType from "./ChooseAccountType";
export default function Signup() {
  const intl = useIntl();

  let dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(false);

  const classes = useStyles();
  const [errorEmail, setErrorEmail] = useState(false);

  const [loader, setLoader] = useState(false);
  const [confirmSignup, setConfirmSignup] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError1, setPasswordError1] = useState({
    error: false,
    message: "",
  });
  const [passwordError2, setPasswordError2] = useState({
    error: false,
    message: "",
  });
  const [responseError, setResponseError] = useState({
    company_name: "",
    email: "",
    name: "",
  });
  const [type, setType] = useState(null);
  //type and signup
  const [currentScreen, setCurrentScreen] = useState("type");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [terms, setTerms] = useState(false);
  const handleEmail = (event) => {
    setEmail(event.target.value);
    setErrorEmail(false);
    setResponseError({ ...responseError, email: "" });
  };
  const handleName = (event) => {
    setResponseError({ ...responseError, name: "" });
    setName(event.target.value);
  };
  const handleTerms = (event) => {
    setTerms(event.target.selected);
  };
  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
    setResponseError({ ...responseError, company_name: "" });
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError1({ error: false, message: "" });
  };
  const handlePassword2 = (event) => {
    setPassword2(event.target.value);
    setPasswordError2({ error: false, message: "" });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && email !== null && password !== null) {
      handleLogin();
    }
  };

  useEffect(() => {
    localStorage.AuthUserKey && history.push("RestaurantsGroups");
  }, [history]);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleLogin = async () => {
    if (
      password.length < 10 ||
      password2.length < 10 ||
      !validateEmail(email) ||
      password !== password2
    ) {
      let e1 = {
        error: false,
        message: "",
      };
      let e2 = {
        error: false,
        message: "",
      };
      if (password !== password2) {
        e1 = {
          error: true,
          message: "Password not matched",
        };
        e2 = {
          error: true,
          message: "Password not matched",
        };
      }
      if (password.length < 10) {
        e1 = {
          error: true,
          message: "Minimum password length is 10",
        };
      }
      if (password2.length < 10) {
        e2 = {
          error: true,
          message: "Minimum password length is 10",
        };
      }
      if (!validateEmail(email)) {
        setErrorEmail(true);
      }
      setPasswordError1({ ...e1 });
      setPasswordError2({ ...e2 });
    } else if (validateEmail(email)) {
      setLoader(true);
      const obj = {
        name: name,
        email: email,
        type: type,
        password: password2,
        password2: password,
        company_name: companyName,
        timezone: "Asia/Nicosia",
      };
      let res = await dispatch(signupUser({ user: obj }));
      if (res?.status === 200) {
        setConfirmSignup(true);
      } else {
        let obj = { company_name: "", name: "", email: "" };
        obj = {
          company_name: res?.data?.company_name,
          name: res?.data?.name,
          email: res?.data?.email,
        };

        setResponseError(obj);

        setErrorMessage(true);
      }
      setLoader(false);
    } else {
      setErrorEmail(true);
    }
  };
  return (
    <>
      {currentScreen === "type" ? (
        <ChooseAccountType
          type={type}
          setType={setType}
          setCurrentScreen={setCurrentScreen}
        />
      ) : (
        <Grid container style={{ height: "90px" }}>
          <Link
            // href="/Login"
            variant="body2"
            onClick={() => setCurrentScreen("type")}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Grid container style={{ marginTop: "18px", marginLeft: "136px" }}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <KeyboardArrowLeftIcon style={{ color: "#303A5F" }} />
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography component="span" className={classes.backButton}>
                  Go back
                </Typography>
              </Grid>
            </Grid>
          </Link>
          {!confirmSignup ? (
            <Grid container justify="center" onKeyDown={handleKeyDown}>
              <Grid item>
                <Container component="main" style={{ width: "80vw" }}>
                  <CssBaseline />
                  <div className={classes.paper}>
                    <Typography
                      className={classes.generalText}
                      component="span"
                    >
                      {intl.formatMessage({
                        id: "Sign up",
                      })}
                    </Typography>
                    <Grid container className={classes.form} noValidate>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={4}
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          justify="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            style={{ maxWidth: "390px" }}
                          >
                            <Typography
                              component="span"
                              className={classes.title}
                            >
                              Your name
                            </Typography>
                            <TextField
                              onChange={handleName}
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              value={name}
                              type="text"
                              placeholder="Enter name"
                              className={classes.textField}
                              error={responseError?.name}
                              helperText={responseError?.name}
                              autoFocus
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            style={{ maxWidth: "390px" }}
                          >
                            <Typography
                              component="span"
                              className={classes.title}
                            >
                              Email
                            </Typography>
                            <TextField
                              onChange={handleEmail}
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              value={email}
                              error={responseError?.email !== "" || errorEmail}
                              helperText={
                                (errorEmail && "Invalid email") ||
                                responseError?.email
                              }
                              type="email"
                              placeholder="Enter email"
                              autoFocus
                              className={classes.textField}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={4}
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          justify="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            style={{ maxWidth: "390px" }}
                          >
                            <Typography
                              component="span"
                              className={classes.title}
                            >
                              Password
                            </Typography>
                            <TextField
                              onChange={handlePassword}
                              variant="outlined"
                              margin="normal"
                              required
                              error={passwordError1.error}
                              helperText={passwordError1.message}
                              fullWidth
                              placeholder="Enter password"
                              type="password"
                              value={password}
                              className={classes.textField}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            xl={6}
                            lg={6}
                            style={{ maxWidth: "390px" }}
                          >
                            <Typography
                              component="span"
                              className={classes.title}
                            >
                              Confirm password
                            </Typography>
                            <TextField
                              onChange={handlePassword2}
                              variant="outlined"
                              margin="normal"
                              required
                              className={classes.textField}
                              fullWidth
                              error={passwordError2.error}
                              helperText={passwordError2.message}
                              placeholder="Re enter password"
                              type="password"
                              value={password2}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "0px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container justify="center">
                        <Grid item xs={12} style={{ maxWidth: "750px" }}>
                          <Typography
                            component="span"
                            className={classes.title}
                          >
                            Company name (Your guests will not see this)
                          </Typography>
                          <TextField
                            onChange={handleCompanyName}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            error={responseError?.company_name !== ""}
                            helperText={responseError?.company_name}
                            placeholder="Enter company name"
                            type="text"
                            className={classes.textField}
                            value={companyName}
                            style={{
                              background: "#FFFFFF",
                              borderRadius: "5px",
                              border: "0px",
                            }}
                          />
                        </Grid>
                        <Grid container justify="center">
                          <Grid
                            item
                            xs={12}
                            style={{
                              maxWidth: "750px",
                              marginTop: "11px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FormControlLabel
                              style={{ paddingLeft: "20px", color: "#909090" }}
                              className={classes.checkButton}
                              control={
                                <CustomCheckbox
                                  value={terms}
                                  onSelect={handleTerms}
                                  className={classes.checkButton}
                                />
                              }
                              label={
                                <span className={classes.checkButton}>
                                  {intl.formatMessage({
                                    id: "Agree to ",
                                  })}
                                  <a
                                    className={classes.checkButton}
                                    href="https://blend.menu/terms-and-conditions/"
                                    target="_blank"
                                  >
                                    Terms & Conditions
                                  </a>
                                </span>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container justify="center">
                        {loader ? (
                          <AcceptButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{
                              width: "159px",
                              marginTop: "37px",
                              height: "40px",
                            }}
                          >
                            <CircularProgress
                              size={25}
                              style={{ color: "#fff" }}
                            />
                          </AcceptButton>
                        ) : (
                          <AcceptButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => handleLogin()}
                            style={{
                              width: "159px",
                              marginTop: "37px",
                              height: "40px",
                            }}
                          >
                            {intl.formatMessage({
                              id: "Sign me up!",
                            })}
                          </AcceptButton>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <Box mt={8}></Box>
                </Container>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.centerContainer}>
              <Grid container justify="center">
                <Grid container justify="center">
                  <Typography component="span" className={classes.headingText}>
                    Account created!
                  </Typography>
                </Grid>
                <Grid container justify="center">
                  <Typography component="span" className={classes.body}>
                    Please check your email to verify the address.
                  </Typography>
                </Grid>
                <Grid container justify="center">
                  <AcceptButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => history.push("Login")}
                    style={{ width: "192px", marginTop: "37px" }}
                  >
                    {intl.formatMessage({
                      id: "Take me to login",
                    })}
                  </AcceptButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}{" "}
    </>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);

const CustomCheckbox = withStyles({
  root: {
    color: "#A3A9BC !important",
    "&$checked": {
      color: "#F66761 !important",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
  checkButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: "#303A5F",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#303A5F",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
    textDecoration: "none",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  generalText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
    marginBottom: "120px",
  },
  textField: {
    marginTop: "6px",
    marginBottom: "28px",
  },
}));
