import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MainBar from "../../Components/Restaurants/MainBar";
import { makeStyles } from "@material-ui/core";
import { getUserDetail } from "../../actions/order";
import "..//../style/home.css";
import TabBar from "../../containers/Hotel/TabBar";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
function RestaurantsGroups() {
  let dispatch = useDispatch();
  const intl = useIntl();

  const [currentTab, setCurrentTab] = useState(0);
  useEffect(() => {
    if (localStorage.AuthUserKey) {
      dispatch(getUserDetail({ Authorization: localStorage.AuthUserKey }));
    }
    // eslint-disable-next-line
  }, [localStorage?.AuthUserKey]);

  const classes = useStyles();
  const handleClickOrders = () => {
    setCurrentTab(0);
  };
  return (
    <div className={classes.root}>
      <MainBar handleClickOrders={handleClickOrders} />
      <TabBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </div>
  );
}
export default RestaurantsGroups;
