import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { TextButtonAdd } from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import {
  addCustomization,
  deleteCategory,
  updateAddCustomization,
  updateCategory,
  addCustomizationTemplate,
  updateCustomizationTemplate,
  getMenuDetails,
  deleteCustomizationTemplate,
  getCustomizationList,
  deleteCustomization,
  updateCustomization,
} from "../../actions/menu";
import { TextButtonNoColor, TextButton } from "../Buttons/Buttons";
import DragnDropCustomization from "./DragnDropCustomization";
import ConfirmUpdateCustomizationDialog from "./ConfirmUpdateCustomizationDialog";
import ConfirmDeleteCustomizationDialog from "./ConfirmDeleteCustomizationDialog";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export default function ItemCustomization(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let menuCustomization = useSelector(
    (state) => state.menuReducer?.menuCustomization
  );

  const [open, setOpen] = React.useState(false);
  const [openUpdateModel, setOpenUpdateModel] = React.useState(false);
  const [name, setName] = React.useState("");
  const [required, setRequired] = React.useState(false);
  const [displayAsMainItem, setDisplayAsMainItem] = React.useState(false);
  const [maxSelectableCount, setMaxSelectableCount] = React.useState(1);
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [currentSelectedCount, setCurrentSelectedCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState({
    loader: false,
    message: "",
    loaderType: "",
  });
  const handleAddNewItem = () => {
    let maxPosition = Math.max.apply(
      Math,
      options.map(function (o) {
        return o.position;
      })
    );
    const obj = {
      customisation_option_id: Math.random().toString(36).substring(7),
      name: "",
      position: maxPosition + 1,
      price: "0.00",
      pre_selected: false,
    };
    setOptions([...options, { ...obj }]);
    setErrors([...errors, { name: false, price: false }]);
    setUpdateData(true);
  };
  const handleAddItems = (arr) => {
    if (arr && arr.length > 0) {
      let newArrOptions = [];
      let newArrErr = [];
      arr.forEach((element) => {
        let obj = {
          customisation_option_id: element?.customisation_option_id,
          name: element?.name,
          position: element?.position,
          pre_selected: element?.pre_selected,
          price: element?.price,
        };
        newArrOptions.push(obj);
        newArrErr.push({ name: false, price: false });
      });
      setOptions([...newArrOptions]);
      setErrors([...newArrErr]);
    }
  };
  useEffect(() => {
    if (props?.editCustmization) {
      setNameError(false);
      setMaxSelectableCount(props?.editCustmization?.max_selectable);
      setName(props?.editCustmization?.name);
      setRequired(props?.editCustmization?.required);
      setDisplayAsMainItem(
        props?.editCustmization?.display_as_main_item > 0 ? true : false
      );
      handleAddItems(props?.editCustmization?.customisation_options);
    } else {
      handleAddNewItem();
    }
    // eslint-disable-next-line
  }, [props?.editCustmization, props]);

  const handleRemoveOption = (id) => {
    let newOptions = options.filter(
      (item) => item.customisation_option_id !== id
    );
    setUpdateData(true);

    setOptions(newOptions);
  };

  const updateOptionValues = (id, value, type) => {
    let index = options.findIndex(
      (item) => item.customisation_option_id === id
    );
    let newOptions = [...options];
    if (index > -1) {
      if (type === "price") {
        if (value >= 0) {
          newOptions[index][type] = value;
        }
      } else {
        newOptions[index][type] = value;
      }
    }
    let arr = newOptions?.filter((n) => n.pre_selected === true);
    setCurrentSelectedCount(arr.length);
    setOptions(newOptions);
    setUpdateData(true);
  };

  const handleClickOpen = () => {
    if (updateData) {
      let arrOfItems = [];
      menuCustomization &&
        menuCustomization.results &&
        menuCustomization.results.forEach((element) => {
          if (element?.id === props?.editCustmization?.customisation_template) {
            arrOfItems = [...element?.assigned_items];
          }
        });
      if (arrOfItems?.length > 1) {
        setOpenUpdateModel(true);
      } else {
        handleUpdateAll();
      }
    } else {
      handleClose();
    }
  };
  const handleCloseUpdate = () => {
    setOpenUpdateModel(false);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };

  const handleAdd = async () => {
    let noError = true;
    if (name === null || name === undefined || name.trim() === "") {
      setNameError(true);
      noError = false;
      setOpenUpdateModel(false);
    }
    let errorsArr = [...errors];
    options.forEach((element, indexElement) => {
      if (element.name.trim() === "") {
        errorsArr[indexElement]["name"] = true;
        setOpenUpdateModel(false);
      }
    });
    setErrors(errorsArr);
    if (noError) {
      let obj = {
        item_customisation_id: props?.editCustmization
          ? props?.editCustmization?.item_customisation_id
          : parseInt(Math.random() * (19 - 11) + 11),
        customisation_options: [...options],
        name: name,
        required: required,
        max_selectable: maxSelectableCount,
        position: parseInt(Math.random() * (19 - 11) + 11),
        display_as_main_item: displayAsMainItem,
      };
      const { categoryId, itemId, menuId } = props.item;
      let res = null;
      if (props?.editCustmization) {
        setLoading({
          loader: true,
          message: "",
          loaderType: "update",
          showError: false,
        });
        res = await dispatch(
          updateCustomization({
            itemId: itemId,
            categoryId: categoryId,
            menuId: menuId,
            id: props?.editCustmization?.item_customisation_id,
            body: obj,
          })
        );
        if (res === 200) {
          props && props.handleCloseDelete();
          setOpenUpdateModel(false);
          setLoading({
            loader: false,
            message: "",
            loaderType: "",
            showError: false,
          });
        } else {
          setLoading({
            loader: false,
            message: intl.formatMessage({
              id: "Something_went_wrong",
            }),
            loaderType: "",
            showError: true,
          });
        }
      } else {
        setLoading({
          loader: true,
          message: "",
          loaderType: "add",
          showError: false,
        });
        res = await dispatch(
          addCustomizationTemplate({
            itemId: itemId,
            categoryId: categoryId,
            menuId: menuId,
            data: obj,
          })
        );
        if (res?.status === 200) {
          let newIdsObj = { ...props?.item, id: props?.item?.itemId };
          dispatch(
            updateAddCustomization({
              ...newIdsObj,
              templete: { id: res?.id },
            })
          );
          props && props.handleCloseDelete();
          setOpenUpdateModel(false);
          setLoading({
            loader: false,
            message: "",
            loaderType: "",
            showError: false,
          });
        } else {
          setLoading({
            loader: false,
            message: intl.formatMessage({
              id: "Something_went_wrong",
            }),
            loaderType: "",
            showError: true,
          });
        }
      }
    }
  };

  const handleDelete = async () => {
    const { categoryId, itemId, menuId } = props.item;
    let ind = props?.addedCustomizations?.findIndex(
      (n) =>
        n?.item_customisation_id ===
        props?.editCustmization?.item_customisation_id
    );
    if (ind > -1) {
      let res = await dispatch(
        deleteCustomization({
          id: props?.editCustmization?.item_customisation_id,
          itemId: itemId,
          categoryId: categoryId,
          menuId: menuId,
        })
      );
      if (res === 200) {
        dispatch(getCustomizationList());
        props &&
          props.handleCloseDeleteById(
            props?.editCustmization?.item_customisation_id
          );
      }
    } else {
      props &&
        props.handleCloseDeleteById(
          props?.editCustmization?.item_customisation_id
        );
    }
  };
  const handleChangeCheckBox = (e, key) => {
    setUpdateData(true);

    if (key === "required") {
      setRequired(e.target.checked);
    } else {
      setDisplayAsMainItem(e.target.checked);
    }
  };
  const handleChangeMamCount = (e) => {
    setUpdateData(true);

    if (e.target.value === "") {
      setMaxSelectableCount(e.target.value);
    } else {
      let regex = /^[0-9]+$/;
      if (e.target.value.match(regex)) {
        setMaxSelectableCount(e.target.value);
      } else {
        setMaxSelectableCount(maxSelectableCount);
      }
    }
  };
  const handleUpdateAll = async () => {
    let noError = true;
    if (name === null || name === undefined || name.trim() === "") {
      setNameError(true);
      noError = false;
      setOpenUpdateModel(false);
    }
    let errorsArr = [...errors];
    options.forEach((element, indexElement) => {
      if (element.name.trim() === "") {
        errorsArr[indexElement]["name"] = true;
        setOpenUpdateModel(false);
      }
    });
    setErrors(errorsArr);
    if (noError) {
      let obj = {
        item_customisation_id: props?.editCustmization
          ? props?.editCustmization?.item_customisation_id
          : parseInt(Math.random() * (19 - 11) + 11),
        customisation_options: [...options],
        name: name,
        required: required,
        max_selectable: maxSelectableCount,
        position: parseInt(Math.random() * (19 - 11) + 11),
        display_as_main_item: displayAsMainItem,
      };
      const { categoryId, itemId, menuId } = props.item;
      let res = null;
      if (props?.editCustmization) {
        setLoading({
          loader: true,
          message: "",
          loaderType: "updateAll",
          showError: false,
        });
        res = await dispatch(
          updateCustomizationTemplate({
            itemId: itemId,
            categoryId: categoryId,
            menuId: menuId,
            id: props?.editCustmization?.item_customisation_id,
            data: obj,
          })
        );
      }
      if (res === 200) {
        dispatch(getMenuDetails({ venue_uuid: venueDetail?.uuid }));
        props && props.handleCloseDelete();
        setOpenUpdateModel(false);
        setLoading({
          loader: false,
          message: "",
          loaderType: "",
          showError: false,
        });
      } else {
        setLoading({
          loader: false,
          message: intl.formatMessage({
            id: "Something_went_wrong",
          }),
          loaderType: "",
          showError: true,
        });
      }
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {openDelete && (
          <ConfirmDeleteCustomizationDialog
            open={openDelete}
            handleClose={setOpenDelete}
            confirmDelete={handleDelete}
            name={name}
          />
        )}
        <ConfirmUpdateCustomizationDialog
          loading={loading}
          open={openUpdateModel}
          handleUpdate={handleAdd}
          handleClose={handleCloseUpdate}
          handleUpdateAll={handleUpdateAll}
          item={props?.editCustmization}
        />
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Typography className={classes.headerText}>
                {props?.editCustmization
                  ? intl.formatMessage({
                      id: "Edit_customization",
                    })
                  : intl.formatMessage({
                      id: "New_customization",
                    })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography className={classes.headingText}>
              {intl.formatMessage({
                id: "Customization_title",
              })}
            </Typography>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "eg_sides",
              })}
              fullWidth
              value={name}
              error={nameError}
              helperText={
                nameError &&
                intl.formatMessage({
                  id: "Field_is_required",
                })
              }
              onChange={(e) => {
                setName(e.target.value);
                setUpdateData(true);
                setNameError(false);
              }}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              inputProps={{
                className: classes.TextInputStyle2,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Typography className={classes.headingText}>
                {intl.formatMessage({
                  id: "Rules",
                })}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography className={classes.bodyText}>
                Select rules to control how customers select items in this
                customization.
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayAsMainItem}
                    onChange={(e) =>
                      handleChangeCheckBox(e, "display_as_main_item")
                    }
                    name="gilad"
                  />
                }
                label={intl.formatMessage({
                  id: "show_separate_options",
                })}
                className={classes.checkBoxLabel}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={required}
                    onChange={(e) => handleChangeCheckBox(e, "required")}
                    name="gilad"
                  />
                }
                label={intl.formatMessage({
                  id: "required_selection",
                })}
                className={classes.checkBoxLabel}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "31px",
                  }}
                >
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={displayAsMainItem}
                        onChange={(e) =>
                          handleChangeCheckBox(e, "displayAsMainItem")
                        }
                        name="gilad"
                        style={{ display: "none" }}
                      />
                    }
                    label="What’s the maximum amount of items customers can select?"
                    className={classes.checkBoxLabel}
                  /> */}
                  <Typography className={classes.checkBoxLabel}>
                    {intl.formatMessage({
                      id: "mex_selection_title",
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    placeholder="0"
                    fullWidth
                    value={maxSelectableCount}
                    onChange={(e) => handleChangeMamCount(e)}
                    // disabled={!setDisplayAsMainItem}
                    style={{
                      borderRadius: "5px",
                      width: "40px",
                      marginLeft: "10px",
                    }}
                    inputProps={{
                      className: classes.TextInputStyle,
                      "aria-label": "search",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography className={classes.headingText}>
                    {intl.formatMessage({
                      id: "Option",
                    })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    minWidth: "22.666667%",
                  }}
                >
                  <Typography className={classes.headingText}>
                    {intl.formatMessage({
                      id: "Price",
                    })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    minWidth: "18.666667%",
                  }}
                >
                  <Typography className={classes.headingText}>
                    {intl.formatMessage({
                      id: "PreSelected",
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DragnDropCustomization
                errors={errors}
                options={options}
                item={{
                  ...props?.item,
                  customizationId:
                    props?.editCustmization?.item_customisation_id,
                }}
                handleRemoveOption={handleRemoveOption}
                updateOptionValues={updateOptionValues}
                displayAsMainItem={displayAsMainItem}
                maxSelectableCount={maxSelectableCount}
                currentSelectedCount={currentSelectedCount}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "6px" }}>
              <TextButtonNoColor onClick={handleAddNewItem}>
                {intl.formatMessage({
                  id: "Add_item",
                })}
              </TextButtonNoColor>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px 30px 24px" }}>
          {props?.editCustmization &&
          props?.editCustmization?.item_customisation_id ? (
            <Grid
              container
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <TextButton onClick={() => setOpenDelete(true)}>
                  {intl.formatMessage({
                    id: "Delete",
                  })}
                </TextButton>
              </Grid>
              <Grid item>
                <TextButtonAdd onClick={handleClickOpen}>
                  {intl.formatMessage({
                    id: "Save",
                  })}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <TextButtonAdd onClick={handleAdd}>
                {intl.formatMessage({
                  id: "Create",
                })}
              </TextButtonAdd>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },
  bodyText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));
