import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import DeleteIcon from "@material-ui/icons/Delete";
import Label from "@material-ui/icons/Label";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import InfoIcon from "@material-ui/icons/Info";
import ForumIcon from "@material-ui/icons/Forum";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, CircularProgress, Divider, Grid } from "@material-ui/core";
import MenuAvailabilityDialog from "../DialogBox/MenuAvailability";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MenuAvailability from "../MenuAvailability";
import { updateAvailability } from "../../actions/menuAvailability";
import { useDispatch } from "react-redux";
// import { ReactComponent as Icon1 } from "../assets/image/arrows.svg";
import { ReactComponent as Icon1 } from "../../assets/image/arrows.svg";
import { useIntl } from "react-intl";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {},
    "&:focus > $content, &$selected > $content": {
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      //   paddingLeft: theme.spacing(2),
    },
  },
  groupLevel2: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  groupLevel3: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(4),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    //padding: theme.spacing(0.5, 0),
  },
  labelRootLevel2: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
  daysContainerSelected: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#F9FAFC",
    background: "#F66761",
    borderRadius: "3px",
    border: "1px solid #F66761",
    height: 16,
    width: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 5px",
  },
  daysContainer: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#A3A9BC",
    border: "1px solid #A3A9BC",
    borderRadius: "3px",
    height: 16,
    width: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 5px",
  },
  daysContainerDisable: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    border: "1px solid #A3A9BC",
    borderRadius: "3px",
    height: 16,
    width: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 5px",
    backgroundColor: "#A3A9BC",
  },
  child: {
    paddingLeft: theme.spacing(3.8),
  },
  child2: {
    paddingLeft: theme.spacing(3.8),
  },
  childTitle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#F66761",
  },
  child1Title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
  child2Title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
  child3Title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    child,
    level,
    availability,
    item,
    indexes,
    getLatestAvalibility,
    ...other
  } = props;
  let menuDetails = useSelector((state) => state.menuAvailability.menuDetails);

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [availabilityTime, setAvailabilityTime] = useState({
    startHours: "00",
    startMin: "00",
    endHours: "00",
    endMin: "00",
    startHours_2: "00",
    startMin_2: "00",
    endHours_2: "00",
    endMin_2: "00",
  });
  const [disabled, setDisabled] = useState({
    hidden_monday: false,
    hidden_tuesday: false,
    hidden_wednesday: false,
    hidden_thursday: false,
    hidden_friday: false,
    hidden_saturday: false,
    hidden_sunday: false,
    checked: false,
  });
  let dayNames = [
    "hidden_monday",
    "hidden_tuesday",
    "hidden_wednesday",
    "hidden_thursday",
    "hidden_friday",
    "hidden_saturday",
    "hidden_sunday",
  ];

  const getDisabled = () => {
    switch (level) {
      case 1:
        let menus = menuDetails[indexes?.menu].availability;
        setDisabled({
          hidden_monday: menus?.hidden_monday,
          hidden_tuesday: menus?.hidden_tuesday,
          hidden_wednesday: menus?.hidden_wednesday,
          hidden_thursday: menus?.hidden_thursday,
          hidden_friday: menus?.hidden_friday,
          hidden_saturday: menus?.hidden_saturday,
          hidden_sunday: menus?.hidden_sunday,
          checked: menus?.out_of_stock,
        });

        //menu
        break;
      case 2:
        let menusCategory =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]
            ?.availability;
        let menus1 = menuDetails[indexes?.menu].availability;

        setDisabled({
          hidden_monday: menusCategory?.hidden_monday || menus1?.hidden_monday,
          hidden_tuesday:
            menusCategory?.hidden_tuesday || menus1?.hidden_tuesday,
          hidden_wednesday:
            menusCategory?.hidden_wednesday || menus1?.hidden_wednesday,
          hidden_thursday:
            menusCategory?.hidden_thursday || menus1?.hidden_thursday,
          hidden_friday: menusCategory?.hidden_friday || menus1?.hidden_friday,
          hidden_saturday:
            menusCategory?.hidden_saturday || menus1?.hidden_saturday,
          hidden_sunday: menusCategory?.hidden_sunday || menus1?.hidden_sunday,
          checked: menusCategory?.out_of_stock || menus1?.out_of_stock,
        });
        //category
        break;
      case 3:
        //item
        let menusCategory30 =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]
            ?.availability;
        let menus30 = menuDetails[indexes?.menu].availability;
        let menusItem =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
            indexes?.item
          ]?.availability;
        setDisabled({
          hidden_monday:
            menusItem?.hidden_monday ||
            menus30?.hidden_monday ||
            menusCategory30?.hidden_monday,
          hidden_tuesday:
            menusItem?.hidden_tuesday ||
            menus30?.hidden_tuesday ||
            menusCategory30?.hidden_tuesday,
          hidden_wednesday:
            menusItem?.hidden_wednesday ||
            menus30?.hidden_wednesday ||
            menusCategory30?.hidden_wednesday,
          hidden_thursday:
            menusItem?.hidden_thursday ||
            menus30?.hidden_thursday ||
            menusCategory30?.hidden_thursday,
          hidden_friday:
            menusItem?.hidden_friday ||
            menus30?.hidden_friday ||
            menusCategory30?.hidden_friday,
          hidden_saturday:
            menusItem?.hidden_saturday ||
            menus30?.hidden_saturday ||
            menusCategory30?.hidden_saturday,
          hidden_sunday:
            menusItem?.hidden_sunday ||
            menus30?.hidden_sunday ||
            menusCategory30?.hidden_sunday,
          checked:
            menusItem?.out_of_stock ||
            menus30?.out_of_stock ||
            menusCategory30?.out_of_stock,
        });
        break;
      case 4:
        let menuCustomization =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
            indexes?.item
          ]?.item_customisations[indexes?.customization]?.availability;
        let menusCategory4 =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]
            ?.availability;
        let menu4 = menuDetails[indexes?.menu].availability;
        let menusItem4 =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
            indexes?.item
          ]?.availability;
        setDisabled({
          hidden_monday:
            menuCustomization?.hidden_monday ||
            menu4?.hidden_monday ||
            menusCategory4?.hidden_monday ||
            menusItem4?.hidden_monday,
          hidden_tuesday:
            menuCustomization?.hidden_tuesday ||
            menu4?.hidden_tuesday ||
            menusCategory4?.hidden_tuesday ||
            menusItem4?.hidden_tuesday,
          hidden_wednesday:
            menuCustomization?.hidden_wednesday ||
            menu4?.hidden_wednesday ||
            menusCategory4?.hidden_wednesday ||
            menusItem4?.hidden_wednesday,
          hidden_thursday:
            menuCustomization?.hidden_thursday ||
            menu4?.hidden_thursday ||
            menusCategory4?.hidden_thursday ||
            menusItem4?.hidden_thursday,
          hidden_friday:
            menuCustomization?.hidden_friday ||
            menu4?.hidden_friday ||
            menusCategory4?.hidden_friday ||
            menusItem4?.hidden_friday,
          hidden_saturday:
            menuCustomization?.hidden_saturday ||
            menu4?.hidden_saturday ||
            menusCategory4?.hidden_saturday ||
            menusItem4?.hidden_saturday,
          hidden_sunday:
            menuCustomization?.hidden_sunday ||
            menu4?.hidden_sunday ||
            menusCategory4?.hidden_sunday ||
            menusItem4?.hidden_sunday,
          checked:
            menuCustomization?.out_of_stock ||
            menu4?.out_of_stock ||
            menusCategory4?.out_of_stock ||
            menusItem4?.out_of_stock,
        });
        //customization
        break;

      case 5:
        //customization option
        let menusOptions =
          menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
            indexes?.item
          ]?.item_customisations[indexes?.customization]
            ?.item_customisation_options[indexes?.options]?.availability;
        setDisabled({
          hidden_monday: menusOptions?.hidden_monday,
          hidden_tuesday: menusOptions?.hidden_tuesday,
          hidden_wednesday: menusOptions?.hidden_wednesday,
          hidden_thursday: menusOptions?.hidden_thursday,
          hidden_friday: menusOptions?.hidden_friday,
          hidden_saturday: menusOptions?.hidden_saturday,
          hidden_sunday: menusOptions?.hidden_sunday,
          checked: menusOptions?.out_of_stock,
        });
        break;

      default:
        break;
    }
  };
  const [checked, setChecked] = useState(availability?.out_of_stock);

  const [days, setDays] = useState([
    { name: "M", isSelected: availability?.hidden_monday },
    { name: "T", isSelected: availability?.hidden_tuesday },
    { name: "W", isSelected: availability?.hidden_wednesday },
    { name: "T", isSelected: availability?.hidden_thursday },
    { name: "F", isSelected: availability?.hidden_friday },
    { name: "S", isSelected: availability?.hidden_saturday },
    { name: "S", isSelected: availability?.hidden_sunday },
  ]);
  useEffect(() => {
    setChecked(availability?.out_of_stock);
    setDays([
      { name: "M", isSelected: availability?.hidden_monday },
      { name: "T", isSelected: availability?.hidden_tuesday },
      { name: "W", isSelected: availability?.hidden_wednesday },
      { name: "T", isSelected: availability?.hidden_thursday },
      { name: "F", isSelected: availability?.hidden_friday },
      { name: "S", isSelected: availability?.hidden_saturday },
      { name: "S", isSelected: availability?.hidden_sunday },
    ]);
    let startHours =
      availability?.available_only_from_time &&
      availability?.available_only_from_time !== "None"
        ? availability?.available_only_from_time.split(":")[0]
        : "";

    let startMint =
      availability?.available_only_from_time &&
      availability?.available_only_from_time !== "None"
        ? availability?.available_only_from_time.split(":")[1]
        : "";

    let endHours =
      availability?.available_only_until_time &&
      availability?.available_only_until_time !== "None"
        ? availability?.available_only_until_time.split(":")[0]
        : "";
    let endMin =
      availability?.available_only_until_time &&
      availability?.available_only_until_time !== "None"
        ? availability?.available_only_until_time.split(":")[1]
        : "";

    //second time

    let startHours_2 =
      availability?.available_only_from_time_second &&
      availability?.available_only_from_time_second !== "None"
        ? availability?.available_only_from_time_second.split(":")[0]
        : "";
    let startMint_2 =
      availability?.available_only_from_time_second &&
      availability?.available_only_from_time_second !== "None"
        ? availability?.available_only_from_time_second.split(":")[1]
        : "";

    let endHours_2 =
      availability?.available_only_until_time_second &&
      availability?.available_only_until_time_second !== "None"
        ? availability?.available_only_until_time_second.split(":")[0]
        : "";
    let endMin_2 =
      availability?.available_only_until_time_second &&
      availability?.available_only_until_time_second !== "None"
        ? availability?.available_only_until_time_second.split(":")[1]
        : "";
    //second time
    setAvailabilityTime({
      startHours: startHours,
      startMin: startMint,
      endHours: endHours,
      endMin: endMin,
      startHours_2: startHours_2,
      startMin_2: startMint_2,
      endHours_2: endHours_2,
      endMin_2: endMin_2,
    });
    getDisabled();
    // eslint-disable-next-line
  }, [
    availability,
    menuDetails[indexes?.menu].availability,

    menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
      indexes?.item
    ]?.item_customisations[indexes?.customization]?.availability,
    menuDetails[indexes?.menu]?.menu_categories[indexes?.category]
      ?.availability,
    menuDetails[indexes?.menu]?.menu_categories[indexes?.category]?.items[
      indexes?.item
    ]?.availability,
  ]);

  const handleOpenDialogBox = (e) => {
    e.preventDefault();
    setOpenDialog(!openDialog);
  };
  const updateDay = (e, index, selection) => {
    if (!disabled[dayNames[index]]) {
      e.preventDefault();
      let newDays = [...days];
      newDays[index] = { name: newDays[index].name, isSelected: !selection };
      setDays(newDays);
      handleUpdateMenu(newDays, checked, availabilityTime);
    }
  };
  const updateOutOffStock = (e) => {
    e.preventDefault();
    handleUpdateMenu(days, !checked, availabilityTime);
    setChecked(!checked);
  };
  const setTime = (obj) => {
    setAvailabilityTime(obj);

    handleUpdateMenu(days, checked, obj);

    setOpenDialog(false);
  };
  const clearTime = () => {
    const obj = {
      item: item,
      data: {
        out_of_stock: checked,
        hidden_monday: days[0].isSelected,
        hidden_tuesday: days[1].isSelected,
        hidden_wednesday: days[2].isSelected,
        hidden_thursday: days[3].isSelected,
        hidden_friday: days[4].isSelected,
        hidden_saturday: days[5].isSelected,
        hidden_sunday: days[6].isSelected,
        available_only_from_time: null,
        available_only_until_time: null,
        available_only_from_time_second: null,
        available_only_until_time_second: null,
      },
      venue_uuid: venueDetail?.uuid,
      level: level,
    };
    dispatch(updateAvailability(obj));
    setAvailabilityTime({
      startHours: "00",
      startMin: "00",
      endHours: "00",
      endMin: "00",
      startHours_2: "00",
      startMin_2: "00",
      endHours_2: "00",
      endMin_2: "00",
    });
    setOpenDialog(false);
  };
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  const handleUpdateMenu = async (newDays, newChecked, newAvailabilityTime) => {
    const obj = {
      item: item,
      data: {
        out_of_stock: newChecked,
        hidden_monday: newDays[0].isSelected,
        hidden_tuesday: newDays[1].isSelected,
        hidden_wednesday: newDays[2].isSelected,
        hidden_thursday: newDays[3].isSelected,
        hidden_friday: newDays[4].isSelected,
        hidden_saturday: newDays[5].isSelected,
        hidden_sunday: newDays[6].isSelected,
        available_only_from_time:
          newAvailabilityTime.startHours === "" &&
          newAvailabilityTime.endMin === "" &&
          newAvailabilityTime.startMin === "" &&
          newAvailabilityTime.endHours === ""
            ? null
            : `${newAvailabilityTime.startHours}:${newAvailabilityTime.startMin}`,
        available_only_until_time:
          newAvailabilityTime.startHours === "" &&
          newAvailabilityTime.endMin === "" &&
          newAvailabilityTime.startMin === "" &&
          newAvailabilityTime.endHours === ""
            ? null
            : `${newAvailabilityTime.endHours}:${newAvailabilityTime.endMin}`,

        available_only_from_time_second:
          newAvailabilityTime.startHours_2 === "" &&
          newAvailabilityTime.startMin_2 === "" &&
          newAvailabilityTime.endHours_2 === "" &&
          newAvailabilityTime.endMin_2 === ""
            ? null
            : `${newAvailabilityTime.startHours_2}:${newAvailabilityTime.startMin_2}`,
        available_only_until_time_second:
          newAvailabilityTime.startHours_2 === "" &&
          newAvailabilityTime.startMin_2 === "" &&
          newAvailabilityTime.endHours_2 === "" &&
          newAvailabilityTime.endMin_2 === ""
            ? null
            : `${newAvailabilityTime.endHours_2}:${newAvailabilityTime.endMin_2}`,
      },
      venue_uuid: venueDetail?.uuid,
      level: level,
    };

    let res = await dispatch(updateAvailability(obj));
    if (level === 30 || level === 4) {
      getLatestAvalibility();
    }
  };
  return (
    <>
      {openDialog && (
        <MenuAvailabilityDialog
          handleClose={handleOpenDialogBox}
          setTime={setTime}
          availabilityTime={availabilityTime}
          itemName={labelText}
          clearTime={clearTime}
          level={level}
        />
      )}
      <TreeItem
        label={
          <Grid
            container
            style={{
              borderBottom:
                level === 1 || level === 2 ? "1px solid #EDF0F4" : "",
              borderTop: level === 1 || level === 2 ? "1px solid #EDF0F4" : "",
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                maxWidth: "400px",
                paddingLeft: level === 3 ? "16px" : "",
              }}
              alignItems="center"
            >
              <Typography
                variant="body2"
                className={
                  level === 0
                    ? classes.childTitle
                    : level === 1
                    ? classes.child1Title
                    : level === 2
                    ? classes.child2Title
                    : classes.child3Title
                }
              >
                {labelText}
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              className={level === 1 || level === 0 ? classes.child : ""}
              alignItems="center"
              style={{
                display: "flex",
                justifyContent: "center",
                //   paddingLeft: child === false ? 20 : -16,
                marginLeft: level === 30 || level === 4 ? "-16px" : 0,
              }}
            >
              <Checkbox
                onClick={(e) => updateOutOffStock(e)}
                // onClick={(e) => e.preventDefault()}
                checked={disabled?.checked ? disabled?.checked : checked}
                disabled={disabled?.checked}
                style={
                  !disabled?.checked && checked
                    ? { color: "#F66761" }
                    : { color: "#A3A9BC" }
                }
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: "flex" }}
              alignItems="center"
              className={level === 1 || level === 0 ? classes.child2 : ""}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {days.map((day, index) => {
                  return (
                    <div
                      className={
                        disabled[dayNames[index]]
                          ? classes.daysContainerDisable
                          : day.isSelected
                          ? classes.daysContainerSelected
                          : classes.daysContainer
                      }
                      onClick={(e) => updateDay(e, index, day.isSelected)}
                    >
                      {day.name}
                    </div>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: !(level === 1 || level === 0) ? "17px" : "0px",
              }}
              alignItems="center"
              className={level === 1 || level === 0 ? classes.child2 : ""}
            >
              <Typography
                onClick={(e) => handleOpenDialogBox(e)}
                variant="caption"
                color="inherit"
                style={{
                  color:
                    level === 1 &&
                    !(
                      availabilityTime.startHours === "" &&
                      availabilityTime.startMin === "" &&
                      availabilityTime.endHours === "" &&
                      availabilityTime.endMin === ""
                    )
                      ? "#F66761"
                      : "",
                }}
              >
                {(availabilityTime.startHours === "00" &&
                  availabilityTime.startMin === "00" &&
                  availabilityTime.endHours === "00" &&
                  availabilityTime.endMin === "00") ||
                (availabilityTime.startHours === "" &&
                  availabilityTime.startMin === "" &&
                  availabilityTime.endHours === "" &&
                  availabilityTime.endMin === "")
                  ? "--:--"
                  : `${availabilityTime.startHours}:${availabilityTime.startMin}-${availabilityTime.endHours}:${availabilityTime.endMin}`}
              </Typography>
              {(
availabilityTime.startHours_2 === "00" &&
availabilityTime.startMin_2 === "00" &&
availabilityTime.endHours_2 === "00" &&
availabilityTime.endMin_2 === "00"

              )||(availabilityTime.startHours_2 === "" &&
              availabilityTime.startMin_2 === "" &&
              availabilityTime.endHours_2 === "" &&
              availabilityTime.endMin_2 === "") ? (
                ""
              ) : (
                <span style={{ padding: "0px 3px" }}>|</span>
              )}
              <Typography
                onClick={(e) => handleOpenDialogBox(e)}
                variant="caption"
                color="inherit"
                style={{
                  color:
                    level === 1 &&
                    !(
                      availabilityTime.startHours_2 === "" &&
                      availabilityTime.startMin_2 === "" &&
                      availabilityTime.endHours_2 === "" &&
                      availabilityTime.endMin_2 === ""
                    )
                      ? "#F66761"
                      : "",
                }}
              >
                {(availabilityTime.startHours_2 === "00" &&
                availabilityTime.startMin_2 === "00" &&
                availabilityTime.endHours_2 === "00" &&
                availabilityTime.endMin_2 === "00")||(availabilityTime.startHours_2 === "" &&
                availabilityTime.startMin_2 === "" &&
                availabilityTime.endHours_2 === "" &&
                availabilityTime.endMin_2 === "")
                  ? ""
                  : `${availabilityTime.startHours_2}:${availabilityTime.startMin_2}-${availabilityTime.endHours_2}:${availabilityTime.endMin_2}`}
              </Typography>
              <Icon1
                onClick={(e) => handleOpenDialogBox(e)}
                style={{ paddingLeft: "20px" }}
              />
            </Grid>
          </Grid>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group:
            level === 2 || level === 4 || level === 2
              ? classes.groupLevel3
              : level === 2
              ? classes.group
              : classes.groupLevel2,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "82vh",
    flexGrow: 1,
    maxWidth: "inherit",
    minWidth: "100%",
  },
  labelText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  child: {
    paddingLeft: theme.spacing(5.6),
    display: "flex",
    justifyContent: "center",
  },
  child2: {
    paddingLeft: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  child201: {
    paddingRight: "20px !important",
  },
  text: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#A3A9BC",
  },
  printButton: {
    marginTop: "18px",
    textTransform: "none",
    background: "#F66761",
    color: "#FFFFFF",
    padding: "5px 26px",
  },
}));

export default function MenuAvailabilityTree(props) {
  const intl = useIntl();

  const { selectedMenu } = props;
  const classes = useStyles();
  let menuDetails = useSelector((state) => state.menuAvailability.menuDetails);
  const [expended, setExpended] = useState([" "]);
  const [expendedArr, setExpendedArr] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const getData = () => {
    setShowLoader(true);
    let arr = [];
    arr.push(" ");
    selectedMenu !== null &&
      menuDetails &&
      menuDetails[selectedMenu] &&
      menuDetails[selectedMenu].menu_categories &&
      menuDetails[selectedMenu].menu_categories.map((category, index) => {
        arr.push(`${index}${category.name.replace(/\s/g, "")}`);
        return category.items.map((item, index2) => {
          arr.push(`${index2}${index}${item.name.replace(/\s/g, "")}`);
          return item?.item_customisations?.map(
            (itemCustomizations, index3) => {
              arr.push(
                `${index3}${index2}${index}${itemCustomizations.name.replace(
                  /\s/g,
                  ""
                )}`
              );
              return itemCustomizations?.item_customisation_options?.map(
                (customizationOptions, index4) => {
                  return arr.push(
                    `${index4}${index3}${index2}${index}${customizationOptions.name.replace(
                      /\s/g,
                      ""
                    )}`
                  );
                }
              );
            }
          );
        });
      });
    setExpended([...arr]);
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };
  const clearData = () => {
    setShowLoader(true);
    let arr = [];
    arr.push(" ");

    setExpended([...arr]);
    setTimeout(() => {
      setShowLoader(false);
    }, 200);
  };
  useEffect(() => {
    if (props?.collapse === "expand") {
      getData();
    } else if (props?.collapse === "collapse") {
      clearData();
    }

    // eslint-disable-next-line
  }, [props?.collapse]);
  return (
    <>
      {!showLoader ? (
        <TreeView
          className={classes.root}
          defaultExpanded={expended}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          onNodeToggle={() => props?.changeExpandCollapse("")}
        >
          {/* titles /column names */}
          <Grid container>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                maxWidth: "400px",
                paddingLeft: "24px",
              }}
              alignItems="center"
            >
              <Typography
                variant="body2"
                className={classes.labelText}
                style={{ textTransform: "capitalize" }}
              >
                {intl.formatMessage({
                  id: "item",
                })}
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className={classes.child}
              alignItems="center"
            >
              <Typography
                variant="body2"
                className={classes.labelText}
                style={{}}
              >
                {intl.formatMessage({
                  id: "Out_Of_Stock",
                })}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: "flex" }}
              alignItems="center"
              className={classes.child2}
            >
              <Typography
                variant="body2"
                className={classes.labelText}
                style={{}}
              >
                {intl.formatMessage({
                  id: "Hide",
                })}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
              alignItems="center"
              // className={classes.child2}
            >
              <Typography
                variant="body2"
                className={classes.labelText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {intl.formatMessage({
                  id: "available_until_from",
                })}
              </Typography>
            </Grid>
          </Grid>
          {menuDetails && menuDetails?.length === 0 ? (
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "82vh",
                minWidth: "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.text}>
                    {intl.formatMessage({
                      id: "edit_menu_availability",
                    })}
                    ,
                    <br />
                    {intl.formatMessage({
                      id: "create_your_first_menu",
                    })}
                    .
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.printButton}
                    onClick={props?.addMenu}
                  >
                    {intl.formatMessage({
                      id: "Take_me_there",
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              {selectedMenu !== null &&
                menuDetails &&
                menuDetails?.length > 0 &&
                menuDetails[selectedMenu] && (
                  <StyledTreeItem
                    nodeId={selectedMenu + "base"}
                    labelText={menuDetails[selectedMenu].name}
                    child={false}
                    level={0}
                    availability={menuDetails[selectedMenu]?.availability}
                    item={{
                      menuId: menuDetails[selectedMenu]?.menu_id,
                      availabilityId:
                        menuDetails[selectedMenu]?.availability?.id,
                    }}
                    indexes={{ menu: selectedMenu }}
                  ></StyledTreeItem>
                )}

              {selectedMenu !== null &&
                menuDetails &&
                menuDetails[selectedMenu] &&
                menuDetails[selectedMenu].menu_categories.map(
                  (category, index) => {
                    return (
                      <>
                        <StyledTreeItem
                          nodeId={`${index}${category.name.replace(/\s/g, "")}`}
                          labelText={category.name}
                          child={false}
                          level={1}
                          availability={category.availability}
                          item={{
                            categoryId: category?.menu_category_id,
                            menuId: menuDetails[selectedMenu]?.menu_id,
                            availabilityId: category?.availability?.id,
                          }}
                          indexes={{ menu: selectedMenu, category: index }}
                        >
                          {category.items.map((item, index2) => {
                            return (
                              <StyledTreeItem
                                nodeId={`${index2}${index}${item.name.replace(
                                  /\s/g,
                                  ""
                                )}`}
                                labelText={item.name}
                                level={2}
                                availability={item.availability}
                                item={{
                                  menuId: menuDetails[selectedMenu]?.menu_id,
                                  categoryId: category?.menu_category_id,
                                  item: item?.item_id,
                                  availabilityId: item?.availability?.id,
                                }}
                                indexes={{
                                  menu: selectedMenu,
                                  category: index,
                                  item: index2,
                                }}
                              >
                                {item?.item_customisations?.map(
                                  (itemCustomizations, index3) => {
                                    return (
                                      <StyledTreeItem
                                        nodeId={`${index3}${index2}${index}${itemCustomizations.name.replace(
                                          /\s/g,
                                          ""
                                        )}`}
                                        labelText={itemCustomizations.name}
                                        level={30}
                                        getLatestAvalibility={
                                          props?.getLatestAvalibility
                                        }
                                        availability={
                                          itemCustomizations.availability
                                        }
                                        item={{
                                          menuId:
                                            menuDetails[selectedMenu]?.menu_id,
                                          categoryId:
                                            category?.menu_category_id,
                                          item: item?.item_id,
                                          availabilityId:
                                            itemCustomizations?.availability
                                              ?.id,
                                          customization_id:
                                            itemCustomizations?.customisation_section_id,
                                        }}
                                        indexes={{
                                          menu: selectedMenu,
                                          category: index,
                                          item: index2,
                                          customization: index3,
                                        }}
                                      >
                                        {itemCustomizations?.item_customisation_options?.map(
                                          (customizationOptions, index4) => {
                                            return (
                                              <StyledTreeItem
                                                nodeId={`${index4}${index3}${index2}${index}${customizationOptions.name.replace(
                                                  /\s/g,
                                                  ""
                                                )}`}
                                                labelText={
                                                  customizationOptions.name
                                                }
                                                level={4}
                                                getLatestAvalibility={
                                                  props?.getLatestAvalibility
                                                }
                                                availability={
                                                  customizationOptions.availability
                                                }
                                                item={{
                                                  menuId:
                                                    menuDetails[selectedMenu]
                                                      ?.menu_id,
                                                  categoryId:
                                                    category?.menu_category_id,
                                                  item: item?.item_id,
                                                  availabilityId:
                                                    customizationOptions
                                                      ?.availability?.id,
                                                  customization_id:
                                                    itemCustomizations?.customisation_section_id,
                                                  customization_option_id:
                                                    customizationOptions?.customisation_option_id,
                                                }}
                                                indexes={{
                                                  menu: selectedMenu,
                                                  category: index,
                                                  item: index2,
                                                  customization: index3,
                                                  options: index4,
                                                }}
                                              />
                                            );
                                          }
                                        )}
                                      </StyledTreeItem>
                                    );
                                  }
                                )}
                              </StyledTreeItem>
                            );
                          })}
                        </StyledTreeItem>
                      </>
                    );
                  }
                )}
            </>
          )}
        </TreeView>
      ) : (
        <Grid container>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "90vh",
              minWidth: "80vw",
            }}
          >
            <CircularProgress size={40} color="primary" />
          </Grid>
        </Grid>
      )}
    </>
  );
}
