import {
  GET_HOTEL_DETAILS,
  UPDATE_VENUE_ORDER_STATUS,
  UPDATE_HOTEL_DETAILS,
} from "../constant/types";

const initialState = {
  hotelDetail: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_VENUE_ORDER_STATUS: {
      console.log(payload);
      // is_currently_accepting_orders;

      let venues = state.hotelDetail.venues;
      let ind = venues.findIndex((n) => n.id === payload.id);
      if (ind > -1) {
        venues[ind].is_currently_accepting_orders =
          payload?.is_currently_accepting_orders;
        console.log(venues);
        return { ...state, venues: [...venues] };
      }
    }
    case GET_HOTEL_DETAILS: {
      return {
        ...state,
        hotelDetail: payload,
      };
    }

    case UPDATE_HOTEL_DETAILS: {
      return {
        ...state,
        hotelDetail: {
          ...state?.hotelDetail,
          business_account: payload,
        },
      };
    }

    default:
      return state;
  }
}
