import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  TextButtonAdd,
  TextButton,
  TextButtonNoColor,
} from "../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
 getUserDetail
} from "../../actions/order";
import { addVenue } from "../../actions/hotelDetails";
import { currenciesList } from "../../utils/currencies";
// import { DEFAULT_STATUS } from "../../constant/types";
// import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import { VENUE_TYPES, HOTEL, SUPPORTED_LANGUAGES } from "../../utils/constants";
import { useIntl } from "react-intl";
import moment, { lang } from "moment-timezone";

export default function CategoryForm(props) {
  let UserDetail = useSelector((state) => state?.VenueOrderReducer?.UserDetail);

  const dispatch = useDispatch();
  const intl = useIntl();
  const timeZones = moment?.tz
    ?.names()
    ?.filter((tz) =>
      tz.match(
        /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(UTC))$/
      )
    );
  const [accountType, setAccountType] = useState("");

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");
  const [responseError, setResponseError] = useState(false);
  const [language, setLanguage] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [currency, setCurrency] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    currency: false,
    domain: false,
    timeZones: false,
    type: false,
    language: false,
  });
  let menuStatus = useSelector((state) => state?.menuReducer?.status);
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );
  let business_account = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account
  );
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangeType = (e) => {
    setErrors({ ...errors, type: false });
    setAccountType(e.target.value);
  };
  const handleChangeLanguage = (e) => {
    setErrors({ ...errors, language: false });
    setLanguage(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };

  const handleSubmit = async () => {
    console.log({ name });
    let objError = {
      name: false,
      currency: false,
      domain: false,
      timeZones: false,
    };

    if (
      language === null ||
      language === "" ||
      language === undefined ||
      accountType === null ||
      accountType === "" ||
      accountType === undefined ||
      name === null ||
      name === undefined ||
      name === "" ||
      currency === null ||
      currency === undefined ||
      currency.trim() === "" ||
      domain === null ||
      domain === undefined ||
      domain.trim() === "" ||
      selectedTimeZone === null ||
      selectedTimeZone === undefined ||
      selectedTimeZone.trim() === ""
    ) {
      if (
        selectedTimeZone === null ||
        selectedTimeZone === undefined ||
        selectedTimeZone === ""
      ) {
        objError["timeZones"] = true;
      }
      if (language === null || language === "" || language === undefined) {
        objError["language"] = true;
      }
      if (
        accountType === null ||
        accountType === "" ||
        accountType === undefined
      ) {
        objError["type"] = true;
      }
      if (domain === null || domain === undefined || domain === "") {
        objError["domain"] = true;
      }
      if (name === null || name === undefined || name === "") {
        objError["name"] = true;
      }
      if (currency === null || currency === undefined || currency === "") {
        objError["currency"] = true;
      }
      setErrors({ ...errors, ...objError });
    } else {
      setLoader(true);
      let obj = {
        name: name,
        currency: currency,
        type: accountType,
        domain: domain,
        timezone: selectedTimeZone,
        default_language: language,
      };
      let res = await dispatch(
        addVenue({
          business_account_uuid: User_uuid,
          body: obj,
        })
      );
      if (localStorage.AuthUserKey) {
        dispatch(getUserDetail({ Authorization: localStorage.AuthUserKey }));
      }

      setLoader(false);
      if (res?.status === 200) {
        // alert("success")
        handleClose();
      } else {
        setResponseError(true);
        // alert("error")
      }
    }
  };
  const handleChangeTimeZone = (e) => {
    setErrors({ ...errors, timeZones: false });
    setSelectedTimeZone(e.target.value);
  };
  const handleChangeCurrency = (e) => {
    setErrors({ ...errors, currency: false });
    setCurrency(e.target.value);
  };
  const handleChangeDomain = (e) => {
    setErrors({ ...errors, domain: false });
    setDomain(e.target.value);
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.model}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Typography className={classes.headersTest}>
                    {props?.item?.item?.id
                      ? intl.formatMessage({
                          id: "edit_venue",
                        })
                      : intl.formatMessage({
                          id: "create_new_venue",
                        })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  {responseError && (
                    <small
                      style={{
                        fontSize: "0.75rem",
                        color: "#f44336",
                      }}
                    >
                      {"Something went wrong"}
                    </small>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  flex: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {intl.formatMessage({
                  id: "Venue name (What your guests will see) ",
                })}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "enter_venue_name",
                })}
                error={errors?.name}
                helperText={
                  errors?.name &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                fullWidth
                value={name}
                onChange={(e) => {
                  setErrors({ ...errors, name: false });
                  setName(e.target.value);
                }}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle2,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item>
                  <Typography
                    className={classes.headingTest}
                    style={{ paddingBottom: "0px", marginBottom: "10px" }}
                  >
                    {intl.formatMessage({
                      id: "venue_type",
                      defaultMessage: "Venue type",
                    })}
                  </Typography>
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "select_type",
                  defaultMessage: "Select type",
                })}
                fullWidth
                error={errors?.type}
                helperText={
                  errors?.type &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                select
                onChange={(e) => handleChangeType(e)}
                value={accountType}
                defaultValue={accountType}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
                className={classes.selectRounded}
              >
                {business_account?.type === HOTEL
                  ? VENUE_TYPES.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                  : VENUE_TYPES.filter((i) => i.id !== 3).map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "Venue default language",
                  defaultMessage: "Venue default language",
                })}
              </Typography>

              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "Select default language",
                  defaultMessage: "Select default language",
                })}
                fullWidth
                error={errors?.type}
                helperText={
                  errors?.type &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                select
                onChange={(e) => handleChangeLanguage(e)}
                value={language}
                defaultValue={language}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
                className={classes.selectRounded}
              >
                {UserDetail?.supported_languages_by_blend&&Object.keys(UserDetail?.supported_languages_by_blend).map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {UserDetail?.supported_languages_by_blend[item]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "venue_domain",
                })}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "enter_venue_domain",
                  defaultMessage: "Enter venue domain",
                })}
                fullWidth
                error={errors?.domain}
                helperText={
                  errors?.domain &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                onChange={(e) => handleChangeDomain(e)}
                value={domain}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                  endAdornment: (
                    <InputAdornment
                      style={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "13px",
                        lineHeight: "18px",
                        color: "#303A5F",
                        paddingLeft: "10px",
                      }}
                      position="start"
                    >
                      .blend.menu
                    </InputAdornment>
                  ),
                }}
                className={classes.classEndronment}
              />
            </Grid>
          </Grid>
     
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "currency",
                })}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "select_currency",
                  defaultMessage: "Select Currency",
                })}
                fullWidth
                error={errors?.currency}
                helperText={
                  errors?.currency &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                select
                onChange={(e) => handleChangeCurrency(e)}
                value={currency}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              >
                {currenciesList?.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "time_zone",
                  defaultMessage: "Time zone",
                })}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "select_time_zone",
                  defaultMessage: "Select Time zone",
                })}
                fullWidth
                error={errors?.timeZones}
                helperText={
                  errors?.timeZones &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                select
                onChange={(e) => handleChangeTimeZone(e)}
                value={selectedTimeZone}
                defaultValue={selectedTimeZone}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              >
                {timeZones?.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
    
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px  30px 8px " }}>
          <Grid container justify="space-between">
            <Grid item>
              <TextButtonNoColor disabled={loader} onClick={handleClose}>
                {intl.formatMessage({
                  id: "Cancel",
                })}{" "}
              </TextButtonNoColor>
            </Grid>
            <Grid item>
              <TextButtonAdd disabled={loader} onClick={handleSubmit}>
                {loader ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  intl.formatMessage({
                    id: "Create",
                  })
                )}
              </TextButtonAdd>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  model: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "760px",
    },
  },
  verified: {
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.colors.primary,
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  headersTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },

  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "14px !important",
      background: "#EAECEE !important",
      boxSizing: "border-box",
      borderRadius: "5px !important",
    },
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },
  selectRounded: {
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },
  selectRounded: {
    "&.MuiSelect-select:focus": {
      borderRadius: "5px",
      backgroundColor: `#fff`,
    },
  },

  classEndronment: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "14px !important",
      background: "#EAECEE !important",
      boxSizing: "border-box",
      borderRadius: "5px !important",
    },
  },
  outlinedStyle: {
    border: "0px",
  },
}));
