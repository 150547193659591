import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as Edit } from "../../assets/image/edit.svg";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [item, setItem] = useState({});
  const [currency, setCurrency] = useState("EUR");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  useEffect(() => {
    setCurrency(venueDetail?.currency ? venueDetail?.currency : "EUR");
  }, [venueDetail]);
  useEffect(() => {
    setItem(props?.item ? props?.item : {});
  }, [props]);

  const handleEditClick = () => {
    props.handleEditCustmizationModal(item);
  };
  return (
    <Grid
      xs={12}
      className={classes.root}
      style={{ cursor: "pointer" }}
      onClick={handleEditClick}
    >
      <Grid container style={{ padding: "20px", paddingBottom: "10px" }}>
        <Grid item xs={8}>
          <Grid container>
            <Grid
              item
              style={{
                maxWidth: "calc(100% - 90px)",
              }}
            >
              <Typography className={classes.heading}>{item?.name}</Typography>
            </Grid>
            {item?.required && (
              <Grid item>
                <Typography component="div" className={classes.requiredDiv}>
                  <Typography className={classes.requiredText}>
                  {intl.formatMessage({
                  id: "Required",
                })}
                    
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* required */}
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography className={classes.heading}>
            <Edit className="column-drag-handle" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ padding: "0px 20px 10px 20px" }}>
        {item?.max_selectable === 1 && (
          <Grid item xs={12}>
            <Typography className={classes.subHeading}>
            {intl.formatMessage({
                  id: "Not_more_than_1",
                })}
              
            </Typography>
          </Grid>
        )}
      </Grid>

      {item?.customisation_options
        ?.sort((a, b) => a.position - b.position)
        ?.map((n, index) => {
          return (
            <Grid container style={{ paddingLeft: "20px" }}>
              <Grid item xs={8}>
                <Typography className={classes.heading}>
                  {item?.max_selectable === 1 && item?.required ? (
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      value={n.name}
                      control={
                        <Radio
                          checked={n.pre_selected}
                          style={{ cursor: "default" }}
                        />
                      }
                      label={n.name}
                      className={classes.checkBoxLabel}
                    />
                  ) : (
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={n.pre_selected}
                          // onChange={handleChange}
                          name="gilad"
                          style={{ cursor: "default" }}
                        />
                      }
                      label={n.name}
                      className={classes.checkBoxLabel}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className={classes.price}>
                  {parseFloat(n.price).toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                  })}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}
