import React, { useEffect, useState } from "react";
// import HomeIcon from "@material-ui/icons/Home";
import { ReactComponent as HomeIcon } from "../../assets/image/Home.svg";
import { ReactComponent as MenuIcon } from "../../assets/image/menu.svg";
import { ReactComponent as Line } from "../../assets/image/Line.svg";
import { ReactComponent as Chat } from "../../assets/image/chat.svg";
import { ReactComponent as Reload } from "../../assets/image/reload.svg";

import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { logoutUser } from "../../actions/order";
import { useHistory } from "react-router-dom";
import Ellipse from "../../assets/image/Ellipse.png";
import {
  Grid,
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Typography,
  Card,
} from "@material-ui/core";
import { updateVenueDetails } from "../../actions/order";
import "../../style/home.css";
import "./menubar.css";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import SelectLanguage from "../SelectLanguage/Select";
import useSound from "use-sound";
import incomingBeep from "../../assets/sound/incomingBeep.mp3";
import MenuList from "./MenuList";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  orderText: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: " 18px",
    color: theme.colors.primary,
  },
  pendingOrders: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "right",
    color: theme.colors.primary,
  },
  editButton: {
    textAlign: "center",
    background: theme.colors.secondary,
    width: "32px",
    height: "32px",
    boxShadow: " 0px 0px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: " 6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editButton2: {
    textAlign: "center",
    background: "#EDF0F4",
    width: "32px",
    height: "32px",
    // boxShadow: " 0px 0px 12px rgba(0, 0, 0, 0.15)",
    boxShadow: "none",
    borderRadius: " 6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ordersCount: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold700,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#F9FAFC",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: "65px",
    // alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: "5px",
    paddingRight: "0px !important",
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  ResturentName: {
    fontFamily: theme.fontFamily,
    fontWeight: theme.fontSemiBold,
    fontStyle: "normal",

    fontSize: "13px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: theme.colors.primary,
    //   ,  display: "flex",
  },
  HomeIcon: {
    color: theme.colors.secondary,
    cursor: "pointer",
  },
  ArrowIcon: {
    color: theme.colors.primary,
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
let trackingPendingOrders = null;

function MainBar(props) {
  const intl = useIntl();
  const params=useParams()

  const [orderIncomingBeep] = useSound(incomingBeep, {
    onend: () => {
      console.info("Sound ended!");
    },
    volume: 1,
    soundEnabled: true,
    interrupt: true,
  });
  const callSound = () => {
    orderIncomingBeep();
  };
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  const [loader, setLoader] = useState(false);
  const [totalOrders, setTotalOrders] = useState("");
  const [showHelpCrunch, setShowHelpCrunch] = useState(true);

  let UserDetail = useSelector((state) => state.VenueOrderReducer.UserDetail);
  let user = useSelector((state) => state.VenueOrderReducer.UserDetail?.user);

  let business_account = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail?.business_account
  );
  let venueDetail = useSelector(
    (state) => state.VenueOrderReducer?.VenueDetail
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  let dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async (obj) => {
    if (venueDetail?.uuid) {
      setLoader(true);

      let res = await dispatch(
        updateVenueDetails({
          body: obj,
          venue_uuid: venueDetail?.uuid,
          slug: params?.id,
        })
      );
      if (res?.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };
  const handleChangeSwitch = (e) => {
    let obj = { is_currently_accepting_orders: e.target.checked };
    handleSubmit(obj);
  };
  let pendingOrders = useSelector(
    (state) => state?.pendingOrdersReducer?.pendingOrders
  );
  let VenueOrderReducer = useSelector((state) => state?.VenueOrderReducer);
  useEffect(() => {
    if (pendingOrders) {
      let orders = 0;
      pendingOrders &&
        pendingOrders.forEach((element) => {
          orders = orders + element.pending_orders;
        });
      setTotalOrders(orders);
      playSound(orders);
    } else {
      playSound(0);
    }
  }, [pendingOrders, VenueOrderReducer]);

  const playSound = (count) => {
    if (count > 0 && !trackingPendingOrders) {
      trackingPendingOrders = setInterval(() => {
        callSound();
        // }, 120000);
      }, 120000);
    }

    if (count === 0 && trackingPendingOrders) {
      clearInterval(trackingPendingOrders);
      trackingPendingOrders = null;
    }
  };

  const handleShowHelpCrunch = () => {
    if (showHelpCrunch) {
      setShowHelpCrunch(false);
    }
  };
  const handleShowChatButton = () => {
    window.HelpCrunch("openChat");
  };
  const handleLogOut = async () => {
    await dispatch(logoutUser());
    // await dispatch(SetUserEmpty())
    window.HelpCrunch("logout", function (data) {
      if (data && data.success) {
        window.HelpCrunch("hideChatWidget");
      }
    });

    history.push("/login");
    handleClose();
  };
  return (
    <div className={classes.root} id={"root2"}>
      {showHelpCrunch && (
        <script type="text/javascript">
          {user?.email &&
            window.HelpCrunch("init", "blend", {
              applicationId: 1,
              applicationSecret:
                "QPWAhxvOKrxo+0t41MhX7ivE03uSsb29vkdXNKd3Ka5ACpgP1X3lx1PRQqLtLcH7CjP7sQPxQWeoqviHY8mXxw==",
              user: {
                email: user?.email,
                name: user?.first_name + " " + user?.last_name,
                phone: "<phone number>",
                company: business_account?.name ? business_account?.name : "",
                user_id: "<user_id>",
              },
            })}
          {user?.email && handleShowHelpCrunch()}
        </script>
      )}
      <AppBar color="white" position="static">
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} style={{ maxHeight: "100px" }}>
              <Grid container justify="space-between">
                <Grid
                  item
                  style={{ minWidth: "50%" }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HomeIcon
                        className={classes.HomeIcon}
                        onClick={() => {
                          history.push("/RestaurantsGroups");
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/RestaurantsGroups");
                      }}
                    >
                      {business_account ? (
                        <>
                          {/* <ArrowRightRoundedIcon
                            className={classes.ArrowIcon}
                          /> */}
                          <p className={classes.ResturentName}>
                            {business_account.name}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {props.venueDetail ? (
                        <Grid container>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ArrowRightRoundedIcon
                              className={classes.ArrowIcon}
                            />
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p className={classes.ResturentName}>
                              {props.venueDetail && props.venueDetail.name}
                            </p>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid
                      item
                      // style={{ maxHeight: "20px" }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {params?.id && (
                        <Grid container>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // maxHeight: "24px",
                            }}
                          >
                            <input
                              type="checkbox"
                              className="lol-checkbox"
                              id="lol-checkbox"
                              checked={
                                venueDetail?.read_only_menu
                                  ? false
                                  : venueDetail?.is_currently_accepting_orders
                              }
                              onChange={
                                venueDetail?.read_only_menu
                                  ? ""
                                  : handleChangeSwitch
                              }
                            />
                            <label className="button" for="lol-checkbox">
                              <div className="knob"></div>
                              <div className="subscribe"></div>
                              <div className="alright"></div>
                            </label>
                          </Grid>

                          <Grid
                            item
                            // style={{ marginLeft: "-20px" }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span className={classes.orderText}>
                              {venueDetail?.is_currently_accepting_orders
                                ? intl.formatMessage({
                                    id: "Orders_enabled",
                                  })
                                : intl.formatMessage({
                                    id: "Orders_disabled",
                                  })}
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.centerAlign}>
                  <Grid container>
                    <Grid item className={classes.centerAlign}>
                      {totalOrders !== "" && (
                        <Grid
                          container
                          className={classes.centerAlign}
                          onClick={props?.handleClickOrders}
                        >
                          <Grid
                            item
                            className={classes.centerAlign}
                            style={{ paddingRight: "10px" }}
                          >
                            <Typography className={classes.pendingOrders}>
                              {totalOrders > 0
                                ? intl.formatMessage({
                                    id: "Pending_orders",
                                  })
                                : intl.formatMessage({
                                    id: "No_pending_orders",
                                  })}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.centerAlign}>
                            {totalOrders > 0 ? (
                              <Card className={classes.editButton}>
                                <Typography className={classes.ordersCount}>
                                  {totalOrders}
                                </Typography>
                              </Card>
                            ) : (
                              <Card className={classes.editButton2}>
                                <Typography
                                  className={classes.ordersCount}
                                ></Typography>
                              </Card>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  
                    <Grid
                      item
                      className={classes.centerAlign}
                      style={{ cursor: "pointer",padding:"0px 20px" }}
                    >
                      <Reload onClick={()=>window.location.reload(false)}/>
                    </Grid>
                    <Grid
                      item
                      className={classes.centerAlign}
                      style={{ cursor: "pointer" }}
                    >
                      <MenuList
                        handleShowChatButton={handleShowChatButton}
                        handleLogOut={handleLogOut}
                        venueName={UserDetail?.user?.first_name}
                      />
                      {/* <SelectLanguage/> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MainBar;
