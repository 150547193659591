import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useDebugValue,
} from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { Grid,  DialogActions,
  Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {  TextButtonAdd } from "../Buttons/Buttons";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "70vw"
  }
}));
export default function CropImage(props) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const classes = useStyles();
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect:props?.type==="item"?3/2:props?.type==="cover"?3/1:
   1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [open, setOpen] = useState(false);
  async function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  

  const generateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

  canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        console.log({ blob });
        // const anchor = document.createElement('a');
        // anchor.download = 'cropPreview.png';
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();

        // window.URL.revokeObjectURL(previewUrl);
        let f = new File([blob], "name");
       
        
        blobToBase64(blob)
          .then(base64String => props.setImage(base64String, previewUrl));
        // console.log(f);
        // props.setImage(f, previewUrl);
        setOpen(false);
      },
      "image/png",
      1
    );
  };

  const onSelectFile = (files) => {
    // if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    reader.addEventListener("load", () => setUpImg(reader.result));
    reader.readAsDataURL(files);
    // }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    console.log( )
    if (props?.files && props?.files !== ""&&!(typeof props?.files ==="string") ) {
      setOpen(true);

      onSelectFile(props?.files);
    }
  }, [props?.files]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
  return (
    <div className="App">
      <Dialog
        // fullScreen
        open={true}
        classes={{
          paper: classes.paper
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // open={true}
        onClose={() => props.handleClose()}
      >
        <div>
          <Grid container justify="flex-end">
            <CloseIcon
              style={{ margin: "10px 10px 0px 0px", cursor: "pointer" }}
              onClick={() => props.handleClose()}
            />
          </Grid>
          <Grid container>
          <Grid item xs={6} style={{padding:"15px"}}>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
</Grid>
          <Grid item xs={6} style={{padding:"15px"}}>

          <canvas
            ref={previewCanvasRef}
            style={{
              width: completedCrop?.width ?? 0,
              height: completedCrop?.height ?? 0,
            }}
          />
          </Grid>
          </Grid>
        </div>
        <DialogActions>
        <Grid container justify="flex-end">
          <TextButtonAdd
            type="button"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() =>
              generateDownload(previewCanvasRef.current, completedCrop)
            }
            // onClick={handleClose}
            color="primary"
            autoFocus
          >
            Save
          </TextButtonAdd>
        </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
