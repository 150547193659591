import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../actions/order";
import { useHistory } from "react-router-dom";
import { ReactComponent as LogoImage } from "../../assets/image/blendLogo.svg";

import { withStyles } from "@material-ui/core/styles";
import LoginPicture from "../../assets/image/loginPic.png";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";

export default function Login() {
  const intl = useIntl();

  let dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorEmail, setErrorEmail] = useState(false);
  // let AuthUserKey = useSelector(state => state.VenueOrderReducer.AuthUserKey);
  // let ErrorMessage = useSelector((state) => state.VenueOrderReducer.LoginError);

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const handleEmail = (event) => {
    setEmail(event.target.value);
    setErrorMessage(false)
    setErrorEmail(false)
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    setErrorMessage(false)

  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && email !== null && password !== null) {
      handleLogin();
    }
  };

  useEffect(() => {
    localStorage.AuthUserKey && history.push("RestaurantsGroups");
  }, [history]);
  // useEffect(()=>{
  //   localStorage.AuthUserKey&& history.push('RestaurantsGroups')
  // },[])
  const validateEmail=(email)=> {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  const handleLogin = async () => {
    if(validateEmail(email)){
    setLoading(true)
    let res = await dispatch(loginUser({ email: email, password: password }));
    if (res === 200) {
      history.push("RestaurantsGroups");
    }else{
      setErrorMessage(true)
    }
    setLoading(false)
  }else{
    setErrorEmail(true)
  }
  };
  return (
    <Grid
      container
      justify="center"
      style={{ paddingTop: "90px" }}
      onKeyDown={handleKeyDown}
    >
      <Grid item>
        <Container component="main" style={{ width: "400px" }}>
          <CssBaseline />
          <div className={classes.paper}>
            <LogoImage style={{ borderRadius: "5px", marginBottom: "24px" }} />
            <Typography
              className={classes.GenralText}
              component="h1"
              variant="h5"
            >
              {intl.formatMessage({
                id: "Login_to_access_Dashboard",
              })}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                onChange={handleEmail}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={email}
                type="email"
                label={intl.formatMessage({
                  id: "Email",
                })}
                error={errorEmail}
                helperText={errorEmail&&"Invalid email"}
                autoFocus
                style={{
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  border: "0px",
                }}
              />
              <TextField
                onChange={handlePassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={intl.formatMessage({
                  id: "Password",
                })}
                type="password"
                value={password}
                style={{
                  background: "#FFFFFF",
                  borderRadius: "5px",
                  border: "0px",
                }}
              />
              {errorMessage && (
                <div className={classes.error}>
                  {intl.formatMessage({
                    id: "Your_Enter_Email_or_Password_Is_wrong_try_again",
                  })}
                </div>
              )}

              {loading?<AcceptButton
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
             >
                <CircularProgress size={25} style={{color:"#fff"}}/>
                
              </AcceptButton>:
              <AcceptButton
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleLogin()}
            >
             {intl.formatMessage({
                id: "Login",
              })}
            </AcceptButton>}
              <Grid container justify="center">
                <Link
                  href="/signup"
                  variant="body2"
                  className={classes.signupButton}
                >
                  {"Sign Up"}
                </Link>
              </Grid>
              <Grid container justify="center">
                {/* <Grid item xs>
                  <FormControlLabel
                    style={{ color: "#A3A9BC", paddingLeft: "20px" }}
                    control={
                      <Checkbox value="remember" style={{ color: "#A3A9BC" }} />
                    }
                    label={intl.formatMessage({
                      id: "Remember_me",
                    })}
                  />
                </Grid> */}
                <Grid item style={{ marginTop: "31px" }}>
                  <div>
                    <Link
                      href="/forgot-password"
                      variant="body2"
                      style={{ color: "#F66761" }}
                    >
                      {intl.formatMessage({
                        id: "Forgot_password",
                      })}
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}></Box>
        </Container>
      </Grid>
      {/* <Grid item xs={6}>
        <img
          src={LoginPicture}
          width={"550px"}
          alt={intl.formatMessage({
            id: "no_img",
          })}
        ></img>
      </Grid> */}
    </Grid>
  );
}
const AcceptButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      border: "  1px solid #20BDA1",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  signupButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
  },
  error: {
    color: "#F66761",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
    marginBottom: "6px",
  },
}));
