import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { TextButtonWhite, TextButton } from "../../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useIntl } from "react-intl";
import Header from "./Components/Header";
import SubHeader from "./Components/SubHeader";
import PermissionsRow from "./Components/PermissionsRow";
export default function DeleteUser(props) {
 

  const handleClose = () => {
    props.handleClose(false);
  };
 
  return (
    <div>
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            paddingBottom: "0px",
            height: "40px",
            paddingTop: "0px",
            backgroundColor: "#F3F5F8",
          }}
        >
          <Grid container>
            <Grid item xs={10} />
            <Grid
              item
              xs={2}
              style={{
                justifyContent: "flex-end",
                minHeight: "40px",

                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CloseIcon
                onClick={handleClose}
                style={{ cursor: "pointer", color: "#A3A9BC" }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0px" }}>
          <Header />
          <PermissionsRow
            title={"Manage users"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: false,
              Admin: true,
              Cashier: false,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Billing"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: false,
              Admin: true,
              Cashier: false,
              border: false,
            }}
          />
          <SubHeader />
          <PermissionsRow
            title={"Incoming orders"}
            data={{
              Server: true,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Order history"}
            data={{
              Server: true,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Manage tabs"}
            data={{
              Server: true,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Menu availability"}
            data={{
              Server: false,
              Kitchen: true,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Menu manager"}
            data={{
              Server: false,
              Kitchen: true,
              Manager: true,
              Admin: true,
              Cashier: false,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Localization"}
            data={{
              Server: false,
              Kitchen: true,
              Manager: true,
              Admin: true,
              Cashier: false,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Reports"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Venue details"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: false,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Order settings"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: false,
              border: true,
            }}
          />
          <PermissionsRow
            title={"Table numbers"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: true,
              border: true,
            }}
          />
          <PermissionsRow
            title={"QR codes"}
            data={{
              Server: false,
              Kitchen: false,
              Manager: true,
              Admin: true,
              Cashier: false,
              border: false,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  columnHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  title: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
}));

// background: #FFFFFF;
// /* Input field */

// border: 1px solid #EDF0F4;
// box-sizing: border-box;
// border-radius: 5px;
