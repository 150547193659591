import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import "../../style/home.css";
import { Grid } from "@material-ui/core";
import { ReactComponent as TickIcon } from "../../assets/image/tick.svg";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    border: "0px",
    padding: "12px",
  },
  LabelText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    margin: "8px 0px",

    color: theme.colors.primary,
  },
  PreviousOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    margin: "0px",
    color: theme.colors.primary,
    padding: "0px 0px 30px 0px",

    display: "flex",
    justifyContent: "center",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },
  printButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#FF3366",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  verifyButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "none",
    background: "#34CEB2",
    marginBottom: "22px",
    color: "#FFFFFF",
    borderRadius: " 5px",
  },
  RejectOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  Description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.colors.primary,
    color: "#303A5F",
  },
  cancelBotton: {
    cursor: "pointer",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
    padding: "0px 0px 0px 20px",
  },
}));

function ConfirmVerificationTabDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

  const { OrderSelected } = props;
  return (
    <div>
      {/* <Dialog
        open={verifyDialog}
        // onClose={handleClose}
        style={{
          // padding: "200px 300px 230px 350px ",
          borderRadius: "5px !important",
        }}
      > */}
      <div
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <Grid item xs={12} style={{ padding: "20px 0px", width: "600px" }}>
          <Grid container>
            <Grid item xs={12} className={classes.PreviousOrder}>
              {intl.formatMessage({
                id: "Tab_verified",
              })}
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "98px",
                height: "98px",
                background: "#34CEB2",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* tick */}
              <TickIcon style={{ width: "47px", height: "33px" }} />
            </div>
          </Grid>

          <Grid container justify="center" style={{ paddingTop: "30px" }}>
            <Typography className={classes.Description}>
             
             {intl.formatMessage({
                id: "table",
              })}
             : {OrderSelected?.tab?.table_number} - {OrderSelected?.name}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {/* </Dialog> */}
    </div>
  );
}

export default ConfirmVerificationTabDialog;
