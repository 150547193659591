import React,{useState} from "react";
import QrPic from "../../assets/image/qrcode.png";
import VerifyTabDialog from "../../Components/DialogBox/VerifyTabDialog";
// import { useEffect, useState, Component, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const DownloadButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
    },
    "&:focus": {
      backgroundColor: "#20BDA1",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    height: "88vh",
    background: "#F9FAFC",
  },
  QrCode: {
    margin: "20px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.colors.primary,
  },
  webAddress: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
  downloadText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: theme.colors.primary,
  },
}));
function QRcode() {
  const intl = useIntl();

  let business_account = useSelector(
    (state) => state.hotelDetailsReducer?.hotelDetail?.business_account
  );
  const classes = useStyles();
  const download = (url, name) => {
    console.log(url);
    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + " qr-code.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const download = (url, name) => {
  //   console.log(url);
  //   fetch(url, {
  //     method: "GET",
  //     headers: {},
  //   })
  //     .then((response) => {
  //       response.arrayBuffer().then(function (buffer) {
  //         const url = window.URL.createObjectURL(new Blob([buffer]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", name + " qr-code.png");
  //         document.body.appendChild(link);
  //         link.click();
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid className={classes.QrCode}> 
          {business_account?.type === 2
                      ? intl.formatMessage({
                          id: "QR_code",
                          defaultMessage: "QR_code",
                        })
                      : intl.formatMessage({
                      id: "Hotel_QR_code",
                      defaultMessage:
                        "Hotel QR code",
                    })}
           </Grid>
          <Grid style={{ textAlign: "center" }} container justify="center">
            <Grid container justify="center">
              <div id="qr-code" style={{ height: "190px", display: "none" }}>
                <QRCode value={`https://${business_account?.domain}`} />
              </div>
              <div style={{ height: "190px" }}>
                <img
                  id="qr-code2"
                  src={business_account?.qr_image}
                  alt={intl.formatMessage({
                    id: "no_img",
                    defaultMessage:
                      "No Img",
                  })}
                  width={"190px"}
                ></img>
              </div>
            </Grid>
            <Grid container justify="center">
              <p className={classes.webAddress}>{business_account?.domain}</p>
            </Grid>

            <Grid container justify="center">
              <DownloadButton
                variant="contained"
                color="primary"
                onClick={() =>
                  download(business_account?.qr_image, business_account?.name)
                }
              >
                {intl.formatMessage({
                      id: "Download_QR_code_image",
                      defaultMessage:
                        "Download QR code image",
                    })}
              </DownloadButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <VerifyTabDialog dialogOpen={true} setDialogOpen={() => {}} />
    </div>
  );
}

export default QRcode;
