import React, { useEffect, useState } from "react";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import { TextButtonAdd } from "../../Components/Buttons/Buttons";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import UserCard from "../../Components/UsersPermissions/Cards/User";
import UserForm from "../../Components/UsersPermissions/Dialogs/UserForm";
import Permissions from "../../Components/UsersPermissions/Dialogs/Permissions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissionUsers } from "../../actions/UserPermissions";
const UsersPermissions = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [editPermission, setEditPermission] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openUserModel, setOpenUserModel] = useState(false);
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );
  let permissions = useSelector((state) => state.userPermissions?.permissions);
  const classes = useStyles();
  const handleCloseUserForm = () => {
    setOpenUserModel(false);
    setEditPermission(null);
  };
  const handleOpenUserForm = () => {
    setOpenUserModel(true);
  };

  const getData = async () => {
    if (User_uuid) {
      setLoader(true);
      let res = await dispatch(
        getPermissionUsers({
          business_account_uuid: User_uuid,
        })
      );
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [User_uuid]);
  const handleEdit = (item) => {
    setEditPermission(item);
    setOpenUserModel(true);
  };
  return (
    <Grid container className={classes.root}>
      {openUserModel && (
        <UserForm
          open={openUserModel}
          handleClose={handleCloseUserForm}
          data={editPermission}
        />
      )}
    
      <Grid item xs={1}></Grid>
      <Grid item xs={10} className={classes.horizontalCenter} container>
        <Grid style={{ maxWidth: "700px", padding: "0px", margin: "0px" }}>
          <Grid
            item
            container
            justify="space-between"
            style={{ marginTop: "30px", maxWidth: "700px", minWidth: "470px" }}
          >
            <Grid item className={classes.heading}>
              Manage users 
            </Grid>
            <Grid item>
              <TextButtonAdd onClick={handleOpenUserForm}>
                Add new user
              </TextButtonAdd>
            </Grid>
          </Grid>
          <Grid
            item
            container
            style={{ marginTop: "25px", marginBottom: "20px" }}
          >
            <Grid item className={classes.subHeading}>
              Name
            </Grid>
          </Grid>
          <Grid container>
            {loader ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              permissions &&
              permissions.map((item, index) => {
                return (
                  <UserCard handleEdit={handleEdit} key={index} data={item} />
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};

export default UsersPermissions;

const useStyles = makeStyles((theme) => ({
  horizontalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  button: {
    padding: "11px 20px",
    width: "133px",
    height: "40px",
    background: "#34CEB2",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  root: {
    backgroundColor: "#F9FAFC",
    minHeight: "80vh",
  },
}));
