import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState, useRef } from "react";
import Select from "@material-ui/core/Select";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { ReactComponent as NextLine } from "../../../assets/image/NextLine.svg";
const User = ({data,handleEdit}) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid container className={classes.card} onClick={()=>handleEdit(data)}>
      <Grid container justify="space-between">
        <Grid item className={classes.verticleCenter}>
          <span className={classes.heading}>{data?.user?.full_name}</span>
          <span className={classes.role}> {data?.permission&&`(${data?.permission})`}</span>
        </Grid>
        <Grid item className={classes.verticleCenter}>
          <span className={classes.email}>{data?.user?.email}</span>
          <NextLine />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default User;

const useStyles = makeStyles((theme) => ({
  card: {
    // background: "#898334",
    background: "#FFFFFF",
    borderRadius: "2px",
    height: "40px",
    padding: "0px 15px 0px 11px",
    marginTop: "5px",
    cursor:"pointer"
  },
  verticleCenter: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    paddingRight: "5px",
  },
  role: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  email: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
    marginRight: "40px",
  },
}));
