import React, { useState, useEffect } from "react";
import { Grid, Divider, Typography, Card, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  TextButtonBorder,
  TextButtonAdd,
  TextButton,
} from "../Buttons/Buttons";
import LanguageForm from "../ItemDetails/LanguageForm";
import { ReactComponent as Edit } from "../../assets/image/edit.svg";
import CreateIcon from "@material-ui/icons/Create";
import { getAllLanguages,setMenuManagerLanguage } from "../../actions/Languages";
import { useDispatch } from "react-redux";
function LanguageHeader(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [editCode, setEditCode] = useState("");
  const classes = useStyles();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let languages = useSelector(
    (state) => state?.languageReducer?.languagesList
  );
  const languagesList = useSelector(
    state=>state?.VenueOrderReducer.VenueDetail?.supported_translations
  );
 let languageReducer = useSelector((state) => state?.languageReducer);

  const handleOpenModel = () => {
    setOpenModel(true);
  };
  const handleOpenEditModel = (code) => {
    setOpenModel(true);
    setEditCode(code)
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setEditCode("")
  };
  useEffect(() => {
    dispatch(getAllLanguages());
  }, []);
  const handleSelectLanguage=(code)=>{
    dispatch(setMenuManagerLanguage(code))
  }
  return (
    <>
      <LanguageForm language={editCode} open={openModel} handleClose={handleCloseModel} />
      <Grid
        container
        className={classes.root}
        style={{
          backgroundColor: `${props?.heading === "Total" ? "#303A5F" : ""} `,
          justifyContent: "space-between",
        }}
      >
        <Grid item>
          <Grid container>
            {languagesList&&languagesList.length > 0 &&
              languagesList.filter(it=>it!==venueDetail?.default_language).map((item, index) => {
                return (
                  <Grid
                    item
                    className={classes.itemsSpace}
                    style={{ position: "relative" }}
                  >
                    {languageReducer?.menuManagerLanguage !== item ? (
                      <TextButtonBorder onClick={()=>handleSelectLanguage(item)} className={classes.buttonStyle}>
                        {languages[item]?languages[item]:item}
                      </TextButtonBorder>
                    ) : (
                      <TextButton style={{backgroundColor:"#F66761"}}
                      onClick={()=>handleSelectLanguage(item)}
                      className={classes.buttonStyle}>
                        {languages[item]?languages[item]:item}
                      </TextButton>
                    )}
                  {languageReducer?.menuManagerLanguage === item &&  <CreateIcon
                      style={{
                        fontSize: "18px",
                        color: "#fff",
                        position: "absolute",
                        top: 5,
                        cursor:"pointer",
                        right: 5,
                      }}
                      onClick={()=>handleOpenEditModel(item)}
                    />}
                  </Grid>
                );
              })}

            <Grid item className={classes.itemsSpace}></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextButtonAdd
            className={classes.buttonStyle}
            onClick={handleOpenModel}
          >
            {intl.formatMessage({
                  id: "Add_Language",
                  defaultMessage:"Add Language"
                })}
            
          </TextButtonAdd>
        </Grid>
      </Grid>
    </>
  );
}

export default LanguageHeader;
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "30px",
  },
  itemsSpace: {
    margin: "0px 10px",
  },
  buttonStyle: {
    minWidth: "100px",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    textTransform: "capitalize",
    "&:hover": {
      textTransform: "capitalize",
    },
  },
}));
