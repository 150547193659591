import {
  GET_HOTEL_GUESTS_LIST,
  ADD_HOTEL_GUESTS,
  UPDATE_HOTEL_GUESTS,
  DELETE_HOTEL_GUESTS,
} from "../constant/types";
import API from "../utils/API";

export const getGuestsList = ({
  business_account_uuid,
  search,
  room_number,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "business-account-uuid": business_account_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.get(
      `/api/business/guest-accounts/${
        search ? "?filter_results_by=" + search : ""
      }${
        search ? room_number && "&room_number=" : room_number && "?room_number="
      }${room_number ? room_number : ""}`,
      config
    );
    let data = response?.data;
    dispatch({ type: GET_HOTEL_GUESTS_LIST, payload: data });

    return 200;
  } catch (error) {
    return 400;
  }
};

export const addGuest = ({ business_account_uuid, data }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "business-account-uuid": business_account_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.post(
      `/api/business/guest-accounts/`,
      data,
      config
    );
    let data2 = response?.data;

    dispatch({ type: ADD_HOTEL_GUESTS, payload: data2 });

    return { status: 200 };
  } catch (error) {
    return {
      status: 400,
      message: error.message.includes("400")
        ? "This email account already exists as an active Guest."
        : "Something went wrong",
    };
  }
};

export const updateGuest = ({ business_account_uuid, data, id }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "business-account-uuid": business_account_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  try {
    const response = await API.patch(
      `/api/business/guest-accounts/${id}/`,
      data,
      config
    );
    let data2 = response?.data;

    dispatch({ type: UPDATE_HOTEL_GUESTS, payload: data2 });

    return { status: 200 };
  } catch (error) {
    return {
      status: 400,
      message: error.message.includes("400")
        ? "This email account already exists as an active Guest."
        : "Something went wrong",
    };
  }
};
export const deleteGuest = ({ business_account_uuid, id }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "business-account-uuid": business_account_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.delete(
      `/api/business/guest-accounts/${id}/`,
      config
    );
    dispatch({ type: DELETE_HOTEL_GUESTS, payload: id });

    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
