import { CardContent, Grid, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import upload from "../../assets/image/addImages.png";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";

const AddImage = (props) => {
  const intl = useIntl();

  const { image, selectedFile, handleClearImage } = props;
  const classes = useStyles();
  const handleClear = (e) => {
    e.stopPropagation();
    handleClearImage();
  };
  return (
    <div className={classes.rootDiv}>
      <>
        {!image ? (
          <label className="profile_image">
            <input
              type="file"
              className="profile_image_input"
              style={{ display: "none" }}
              accept="image/*"
              onChange={selectedFile}
            />
            <CardContent className={classes.imageCardRoot}>
              <Grid
                container
                direction="column"
                justify="center"
                style={{
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {
                  <Grid
                    xs={12}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <img alt={"upload"} src={upload} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.addBtn}>
                        {intl.formatMessage({
                      id: "Add_image",
                    })}
                          
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </CardContent>
          </label>
        ) : (
          <div
            style={{
              position: "relative",
            }}
          >
            <Grid
              onClick={(e) => handleClear(e)}
              style={{
                position: "absolute",
                opacity: "0.5",
                right: 5,
                top: 5,
                height: "24px",
                width: "24px",
                backgroundColor: "#000",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1009,
              }}
            >
              <CloseIcon style={{ color: "#fff", fontSize: "20px" }} />
            </Grid>

            <label className="profile_image">
              <input
                type="file"
                className="profile_image_input"
                style={{ display: "none" }}
                accept="image/*"
                onChange={selectedFile}
              />
              <CardContent
                className={classes.imageCardRoot}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundColor: "#FEEFCC",
                  borderRadius: "6px",
                  backgroundSize: "cover !important",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "relative",
                }}
              ></CardContent>
            </label>
          </div>
        )}
      </>
    </div>
  );
};

export default AddImage;

const useStyles = makeStyles((theme) => ({
  addBtn: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  rootDiv: {
    height: "100px",
    width: "318px",
    cursor: "pointer",
  },
  imageCardRoot: {
    height: "100px",
    width: "318px",
    border: "1px dashed #A3A9BC",
    boxSizing: "border-box",
    borderRadius: "6px",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    cursor: "pointer",
  },
  cardButtonSelected: {
    cursor: "pointer",
    width: "4em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: "rgb(116, 103, 239)",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  cardButton: {
    cursor: "pointer",
    width: "18em",
    height: "4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: "rgb(116, 103, 239)",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#FFFFFF",
    marginTop: "20px",
    margin: "auto",
  },
  cardText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363636",
  },
  profile_image: {
    padding: "10px",
    cursor: "pointer",
  },
}));
