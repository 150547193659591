import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { TextButtonAdd, TextButtonNoColor } from "../../Buttons/Buttons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewUser,
  updateUser,
  getAvailablePermissionRoles,
  getAvailableVenues,
  deleteUser,
} from "../../../actions/UserPermissions";
import { useIntl } from "react-intl";
import MultiSelect from "./Components/MultiSelect";
import DeleteUserConfirmation from "./DeleteUser";
import Permissions from "./Permissions"
export default function UserForm(props) {
  console.log("props", props);
  const dispatch = useDispatch();
  const intl = useIntl();
  let User_uuid = useSelector(
    (state) => state.VenueOrderReducer?.UserDetail?.business_account?.uuid
  );

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [permission, setPermission] = React.useState("");
  const [venues, setVenues] = React.useState([]);
  const [responseError, setResponseError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  useEffect(() => {
    if (props.data) {
      setEditMode(true);
      setName(props?.data?.user?.full_name);
      setEmail(props?.data?.user?.email);
      setPermission(props?.data?.permission);
      setVenues(props?.data?.venues);
    }
  }, [props]);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    venues: false,
    permission: false,
  });
  let roles = useSelector((state) => state?.userPermissions?.roles);
  let venuesList = useSelector((state) => state?.userPermissions?.venues);

  const getData = async () => {
    if (User_uuid) {
      setLoading(true);
      let res = await dispatch(
        getAvailablePermissionRoles({
          business_account_uuid: User_uuid,
        })
      );
      let res1 = await dispatch(
        getAvailableVenues({
          business_account_uuid: User_uuid,
        })
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [User_uuid]);

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };

  const handleSubmit = async () => {
    console.log({ name });
    let objError = {
      name: false,
      email: false,
      permission: false,
      venues: false,
    };

    if (
      name === null ||
      name === undefined ||
      name === "" ||
      email === null ||
      email === undefined ||
      email.trim() === "" ||
      permission === null ||
      permission === undefined ||
      permission.trim() === "" ||
      venues.length === 0
    ) {
      if (venues.length) {
        objError["venues"] = true;
      }
      if (
        permission === null ||
        permission === undefined ||
        permission === ""
      ) {
        objError["permission"] = true;
      }
      if (name === null || name === undefined || name === "") {
        objError["name"] = true;
      }
      if (email === null || email === undefined || email === "") {
        objError["email"] = true;
      }
      setErrors({ ...errors, ...objError });
    } else {
      setLoader(true);
      let obj = {
        user: { full_name: name, email: email },
        permission: permission,
        venues: [...venues],
      };
      let res = await dispatch(
        editMode
          ? updateUser({
              business_account_uuid: User_uuid,
              data: obj,
              id: props?.data?.id,
            })
          : addNewUser({
              business_account_uuid: User_uuid,
              data: obj,
            })
      );
      setLoader(false);
      if (res?.status === 200) {
        // alert("success")
        handleClose();
      } else {
        setResponseError(true);
        // alert("error")
      }
    }
  };
  const handleDelte = async () => {
    let res = await dispatch(
      deleteUser({
        business_account_uuid: User_uuid,
        id: props?.data?.id,
      })
    );
    if (res?.status === 200) {
      handleClose();
    } else {
      setResponseError(true);
    }
  };
  const handleChangePermissions = (e) => {
    setErrors({ ...errors, permission: false });
    setPermission(e.target.value);
  };
const closeDetails=()=>{
  setOpenDetails(false)
}
  return (
    <div>
      <Permissions
      open={openDetails}
      handleClose={closeDetails}
      />
      <DeleteUserConfirmation
        handleClose={handleClose}
        close={setOpenDelete}
        open={openDelete}
        handleDelte={handleDelte}
      />
      <Dialog
        fullWidth
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Typography className={classes.headersTest}>
                    {editMode
                      ? intl.formatMessage({
                          id: "edit_user_details",
                          defaultMessage: "Edit user details",
                        })
                      : intl.formatMessage({
                          id: "new_user_details",
                          defaultMessage: "New user details",
                        })}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  {responseError && (
                    <small
                      style={{
                        fontSize: "0.75rem",
                        color: "#f44336",
                      }}
                    >
                      {"Something went wrong"}
                    </small>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography
              className={classes.headingTest}
              style={{ paddingBottom: "0px", marginBottom: "10px" }}
            >
              {intl.formatMessage({
                id: "name",
                defaultMessage: "Name",
              })}
            </Typography>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "Enter_name",
                defaultMessage: "Enter Name",
              })}
              error={errors?.name}
              helperText={
                errors?.name &&
                intl.formatMessage({
                  id: "Field_is_required",
                  defaultMessage: "Field is required",
                })
              }
              fullWidth
              disabled={editMode}
              value={name}
              onChange={(e) => {
                setErrors({ ...errors, name: false });
                setName(e.target.value);
              }}
              style={{
                borderRadius: "5px",
                width: "100%",
              }}
              inputProps={{
                className: classes.TextInputStyle2,
                "aria-label": "search",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "Email",
                })}
                <span className={classes.labelMessage}>
                  (
                  {intl.formatMessage({
                    id: "send_email_instruction",
                  })}
                  )
                </span>
              </Typography>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "Enter_email",
                  defaultMessage: "Enter Email",
                })}
                disabled={editMode}
                error={errors?.email}
                helperText={
                  errors?.email &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                fullWidth
                value={email}
                onChange={(e) => {
                  setErrors({ ...errors, email: false });
                  setEmail(e.target.value);
                }}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle2,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Typography
                    className={classes.headingTest}
                    style={{
                      paddingBottom: "0px",
                      marginBottom: "10px",
                      marginTop: "16px",
                    }}
                  >
                    {intl.formatMessage({
                      id: "admin_role",
                      defaultMessage: "Admin role",
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <div

                  onClick={()=>setOpenDetails(true)}
                    style={{
                      cursor:"pointer",
                      marginLeft:"5px",
                      marginTop: "16px",
                      backgroundColor: "#F66761",
                      borderRadius: "50%",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      width: "20px",
                      fontSize:"13px",
                      fontWeight:700
                    }}
                  >
                    ?
                  </div>
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "select_admin_role",
                  defaultMessage: "Select Admin role",
                })}
                fullWidth
                error={errors?.permission}
                helperText={
                  errors?.permission &&
                  intl.formatMessage({
                    id: "Field_is_required",
                    defaultMessage: "Field is required",
                  })
                }
                select
                onChange={(e) => handleChangePermissions(e)}
                value={permission}
                defaultValue={permission}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              >
                {roles?.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.headingTest}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "10px",
                  marginTop: "16px",
                }}
              >
                {intl.formatMessage({
                  id: "Venue access",
                  defaultMessage: "Venue access",
                })}
              </Typography>
              <MultiSelect
                venuesList={venuesList}
                setVenues={setVenues}
                venues={venues}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "18px 24px  30px 8px " }}>
          {!editMode ? (
            <Grid container justify="space-between">
              <Grid item>
                <TextButtonNoColor disabled={loader} onClick={handleClose}>
                  {intl.formatMessage({
                    id: "Cancel",
                  })}{" "}
                </TextButtonNoColor>
              </Grid>
              <Grid item>
                <TextButtonAdd disabled={loader} onClick={handleSubmit}>
                  {loader ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Add",
                    })
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="space-between">
              <Grid item>
                <TextButtonNoColor
                  disabled={loader}
                  onClick={() => setOpenDelete(true)}
                >
                  {intl.formatMessage({
                    id: "Delete",
                  })}
                </TextButtonNoColor>
              </Grid>
              <Grid item>
                <TextButtonAdd disabled={loader} onClick={handleSubmit}>
                  {loader ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({
                      id: "Save",
                    })
                  )}
                </TextButtonAdd>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  verified: {
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: theme.colors.primary,
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
  headersTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  labelMessage: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#303A5F",
    paddingLeft: "4px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  outlinedStyle: {
    border: "0px",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },

  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "14px !important",
      background: "#EAECEE !important",
      boxSizing: "border-box",
      borderRadius: "5px !important",
    },
  },
  classEndronment: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "14px !important",
      background: "#EAECEE !important",
      boxSizing: "border-box",
      borderRadius: "5px !important",
    },
  },
  outlinedStyle: {
    border: "0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
}));
