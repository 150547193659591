import React from "react";
import QrPic from "../../assets/image/qrcode.png";
import { withStyles } from "@material-ui/core/styles";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const DownloadButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    textTransform: "none",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    backgroundColor: "#34CEB2",
    border: "  1px solid #34CEB2",

    "&:hover": {
      backgroundColor: "#20BDA1",
      // border: "  1px solid #20BDA1",
    },
    "&:focus": {
      // boxShadow: 'none',
      // backgroundColor: 'white',
      // borderColor: '#005cbf',
      backgroundColor: "#20BDA1",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    height: "88vh",
    background: "#F9FAFC",
    paddingTop: "100px",
  },
  QrCode: {
    margin: "20px 0px 60px 0px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  webAddress: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  downloadText: {
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
}));
function QRcode() {
  const intl = useIntl();

  const classes = useStyles();
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  const download = (url, name) => {
    console.log(url);
    fetch(url, {
      method: "GET",
     
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + " qr-code.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid style={{ textAlign: "center" }}>
            <img src={venueDetail?.qr_image} alt="no QR" width={"190px"}></img>
            <p className={classes.webAddress}>{venueDetail?.domain}</p>

            <DownloadButton
              variant="contained"
              color="primary"
              onClick={() => download(venueDetail?.qr_image, venueDetail?.name)}
            >
              {intl.formatMessage({
                    id: "Download_QR_code_image",
                    defaultMessage: "Download QR code image",
                  })}
              
            </DownloadButton>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default QRcode;
