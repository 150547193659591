import {
  GET_VENUE_DETAILS,
  ADD_MENU,
  DELETE_MENU,
  UPDATE_MENU,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_ITEM,
  GET_TAGS_META,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_TAG,
  ADD_CUSTOMIZATION,
  DEFAULT_STATUS,
  GET_CUSTOMIZATION_LIST,
  ADD_ITEM,
  UPDATE_ITEM,
  GET_ALL_TAGS,
  ADD_TAGS_TEMPLATE,
  UPDATE_TAGS_TEMPLATE,
  DELETE_TAGS_TEMPLATE,
  DELETE_CUSTOMIZATION,
  UPDATE_CUSTOMIZATION,
  REORDER_MENU,
  REORDER_CATEGORY,
  REORDER_ITEMS,
  REORDER_CUSTOMIZATIONS,
  REORDER_CUSTOMIZATIONS_OPTIONS,
  UPDATE_ALL_ITEM_CUSTOMIZATIONS,
  UPDATE_ITEM_PRICES,
} from "../constant/types";

const initialState = {
  venueDetails: null,
  tagsMeta: {},
  status: {
    added: false,
    updated: false,
    deleted: false,
  },
  menuCustomization: null,
  allTags: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TAGS: {
      return {
        ...state,
        allTags: payload?.data,
      };
    }
    case ADD_TAGS_TEMPLATE: {
      return {
        ...state,
        allTags: [...state.allTags, { ...payload?.data }],
      };
    }
    case UPDATE_TAGS_TEMPLATE:
      let menu = [...state.allTags];
      let ind = menu.findIndex((n) => n.id === payload?.id);
      if (ind > -1) {
        menu[ind] = action.payload.data;
      }
      return {
        ...state,
        allTags: [...menu],
      };
    case DELETE_TAGS_TEMPLATE: {
      let menu2 = [...state.allTags];
      let ind = menu2.findIndex((n) => n.id === payload?.id);
      if (ind > -1) {
        menu2.splice(ind, 1);
      }
      return {
        ...state,
        allTags: [...menu2],
      };
    }
    case ADD_ITEM: {
      const { item, menuId, categoryId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];

          itemsList.push(item);

          newMenuItem[indCategory].items = [...itemsList];
          menu[ind].menu_categories = [...newMenuItem];

          return {
            ...state,
            venueDetails: {
              ...state.venueDetails,
              menus: [...menu],
            },
          };
        }
      }
    }
    case DEFAULT_STATUS: {
      return {
        ...state,
        status: {
          added: false,
          updated: false,
          deleted: false,
        },
      };
    }
    case GET_TAGS_META: {
      return {
        ...state,
        tagsMeta: payload?.response?.data,
      };
    }
    case GET_VENUE_DETAILS: {
      return {
        ...state,
        venueDetails: payload,
      };
    }
    case ADD_MENU:
      let m = state.venueDetails.menus.map((a) => a);
      return {
        ...state,
        venueDetails: {
          ...state.venueDetails,
          menus: [...m, action.payload],
        },
      };
    case REORDER_MENU:
      return {
        ...state,
        venueDetails: {
          ...state.venueDetails,
          menus: [...action.payload],
        },
      };
    case REORDER_CATEGORY: {
      const { id, data } = payload;
      {
        let menu = state.venueDetails.menus.map((a) => a);
        let ind = menu.findIndex((n) => n.id === id);
        let newMenuItem = [...data];
        let returnObj = { ...state };
        if (ind > -1) {
          menu[ind].menu_categories = newMenuItem.map((a) => a);
          let details = state.venueDetails;
          details["menus"] = menu.map((a) => a);

          return {
            ...state,
            venueDetails: { ...details },
          };
        } else {
          return state;
        }
        // return returnObj;
      }
    }
    case REORDER_ITEMS: {
      const { data, id, category_id } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === id);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === category_id);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...data];
          newMenuItem[indCategory].items = [...itemsList];
          menu[ind].menu_categories = [...newMenuItem];

          return {
            ...state,
            venueDetails: {
              ...state.venueDetails,
              menus: [...menu],
            },
          };
        }
      }
    }
    case DELETE_MENU: {
      let menu2 = state.venueDetails.menus;
      let ind = menu2.findIndex((n) => n.id === action.payload);
      if (ind > -1) {
        menu2.splice(ind, 1);
      }
      return {
        ...state,
        venueDetails: {
          ...state.venueDetails,
          menus: [...menu2],
        },
      };
    }
    case UPDATE_MENU: {
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === action.payload?.id);
      if (ind > -1) {
        menu[ind].name = action.payload.name;
      }
      return {
        ...state,
        venueDetails: {
          ...state.venueDetails,
          menus: [...menu],
        },
      };
    }
    case ADD_CATEGORY: {
      let menu = state.venueDetails.menus.map((a) => a);
      let ind = menu.findIndex((n) => n.id === action.payload?.menuId);
      let newMenuItem = [];
      let returnObj = { ...state };
      if (ind > -1) {
        newMenuItem = menu[ind]?.menu_categories.map((a) => a);
        let newItem = action.payload.data;
        newItem.position = newMenuItem.length;
        newMenuItem.push(newItem);
        menu[ind].menu_categories = newMenuItem.map((a) => a);
        let details = state.venueDetails;
        details["menus"] = menu.map((a) => a);

        return {
          ...state,
          status: {
            added: true,
            updated: false,
            deleted: false,
          },
          venueDetails: { ...details },
        };
        return state;
      } else {
        return state;
      }
      // return returnObj;
    }
    case DELETE_CATEGORY: {
      let menu = state.venueDetails.menus;
      let ind = menu.findIndex((n) => n.id === action.payload?.menuId);
      let newMenuItem = [];
      if (ind > -1) {
        newMenuItem = menu[ind]?.menu_categories;
        let indCategory = newMenuItem.findIndex(
          (n) => n.id === action.payload?.id
        );
        if (indCategory > -1) {
          newMenuItem.splice(indCategory, 1);
          menu[ind].menu_categories = [...newMenuItem];

          return {
            ...state,
            status: {
              added: false,
              updated: false,
              deleted: true,
            },
            venueDetails: {
              ...state.venueDetails,
              menus: menu,
            },
          };
        }
      }
    }
    case UPDATE_CATEGORY: {
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === action.payload?.menuId);
      let newMenuItem = [];
      if (ind > -1) {
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex(
          (n) => n.id === action.payload?.id
        );
        if (indCategory > -1) {
          newMenuItem[indCategory].name = action.payload.name;
          menu[ind].menu_categories = [...newMenuItem];
          return {
            ...state,
            venueDetails: {
              ...state.venueDetails,
              menus: [...menu],
            },
          };
        }
      }
    }

    case DELETE_ITEM: {
      const { id, menuId, categoryId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList.splice(indItem, 1);

            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }
    case ADD_TAG: {
      const { id, menuId, categoryId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].tags.push({ ...action.payload.data });

            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }
    case REMOVE_TAG: {
      const { id, menuId, categoryId, data } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            let tags = itemsList[indItem].tags;
            let tagIndex = tags.findIndex((n) => n.tag_id === data);
            if (tagIndex > -1) {
              tags.splice(tagIndex, 1);
              itemsList[indItem].tags = [...tags];
              newMenuItem[indCategory].items = [...itemsList];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                venueDetails: {
                  ...state.venueDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }

    case UPDATE_TAG: {
      const { id, menuId, categoryId, data, tag } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            let tags = itemsList[indItem].tags;
            let tagIndex = tags.findIndex((n) => n.name === tag);
            if (tagIndex > -1) {
              tags[tagIndex] = data;
              itemsList[indItem].tags = [...tags];
              newMenuItem[indCategory].items = [...itemsList];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                venueDetails: {
                  ...state.venueDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }
    case REORDER_CUSTOMIZATIONS: {
      const { itemId: id, menuId, categoryId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].item_customisations = [...action.payload.data];
          }
          newMenuItem[indCategory].items = [...itemsList];
        }
        menu[ind].menu_categories = [...newMenuItem];
        return {
          ...state,
          venueDetails: {
            ...state.venueDetails,
            menus: [...menu],
          },
        };
      }
    }

    case ADD_CUSTOMIZATION: {
      const { itemId: id, menuId, categoryId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].item_customisations.push({
              ...action.payload.data,
            });
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];
            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }
    case GET_CUSTOMIZATION_LIST: {
      return {
        ...state,
        menuCustomization: payload,
      };
    }

    case UPDATE_CUSTOMIZATION: {
      const { id, menuId, categoryId, itemId, data } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === itemId);

          if (indItem > -1) {
            let cust = [...itemsList[indItem].item_customisations];
            let indCust = cust.findIndex((n) => n.item_customisation_id === id);

            if (indCust > -1) {
              cust[indCust] = { ...data };
              itemsList[indItem].item_customisations = [...cust];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                venueDetails: {
                  ...state.venueDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }
    case REORDER_CUSTOMIZATIONS_OPTIONS: {
      const { id, menuId, categoryId, itemId, data } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === itemId);

          if (indItem > -1) {
            let cust = [...itemsList[indItem].item_customisations];
            let indCust = cust.findIndex((n) => n.item_customisation_id === id);

            if (indCust > -1) {
              cust[indCust].customisation_options = [...data];
              itemsList[indItem].item_customisations = [...cust];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                venueDetails: {
                  ...state.venueDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }
    case DELETE_CUSTOMIZATION: {
      const { id, menuId, categoryId, itemId } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);

      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);

        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === itemId);

          if (indItem > -1) {
            let cust = [...itemsList[indItem].item_customisations];
            let indCust = cust.findIndex((n) => n.item_customisation_id === id);

            if (indCust > -1) {
              cust.splice(indCust, 1);
              itemsList[indItem].item_customisations = [...cust];
              newMenuItem[indCategory].items = [...itemsList];
              menu[ind].menu_categories = [...newMenuItem];
              return {
                ...state,
                venueDetails: {
                  ...state.venueDetails,
                  menus: [...menu],
                },
              };
            }
          }
        }
      }
    }

    case UPDATE_ITEM: {
      const { id, menuId, categoryId, item } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem] = item;

            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }
    case UPDATE_ALL_ITEM_CUSTOMIZATIONS: {
      const { id, menuId, categoryId, item } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].item_customisations = [...item];
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }

    case UPDATE_ITEM_PRICES: {
      const { id, menuId, categoryId, item } = action.payload;
      let menu = [...state.venueDetails.menus];
      let ind = menu.findIndex((n) => n.id === menuId);
      if (ind > -1) {
        let newMenuItem = [];
        newMenuItem = [...menu[ind]?.menu_categories];
        let indCategory = newMenuItem.findIndex((n) => n.id === categoryId);
        if (indCategory > -1) {
          let itemsList = [];
          itemsList = [...newMenuItem[indCategory]?.items];
          let indItem = itemsList.findIndex((n) => n.item_id === id);
          if (indItem > -1) {
            itemsList[indItem].prices = [...item];
            console.log("itemsList[indItem].prices = item", itemsList[indItem]);
            newMenuItem[indCategory].items = [...itemsList];
            menu[ind].menu_categories = [...newMenuItem];

            return {
              ...state,
              venueDetails: {
                ...state.venueDetails,
                menus: [...menu],
              },
            };
          }
        }
      }
    }
    default:
      return state;
  }
}
