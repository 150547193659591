import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

export default function ModelHeader() {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.heading}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.columnHeading}>
                {intl.formatMessage({
                  id: "admin",
                  defaultMessage: "Admin",
                })}
              </Typography>
            </Grid>
            
            <Grid item>
              <Typography className={classes.columnHeading}>
                {intl.formatMessage({
                  id: "manager",
                  defaultMessage: "Manager",
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.columnHeading}>
                {intl.formatMessage({
                  id: "cashier",
                  defaultMessage: "Cashiers",
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.columnHeading}>
                {intl.formatMessage({
                  id: "kitchen",
                  defaultMessage: "Kitchen",
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.columnHeading}>
                {intl.formatMessage({
                  id: "server",
                  defaultMessage: "Server",
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.subHeading}>
          <Typography className={classes.columnHeading}>
            {intl.formatMessage({
              id: "account_permissions",
            })}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
    heading:{
        marginTop:"14px"

    },
  columnHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#303A5F",
  },
  subHeading:{
      marginTop:"29px"
  }
}));
