import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import { getReport, clearReports, getAllDaysReport } from "../actions/report";
import { useEffect, useState, useRef } from "react";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CircularProgress,
  makeStyles,
  TextField,
  FormControl,
  Fab,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import ReportCard from "./Cards/Report";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import calendar from "../assets/image/calendar.svg";
import CloseIcon from "@material-ui/icons/Close";
import { dayStartTime } from "../constant/config";
import { useIntl } from "react-intl";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F9FAFC",
    color: "#303A5F",

    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
  },
  body: {
    color: "#303A5F",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
  },
}))(TableCell);
let date = moment().startOf("day").add("hours", dayStartTime);
let date2 = moment().format("YYYY-MM-DD");
let todayStart = date.toString();

let todayEnd = moment(date2.toString() + " " + "05:59")
  .add("days", 1)
  .toString();


const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F6F6F8",
      marginLeft: "10px",
    },

    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  hideIcon: {
    "& .MuiSelect-icon": {
      // display: "none",
      paddingRight: "40px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiSelect-iconOpen": {
      paddingRight: "40px",
    },
  },
  showIcon: {
    // "& .MuiSelect-iconOpen": {
    //   paddingRight: "40px",
    // },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    // "& .MuiSelect-icon": {
    //   // display: "none",
    //   paddingRight: "40px",
    // },
  },
  cssApply: {
    "& .daterangepicker": {
      "& .rangecontainer": {
        display: "none",
      },
      "& .inputDate": {
        border: "1px solid",
        width: "70%",
        marginLeft: "15%",
      },
      "& .activeNotifier": {
        display: "none",
      },
      "& .fromDateTimeContainer": {
        fontSize: "13px",
        width: "270px",
        margin: "4px",
        paddingBottom: "40px",
      },
      "& .applyButton": {
        borderColor: "#F66761",
        color: "#fff",
        fontSize: "12px",
        borderRadius: "3px",
        padding: "5px 10px",
        cursor: "pointer",
        marginRight: "4px",
        backgroundColor: "#F66761 !important",
        border: "1px solid #F66761",
      },

      // "#F66761"
    },
  },

  root: {
    minHeight: "88vh",
    background: "#F9FAFC",
  },
  NoOrder: {
    backgroundColor: "#F9FAFC",
    color: "#303A5F",
    paddingTop: "20px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
  },
  PaymentProvider: {
    margin: "30px 0px 60px 0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },
  customLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#303A5F",
    margin: "0px",
  },
  table: {
    minWidth: 700,
    overflowY: "auto",
    // height: "60vh",
  },
  TableGrid: {
    overflowY: "auto",
    // height: "60vh",
    margin: "5px 0px 0px 0px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#303A5F",
    margin: "15px 0px 15px 0px",
  },
  outlinedStyle: {
    border: "0px",
  },
  rangeInputPadding: {
    padding: "12px",
    fontSize: "13px",
    color: "#303A5F",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px",
  },
  OneTextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px 12px 9px 12px",
  },
  customTimePicker: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  displayDate: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    paddingLeft: "15px",
    color: "#303A5F",
  },
}));
function Reports() {
  const intl = useIntl();
  const fixedDates = [
    {
      name: intl.formatMessage({
        id: "Custom",
        defaultMessage: "Custom",
      }),
      from_date: null,
      until_date: null,
      value: "Group0",
    },
    {
      name:intl.formatMessage({
        id: "Today",
        defaultMessage: "Today",
      }),
      from_date: moment(todayStart).format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group1",
    },
    {
      name: intl.formatMessage({
        id: "Yesterday",
        defaultMessage: "Yesterday",
      }),
      from_date: moment(todayStart).add("day", -1).format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd).add("day", -1).format("YYYY-MM-DD HH:mm"),
  
      value: "Group2",
    },
    {
      name: intl.formatMessage({
        id: "This_Week",
        defaultMessage: "This Week",
      }),
      from_date: moment(todayStart)
        .startOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group3",
    },
    {
      name: intl.formatMessage({
        id: "Last_Week",
        defaultMessage: "Last Week",
      }),
      from_date: moment(todayStart)
        .add("day", -7)
        .startOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd)
        .add("day", -7)
        .endOf("week")
        .add("day", 1)
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      value: "Group4",
    },
    {
      name: intl.formatMessage({
        id: "This_Month",
        defaultMessage: "This Month",
      }),
      from_date: moment(todayStart)
        .startOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment().format("YYYY-MM-DD HH:mm"),
      value: "Group5",
    },
  
    {
      name: intl.formatMessage({
        id: "Last_Month",
        defaultMessage: "Last Month",
      }),
      from_date: moment(todayStart)
        .add("month", -1)
        .startOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      until_date: moment(todayEnd)
        .add("month", -1)
        .endOf("month")
        .add("hours", dayStartTime)
        .format("YYYY-MM-DD HH:mm"),
      value: "Group6",
    },
  ];
  
  let dispatch = useDispatch();
  const [Datevalue, setDatevalue] = useState([null, null]);
  const [fromDate, setfromDate] = useState("");
  const [untilDate, setuntilDate] = useState("");
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let reportReducerState = useSelector((state) => state?.reportReducer?.report);

  const [reportReducer, setReportReducer] = useState(reportReducerState);
  let OrderHistoryReducer = useSelector((state) => state.VenueOrderReducer);
  const [OrderList, setOrderList] = useState([]);
  const [TotalAccepted, setTotalAccepted] = useState(0);
  const [TotalRejected, setTotalRejected] = useState(0);
  const [SearchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const inputEl = useRef(null);
  const [OrderSelected, setOrderSelected] = useState(null);
  const [customGroup, setcustomGroup] = React.useState("Group1");
  const [open, setOpen] = useState(false);

  const [startDate1, setStartDate1] = useState(moment().startOf("day"));
  const [startDate2, setStartDate2] = useState(moment());
  const [isSetDate, setIsSetDate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setReportReducer(reportReducerState);
  }, [reportReducerState]);
  const handleChange = (event) => {
    setcustomGroup(event.target.value);
    let dates1 = fixedDates?.filter((n) => n.value === event.target.value);
    if (event.target.value !== "Group0") {
      let dates = dates1[0];
      let end1 = dates?.until_date;
      let start1 = dates?.from_date;
      console.log("date:", start1, end1);
      setStartDate1(moment(start1));
      setStartDate2(moment(end1));
      getReports(moment(start1), moment(end1));
      setIsSetDate(true);
    } else {
      setStartDate1(moment().startOf("day"));
      setStartDate2(moment().startOf("day"));
      setIsSetDate(false);
    }
  };
  // eslint-disable-next-line
  const getReports = (d1, d2) => {
    setShowLoader(true);
    if(venueDetail?.uuid){
    dispatch(
      getReport({
        venue_uuid: venueDetail?.uuid,
        Authorization: localStorage.AuthUserKey,
        from_date: moment(d1).format("YYYY-MM-DD HH:mm:SS").toString(),
        until_date: moment(d2).format("YYYY-MM-DD HH:mm:SS").toString(),
      })
    ).then(() => setShowLoader(false));
    }
  };
  const handleClearSelection = (e) => {
    e.stopPropagation();
    setStartDate1(moment().startOf("day"));
    setStartDate2(moment());
    setIsSetDate(false);
    dispatch(clearReports());
    setcustomGroup("Group0");
    //getReports(moment().startOf("day"), moment());
    // dispatch(getAllDaysReport({ venue_uuid: venueDetail?.uuid }));
  };
  useEffect(() => {
    let fixDate = fixedDates[1];

    let end1 = fixDate?.until_date;
    let start1 = fixDate?.from_date;
    setStartDate1(moment(start1));
    setStartDate2(moment(end1));
    getReports(moment(start1), moment(end1));
    setIsSetDate(true);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let fixDate = fixedDates[1];

    let end1 = fixDate?.until_date;
    let start1 = fixDate?.from_date;
    setStartDate1(moment(start1));
    setStartDate2(moment(end1));
    getReports(moment(start1), moment(end1));
    setIsSetDate(true);
    // eslint-disable-next-line
  }, [venueDetail?.uuid]);

  let local = {
    format: "DD-MM-YYYY HH:mm",
    sundayFirst: false,
    cancel: intl.formatMessage({
      id: "Close",
      defaultMessage: "Close",
    }),
  };

  const applyCallback = (startDate, endDate) => {
    setStartDate1(startDate);
    setStartDate2(endDate);
    getReports(startDate, endDate);
    setcustomGroup("Group0");
    setIsSetDate(true);
  };
  const formatTime = () => {
    try {
      let sDate = new Date(startDate1);
      let endDate = new Date(startDate2);

      return `${sDate?.getDate()}/${
        sDate?.getMonth() + 1
      }/${sDate?.getFullYear()} [${
        sDate?.getHours() < 10 ? "0" + sDate?.getHours() : sDate?.getHours()
      }:${
        sDate?.getMinutes() < 10
          ? "0" + sDate?.getMinutes()
          : sDate?.getMinutes()
      }] - ${endDate?.getDate()}/${
        endDate?.getMonth() + 1
      }/${endDate?.getFullYear()} [${
        endDate?.getHours() < 10
          ? "0" + endDate?.getHours()
          : endDate?.getHours()
      }:${
        endDate?.getMinutes() < 10
          ? "0" + endDate?.getMinutes()
          : endDate?.getMinutes()
      }]`;
    } catch {
      let sDate = startDate1;
      let endDate = startDate2;

      return `${sDate?.getDate()}/${
        sDate?.getMonth() + 1
      }/${sDate?.getFullYear()} [${
        sDate?.getHours() < 10 ? "0" + sDate?.getHours() : sDate?.getHours()
      }:${
        sDate?.getMinutes() < 10
          ? "0" + sDate?.getMinutes()
          : sDate?.getMinutes()
      }] - ${endDate?.getDate()}/${
        endDate?.getMonth() + 1
      }/${endDate?.getFullYear()} [${
        endDate?.getHours() < 10
          ? "0" + endDate?.getHours()
          : endDate?.getHours()
      }:${
        endDate?.getMinutes() < 10
          ? "0" + endDate?.getMinutes()
          : endDate?.getMinutes()
      }]`;
    }
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setStartDate1(moment().startOf("day"));
    setStartDate2(moment());
    setIsSetDate(false);
    setcustomGroup("Group0");
    //getReports(moment().startOf("day"), moment());
    dispatch(clearReports());
    // dispatch(getAllDaysReport({ venue_uuid: venueDetail?.uuid }));
  };
  const [openDropDown, setOpenDropDown] = React.useState(true);

  const handleCloseDropDown = () => {
    setOpen(false);
  };

  const handleOpenDropDown = () => {
    setOpen(false);
  };
  return (
    <div>
      {venueDetail && venueDetail.uuid !== null ? (
        <Grid container className={classes.root}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container justify="space-between">
              <Grid item xs={9} className={classes.GenralText}>
                {intl.formatMessage({
                  id: "Reports",
                  defaultMessage: "Reports",
                })}
              </Grid>
            </Grid>
            <Grid container spacing={2} justify={"space-between"}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className={classes.cssApply}
              >
                <div style={{ width: "100%" }}>
                  <p className={classes.textLabel}>
                    {intl.formatMessage({
                      id: "Custom_Dates",
                      defaultMessage: "Custom Dates",
                    })}
                  </p>
                  <DateTimeRangeContainer
                    ranges={[]}
                    start={startDate1}
                    end={startDate2}
                    local={local}
                    applyCallback={applyCallback}
                  >
                    <>
                      {isSetDate ? (
                        <Grid
                          container
                          className={classes.customTimePicker}
                          style={{
                            alignItems: "center",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                            }}
                          >
                            <img src={calendar} alt="calender" />
                            {/* <DateRangeIcon
                              style={{
                                paddingLeft: "10px",
                                color: "#303A5F",
                              }}
                            /> */}
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                              maxWidth: "70%",
                            }}
                          >
                            <Typography
                              className={classes.displayDate}
                              style={{ paddingLeft: "0px" }}
                            >
                              {new Date(startDate1) && isSetDate
                                ? formatTime()
                                : intl.formatMessage({
                                    id: "Select_date_and_time",
                                    defaultMessage: "Select date and time",
                                  })}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            direction="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // alignSelf: "flex-end",
                            }}
                          >
                            <CloseIcon
                              onClick={(e) => handleClose(e)}
                              style={{
                                color: "#303A5F",
                                width: "40px",
                                cursor: "pointer",
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          className={classes.customTimePicker}
                          style={{
                            justifyContent: " space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={classes.displayDate}>
                              {intl.formatMessage({
                                id: "Select_date_and_time",
                                defaultMessage: "Select date and time",
                              })}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              justifyContent: " space-between",
                              alignItems: "center",
                            }}
                          >
                            <ArrowDropDownIcon
                              style={{
                                color: "#303A5F",
                                paddingRight: "10px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </DateTimeRangeContainer>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <p className={classes.textLabel}>
                  {" "}
                  {intl.formatMessage({
                    id: "FIXED_DATES",
                    defaultMessage: "FIXED DATES",
                  })}
                </p>

                <TextField
                  id="filled-full-width"
                  variant="outlined"
                  fullWidth
                  value={customGroup}
                  onChange={handleChange}
                  select
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    border: "1px solid rgb(237, 240, 244)",
                  }}
                  open={openDropDown}
                  onClose={handleCloseDropDown}
                  onOpen={handleOpenDropDown}
                  inputProps={{
                    className: classes.OneTextInputStyle,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.outlinedStyle,
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        {customGroup !== "Group0" && (
                          <CloseIcon
                            style={{
                              color: "#303A5F",
                              width: "40px",
                              cursor: "pointer",
                            }}
                            onClick={handleClearSelection}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  className={
                    customGroup !== "Group0"
                      ? classes.hideIcon
                      : classes.showIcon
                  }
                >
                  {fixedDates.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={false} sm={false} md={4} lg={4} xl={4}></Grid>
            </Grid>
            {showLoader ? (
              <Grid
                container
                style={{
                  minHeight: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            ) : reportReducer ? (
              <Grid
                container
                style={{ paddingTop: "15px" }}
                spacing={2}
                justify={"space-between"}
              >
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <ReportCard
                    heading={intl.formatMessage({
                      id: "Paid",
                      defaultMessage: "Paid",
                    })}
                    price={reportReducer?.paid?.total}
                    subTotal={reportReducer?.paid?.subtotal}
                    tip={reportReducer?.paid?.tip}
                    orders={reportReducer?.paid?.orders}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <ReportCard
                    heading={intl.formatMessage({
                      id: "Not_paid",
                      defaultMessage: "Not paid",
                    })}
                    price={reportReducer?.unpaid?.total}
                    orders={reportReducer?.unpaid?.orders}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <ReportCard
                    heading={intl.formatMessage({
                      id: "Total",
                      defaultMessage: "Total",
                    })}
                    price={reportReducer?.total?.total}
                    orders={reportReducer?.total?.orders}
                    colors="#303A5F"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  minHeight: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.customLabel}>
                  {intl.formatMessage({
                    id: "Select_date_for_reports",
                    defaultMessage:
                      "Select Custom date or Fixed date to view Reports",
                  })}
                </Typography>
              </Grid>
            )}
            <div className={classes.TableGrid}></div>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center">
          <CircularProgress size={40} color="primary" />
        </Grid>
      )}
      <Grid item xs={1}></Grid>
    </div>
  );
}

export default Reports;
