import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

// const useStyles = makeStyles({
//   root: {
//     '&$checked': {
//       color: '#F66761',
//     },
//   },
//   checked: {},
  

  

// });

// // Inspired by blueprintjs
// export const  StyledCheckbox=(props)=> {
//   const classes = useStyles();

//   return (
//     <Checkbox
//       className={classes.root}
//       disableRipple
//       color="default"
//     //   icon={<span className={classes.icon} />}
//       inputProps={{ 'aria-label': 'decorative checkbox' }}
//       {...props}
//     />
//   );
// }

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#F66761',
    },
  },
  checked: {},
 })

 export const StyledCheckbox = withStyles(checkBoxStyles)(Checkbox);