import {
  GET_VENUE_TABLES,
  ADD_VENUE_TABLE,
  DELETE_VENUE_TABLE,
  UPDATE_VENUE_TABLE,
  ADD_TABLES,
} from "../constant/types";
import API from "../utils/API";

export const getVenueTables = ({ venue_uuid }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.get(`/api/business/venue/tables/`, config);
    let data = response?.data;
    console.log("response", response);
    dispatch({ type: GET_VENUE_TABLES, payload: data });

    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }
};
export const getVenueTablesNext = ({ venue_uuid, url }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.get(url, config);
    let data = response?.data;
    console.log("response", response);
    dispatch({ type: ADD_TABLES, payload: data });

    return 200;
  } catch (error) {
    console.log("res=========>error:", error);

    return 400;
  }
};
export const updateVenueTable = ({ venue_uuid, data, id }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    // const response = await API.get(
    //   `api/business/reports/revenue-summary/?from_date=${from_date}&until_date=${until_date}`,
    //   config
    // );
    // let data = response?.data;
    const response = await API.patch(
      `/api/business/venue/tables/${id}/`,
      data,
      config
    );
    dispatch({ type: UPDATE_VENUE_TABLE, payload: response?.data });

    return { status: 200 };
  } catch (error) {
    console.log("res=========>error:", error);

    return { status: 400 };
  }
};

export const addVenueTable = ({ venue_uuid, table_name, position }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.post(
      `api/business/venue/tables/`,
      { number: table_name, position: 1 },
      config
    );
    let data = response?.data;

    dispatch({ type: ADD_VENUE_TABLE, payload: data });
    return { status: 200 };
  } catch (error) {
    console.log("res=========>error:", error);

    return { status: 400 };
  }
};

export const deleteVenueTable = ({ venue_uuid, id }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      "venue-uuid": venue_uuid,
      Authorization: "Token " + localStorage.AuthUserKey,
    },
  };

  let body = {};

  try {
    const response = await API.delete(
      `/api/business/venue/tables/${id}/`,
      config
    );
    let data = response?.data;
    dispatch({ type: DELETE_VENUE_TABLE, payload: id });

    return { status: 200 };
  } catch (error) {
    console.log("res=========>error:", error);

    return { status: 400 };
  }
};
