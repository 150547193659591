import React from "react";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContentText from "@material-ui/core/DialogContentText";
import { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, Divider, DialogContent } from "@material-ui/core";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";
import "../style/home.css";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import {useDispatch} from "react-redux";
import { updateReviewOrderFromHistory } from "../actions/order";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  pendingOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    padding: " 0px",
    paddingLeft: "10px",
    margin: "auto",
    color: "#485379",
  },

  OrderGridInner: {
    padding: "30px 0px 20px 30px",
  },
  gridList: {
    width: 320,
    height: "100vh",
  },
  cardList: {
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "20px",
  },
  cardStyle: {
    marginTop: "28px",
    boxShadow: "none",
    borderRadius: "5px",
  },
  tableNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",

    textTransform: "uppercase",
    paddingLeft: "10px",
  },
  Accepted: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "10px  0px 0px 0px",
    margin: "auto",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  tickIcon: {
    color: "#FFFFFF",
    margin: "10px  0px 0px 3px",
    height: 15,
    width: 15,
  },

  TableDigit: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "18px",
    padding: " 0px",
    margin: "20px 0px 20px 10px",
    color: "#F66761",
  },
  orderIdCard: {
    borderRadius: "5px",
    width: "99px",
    margin: "30px 0px 20px 10px",
    boxShadow: "none",
  },
  orderId: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "5px 5px 5px 5px",
  },
  time: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    margin: "0px",
    color: "#303A5F",
    padding: "10px  0px 0px 0px",
  },
  loadingIcon: {
    width: "16px",
    height: "16px",
    padding: "10px  5px 0px 0px",
    color: "#303A5F",
  },
  SelectedOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    margin: "0px",
    color: "#303A5F",
    paddingTop: "10px",
  },
  PreviousOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "33px",
    margin: "0px",
    color: "#303A5F",
    padding: "0px 0px 30px 0px",
    display: "flex",
    justifyContent: "center",
  },
  outlinedStyle: {
    border: "0px",
  },
  printButton: {
    textTransform: "none",
    background: "#F66761",
    marginBottom: "22px",
    color: "#FFFFFF",
  },
  FullScreenIcon: {
    width: "25px",
    height: "20px",
    color: "#FFFFFF",
    margin: "10px 0px 10px 10px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px",
  },
  TextInputStyle1: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#fff",
    border: "0px",
    padding: "12px",
  },
  textLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    textTransform: "uppercase",

    color: "#303A5F",
    margin: "0px",
  },
  dishName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#F66761",
  },
  dishPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  DishListItem: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  OrderListFooter: {
    marginTop: "10px",
  },
  FotterPrices: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
    margin: "10px 0px",
  },
  Subtotal: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "18px",
    color: "#F66761",
    margin: "10px 0px 0px 0px",
  },
  CrossIcon: {
    cursor: "pointer",

    color: "#303A5F",
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight:" 20px",
    color: "#303A5F",
    borderRadius: 4,
    position: 'relative',
    background:"#FFFFFF",   
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: theme.fontFamily,
    '&:focus': {
      background:"#FFFFFF",   
      border: "1px solid #EDF0F4",
      boxSizing: "border-box",
      borderRadius: "5px",
    
    },
  },
}))(InputBase);
function PreviousOrderDialog(props) {
  const intl = useIntl();
  const { open, setOpen, OrderSelected } = props;
  const [age, setAge] = React.useState('');
const dispatch=useDispatch();
  const classes = useStyles();
  const [SelectedOrder, setSelectedOrder] = useState(OrderSelected);
  useEffect(() => {
    setSelectedOrder(OrderSelected);
    setAge(OrderSelected?.status)
  }, [OrderSelected]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
    dispatch(updateReviewOrderFromHistory({from:props?.from,Status:event.target.value,venue_uuid:props?.venue_uuid,order_uuid:OrderSelected?.order_uuid}))
  };
  return (
    <div>
      {SelectedOrder ? (
        <Dialog
          open={open}
          onClose={handleClose}
          style={{
            padding: "80px 150px 80px 150px",
            borderRadius: "5px",
          }}
          fullScreen={true}
        >
          <DialogContent className="dialogContext">
            <Grid item xs={12}>
              <Grid className={classes.OrderGridInner}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8} className={classes.PreviousOrder}>
                    {SelectedOrder?.submitted_for_review
                      ? moment(SelectedOrder?.submitted_for_review)
                          .format("HH:mm - dddd D MMMM")
                          .toString()
                      : intl.formatMessage({
                          id: "View_previous_order",
                          defaultMessage: "View previous order",
                        })}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "end" }}>
                    <ClearIcon
                      className={classes.CrossIcon}
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} justify={"space-between"}>
                  <Grid item xs={3}>
                    <p className={classes.textLabel}>
                      {intl.formatMessage({
                        id: "Table_number",
                        defaultMessage: "table number",
                      })}
                    </p>

                    <TextField
                      variant="outlined"
                      disabled
                      //  className={classes.fieldtext}
                      fullWidth
                      classes={{}}
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        border: "0px",
                      }}
                      value={
                        SelectedOrder.tab_uuid
                          ? `${SelectedOrder.table_number}`
                          : SelectedOrder.table_number
                      }
                      inputProps={{
                        // defaultValue: OrderSelected.table_number,
                        className: classes.TextInputStyle,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p className={classes.textLabel}>
                      {intl.formatMessage({
                        id: "Tab_name",
                        defaultMessage: "tab name",
                      })}
                    </p>

                    <TextField
                      variant="outlined"
                      disabled
                      //  className={classes.fieldtext}
                      fullWidth
                      classes={{}}
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        border: "0px",
                      }}
                      value={
                        SelectedOrder.tab_name
                          ? SelectedOrder.tab_name
                          : props?.tabName
                          ? props?.tabName
                          : intl.formatMessage({
                              id: "na",
                              defaultMessage: "n/a",
                            })
                      }
                      inputProps={{
                        // defaultValue: OrderSelected.table_number,
                        className: classes.TextInputStyle,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <p className={classes.textLabel} >
                      {intl.formatMessage({
                        id: "Order_ID",
                        defaultMessage: "order id",
                      })}
                    </p>

                    <TextField
                      variant="outlined"
                      disabled
                      //  className={classes.fieldtext}
                      value={SelectedOrder.reference_id}
                      fullWidth
                      classes={{}}
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        border: "0px",
                      }}
                      inputProps={{
                        className: classes.TextInputStyle,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <p className={classes.textLabel}>
                      {intl.formatMessage({
                        id: "Payment_Status",
                        defaultMessage: "payment Status",
                      })}
                    </p>

                    <TextField
                      variant="outlined"
                      disabled
                      //  className={classes.fieldtext}
                      fullWidth
                      classes={{}}
                      inputProps={{
                        className: classes.TextInputStyle1,
                      }}
                      style={{
                        background:
                          SelectedOrder.payment_method === "card"
                            ? "#F66761"
                            : "#6772E5",
                        borderRadius: "5px",
                        border: "0px",
                        color: "#FFFFFF",
                      }}
                      value={
                        SelectedOrder.payment_method === "card"
                          ? intl.formatMessage({
                              id: "Paid",
                              defaultMessage: "Paid",
                            })
                          : intl.formatMessage({
                              id: "Not_paid",
                              defaultMessage: "Not Paid",
                            })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <p className={classes.textLabel}>
                      {intl.formatMessage({
                        id: "order_status",
                        defaultMessage: "Order Status",
                      })}
                    </p>
                    <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={age}
          fullWidth
          onChange={handleChange}
          input={<BootstrapInput />}
        >
         
          <MenuItem value={3}>{intl.formatMessage({
                        id: "Accepted",
                        defaultMessage: "Order Status",
                      })}</MenuItem>
          <MenuItem value={4}>{intl.formatMessage({
                        id: "Rejected",
                        defaultMessage: "Order Status",
                      })}</MenuItem>
        </Select>
                  </Grid>
                
                </Grid>

                <Grid className={classes.OrderDetailList}>
                  {SelectedOrder &&
                    SelectedOrder.order_items &&
                    SelectedOrder.order_items.map((MenuList) => (
                      <div>
                        <Grid
                          container
                          justify={"space-between"}
                          style={{ margin: "20px 0px 15px 0px" }}
                        >
                          <Grid item className={classes.dishName}>
                            {MenuList.name} {MenuList?.price_description&&` - ${MenuList?.price_description}`} (x{MenuList.quantity})
                          </Grid>
                          <Grid item className={classes.dishPrice}>
                            {parseFloat(
                              MenuList.price * MenuList.quantity
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: props.currency,
                            })}
                          </Grid>
                        </Grid>

                        {MenuList.order_item_options &&
                          MenuList.order_item_options
                            ?.filter(
                              (item) => item?.display_as_main_item === false
                            )
                            .map((CustomMenu) => (
                              <Grid
                                container
                                justify={"space-between"}
                                style={{ margin: "10px 0px 10px 0px" }}
                              >
                                <Grid item className={classes.DishListItem}>
                                  {CustomMenu.name}
                                </Grid>
                                <Grid item className={classes.dishPrice}>
                                  {CustomMenu.price
                                    ? parseFloat(
                                        CustomMenu.price * MenuList.quantity
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: props.currency,
                                      })
                                    : ""}{" "}
                                </Grid>
                              </Grid>
                            ))}

                        {MenuList.note && (
                          <Grid>
                            <TextField
                              variant="outlined"
                              disabled
                              fullWidth
                              classes={{}}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                margin: "15px 0px",
                              }}
                              value={MenuList.note}
                              inputProps={{
                                // defaultValue: "Order Note",
                                className: classes.TextInputStyle,
                              }}
                            />
                          </Grid>
                        )}

                        {MenuList.order_item_options &&
                          MenuList.order_item_options
                            ?.filter(
                              (item) => item?.display_as_main_item === true
                            )
                            .map((CustomMenu) => (
                              <div>
                                <Divider style={{ background: "#3C476B" }} />

                                <Grid
                                  container
                                  justify={"space-between"}
                                  style={{ margin: "20px 0px 15px 0px" }}
                                >
                                  <Grid item className={classes.dishName}>
                                    {CustomMenu.name} (x{MenuList.quantity})
                                  </Grid>
                                  <Grid item className={classes.dishPrice}>
                                    {/* ${MenuList.price} */}
                                    {parseFloat(
                                      CustomMenu.price * MenuList.quantity
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: props.currency,
                                    })}
                                  </Grid>
                                </Grid>
                              </div>
                            ))}

                        {/* {MenuList.note && (
                          <Grid>
                            <TextField
                               
                              variant="outlined"
                              disabled
                              fullWidth
                              classes={{}}
                              style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                margin: "15px 0px",
                              }}
                              value={MenuList.note}
                              inputProps={{
                                // defaultValue: "Order Note",
                                className: classes.TextInputStyle,
                              }}
                            />
                          </Grid>
                        )} */}

                        <Divider style={{ background: "#3C476B" }} />
                      </div>
                    ))}
                </Grid>

                {/*                 
                <Grid className={classes.OrderDetailList}>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "20px 0px 15px 0px" }}
                  >
                    <Grid item className={classes.dishName}>
                    {MenuList.name} (x{MenuList.quantity})
                    </Grid>
                    <Grid item className={classes.dishPrice}>
                      $5.50
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      Rocket leaves{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      shrimp
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      lemon{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      almonds{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      shrimp cocktail sauce{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify={"space-between"}
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Grid item className={classes.DishListItem}>
                      Extra bread{" "}
                    </Grid>
                    <Grid item className={classes.dishPrice}>
                      $0.50
                    </Grid>
                  </Grid>
                  <Divider style={{ background: "#3C476B" }} />

                  <Divider style={{ background: "#3C476B" }} />
                </Grid>
               
                */}

                <Grid className={classes.OrderListFooter}>
                  {OrderSelected.note && (
                    <Grid>
                      <p className={classes.SelectedOrder}>
                      {intl.formatMessage({
                          id: "Order_Note",
                          defaultMessage: "Order Note",
                        })}
                        </p>
                      <TextField
                        variant="outlined"
                        //  placeholder="Month"
                        //  className={classes.fieldtext}
                        disabled
                        fullWidth
                        classes={{}}
                        style={{
                          background: "#FFFFFF",
                          borderRadius: "5px",
                          margin: "15px 0px",
                        }}
                        value={OrderSelected.note}
                        inputProps={{
                          // defaultValue: "Order Note",
                          className: classes.TextInputStyle,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid container>
                    <Grid xs={8}></Grid>
                    <Grid xs={4}>
                      <Grid container justify="space-between">
                        <p className={classes.FotterPrices}>
                        {intl.formatMessage({
                          id: "Subtotal",
                          defaultMessage: "Subtotal",
                        })}
                          </p>
                        <p className={classes.Subtotal}>
                          {parseFloat(
                            parseFloat(OrderSelected.order_price) -
                              parseFloat(OrderSelected.tip_amount)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: props.currency,
                          })}
                        </p>
                      </Grid>
                      <Grid container justify="space-between">
                        <p className={classes.FotterPrices}>Tip</p>
                        <p className={classes.FotterPrices}>
                          {parseFloat(OrderSelected.tip_amount).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: props.currency,
                            }
                          )}
                        </p>
                      </Grid>
                      <Grid container justify="space-between">
                        <p className={classes.FotterPrices}>{intl.formatMessage({
                          id: "Total",
                          defaultMessage: "Total",
                        })}</p>
                        <p className={classes.FotterPrices}>
                          {OrderSelected.order_price
                            ? parseFloat(
                                parseFloat(OrderSelected.order_price)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: props.currency,
                              })
                            : ""}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}

export default PreviousOrderDialog;
