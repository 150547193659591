import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import indexReducer from "../reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducer = persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    whitelist: ["VenueOrderReducer", "hotelDetailsReducer","languageReducer"],
  },
  combineReducers({ ...indexReducer })
);
const middleWare = [thunk];

const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleWare))
  );
  return {
    persistor: persistStore(store),
    store,
  };
};
const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
