/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@material-ui/core";

import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { ReactComponent as RightIcon } from "../../../../../assets/image/right.svg";
import { useSelector } from "react-redux";
export default function CustomizedHook(props) {
  const classes = useStyles();
  const intl = useIntl();
 
  const [item, setItem] = useState({});
  const [itemValue, setItemValue] = useState("");

  
  useEffect(() => {
    setItem({...props?.data});
  if(props?.data&&props?.data?.translations[0]?.text){
    setItemValue(props?.data?.translations[0]?.text)
  }else{
    setItemValue("")
  }
    // eslint-disable-next-line
  }, [props, props?.data?.id,props?.data&&props?.data?.translations[0]?.text]);

  

  const handleChangeTagData = (e) => {
    setItemValue(e.target.value)
    props.handleChangeTags(e, props.index);
  };
  return (
    <Grid container >
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          width: "28px",
          justifyContent: "center",
          marginBottom: "10px",
          backgroundColor: item?.colour?.bg_hex,
          borderRadius: "5px",
          marginRight: "10px",
        }}
      >
        <img
          src={item?.image?.image}
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            height: "20px",
            width: "20px",
            background: "",
          }}
        />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <TextField
          variant="outlined"
          placeholder={intl.formatMessage({
            id: "Item_name",
          })}
          disabled={true}
          fullWidth
          value={item?.name}
          style={{
            borderRadius: "5px",
            width: "100%",
          }}
          inputProps={{
            className: classes.TextInputStyleDisabled,
            "aria-label": "search",
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.outlinedStyle,
            },
          }}
        />
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          width: "28px",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <RightIcon />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <TextField
          variant="outlined"
          placeholder={item?.name?item?.name:intl.formatMessage({
            id: "Item_name",
          })}
          onChange={(e) => handleChangeTagData(e)}
          fullWidth
          // error={itemDetailsErrors?.name}
          // helperText={
          //   itemDetailsErrors?.name &&
          //   intl.formatMessage({
          //     id: "Item_Name_is_required",
          //   })
          // }
          // onChange={(e) => handleChangeDetails(e, "name")}
          value={itemValue}
          style={{
            borderRadius: "5px",
            width: "100%",
          }}
          inputProps={{
            className: classes.TextInputStyle,
            "aria-label": "search",
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.outlinedStyle,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  TextInputStyleDisabled: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    border: "0px",
    padding: "10px",
    color: "#A3A9BC",
    background: "#F9FAFC",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outlinedStyle: {
    border: "0px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginTop: "30px",
  },
  heading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: "#F66761",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },

  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#A3A9BC",
    paddingRight: "20px",
  },
  subHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#A3A9BC",
  },
  requiredDiv: {
    width: "77px",
    height: "24px",
    background: "#F66761",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  requiredText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));
