import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { getMenuDetails } from "../../../../actions/menu";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddImage from "../AddImage";
import CustomDropDown from "../CustomDropDown";
import DragnDropDisplayCustomization from "./DragnDropDisplayCustomization";
import Tags from "./Tags";
import {
  TextButton,
  TextButtonAdd,
  TextButtonWhite,
} from "../../../Buttons/Buttons";
import {
  deleteItem,
  addItem,
  updateItem,
  getCustomizationList,
  getAlltags,
} from "../../../../actions/Localization";
import DeleteItem from "../DeleteItem";
import TagsModal from "../TagsModal";
import ItemCustomization from "../../ItemCustomization";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../ckEditor.css";
import PricesListForm from "../PricesListForm";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useIntl } from "react-intl";
import { ReactComponent as RightIcon } from "../../../../assets/image/right.svg";
import PriceDescriptions from "./PriceDescriptions";
function ItemDetails(props) {
  let dispatch = useDispatch();
  const intl = useIntl();
  let languageReducer = useSelector((state) => state?.languageReducer);

  const [image, setImage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatingType, setUpdatingType] = useState("");
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [pricesList, setPricesList] = useState([]);
  const [pricesErrorsList, setPricesErrorsList] = useState([]);
  const [currency, setCurrency] = useState("EUR");
  const [note, setNote] = useState(false);
  const [newTag, setNewTag] = useState(null);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [priceDescription, setPriceDescription] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [priceDescriptionError, setPriceDescriptionError] = useState(false);
  let venueDetail = useSelector((state) => state.VenueOrderReducer.VenueDetail);
  let menuCustomization = useSelector(
    (state) => state.menuReducer?.menuCustomization
  );

  let allTags = useSelector((state) => state.menuReducer?.allTags);
  useEffect(() => {
    setCurrency(venueDetail?.currency ? venueDetail?.currency : "EUR");
  }, [venueDetail]);
  const selectedFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const classes = useStyles();
  const [item, setItem] = useState({});
  const [itemDetail, setItemDetail] = useState({ price: 0 });
  const [itemDetailsErrors, setItemDetailsErrors] = useState({});
  useEffect(() => {
    setMainLoader(true);
    setTimeout(() => {
      setMainLoader(false);
    }, 500);
  }, [item?.item_id]);
  useEffect(() => {
    if (props.items) {
      props.items &&
        props.items.map((p) => {
          if (props?.selectedMenuId === p.item_id) {
            setItem(p);
          }
        });
    } else {
      setItem({});
      setPricesList([]);
      setPricesErrorsList([]);
      setPriceDescription("");
      setPrice("");
    }
    // eslint-disable-next-line
  }, [props]);
  useEffect(() => {
    let Obj = {
      name: item?.name ? item?.name : "",
      description: item?.description ? item?.description : "",
      price: item?.price ? item?.price : 0,
      item_customisations: item?.item_customisations
        ? item?.item_customisations
        : "",
      tags: item?.tags,
    };

    let IDesc = item?.translations?.findIndex(
      (i) => i?.field === "description"
    );
    if (IDesc > -1) {
      setDescription(item?.translations[IDesc]?.text);
    } else {
      setDescription("");
    }
    let IName = item?.translations?.findIndex((i) => i?.field === "name");
    if (IName > -1) {
      Obj.name = item?.translations[IName]?.text;
    } else {
      Obj.name = "";
    }
    setItemDetail({ ...Obj });
    if (item?.prices?.length > 0) {
      setPriceDescription(item?.prices[0]?.description);
      setPrice(item?.prices[0]?.amount);
      let newArr = [];
      let errorsPrice = [];
      item.prices.forEach((item, index) => {
        let itm = { ...item };
        if (itm.translations.length === 0) {
          itm.translations = [
            {
              text: "",
              field: "description",
              language: "",
            },
          ];
        }
        newArr.push(itm);
        errorsPrice.push({ amount: false, description: false, id: item?.id });
      });
      setPricesList(item?.prices ? [...newArr] : []);
      setPricesErrorsList([...errorsPrice]);
    }

    setItemDetailsErrors({
      name: false,
      description: false,
      price: false,
      item_customisations: false,
      tags: false,
    });
    setTagsList(item?.tags ? item?.tags : []);
    setNote(item?.note ? item?.note : false);
    setImage(item?.image ? item?.image : "");
    // eslint-disable-next-line
  }, [item, item?.prices, item?.tags, item?.item_customisations]);

  const updateItemsCustomization = (itemCust) => {
    let arr = [...itemDetail?.item_customisations];
    let ind = arr.findIndex(
      (n) => n?.item_customisation_id === itemCust?.item_customisation_id
    );

    if (ind < 0) {
      arr.push(itemCust);
      setItemDetail({
        name: itemDetail?.name ? itemDetail?.name : "",
        description: itemDetail?.description ? itemDetail?.description : "",
        price: itemDetail?.price ? itemDetail?.price : 0,
        item_customisations: arr,
        tags: itemDetail?.tags,
        tax_rate: itemDetail?.tax_rate ? itemDetail?.tax_rate : 0,
      });
      setDescription(itemDetail?.description ? itemDetail?.description : "");
    }
  };

  useEffect(() => {
    dispatch(getCustomizationList());
    dispatch(getAlltags());
    // eslint-disable-next-line
  }, []);
  const handleChange = (event, key) => {};
  const handleDeleteItem = () => {
    setOpenDeleteModal(true);
  };
  const confirmDelete = async () => {
    setLoading(true);
    setUpdatingType("delete");
    let res = await dispatch(
      deleteItem({
        id: item?.item_id,
        menuId: props?.menuId,
        categoryId: props?.categoryId,
      })
    );
    if (res === 200) {
      return props?.handleRemoveItem();
    }
    setLoading(false);
    setUpdatingType("");
  };
  const [editTag, setEditTag] = useState(null);
  const handleOpenTagsModal = () => {
    setOpenTagsModal(true);
  };
  const handleCloseTagsModal = () => {
    setOpenTagsModal(false);
    setEditTag(null);
  };
  const handleCloseAndAdd = (tag) => {
    setOpenTagsModal(false);
    setEditTag(null);
    setNewTag(tag);
  };

  const handleClearNewTag = () => {
    setNewTag("");
  };
  const handleEditTagsModal = (tag) => {
    setOpenTagsModal(true);
    setEditTag(tag);
  };

  const [editCustmization, setEditCustmization] = useState(null);
  const [openCustmizationModal, setOpenCustmizationModal] = useState(false);
  const handleOpenCustmizationModal = () => {
    setOpenCustmizationModal(true);
  };
  const handleCloseCustmizationModal = () => {
    setOpenCustmizationModal(false);
    setEditCustmization(null);
  };
  const handleCloseCustmizationModalDelete = () => {
    dispatch(getCustomizationList());
    //get customizations list again here
    setOpenCustmizationModal(false);
    setEditCustmization(null);
    setItemDetail({
      ...itemDetail,
      item_customisations: item?.item_customisations
        ? item?.item_customisations
        : "",
    });
  };
  const handleCloseCustmizationModalDeleteById = (id) => {
    setOpenCustmizationModal(false);
    setEditCustmization(null);
    let custArr = itemDetail?.item_customisations;

    const custArrFiltered = custArr.filter(
      (element) => element.item_customisation_id !== id
    );
    setItemDetail({
      ...itemDetail,
      item_customisations: custArrFiltered,
    });
  };
  const handleEditCustmizationModal = (cust) => {
    setOpenCustmizationModal(true);
    setEditCustmization(cust);
  };
  const handleChangeDetails = (e, type) => {
    switch (type) {
      case "prices":
        setItemDetail({
          ...itemDetail,
          prices: [...e],
        });
        break;
      case "price":
        setItemDetail({
          name: itemDetail?.name ? itemDetail?.name : "",
          description: itemDetail?.description ? itemDetail?.description : "",
          price: e.target.value > -1 ? e.target.value : itemDetail?.price,
          item_customisations: itemDetail?.item_customisations
            ? itemDetail?.item_customisations
            : [],
          tags: itemDetail?.tags ? itemDetail?.tags : [],
          tax_rate: itemDetail?.tax_rate ? itemDetail?.tax_rate : 0,
        });
        let n = { ...itemDetailsErrors };
        n["price"] = false;
        setItemDetailsErrors(n);
        break;
      case "description":
        setDescription(e);
        break;
      case "name":
        setItemDetail({
          name: e.target.value,
          description: itemDetail?.description ? itemDetail?.description : "",
          price: itemDetail?.price ? itemDetail?.price : 0,
          item_customisations: itemDetail?.item_customisations
            ? itemDetail?.item_customisations
            : [],
          tags: itemDetail?.tags ? itemDetail?.tags : [],
          tax_rate: itemDetail?.tax_rate ? itemDetail?.tax_rate : 0,
        });
        let er = { ...itemDetailsErrors };
        er["name"] = false;
        setItemDetailsErrors(er);
        break;
      case "tax_rate":
        setItemDetail({
          name: itemDetail?.name ? itemDetail?.name : "",
          description: itemDetail?.description ? itemDetail?.description : "",
          price: itemDetail?.price ? itemDetail?.price : 0,
          item_customisations: itemDetail?.item_customisations
            ? itemDetail?.item_customisations
            : [],
          tags: itemDetail?.tags ? itemDetail?.tags : [],
          tax_rate:
            e.target.value < 100 && e.target.value > -1
              ? e.target.value
              : itemDetail?.tax_rate,
        });
        break;

      default:
        break;
    }
  };
  const checkError = () => {
    let tempValid = false;
    if (price == "" || price === null || price === undefined) {
      setPriceError(true);
      tempValid = true;
    }
    if (pricesList.length > 0) {
      if (priceDescription === "") {
        setPriceDescriptionError(true);
        tempValid = true;
      }
      let pricesErrors = [...pricesErrorsList];
      pricesList.forEach((element, index) => {
        if (
          element.amount == "" ||
          element.amount === null ||
          element.amount === undefined
        ) {
          tempValid = true;
          pricesErrors[index].amount = true;
        }
        if (element.description == "") {
          pricesErrors[index].description = true;
          tempValid = true;
        }
      });
      setPricesErrorsList([...pricesErrors]);
    }
    return tempValid;
  };
  const updatePricesError = () => {
    if (pricesList.length < 2) {
      setPriceDescriptionError(false);
    }
  };
  const handleAddItem = async () => {
    setLoading(true);
    setUpdatingType("add");

    let formData = new FormData();

    formData.append("name", itemDetail?.name);
    if (description) {
      formData.append("description", description);
    }

    let res = "";
    if (item?.item_id) {
      res = await dispatch(
        updateItem({
          id: item?.item_id,
          language: languageReducer?.menuManagerLanguage,
          menuId: props?.menuId,
          categoryId: props?.categoryId,
          data: formData,
          data2: itemDetail?.item_customisations,
          tagsList: tagsList,
          pricesList: [...pricesList],
        })
      );
    } else {
      formData.append("menu_category", props?.categoryId);

      res = await dispatch(
        addItem({
          menuId: props?.menuId,
          categoryId: props?.categoryId,
          data: formData,
          data2: itemDetail?.item_customisations,
          tagsList: tagsList,
          pricesList: [
            { amount: price, description: priceDescription },
            ...pricesList,
          ],
        })
      );
    }
    dispatch(getCustomizationList());
    if (!item?.item_id && res?.status === 200) {
      setLoading(false);
      setUpdatingType("");
      return props?.HandleReopenItem(res?.id);
    }

    setLoading(false);
    setUpdatingType("");
  };

  const handleCheckBoxUpdate = (e) => {
    setNote(e.target.checked);
  };
  const handleChangeCustomizationOption = (
    customizationIndex,
    optionIndex,
    value
  ) => {
    let cust = [...itemDetail?.item_customisations];
    let selectedCust = cust[customizationIndex];
    let selectedOpt = selectedCust.customisation_options[optionIndex];
    selectedOpt = {
      ...selectedOpt,
      translations: [
        {
          text: value,
          field: "name",
          language: languageReducer?.menuManagerLanguage,
        },
      ],
    };
    selectedCust.customisation_options[optionIndex] = { ...selectedOpt };
    cust[customizationIndex] = { ...selectedCust };
    setItemDetail({ ...itemDetail, item_customisations: [...cust] });
  };
  const handleChangeCustomizationName = (
    customizationIndex,
    value
  ) => {
    let cust = [...itemDetail?.item_customisations];
    let selectedCust = cust[customizationIndex];
    selectedCust={...selectedCust,translations: [
          {
            text: value,
            field: "name",
            language: languageReducer?.menuManagerLanguage,
          },
        ]}

    // let selectedOpt = selectedCust.customisation_options[optionIndex];
    // selectedOpt = {
    //   ...selectedOpt,
    //   translations: [
    //     {
    //       text: value,
    //       field: "name",
    //       language: languageReducer?.menuManagerLanguage,
    //     },
    //   ],
    // };
    // selectedCust.customisation_options[optionIndex] = { ...selectedOpt };
    cust[customizationIndex] = { ...selectedCust };
  setItemDetail({ ...itemDetail, item_customisations: [...cust] });
  };

  return (
    <>
      {mainLoader ? (
        <Grid
          container
          style={{
            backgroundColor: "#f9fafc",
            minHeight: "82vh",
            maxHeight: `${
              (parseInt(window?.innerHeight) - 10,
              parseInt(document.getElementById("tabBar")?.clientHeight) -
                parseInt(document.getElementById("root2")?.clientHeight))
            }px`,
            paddingLeft: "10px",
            paddingRight: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} />
        </Grid>
      ) : (
        <Grid
          container
          style={{
            backgroundColor: "#f9fafc",
            minHeight: "82vh",
            maxHeight: `${
              (parseInt(window?.innerHeight) - 10,
              parseInt(document.getElementById("tabBar")?.clientHeight) -
                parseInt(document.getElementById("root2")?.clientHeight))
            }px`,
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid container className={classes.headerDiv}>
            <Typography className={classes.headingTestName}>
              {item?.name}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.headingTest}
                style={{ paddingBottom: "0px", marginBottom: "10px" }}
              >
                {intl.formatMessage({
                  id: "Item_name",
                })}
              </Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "Item_name",
                })}
                fullWidth
                error={itemDetailsErrors?.name}
                helperText={
                  itemDetailsErrors?.name &&
                  intl.formatMessage({
                    id: "Item_Name_is_required",
                  })
                }
                disabled={true}
                onChange={(e) => handleChangeDetails(e, "name")}
                value={item?.name}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle3Disabled,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                width: "28px",
                justifyContent: "center",
                height: "40px",
              }}
            >
              <RightIcon />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                variant="outlined"
                placeholder={
                  item?.name
                    ? item?.name
                    : intl.formatMessage({
                        id: "Item_name",
                      })
                }
                fullWidth
                error={itemDetailsErrors?.name}
                helperText={
                  itemDetailsErrors?.name &&
                  intl.formatMessage({
                    id: "Item_Name_is_required",
                  })
                }
                onChange={(e) => handleChangeDetails(e, "name")}
                value={itemDetail?.name}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                  "aria-label": "search",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </Grid>
          </Grid>
         {item?.description&&item?.description!==""&& <Grid container style={{ minHeight: "40px", marginTop: "20px" }}>
            <Grid item xs={12}>
              <Typography
                className={classes.headingTest}
                style={{ paddingBottom: "0px", marginBottom: "10px" }}
              >
                {intl.formatMessage({
                  id: "Description",
                })}
              </Typography>
            </Grid>
          <Grid container 
          // justifyContent="center" 
          
          style={{
            display: "grid",
            gridTemplateColumns: `calc(50% - 14px) 28px  calc(50% - 14px)`,
           
          }}
         
          >

            <Grid item  
            
            
            style={{ flexGrow: 1, }}>
              <div
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "Description",
                })}
                fullWidth
                multiline
                rows={3}
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  border: "1px solid #EDF0F4",
                  backgroundColor: "#fff",
                  height: "89px",
                  overflowX: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: item?.description }}
                type="text"
                disabled={true}
                className={classes.TextInputStyle3Disabled}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                width: "28px",
                justifyContent: "center",
                height: "89px",
              }}
            >
              <RightIcon />
            </Grid>
            <Grid item  style={{ flexGrow: 1}}>
              <CKEditor
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor && editor.data && editor.data.set(description);
                }}
                config={{
                  toolbar: [],
                  placeholder: item?.description
                    ?.replace(/(<([^>]+)>)/gi, " ")
                    .replace("&nbsp;", " "),
                }}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeDetails(data, "description");
                }}
              />
            </Grid>
          </Grid>
          </Grid>}
          <PriceDescriptions
            pricesList={pricesList}
            setPricesList={setPricesList}
            pricesErrorsList={pricesErrorsList}
          />

          {itemDetail?.item_customisations?.length > 0 && (
            <Grid container style={{ marginTop: "30px" }}>
              <Grid xs={12}>
                <Typography
                  className={classes.headingTest}
                  style={{ paddingBottom: "0px",
                  paddingTop:"0px"}}
                >
                  {intl.formatMessage({
                    id: "Customization",
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
          {itemDetail?.item_customisations?.length > 0 && (
            <Grid
              container
              className={classes.itemCustomizationDiv}
              style={{ paddingTop: "0px" }}
            >
              <DragnDropDisplayCustomization
                item_customisations={itemDetail?.item_customisations}
                handleEditCustmizationModal={handleEditCustmizationModal}
                handleChangeCustomizationOption={
                  handleChangeCustomizationOption
                }
                item={{
                  itemId: item?.item_id,
                  menuId: props?.menuId,
                  categoryId: props?.categoryId,
                }}
                handleChangeCustomizationName={handleChangeCustomizationName}
              />
            </Grid>
          )}
          <Grid
            container
            className={classes.itemCustomizationDiv}
            style={{ marginBottom: "30px", marginTop: "20px" }}
          >
            <Grid item xs={12}>
              {
                <Tags
                  oldTags={tagsList}
                  item={{
                    itemId: item?.item_id,
                    menuId: props?.menuId,
                    categoryId: props?.categoryId,
                  }}
                  handleClearNewTag={handleClearNewTag}
                  newAddedTag={newTag}
                  setTagsToItem={setTagsList}
                  tags={allTags}
                  handleOpenTagsModal={handleOpenTagsModal}
                  handleEditTagsModal={handleEditTagsModal}
                />
              }
            </Grid>
          </Grid>
          <Grid container style={{ height: "60px" }}></Grid>
          <Grid
            container
            className={classes.itemCustomizationDiv}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              width: "41.666667%",
              position: "fixed",
              bottom: 0,
              paddingRight: " 30px",
              backgroundColor: "#f9fafc",
              maxHeight: "60px",
              minWidth: `${
                document.getElementById("itemDetails2")?.clientWidth
                  ? parseInt(
                      document.getElementById("itemDetails2")?.clientWidth
                    )
                  : document.getElementById("itemDetails1")?.clientWidth
                  ? parseInt(
                      document.getElementById("itemDetails1")?.clientWidth
                    )
                  : ""
              }px`,
            }}
          >
            <Grid item> </Grid>
            <Grid item>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <TextButtonAdd disabled={loading} onClick={handleAddItem}>
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    ) : (
                      intl.formatMessage({
                        id: "Save",
                      })
                    )}
                  </TextButtonAdd>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
}

export default ItemDetails;

const useStyles = makeStyles((theme) => ({
  TextInputStyle3Disabled: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#A3A9BC",
    border: "0px",
    minWidth: "150px",
    background: "#F9FAFC !important",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  errorMessage: {
    marginLeft: "0px",
    marginRight: "0px",
    "&.MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#FF3366",
  },
  appBar: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  itemCustomizationDiv: {
    padding: "10px 0px",
  },
  headerDiv: {
    padding: "15px 0px 10px 0px",
  },
  labelAddButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#303A5F",
  },
  checkBoxLabel: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px !important",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    "& .MuiCheckbox-colorSecondary.Mui-checked": { color: "#F66761" },
    "& .MuiTypography-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px !important",
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      color: "#303A5F",
    },
  },
  outlinedStyle: {
    border: "0px",
  },
  startIcon: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
      background: "#EDF0F4",
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart ": {
      margin: 0,
      minWidth: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  overRideCkEditorStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    minHeight: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    width: "100%",
    minWidth: "150px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    "& .ck.ck-editor__main>.ck-editor__editable": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      minHeight: "40px",
      lineHeight: "18px",
      color: "#303A5F",
      border: "0px",
      width: "100%",
      minWidth: "150px",
      background: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "5px",
    },
  },
  TextAreaStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    minHeight: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    width: "100%",
    minWidth: "150px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  testArea: {
    "& .MuiOutlinedInput-multiline": {
      padding: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  TextInputStylePrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  TextInputStyle3: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    minWidth: "150px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    paddingLeft: "5px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    minWidth: "150px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    height: "40px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "10px",
    // minWidth: "350px",
    background: "#FFFFFF",
    border: "1px solid #EDF0F4",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
  menuItemSelected: {
    height: "60px",
    background: "#F66761",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  headingTestName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  headingTest: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "#303A5F",
    padding: "10px 0px 5px 0px",
  },
  customScrollBar: {
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#EDF0F4",
      width: "5px", //for horizontal scrollbar
      height: "5px",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#EDF0F4",
    },
    "&::-webkit-scrollbar-button": {
      width: "50px", //for horizontal scrollbar
      height: "70vh", //for vertical scrollbar
    },
  },
}));
