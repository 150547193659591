import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const TextButton = withStyles((theme) => ({

  root: {
    background: "#FF3366",
    borderRadius: "5px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#FFFFFF",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "#FF3366",
    },
  },
}))(Button);

export const TextButtonAdd = withStyles((theme) => ({
  root: {
    background: "#34CEB2",
    borderRadius: "5px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#FFFFFF",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "#34CEB2",
    },
  },
}))(Button);
export const TextButtonWhite = withStyles((theme) => ({
  root: {
    background: "#F9FAFC",
    borderRadius: "5px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#303A5F",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "#F9FAFC",
    },
  },
}))(Button);
export const TextButtonNoColor = withStyles((theme) => ({
  root: {
    background: "#fff",
    borderRadius: "5px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#303A5F",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "#fff",
    },
  },
}))(Button);

export const TextButtonBorder = withStyles((theme) => ({
  root: {
    background: "#fff",
    borderRadius: "5px",
     fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#A3A9BC",
    border:"1px solid #A3A9BC",
    "&:hover": {
      textTransform: "none",
      backgroundColor: "#fff",
    },
  },
}))(Button);
